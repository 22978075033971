import createDataContext from '../../utils/createDataContext';
import {
  getTokensFromLocalStorage,
  setTokensToLocalStorage,
  removeTokensFromLocalStorage,
} from './services/localStorage';

const initialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isLoggedIn: false,
};

function restoreStateFromLocalStorage(state) {
  const {accessToken, refreshToken, rememberMe} = getTokensFromLocalStorage();
  if (accessToken) {
    return {
      ...state,
      accessToken,
      refreshToken,
      rememberMe,
      isLoggedIn: true,
    };
  }

  return initialState;
}

function loginReducer(state, action) {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        rememberMe: action.payload.rememberMe,
        isLoggedIn: true,
      };
    case 'setAccountData':
      return {
        ...state,
        user: action.payload,
      };
    case 'updateTokens': {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    }
    case 'logout':
      return initialState;
    default:
      throw new Error(`invalid message type:${action.type}`);
  }
}

// TODO when doing refactor with Typescript, remove action creators to use dispatch only
function login(dispatch) {
  return function (accessToken, refreshToken, rememberMe) {
    if (accessToken && refreshToken) {
      setTokensToLocalStorage({
        accessToken,
        rememberMe,
        refreshToken,
      });
      dispatch({
        type: 'login',
        payload: {
          accessToken,
          refreshToken,
          rememberMe,
        },
      });
    } else {
      throw new Error('Unable to login at this moment. Please try again later.');
    }
  };
}

function logout(dispatch) {
  return function () {
    removeTokensFromLocalStorage();
    dispatch({type: 'logout'});
  };
}

function setAccountData(dispatch) {
  return function (data) {
    dispatch({type: 'setAccountData', payload: data});
  };
}

function updateTokens(dispatch) {
  return function (accessToken, refreshToken, rememberMe) {
    setTokensToLocalStorage({
      accessToken,
      rememberMe,
      refreshToken,
    });
    dispatch({
      type: 'updateTokens',
      payload: {
        accessToken,
        refreshToken,
      },
    });
  };
}

export const {Context, Provider} = createDataContext(
  loginReducer,
  {
    login,
    logout,
    setAccountData,
    updateTokens,
  },
  initialState,
  restoreStateFromLocalStorage
);
