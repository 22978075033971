import {useState} from 'react';
import SendTestEmail from './SendTestEmail';
import useSendTestEmail from '../hooks/useSendTestEmail';
import {handleError} from '../../../utils/handleError';
import useAuth from '../../auth/useAuth';

function SendTestEmailContainer({onRequestClose, newsletterID}) {
  const {state: authState} = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [sentEmail, setSentEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {sendTestEmail} = useSendTestEmail(newsletterID);

  const handleFormSubmit = async (values, setFormError) => {
    setErrorMessage('');
    try {
      await sendTestEmail(values);
      setSentEmail(values.email);
      setIsSubmitted(true);
    } catch (error) {
      handleError(error, setErrorMessage, setFormError);
    }
  };

  return (
    <SendTestEmail
      errorMessage={errorMessage}
      isSubmitted={isSubmitted}
      defaultEmail={authState.user.email}
      sentEmail={sentEmail}
      onRequestClose={onRequestClose}
      onSubmit={handleFormSubmit}
    />
  );
}

export default SendTestEmailContainer;
