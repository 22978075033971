import {Switch, Route} from 'react-router-dom';
import PageNotFound from '../../Router/PageNotFound';
import ROUTES from '../../Router/routes';
import {AutomationProvider} from './stores/automation';
import AutomationOverview from './AutomationOverview';
import AutomationPage from './Automation/AutomationPage';

function AutomationsRouter() {
  return (
    <AutomationProvider>
      <Switch>
        <Route path={ROUTES.automation.createAutomation}>
          <AutomationPage />
        </Route>
        <Route path={ROUTES.automation.index} exact>
          <AutomationOverview />
        </Route>

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </AutomationProvider>
  );
}

export default AutomationsRouter;
