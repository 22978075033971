import React, {useState} from 'react';
import {getComponentStyle, getTextStyles, stringToOption} from '../../service';

import IconTrash from '../../../../img/trash-red.png';
import {SubscriptionFormBuilderContext} from '../../context';
import clsx from 'clsx';
import {removeField} from '../../context/actions';
import styles from './FormPreview.module.scss';
import {useEnrichedField} from '../../hooks/useEnrichedField';

const FormField = ({field, previewMode = false}) => {
  const {setSelectedElement, formState, selectedElement, dispatch} = React.useContext(
    SubscriptionFormBuilderContext
  );

  const [fieldValue, setFieldValue] = useState();
  const [fieldDate, setFieldDate] = useState();

  const enrichedField = useEnrichedField(field);

  const handleSetSelectedField = React.useCallback(() => {
    if (previewMode) {
      return;
    }

    setSelectedElement(`field.${field.name}`);
  }, [setSelectedElement, previewMode, field]);

  const removeItem = React.useCallback(
    (fieldName) => {
      dispatch(removeField(fieldName));
    },
    [dispatch]
  );

  if (!enrichedField) {
    return null;
  }

  if (!enrichedField.optionValues?.length) {
    return (
      <div>
        {selectedElement === `field.${field.name}` && field.name !== 'email' && !previewMode && (
          <div className={styles.removeField} onClick={() => removeItem(field.name)}>
            <img src={IconTrash} alt="Remove field" />
          </div>
        )}
        <label
          className={clsx({
            'md-embedded-form--input-wrapper': true,
            [styles.selectableFrame]: !previewMode,
            [styles.selectableFrameActive]:
              selectedElement === `field.${field.name}` && !previewMode,
          })}
        >
          <div
            style={getTextStyles(formState.jsonConfig.body.inputFieldStyle.label)}
            className="md-embedded-form--input-title"
            onClick={handleSetSelectedField}
          >
            {enrichedField.label}
          </div>
          <div className="md-embedded-form--input" onClick={handleSetSelectedField}>
            {enrichedField.dataType === 'TEXTAREA' ? (
              <textarea
                readOnly={!previewMode}
                className={styles.textArea}
                placeholder={enrichedField.placeholder}
                focusable="false"
                disabled={!previewMode}
                style={getComponentStyle(formState.jsonConfig.body.inputFieldStyle)}
                type={previewMode ? enrichedField.dataType : undefined}
              />
            ) : (
              <input
                readOnly={!previewMode}
                className="md-embedded-form--input-el md-embedded-form--input-el--form-builder"
                style={getComponentStyle(
                  formState.jsonConfig.body.inputFieldStyle,
                  true,
                  fieldDate
                )}
                placeholder={enrichedField.placeholder}
                onChange={(e) => setFieldDate(e.target.value)}
                focusable="false"
                disabled={!previewMode}
                type={previewMode ? enrichedField.dataType : undefined}
              />
            )}
          </div>
        </label>
      </div>
    );
  }

  if (enrichedField.optionValues?.length === 1) {
    return (
      <div>
        {selectedElement === `field.${field.name}` && field.name !== 'email' && !previewMode && (
          <div className={styles.removeField} onClick={() => removeItem(field.name)}>
            <img src={IconTrash} alt="Remove field" />
          </div>
        )}
        <label
          className={clsx({
            'md-embedded-form--input-wrapper': true,
            [styles.selectableFrame]: !previewMode,
            [styles.selectableFrameActive]: selectedElement === `field.${field.name}`,
          })}
        >
          <div className={styles.customFieldContainer} onClick={handleSetSelectedField}>
            {enrichedField.optionValues?.map(stringToOption).map(({value}) => (
              <div key={value}>
                <label>
                  <input
                    readOnly={!previewMode}
                    type="checkbox"
                    value={value}
                    name={enrichedField.name}
                    disabled={!previewMode}
                  />
                  <span className={styles.radioButtonLabel}>{enrichedField.label}</span>
                </label>
              </div>
            ))}
          </div>
        </label>
      </div>
    );
  }

  if (
    enrichedField.optionValues?.length > 1 &&
    (enrichedField.dataType === 'CHECKBOX' || enrichedField.dataType === 'RADIO')
  ) {
    return (
      <div>
        {selectedElement === `field.${field.name}` && field.name !== 'email' && !previewMode && (
          <div className={styles.removeField} onClick={() => removeItem(field.name)}>
            <img src={IconTrash} alt="Remove field" />
          </div>
        )}
        <label
          className={clsx({
            'md-embedded-form--input-wrapper': true,
            [styles.selectableFrame]: !previewMode,
            [styles.selectableFrameActive]: selectedElement === `field.${field.name}`,
          })}
        >
          <div className={styles.customFieldContainer} onClick={handleSetSelectedField}>
            <div
              style={getTextStyles(formState.jsonConfig.body.inputFieldStyle.label)}
              className={`md-embedded-form--input-title ${styles.customFieldContainer}`}
            >
              {enrichedField.label}
            </div>
            {enrichedField.optionValues?.map(stringToOption).map(({value, label}) => (
              <div key={value}>
                <label>
                  <input
                    readOnly={!previewMode}
                    type={enrichedField.dataType}
                    defaultValue={value}
                    name={enrichedField.name}
                  />
                  <span
                    className={styles.radioButtonLabel}
                    style={{color: formState.jsonConfig.body.text.font.color}}
                  >
                    {label}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </label>
      </div>
    );
  }

  if (enrichedField.optionValues?.length > 1 && enrichedField.dataType === 'DROPDOWN') {
    return (
      <div onClick={handleSetSelectedField}>
        {selectedElement === `field.${field.name}` && field.name !== 'email' && !previewMode && (
          <div className={styles.removeField} onClick={() => removeItem(field.name)}>
            <img src={IconTrash} alt="Remove field" />
          </div>
        )}
        <label
          className={clsx({
            'md-embedded-form--input-wrapper md-embedded-form--input': true,
            [styles.selectableFrame]: !previewMode,
            [styles.selectableFrameActive]: selectedElement === `field.${field.name}`,
          })}
        >
          <div
            style={getTextStyles(formState.jsonConfig.body.inputFieldStyle.label)}
            className={`md-embedded-form--input-title ${styles.customFieldContainer}`}
          >
            {enrichedField.label}
          </div>

          <div>
            <select
              className="md-embedded-form--input-el md-embedded-form--input-el--form-builder"
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              disabled={!previewMode}
              style={getComponentStyle(formState.jsonConfig.body.inputFieldStyle, true, fieldValue)}
              defaultValue={enrichedField.placeholder}
            >
              <option disabled hidden>
                {enrichedField.placeholder}
              </option>
              {enrichedField.optionValues?.map(stringToOption).map(({value, label}) => (
                <option
                  style={{color: formState.jsonConfig.body.inputFieldStyle.color}}
                  value={value}
                  key={value}
                >
                  {label}
                </option>
              ))}
            </select>
          </div>
        </label>
      </div>
    );
  }
};

export default FormField;
