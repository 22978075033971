import React from 'react';

import cx from 'clsx';
import Button from '../../../../components/Button';
import styles from './Toolbar.module.scss';
import {SubscriptionFormBuilderContext} from '../../context';

function ToolbarSection({sectionName, contentClassName, title, children}) {
  const {resetSelectedElement} = React.useContext(SubscriptionFormBuilderContext);
  return (
    <div className={styles.editSidebarGroup}>
      <h4 className={styles.editSidebarGroupHeading}>
        {sectionName ? (
          <>
            <Button
              type="button"
              variant="link"
              onClick={resetSelectedElement}
              className={styles.sectionHeaderButton}
            >
              General Styles
            </Button>
            {` > ${sectionName}`}
          </>
        ) : (
          title
        )}
      </h4>
      <div className={cx(styles.editSidebarGroupContent, contentClassName)}>{children}</div>
    </div>
  );
}

export default ToolbarSection;
