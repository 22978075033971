import AlignCenterIcon from '../../../../components/icons/AlignCenterIcon';
import AlignLeftIcon from '../../../../components/icons/AlignLeftIcon';
import AlignRightIcon from '../../../../components/icons/AlignRightIcon';
import PropTypes from 'prop-types';
import TextAlignmentInputField from './TextAlignmentInputField';
import styles from './TextAlignmentInput.module.scss';

const TextAlignmentInput = ({paramPath, value, onChange}) => {
  return (
    <div className={styles.mainContainer} data-testid={paramPath}>
      <TextAlignmentInputField value={value} onChange={onChange} name={paramPath} fieldValue="LEFT">
        <span className={styles.label}>
          <AlignLeftIcon />
        </span>
      </TextAlignmentInputField>
      <TextAlignmentInputField
        name={paramPath}
        onChange={onChange}
        value={value}
        fieldValue="CENTER"
      >
        <span className={styles.label}>
          <AlignCenterIcon />
        </span>
      </TextAlignmentInputField>
      <TextAlignmentInputField
        name={paramPath}
        onChange={onChange}
        value={value}
        fieldValue="RIGHT"
      >
        <span className={styles.label}>
          <AlignRightIcon />
        </span>
      </TextAlignmentInputField>
    </div>
  );
};

TextAlignmentInput.propTypes = {
  paramPath: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextAlignmentInput;
