import React from 'react';

import AddNewFieldStyles from './AddNewFieldStyles';
import GeneralStyles from './GeneralStyles';
import ButtonStyles from './ButtonStyles';
import HeaderStyles from './HeaderStyles';
import BodyStyles from './BodyStyles';
import FooterStyles from './FooterStyles';
import CustomFieldStyles from './CustomFieldStyles';
import {SubscriptionFormBuilderContext} from '../../context';
import SubscriptionCount from './SubscriptionCount';
import ProgressBarTypes from './ProgressBarTypes';

function Toolbar() {
  const {selectedElement, formState} = React.useContext(SubscriptionFormBuilderContext);

  if (selectedElement.includes('field.')) {
    const fieldName = selectedElement.split('.')[1];
    const field = formState.inputFields.find((f) => f.name === fieldName);

    if (!field) {
      return null;
    }

    return <CustomFieldStyles field={field} />;
  }

  switch (selectedElement) {
    case 'header':
      return <HeaderStyles />;
    case 'footer':
      return <FooterStyles />;
    case 'body':
      return <BodyStyles />;
    case 'button':
      return <ButtonStyles />;
    case 'add_new_field':
      return <AddNewFieldStyles />;
    case 'subscriber_counter':
      return <SubscriptionCount fontStylesToolbar isSection />;
    case 'progressBar_type':
      return <ProgressBarTypes fontStylesToolbar isSection />;
    default:
      return <GeneralStyles />;
  }
}

export default Toolbar;
