export const initialState = {
  data: null,
  dataMapped: [],
};

const mapper = (dataAsMap) =>
  Object.entries(dataAsMap).map(([value, label]) => {
    return {value, label};
  });

export function setDataReducer(state, action) {
  return {
    data: action.payload,
    dataMapped: action.payload ? mapper(action.payload) : [],
  };
}
