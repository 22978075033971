import PropTypes from 'prop-types';

import styles from './TableHeading.module.scss';

function TableHeading({children}) {
  return <th className={styles.headerTitle}>{children}</th>;
}

TableHeading.propTypes = {
  children: PropTypes.node,
};

export default TableHeading;
