import {Switch, Route} from 'react-router-dom';
import ROUTES from '../../Router/routes';
import PageNotFound from '../../Router/PageNotFound';
import SubscriberPage from '../subscriber/SubscriberPage';

function SubscribersRouter() {
  return (
    <Switch>
      <Route path={ROUTES.subscribers.create}>
        <SubscriberPage />
      </Route>
      <Route path={ROUTES.subscribers.edit}>
        <SubscriberPage />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default SubscribersRouter;
