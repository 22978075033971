import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import omitBy from 'lodash.omitby';
import ImportSubscribers from './components/ImportSubscribers';
import {useImportSubscribersContext} from './useImportSubscribersContext';
import {STEPS, stepsSettings} from './constants';
import useFetch from '../../hooks/useFetch';
import useUpdateFreePlanInfo from '../../hooks/useUpdateFreePlanInfo';
import {
  importSubscribersService,
  fetchImportPreviewService,
  fetchSubscribersBulkLockService,
} from './service';
import {getFormData} from '../../utils/toFormData';
import {GENERIC_ERROR_MESSAGE} from '../../utils/constants';
import {fetchActiveSubscriptionService} from '../settings/subscription/service';

function ImportSubscribersContainer({onRequestClose}) {
  const [isLocked, setIsLocked] = useState(false);
  const {state, setStepError, setStepData, setActiveStep} = useImportSubscribersContext();
  const {run: fetchImportPreviewRequest} = useFetch(fetchImportPreviewService, {throwError: true});
  const {run: importSubscribersRequest} = useFetch(importSubscribersService, {throwError: true});
  const {run: fetchSubscribersBulkLockRequest} = useFetch(fetchSubscribersBulkLockService, {
    throwError: true,
  });
  const {updateFreePlanInfo} = useUpdateFreePlanInfo();

  const {data: activeSubscription} = useFetch(fetchActiveSubscriptionService, {
    immediate: true,
  });

  useEffect(
    function () {
      async function fetchData() {
        try {
          const response = await fetchSubscribersBulkLockRequest();
          if (response.isLocked) {
            setIsLocked(true);
            setStepError('Please wait for the currently active bulk operation to finish.');
          }
        } catch (error) {
          setStepError(error.message || GENERIC_ERROR_MESSAGE);
        }
      }

      fetchData();
    },
    [fetchSubscribersBulkLockRequest]
  );

  async function handleImport(values) {
    setStepError('');
    try {
      const formData = getFormData(values);
      const response = await fetchImportPreviewRequest(formData);
      setStepData({
        ...response,
        file: values.file,
      });
      setActiveStep(STEPS.mapping);
    } catch (error) {
      setStepError(error.message || GENERIC_ERROR_MESSAGE);
    }
  }

  async function handleMapping(values) {
    if (!Object.values(values).some((el) => el === 'email')) {
      setStepError('You must assign the email to some field.');
      return;
    }

    setStepError('');
    try {
      const mappingStepData = state.steps[stepsSettings[0].key].data;
      const fieldMapping = omitBy(values, (el) => !el);
      const requestParams = {
        delimiter: mappingStepData.delimiter,
        skipFirstRow: mappingStepData.skipFirstRow,
        subscriberStatus: mappingStepData.subscriberStatus,
        file: mappingStepData.file,
        fileName: mappingStepData.file.name,
        fieldMapping: JSON.stringify(fieldMapping),
      };

      const formData = getFormData(requestParams);
      await importSubscribersRequest(formData);
      await updateFreePlanInfo();
      setStepData({fieldMapping});
      setActiveStep(STEPS.success);
    } catch (error) {
      setStepError(error.message || GENERIC_ERROR_MESSAGE);
    }
  }

  return (
    <ImportSubscribers
      onImport={handleImport}
      onMappingSubmit={handleMapping}
      onRequestClose={onRequestClose}
      isLocked={isLocked}
      planLimit={activeSubscription?.subscriptionPlan.limit.formattedLimit}
    />
  );
}

ImportSubscribersContainer.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default ImportSubscribersContainer;
