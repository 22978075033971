import PropTypes from 'prop-types';
import PaginatedCard from '../../../components/PaginatedCard';
import PaginatedCardHeader from '../../../components/PaginatedCard/PaginatedCardHeader';
import SearchInput from '../../../components/forms/SearchInput';
import OverviewList from './OverviewList';
import {paginatedMetaPropType} from '../../../utils/prop-types';
import {overviewNewslettersPropType} from '../../../utils/prop-types/newsletter';
import {LANDING_URL} from '../../../utils/constants';
import Button from '../../../components/Button';
import IconSettings from '../../../img/icon-form-settings.png';
import DropdownButton from '../../../components/ButtonDropdown';
import ButtonDropdownItem from '../../../components/ButtonDropdown/ButtonDropdownItem';
import styles from './Overview.module.scss';
import ROUTES from '../../../Router/routes';

function Overview({
  newsletters,
  loading,
  onSearch,
  onDelete,
  onMarkDraft,
  onActionBtnClick,
  onPageChange,
  onUnschedule,
  handleReschedule,
  isTrackingOptionsOpen,
  setTrackingOptionsOpen,
  trackingOptions,
  handleTrackingOptionsUpdate,
}) {
  return (
    <>
      <PaginatedCard
        title="Email Campaigns"
        subtitle={
          <p>
            Create and send email campaigns here. For more information, please read our{' '}
            <a
              href={`${LANDING_URL}/guides/newsletter-campaigns`}
              title="Click here for more details"
              target="_blank"
              rel="noopener noreferrer"
            >
              Email Campaign Guide
            </a>
            .
          </p>
        }
        noPadding
        meta={newsletters?.meta}
        onPageChange={onPageChange}
        loading={loading}
        header={
          <PaginatedCardHeader>
            <SearchInput onSearch={onSearch} placeholder="Search for campaigns" name="" />
            <div style={{display: 'flex'}}>
              <Button color="green" variant="outlined" onClick={() => setTrackingOptionsOpen(true)}>
                <img src={IconSettings} alt="settings" style={{marginRight: 10}} />
                Tracking Options
              </Button>
              <DropdownButton
                buttonText="Create Campaign"
                variant="filled"
                color="green"
                type="white"
                as={Button}
                className={styles.btn}
              >
                <ButtonDropdownItem onSelect={onActionBtnClick(ROUTES.newsletters.templateSelect)}>
                  From Template
                </ButtonDropdownItem>
                <ButtonDropdownItem onSelect={onActionBtnClick(ROUTES.newsletters.create)}>
                  Start From Scratch
                </ButtonDropdownItem>
              </DropdownButton>
            </div>
          </PaginatedCardHeader>
        }
      >
        <OverviewList
          newsletters={newsletters?.data || []}
          onMarkDraft={onMarkDraft}
          onDelete={onDelete}
          onUnschedule={onUnschedule}
          handleReschedule={handleReschedule}
          isTrackingOptionsOpen={isTrackingOptionsOpen}
          setTrackingOptionsOpen={setTrackingOptionsOpen}
          trackingOptions={trackingOptions}
          handleTrackingOptionsUpdate={handleTrackingOptionsUpdate}
        />
      </PaginatedCard>
    </>
  );
}

Overview.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onMarkDraft: PropTypes.func.isRequired,
  onActionBtnClick: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  newsletters: PropTypes.shape({
    data: overviewNewslettersPropType,
    meta: paginatedMetaPropType,
  }),
};

export default Overview;
