export const ENDPOINTS = {
  automationOverview: '/internal/automation-overview',
  getAutomationConfig: (id) => `/internal/automation-config/${id}`,
  automationConfig: '/internal/automation-config',
  automationToolset: '/internal/automation-toolset',
  updateActiveStatus: (data) =>
    `/internal/automation-config/update-status/${data.id}?status=${data.status}`,
  checkAutomationReferences: (id) => `/internal/automation-config/check-references/${id}`,
  getAutomationInitiators: (id) => `/internal/automation-config/initiators/${id}`,
  newAutomationRevision: (id) => `/internal/automation-config/new-version/${id}`,
  newAutomationRevisionWithRedirect: (id) =>
    `/internal/automation-config/new-version-with-redirect/${id}`,
};
