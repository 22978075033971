import {isFieldTag, isMatcher} from '../utils';

export const initialState = {
  fetchLoading: false,
  data: null,
  dataCustomFields: [],
  dataTags: [],
  dataMatchers: null,
};

export function setFetchLoadingReducer(state, action) {
  return {
    ...state,
    fetchLoading: action.payload,
  };
}

export function setDataReducer(state, action) {
  const data = action.payload;
  return {
    ...state,
    data,
    dataCustomFields: data?.filter((v) => !isFieldTag(v) && !isMatcher(v)),
    dataMatchers: data?.filter((v) => isMatcher(v)) && data?.filter((v) => isMatcher(v))[0]?.value,
    dataTags: data?.filter((v) => isFieldTag(v)),
  };
}

export function addDataItemReducer(state, action) {
  return setDataReducer(state, {
    payload: [...state.data, action.payload],
  });
}

export function replaceDataItemReducer(state, action) {
  const {matcher, replacer} = action.payload;
  let replaced = false;
  const mapper = replacer === null ? Array.prototype.filter : Array.prototype.map;
  return setDataReducer(state, {
    payload: mapper.call(state.data, (v) => {
      if (replaced) {
        return v;
      }
      const matches = Object.keys(matcher).every((key) => v[key] === matcher[key]);
      if (matches) {
        replaced = true;
        return replacer;
      }
      return v;
    }),
  });
}
