export const initialState = [];

export function showAlertReducer(state, action) {
  return [...state, action.payload];
}

export function closeAlertReducer(state, action) {
  return state.filter((alert) => alert.id !== action.payload);
}

export function closeAllReducer() {
  return initialState;
}
