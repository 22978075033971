import CheckBox from '../../../../../components/forms/Checkbox';
import styles from './editors.module.scss';
import {TYPE_FIELD_OPTIONS} from '../../constants';

export const TextRenderer = ({value}) => <span>{value}</span>;

export const SelectRenderer = ({value}) => (
  <span>{TYPE_FIELD_OPTIONS.find((v) => v.value === value).label}</span>
);

export const CheckboxRenderer = ({id, value}) => (
  <CheckBox
    id={`renderer_${id}`}
    defaultChecked={value}
    readOnly
    disabled
    className={[styles.checkboxField, styles.readOnly].join(' ')}
  />
);

export const OptionValuesRenderer = ({value = []}) => {
  return <span>{value.join(' ')}</span>;
};
