import {useEffect} from 'react';
import cx from 'clsx';
import Alert from '../../components/Alert';
import useAlert from './useAlert';
import useAuth from '../auth/useAuth';
import useRouter from '../../hooks/useRouter';
import styles from './Alerts.module.scss';

function Alerts() {
  const {state, closeAllAlerts} = useAlert();
  const {state: authState} = useAuth();
  const {pathname} = useRouter();

  useEffect(
    function () {
      if (authState.isLoggedIn === false) {
        closeAllAlerts();
      }
    },
    [authState.isLoggedIn]
  );

  useEffect(
    function () {
      closeAllAlerts();
    },
    [pathname]
  );

  return (
    <div className={cx([styles.alerts, {[styles.active]: state?.length}])}>
      {state.map((alert) => (
        <Alert key={alert.id} className={styles.alert} {...alert} />
      ))}
    </div>
  );
}

Alerts.defaultProps = {
  type: 'info',
};

export default Alerts;
