import {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import Button from 'components/Button';
import StatusMessage from 'components/StatusMessage/StatusMessage';
import Spinner from 'components/Spinner';
import {dateToTransmisionString} from 'utils/datetime';
import PropTypes from 'prop-types';
import CustomInput from './CustomInput';
import styles from './ScheduleNewsletter.module.scss';
import useNextScheduleTime from '../hooks/useNextScheduleTime';
import useFormattedScheduleTime from '../hooks/useFormattedScheduleTime';

import 'react-datepicker/dist/react-datepicker.css';

const ScheduleNewsletter = ({onScheduleSelect, scheduleTime, title, onlyReschedule}) => {
  const [selectedSlot, setSelectedSlot] = useState(scheduleTime);
  const nextScheduleTimeRequest = useNextScheduleTime();
  const formattedScheduleTimeRequest = useFormattedScheduleTime();

  useEffect(() => {
    if (nextScheduleTimeRequest.data && !scheduleTime) {
      setSelectedSlot(nextScheduleTimeRequest.data);
    }
  }, [scheduleTime, nextScheduleTimeRequest.data]);

  const filterPassedTime = (slotTime) => {
    const firstAvailableSlot = new Date(nextScheduleTimeRequest.data.transmissionTimestamp);
    const slotOption = new Date(slotTime);
    return firstAvailableSlot.getTime() < slotOption.getTime();
  };

  const handleSlotChange = async (date) => {
    try {
      const transmittionDateString = dateToTransmisionString(date);
      const formattedScheduleTime = await formattedScheduleTimeRequest.run(transmittionDateString);
      setSelectedSlot(formattedScheduleTime);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUnschedule = () => {
    onScheduleSelect(null);
  };

  const handleSchedule = () => {
    onScheduleSelect(selectedSlot);
  };

  return (
    <div>
      <div className={styles.scheduleHeader}>{title || 'When do you want to send this email?'}</div>
      {nextScheduleTimeRequest.loading && <Spinner />}

      {formattedScheduleTimeRequest.error && (
        <StatusMessage message={formattedScheduleTimeRequest.error.message} />
      )}
      {nextScheduleTimeRequest.error && (
        <StatusMessage message={nextScheduleTimeRequest.error?.message} />
      )}

      {nextScheduleTimeRequest.data && selectedSlot && (
        <>
          <CustomInput
            formattedDatetime={selectedSlot.formattedDateTime}
            timezoneLabel={selectedSlot.formattedTimezone}
          />
          <div className={styles.datePicker}>
            <div className={styles.datePickerTriangle} />
            <DatePicker
              inline
              showTimeSelect
              useWeekdaysShort
              selected={new Date(selectedSlot.transmissionTimestamp)}
              onChange={handleSlotChange}
              timeIntervals={5}
              minDate={new Date()}
              filterTime={filterPassedTime}
              wrapperClassName={styles.datePcikerInputWrapper}
            />
          </div>
          <div className={styles.scheduleFooterWrapper}>
            {scheduleTime && !onlyReschedule && (
              <Button
                type="submit"
                color="grey"
                variant="outlined"
                disabled={formattedScheduleTimeRequest.loading}
                onClick={handleUnschedule}
              >
                Unschedule
              </Button>
            )}
            <Button
              type="submit"
              variant="filled"
              color="green"
              onClick={handleSchedule}
              disabled={
                selectedSlot.transmissionTimestamp === scheduleTime?.transmissionTimestamp ||
                formattedScheduleTimeRequest.loading
              }
            >
              {scheduleTime || onlyReschedule ? 'Reschedule' : 'Schedule'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

ScheduleNewsletter.propTypes = {
  onScheduleSelect: PropTypes.func,
  scheduleTime: PropTypes.shape({
    formattedDateTime: PropTypes.string.isRequired,
    formattedTimezone: PropTypes.string.isRequired,
    transmissionTimestamp: PropTypes.string.isRequired,
  }),
  title: PropTypes.string,
  onlyReschedule: PropTypes.bool,
};

export default ScheduleNewsletter;
