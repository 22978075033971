import {useState} from 'react';
import useFetch from '../../hooks/useFetch';
import {fetchAuditStatusService, initAuditService} from './service';
import {STATUS_MANUAL_AUDIT, STATUS_PENDING} from './constants';

class AuditStatusCheckError extends Error {}

function useAuditStatus(options = {}) {
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false);
  const {run: fetchAuditStatusRequest, data: fetchData} = useFetch(fetchAuditStatusService, {
    ...options,
    immediate: options.fetchImmediate,
  });
  const {run: initAuditRequest} = useFetch(initAuditService, options);
  let auditStatusLast;

  function toggleAuditModalOpen() {
    setIsAuditModalOpen(!isAuditModalOpen);
  }

  function isAuditPending(auditStatus) {
    return auditStatus === STATUS_PENDING;
  }

  function isAuditOk(auditStatus) {
    return auditStatus !== STATUS_MANUAL_AUDIT;
  }

  async function recheckAuditStatusOk() {
    auditStatusLast = (await fetchAuditStatusRequest(null)).auditStatus;
    return isAuditOk(auditStatusLast);
  }

  async function recheckAuditStatusPassed(newsletterID) {
    auditStatusLast = (await fetchAuditStatusRequest(null)).auditStatus;
    if (!isAuditOk(auditStatusLast)) {
      throw new AuditStatusCheckError();
    }
    if (!isAuditPending(auditStatusLast)) {
      return;
    }
    auditStatusLast = (await initAuditRequest(newsletterID)).auditStatus;
    if (isAuditOk(auditStatusLast)) {
      return;
    }
    throw new AuditStatusCheckError();
  }

  return {
    auditStatus: fetchData?.auditStatus || auditStatusLast,
    isAuditModalOpen,
    toggleAuditModalOpen,
    isAuditOk,
    recheckAuditStatusOk,
    recheckAuditStatusPassed,
    AuditStatusCheckError,
  };
}

export default useAuditStatus;
