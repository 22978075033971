import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import Button from '../Button';
import Label from '../Label';
import RangeInput from '../forms/RangeInput';
import styles from './Plans.module.scss';
import {subscriptionPlanPropType} from '../../utils/prop-types';
import {isPremiumPlan} from '../../utils/planUtils';

function Plan({activePlanId, data, corporatePlans, onSelect}) {
  const [dynamicData, setDynamicData] = useState(data);
  const [rangeValue, setRangeValue] = useState(0);
  const active = activePlanId === dynamicData.id;
  const isPremium = isPremiumPlan(data);

  const handleSelect = () => {
    onSelect(dynamicData);
  };

  useEffect(
    function () {
      if (isPremium) {
        const corporatePlanIndex = corporatePlans.findIndex((plan) => plan.id === activePlanId);
        if (corporatePlanIndex !== -1) {
          setRangeValue(corporatePlanIndex);
          setDynamicData(corporatePlans[corporatePlanIndex]);
        }
      }
    },
    [isPremium]
  );

  function handleRangeChange(value) {
    setRangeValue(Number(value));
    setDynamicData(corporatePlans[value]);
  }

  function renderTrialLabel() {
    const label = activePlanId
      ? 'Take your free trial with you'
      : `${dynamicData.trialDays.formattedValue}-day free trial`;

    return (
      <li
        className={styles.feature}
        style={{visibility: dynamicData.trialDays.value > 0 ? 'visible' : 'hidden'}}
      >
        <Label>{label}</Label>
      </li>
    );
  }

  return (
    <div className={cx(styles.plan, {[styles.highlighted]: active})}>
      {active && <span className={styles.current}>Current Plan</span>}
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>{data.name}</h2>
      </div>
      <div className={styles.priceWrapper}>
        <strong className={styles.price}>{dynamicData.unitAmount.formattedValue}</strong>
      </div>
      <span className={styles.duration}>per month</span>
      {isPremium && (
        <div className={styles.input}>
          <RangeInput
            step={1}
            min={0}
            value={rangeValue}
            max={corporatePlans.length - 1}
            onChange={handleRangeChange}
          />
        </div>
      )}
      <span className={cx(styles.vat, {[styles.vatBigSpaceBottom]: !isPremium})}>
        Value-added tax (VAT) may apply
      </span>
      <div className={styles.btnWrapper}>
        <Button variant="filled" color="green" disabled={active} onClick={handleSelect}>
          select
        </Button>
      </div>
      <ul className={styles.features}>
        {renderTrialLabel()}
        <li className={styles.feature}>Up to 99% deliverability</li>
        <li className={styles.feature}>Unlimited emails</li>
        <li className={styles.feature}>
          Up to <strong>{dynamicData.limit.formattedLimit}</strong> subscribers
        </li>
      </ul>
    </div>
  );
}

Plan.propTypes = {
  onSelect: PropTypes.func,
  activePlanId: PropTypes.string,
  data: subscriptionPlanPropType.isRequired,
};

export default Plan;
