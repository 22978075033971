import {useDataTableContext} from './context/TableContext';

function useDataTableFetcher() {
  const {
    state: {fetcher, meta, filters, searchText},
    setProcessing,
  } = useDataTableContext();

  async function tableFetcher(overrideParams) {
    setProcessing(true);

    return fetcher({
      page: meta.page,
      limit: meta.perPage,
      query: searchText,
      ...filters,
      ...overrideParams,
    })
      .then((response) => {
        setProcessing(false);
        return response;
      })
      .catch(() => {
        setProcessing(false);
      });
  }

  return [tableFetcher];
}

export default useDataTableFetcher;
