import PropTypes from 'prop-types';
import StepsList from '../Steps';
import Checkout from '../../panels/CheckoutContainer';
import {useBoardingWizard} from '../../context/WizardContext';
import {STEPS} from '../../constants';
import styles from './BodyContainer.module.scss';
import Plans from '../../panels/Plans';
import Billing from '../../panels/Billing';
import Success from '../../panels/Success';

const panelsMap = {
  [STEPS.plans]: Plans,
  [STEPS.billing]: Billing,
  [STEPS.checkout]: Checkout,
  [STEPS.success]: Success,
};

function BodyContainer({onRequestClose}) {
  const {state} = useBoardingWizard();
  const isSuccess = state.activeStep === STEPS.success;

  if (!panelsMap[state.activeStep]) {
    return null;
  }

  const ActivePanel = panelsMap[state.activeStep];

  function renderContent() {
    return (
      <>
        <div className={styles.col}>
          <StepsList disabled={isSuccess} />
        </div>
        <div className={styles.col}>
          <ActivePanel />
        </div>
      </>
    );
  }

  return (
    <div className={styles.bodyContainer}>
      {isSuccess ? <Success onRequestClose={onRequestClose} /> : renderContent()}
    </div>
  );
}

BodyContainer.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default BodyContainer;
