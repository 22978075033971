const STRINGIFY_SPACE = 2;

class ApiError extends Error {
  constructor(message, response, status) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
    this.response = response;
    let isObject = false;

    if (typeof response === 'object' && response !== null) {
      isObject = true;
      // @FIXME use only backend error
      this.error = response.validationErrors ? 'validation' : response.error;
      if (response.message) {
        this.message = response.message;
      }
    }

    this.toString = function () {
      return `${message}\nResponse:\n${
        isObject ? JSON.stringify(this.response, null, STRINGIFY_SPACE) : this.response
      }`;
    };
  }
}

export default ApiError;
