import {Redirect, Route, Switch} from 'react-router-dom';
import PublicLayout from '../layouts/PublicLayout';
import ROUTES from './routes';
import useRouter from '../hooks/useRouter';
import ConfirmEmailPage from '../domain/confirm-email/ConfirmEmailPage';
import ExportDownloadPage from '../domain/export-download/ExportDownloadPage';

function PublicRouter() {
  const {match} = useRouter();
  return (
    <PublicLayout>
      <Switch>
        <Route path={`${match.url}${ROUTES.confirmEmail}/:type?`}>
          <ConfirmEmailPage />
        </Route>
        <Route path={`${match.url}${ROUTES.downloadExport}/:id`}>
          <ExportDownloadPage />
        </Route>
        <Route path="*">
          <Redirect to={ROUTES.index} />
        </Route>
      </Switch>
    </PublicLayout>
  );
}

export default PublicRouter;
