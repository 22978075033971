import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './Title.module.scss';

function Title({children, noMarginTop}) {
  return <h2 className={cx(styles.title, {[styles.noMarginTop]: noMarginTop})}>{children}</h2>;
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  noMarginTop: PropTypes.bool,
};

export default Title;
