import {ENDPOINTS} from '../subscribers/constants';
import {get, post, put} from '../../utils/api';

export async function addSubscriberService(apiSettings, data) {
  return post(ENDPOINTS.add, data, apiSettings);
}

export async function fetchSubscriberService(apiSettings, data) {
  return get(ENDPOINTS.fetchSingle(data), null, apiSettings);
}

export async function editSubscriberService(apiSettings, data) {
  return put(ENDPOINTS.update, data, apiSettings);
}
