import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Tile.module.scss';

const Tile = ({onClick, img, SVGComponent, label, className}) => (
  <div className={clsx(styles.tile, className)} onClick={onClick}>
    {img && <img src={img} className={styles.img} alt="Filter icon" />}
    {!img && SVGComponent && (
      <div className={styles.img}>
        <SVGComponent />
      </div>
    )}
    <div className={styles.label}>{label}</div>
  </div>
);

Tile.propTypes = {
  label: PropTypes.string.isRequired,
  img: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  SVGComponent: PropTypes.elementType,
};

export default Tile;
