const billingDetails = '/internal/billing-details';

export const ENDPOINTS = {
  fetchBillingDetails: billingDetails,
  updateBillingDetails: billingDetails,
  addBillingDetails: billingDetails,
  fetchInvoices: '/internal/invoices',
  downloadInvoice: (id) => `/internal/invoices/${id}/pdf`,
  fetchInvoicesCount: '/internal/invoices/count',
  exportCSV: '/export',
  validateVat: '/internal/vat-validate',
};
