import React from 'react';
import clsx from 'clsx';
import Button from '../../../../components/Button';
import styles from './FormPreview.module.scss';

export default function FormPreviewCollapseButton({isSliderOpen, setSliderOpen}) {
  const handleSwitchSliderView = React.useCallback(() => {
    setSliderOpen((prev) => !prev);
  }, [setSliderOpen]);

  return (
    <div className={styles.colapsedViewSwitchButton}>
      <Button color="grey" onClick={handleSwitchSliderView}>
        {isSliderOpen ? 'Show collapsed view' : 'Show expanded view'}
        <i
          className={clsx([
            isSliderOpen ? 'icon-arrow-down' : 'icon-arrow-up',
            styles.buttonCollapseIcon,
          ])}
        />
      </Button>
    </div>
  );
}
