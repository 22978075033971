import PropTypes from 'prop-types';
import styles from './Spinner.module.scss';

function Spinner({height, width}) {
  return (
    <div className={styles.wrapper} style={{height, width}} role="status">
      <span className={styles.spinner} />
    </div>
  );
}

Spinner.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Spinner;
