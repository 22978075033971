import {useEffect, useState} from 'react';
import cx from 'clsx';

import StatusMessage from '../../../components/StatusMessage/StatusMessage';
import FooterText from './FooterText';
import useFetch from '../../../hooks/useFetch';
import signupService, {signupRequestCount} from '../services/signup';
import SignupForm from './SignupForm';
import styles from '../auth.module.scss';
import Hero from './Hero';
import Logo from '../Logo';
import ROUTES from '../../../Router/routes';
import useRouter from '../../../hooks/useRouter';
import Footer from '../../../components/Footer';
import useAuth from '../useAuth';
import loginService from '../services/login';
import {getDetailsHash} from '../../subscription-form/helper';

function Signup() {
  const {history, location} = useRouter();
  const {login} = useAuth();
  const {run: signup} = useFetch(signupService, {throwError: true});
  const {run: loginRequest} = useFetch(loginService, {throwError: true});
  const {run: signupCount} = useFetch(signupRequestCount, {throwError: true});
  const [errorMessage, setErrorMessage] = useState('');

  async function handleFormSubmit(values) {
    setErrorMessage('');
    try {
      const query = new URLSearchParams(location.search);
      const aid = query.get('aid');
      const ep = query.get('ep');
      const valuesWithQueryParams = {...values};
      if (aid) {
        valuesWithQueryParams.aid = aid;
      }
      if (ep) {
        valuesWithQueryParams.ep = ep;
      }
      await signup(valuesWithQueryParams);
      const response = await loginRequest(values);

      login(response.access_token, response.refresh_token, false);
      history.push(ROUTES.index);
    } catch (error) {
      setErrorMessage(error.message);
    }
  }

  useEffect(() => {
    const makeSignupRequest = async () => {
      try {
        const query = new URLSearchParams(location.search);

        const aid = query.get('aid');

        const fingerPrint = await getDetailsHash();

        await signupCount(aid ? {aid, fingerPrint} : {fingerPrint});
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
    makeSignupRequest();
  }, []);

  return (
    <div className={styles.auth}>
      <div className={styles.inner}>
        <div className={cx([styles.form, styles.formSmallSpaces])}>
          <Logo />
          <h1 className={styles.title}>Create Your Account</h1>
          <StatusMessage message={errorMessage} />
          <SignupForm onSubmit={handleFormSubmit} />
          <FooterText />
        </div>
        <Hero />
      </div>
      <Footer />
    </div>
  );
}

export default Signup;
