const REVOKE_TIMEOUT = 250;

export default function downloadFile(data, type, defaultFileName) {
  const newBlob = new Blob([data.blob], {type});

  const fileName = data.fileName || defaultFileName;
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, fileName);
    return;
  }

  const objectURL = URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = objectURL;
  link.download = fileName;

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  setTimeout(function () {
    URL.revokeObjectURL(objectURL);
    link.remove();
  }, REVOKE_TIMEOUT);
}
