import {useCallback, useMemo, useState} from 'react';
import useFetch from '../../../../hooks/useFetch';
import {deleteTemplateService, fetchTemplateService} from '../../service';
import useRouter from '../../../../hooks/useRouter';
import ROUTES from '../../../../Router/routes';
import {categoryOptions, TEMPLATE_TYPE} from '../constants';
import useFetchTemplate from '../../hooks/useFetchTemplate';

const useNewsletterTemplates = () => {
  const {history} = useRouter();
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [search, setSearch] = useState('');

  const [categories, setCategories] = useState(categoryOptions.map(({value}) => value));

  const {templates, page, hasMore, handleFetchMore, loading, handlerDelete} = useFetchTemplate(
    TEMPLATE_TYPE.CUSTOM,
    categories,
    search
  );
  const {run: fetchTemplateRequest} = useFetch(fetchTemplateService, {
    throwError: true,
  });

  const {run: deleteTemplateRequest, loading: deleteLoading} = useFetch(deleteTemplateService, {
    throwError: true,
  });

  const handleCreateTemplate = useCallback(() => {
    history.push({
      pathname: ROUTES.newsletters.templateEditor,
      state: {isCreateTemplateMode: true},
    });
  }, [history]);

  const handleEdit = useCallback(
    (template) => async () => {
      const templateData = await fetchTemplateRequest(template?.id);
      history.push({
        pathname: ROUTES.newsletters.templateEditor,
        state: {template: templateData, isEditTemplateMode: true},
      });
    },
    [fetchTemplateRequest, history]
  );

  const handleDuplicate = useCallback(
    (template) => async () => {
      const templateData = await fetchTemplateRequest(template?.id);

      history.push({
        pathname: ROUTES.newsletters.templateEditor,
        state: {
          template: {
            ...templateData,
            previewImagePath: undefined,
            id: undefined,
            name: `${templateData.name} (copy)`,
          },
          isEditTemplateMode: false,
        },
      });
    },
    [fetchTemplateRequest, history]
  );

  const handleTemplateDelete = useCallback(
    async (id) => {
      await deleteTemplateRequest(id);
      handlerDelete(id);
      setTemplateToDelete(null);
    },
    [deleteTemplateRequest, handlerDelete]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setTemplateToDelete(null);
  }, []);

  const handleSearch = useCallback((value) => setSearch(value ?? null), []);

  const actions = useMemo(() => {
    return [
      {
        label: 'Duplicate',
        action: handleDuplicate,
      },
      {
        label: 'Edit',
        action: handleEdit,
      },
      {
        label: 'Delete',
        action: (template) => () => {
          setTemplateToDelete(template);
        },
      },
    ];
  }, [handleDuplicate, handleEdit]);

  const handelClickCategory = useCallback(
    (category) => () => {
      setCategories((prev) => {
        if (prev?.length === 1 && prev.includes(category)) {
          return prev;
        }

        return prev.includes(category)
          ? prev.filter((item) => item !== category)
          : [...prev, category];
      });
    },
    []
  );

  return {
    templates,
    page,
    categories,
    loading,
    hasMore,
    handleFetchMore,
    deleteLoading,
    templateToDelete,
    actions,
    handleCreateTemplate,
    handleTemplateDelete,
    handleCloseDeleteModal,
    handelClickCategory,
    handleSearch,
  };
};

export default useNewsletterTemplates;
