import useSubscriberFieldsFetcher from '../../../subscriber-fields/hooks/useSubscriberFieldsFetcher';
import SelectField from './SelectField';
import {selectMapper} from '../../../../../utils/arrays';

function CustomFieldSelectField(props) {
  const {dataCustomFields, loading} = useSubscriberFieldsFetcher({immediate: true});

  return (
    <SelectField
      {...props}
      placeholder={loading ? 'Loading..' : undefined}
      options={dataCustomFields.map(selectMapper('id', 'name'))}
    />
  );
}

export default CustomFieldSelectField;
