import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import Input from '../../../../components/forms/Input';
import CheckBox from '../../../../components/forms/Checkbox';
import styles from './LoginForm.module.scss';
import ROUTES from '../../../../Router/routes';

function LoginForm({onSubmit}) {
  const {register, formState, handleSubmit, errors} = useForm({
    mode: 'onChange',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="email"
        type="email"
        label="Email"
        placeholder="your@email.com"
        required
        error={errors.email}
        inputRef={register({required: 'Email address is required.'})}
      />
      <Input
        name="password"
        type="password"
        label="Password"
        placeholder="Your password"
        required
        error={errors.password}
        inputRef={register({
          required: 'Password is required.',
        })}
      />
      <div className={styles.remember}>
        <CheckBox
          name="rememberMe"
          size="big"
          id="remember-me"
          label="Remember me"
          inputRef={register}
        />
        <Link
          to={ROUTES.auth.forgotPassword}
          className={styles.rememberLink}
          title="Click to recover"
        >
          Forgot Password?
        </Link>
      </div>
      <div className={styles.btnWrapper}>
        <Button
          type="submit"
          variant="filled"
          full
          size="big"
          color="green"
          loading={formState.isSubmitting}
        >
          Log in
        </Button>
      </div>
    </form>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
