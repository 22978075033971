import {useEffect} from 'react';
import useFetch from '../../../hooks/useFetch';
import {fetchSubscriberService} from '../service';
import {handleErrorAlert} from '../../../utils/handleErrorAlert';
import useAlert from '../../alerts/useAlert';

function useSubscriberFetcher(subscriberID, options = {}) {
  const {showAlert} = useAlert();
  const {data, loading, run: fetchSubscriberRequest, setData} = useFetch(fetchSubscriberService, {
    throwError: true,
  });

  async function fetcher(id) {
    try {
      await fetchSubscriberRequest(id);
    } catch (error) {
      handleErrorAlert(error, showAlert);
    }
  }

  useEffect(() => {
    if (subscriberID && options.immediate) {
      fetcher(subscriberID);
    }
  }, []);

  return {
    data,
    loading,
    fetcher,
    setData,
  };
}

export default useSubscriberFetcher;
