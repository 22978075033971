export default function createReducer(initialState, handlers) {
  // prettier-ignore
  return function (state = initialState, action = {}) {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
