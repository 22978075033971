import ToolbarSection from './ToolbarSection';
import TextField from './TextField';
import styles from './Toolbar.module.scss';
import FontOptionsSection from './FontOptionsSection';

const HeaderStyles = () => {
  return (
    <>
      <ToolbarSection sectionName="Header Content" className={styles.toolbarContent}>
        <TextField label="Text" paramPath="jsonConfig.header.text.value" />
      </ToolbarSection>
      <FontOptionsSection pathPrefix="jsonConfig.header.text" sectionTitle="Header Text Styles" />
    </>
  );
};

export default HeaderStyles;
