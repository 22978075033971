import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';
import Subtitle from '../../../components/Subtitle';
import HighlightedText from '../../../components/HighlightedText';
import PostalAddressContent from '../components/PostalAddressContent';
import styles from './PostalAddressModal.module.scss';

function PostalAddressModal({isOpen, onRequestClose}) {
  return (
    <Modal onRequestClose={onRequestClose} isOpen={isOpen} headerLeft className={styles.modal}>
      <Subtitle noMarginTop>We still need some information from you!</Subtitle>
      <HighlightedText>
        Welcome to Maildroppa! Your account setup is almost complete. To comply with anti-spam
        regulations, all emails you send with Maildroppa will need to include a valid postal
        address. Please add your address details to your account by filling in this form and
        clicking the <b>Submit</b> button.
      </HighlightedText>
      <PostalAddressContent submitCallback={onRequestClose} />
    </Modal>
  );
}

PostalAddressModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default PostalAddressModal;
