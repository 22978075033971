import createDataContext from '../../../utils/createDataContext';
import createReducer from '../../../utils/createReducer';
import {
  CLOSE_WIZARD,
  OPEN_WIZARD,
  SAVE_STEP_DATA,
  SET_ACTIVE_STEP,
  SET_STEP_ERROR,
} from './actionTypes';
import {
  initialState,
  closeWizardReducer,
  openWizardReducer,
  saveStepDataReducer,
  setActiveStepReducer,
  saveStepErrorReducer,
} from './reducers';

const wizardReducer = createReducer(initialState, {
  [SET_ACTIVE_STEP]: setActiveStepReducer,
  [SAVE_STEP_DATA]: saveStepDataReducer,
  [OPEN_WIZARD]: openWizardReducer,
  [CLOSE_WIZARD]: closeWizardReducer,
  [SET_STEP_ERROR]: saveStepErrorReducer,
});

// TODO when doing refactor with Typescript, remove action creators to use dispatch only
function setActiveStep(dispatch) {
  return function (nextStep, skipped = false) {
    dispatch({type: SET_ACTIVE_STEP, payload: {nextStep, skipped}});
  };
}

function setStepError(dispatch) {
  return function (error, step) {
    dispatch({type: SET_STEP_ERROR, payload: error, meta: step});
  };
}

function setStepData(dispatch) {
  return function (data, step) {
    dispatch({type: SAVE_STEP_DATA, payload: data, meta: step});
  };
}

function openWizard(dispatch) {
  return function () {
    dispatch({type: OPEN_WIZARD});
  };
}

function closeWizard(dispatch) {
  return function () {
    dispatch({type: CLOSE_WIZARD});
  };
}

export const {Context, Provider} = createDataContext(
  wizardReducer,
  {
    setActiveStep,
    setStepData,
    openWizard,
    closeWizard,
    setStepError,
  },
  initialState
);
