import {useState} from 'react';
import EmojiPicker from 'emoji-picker-react';
import {cx} from '@emotion/css';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import {useClickAway} from '@uidotdev/usehooks';
import {Icon as EditorIcon} from '../SlateEditor/components';
import styles from './Emoji.module.scss';

export const POSITION = {
  x: {
    left: 'left',
    center: 'x-center',
    right: 'right',
  },
  y: {
    top: 'top',
    center: 'y-center',
    bottom: 'bottom',
  },
};

const positionToClass = {
  [POSITION.x.left]: styles.left,
  [POSITION.x.center]: styles.xCenter,
  [POSITION.x.right]: styles.right,
  [POSITION.y.top]: styles.top,
  [POSITION.y.center]: styles.yCenter,
  [POSITION.y.bottom]: styles.bottom,
};

const defaultPosition = {
  x: POSITION.x.left,
  y: POSITION.y.bottom,
};

const Emoji = ({className, position = defaultPosition, ...restProps}) => {
  const [isEmojiModalOpen, setEmojiModalOpen] = useState(false);
  const {onEmojiClick, ...pickerProps} = restProps;

  const pickerWrapperRef = useClickAway(() => {
    setEmojiModalOpen(false);
  });

  return (
    <div className={cx(styles.emojiContainer, className)} ref={pickerWrapperRef}>
      <EditorIcon onClick={() => setEmojiModalOpen(true)} className={styles.emojiButton}>
        emoji_emotions
      </EditorIcon>
      {isEmojiModalOpen && (
        <div
          className={cx(
            styles.pickerWrapper,
            positionToClass[position.x],
            positionToClass[position.y]
          )}
        >
          <EmojiPicker
            previewConfig={{showPreview: false}}
            lazyLoadEmojis
            {...pickerProps}
            onEmojiClick={(emojiData) => {
              onEmojiClick?.(emojiData);
              setEmojiModalOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

Emoji.propTypes = {
  position: PropTypes.shape({
    x: PropTypes.oneOf([POSITION.x.left, POSITION.x.center, POSITION.x.right]).isRequired,
    y: PropTypes.oneOf([POSITION.y.top, POSITION.y.center, POSITION.y.bottom]).isRequired,
  }),
};

export default Emoji;
