import React from 'react';
import {SubscriptionFormBuilderContext} from '../context';
import get from 'lodash.get';
import {updateConfigProp} from '../context/actions';

export const useHandleConfigParam = (paramPath) => {
  const {dispatch, formState} = React.useContext(SubscriptionFormBuilderContext);

  const value = React.useMemo(() => (paramPath ? get(formState, paramPath) : ''), [
    paramPath,
    formState,
  ]);

  const handleInputChange = React.useCallback(
    (e) => {
      if (paramPath) {
        const isCheckbox = e?.target?.type === 'checkbox';

        let v = '';
        if (isCheckbox) {
          v = e.target.checked;
        } else {
          v = typeof e?.target === 'object' ? e.target.value : e;

          if (e?.target?.type === 'number') {
            v = parseFloat(v);
          }
        }

        dispatch(updateConfigProp(paramPath, v));
      }
    },
    [dispatch, paramPath]
  );

  return [value, handleInputChange];
};
