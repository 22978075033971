import {useCallback, useEffect, useMemo, useRef} from 'react';
import ReactFlow, {addEdge, ConnectionLineType, useReactFlow, Controls} from 'reactflow';
import dagre from 'dagre';
import {v4 as uuidv4} from 'uuid';
import 'reactflow/dist/style.css';
import TriggerNode from '../CustomNodes/TriggerNode';
import StepNode from '../CustomNodes/StepNode';
import EndNode from '../CustomNodes/EndNode';
import addActionEdge from '../CustomEdges/addActionEdge';
import conditionalEdge from '../CustomEdges/conditionalEdge';
import useRouter from '../../../hooks/useRouter';
import {NO_VALUE} from '../CustomNodes/hooks/use-get-trigger-value-options';
import {convertJsonToGraph, position} from '../utils/jsonToGraph';
import {useAutomationFlow} from '../stores/automationFlow';
import {useAutomation} from '../stores/automation';
import {automationStatus} from '../utils/automationFlow';
import AutomationUpdateAlert from '../modals/AutomationUpdateAlert';
import './index.css';

export const initialNodes = [
  {
    id: uuidv4(),
    type: 'startNode',
    data: {height: 50, width: 220},
    position,
  },
];

const initialConfig = {
  name: '',
  status: automationStatus.DRAFT,
  steps: [],
  triggers: [],
  startStepId: '',
  data: {
    width: 220,
    height: 50,
  },
};

const nodeWidth = 220;
const nodeHeight = 80;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({rankdir: direction});

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: node.data?.height || nodeHeight,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    // @TODO: fix this
    // eslint-disable-next-line no-param-reassign
    node.targetPosition = isHorizontal ? 'left' : 'top';
    // eslint-disable-next-line no-param-reassign
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';
    // eslint-disable-next-line no-param-reassign
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - (node.data?.height || nodeHeight) / 2,
    };

    return node;
  });

  return {nodes, edges};
};

const Automation = () => {
  const {nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange} = useAutomationFlow();
  const {nodes: layoutedNodes, edges: layoutedEdges} = getLayoutedElements(initialNodes, []);
  const reactFlowInstance = useReactFlow();
  const {
    fetchAutomationConfig,
    fieldsLoading,
    setStepReportData,
    setAutomationReport,
    automationData,
    setAutomationData,
    isConfigUpdate,
    setIsConfigUpdate,
    resetAutomationState,
    initAutomationData,
  } = useAutomation();

  const {location} = useRouter();
  const wrapperRef = useRef(null);

  useEffect(() => {
    const getAutomation = async () => {
      if (location?.state?.draft) {
        const {draft} = location.state;

        initAutomationData(draft);

        const {n, e} = convertJsonToGraph(draft);

        reactFlowInstance.setNodes(n);

        reactFlowInstance.setEdges(e);
        getLayoutedElements(n, e);
        return;
      }

      try {
        if (!location?.state?.id) {
          initAutomationData(initialConfig);
          reactFlowInstance.setNodes(layoutedNodes);
          reactFlowInstance.setEdges(layoutedEdges);
          return;
        }

        const automationId = location.state?.id;

        const automation = await fetchAutomationConfig(automationId);

        setAutomationReport({status: automation?.config?.status, ...automation?.report});
        setStepReportData(automation?.stepReports);
        const {config} = automation;

        if (config) {
          setAutomationData(config);
          const {n, e} = convertJsonToGraph(config, automation.config.status);

          reactFlowInstance.setNodes(n);

          reactFlowInstance.setEdges(e);
          getLayoutedElements(n, e);
        } else {
          reactFlowInstance.setNodes(layoutedNodes);
          reactFlowInstance.setEdges(layoutedEdges);
        }
      } catch (e) {
        reactFlowInstance.setNodes(layoutedNodes);
        reactFlowInstance.setEdges(layoutedEdges);
      }
    };

    getAutomation();

    return () => {
      setAutomationReport();
    };
    // @TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.id]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({...params, type: ConnectionLineType.SmoothStep, animated: true}, eds)
      ),
    // @TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const nodeTypes = useMemo(
    () => ({
      startNode: TriggerNode,
      triggerNode: TriggerNode,
      stepNode: StepNode,
      startStep: StepNode,
      conditionalNode: StepNode,
      endNode: EndNode,
    }),
    []
  );

  const edgeTypes = useMemo(() => ({addActionEdge, conditionalEdge}), []);

  const updateConfig = () => {
    const config = automationData || initialConfig;
    const startStepNode = nodes.find((n) => n.data.isStartStep || n.type === 'startStep');
    const startStepId =
      startStepNode?.id && edges.find((e) => e.source === startStepNode.id)?.target;
    const filteredTriggers = nodes.filter((node) => node.type === 'triggerNode');
    const filteredSteps = nodes.filter((node) => node.type === 'stepNode');
    const triggers = filteredTriggers.map(({data, id}) =>
      data.triggerValue && data.triggerValue !== NO_VALUE
        ? {
            id: data?.idAssigned ? id : '',
            triggerType: data.triggerType,
            triggerParam: {
              id: data.triggerValue,
              type: 'id_trigger_param',
            },
            isGeneral: false,
          }
        : {
            id: data?.idAssigned ? id : '',
            triggerType: data.triggerType,
            isGeneral: true,
          }
    );
    const steps = filteredSteps.map((step) => {
      const stepParam = {};
      const nextStepId = edges.find((edge) => edge.source === step.id)?.target;
      if (
        nextStepId &&
        nodes.filter((node) => node.id === nextStepId)[0]?.type !== 'conditionalNode'
      ) {
        stepParam.nextStepId = nextStepId;
      } else {
        const nextStepEdgeExists = edges.find((edge) => edge.source === nextStepId);
        if (nextStepEdgeExists && step.data.actionTypeValue !== 'EITHER_OR_CONDITION') {
          stepParam.nextStepId = nextStepEdgeExists.target;
        }
      }

      const stepType = step.data.actionTypeValue;

      if (stepType === 'EITHER_OR_CONDITION') {
        const ifFalseEdge = edges.find((edge) => edge.source === step.data.falseEdgeSourceId);
        const ifTrueEdge = edges.find((edge) => edge.source === step.data.trueEdgeSourceId);
        const ifFalseStepId =
          ifFalseEdge && nodes.find((node) => node.id === ifFalseEdge.target)?.id;
        const ifTrueStepId = ifTrueEdge && nodes.find((node) => node.id === ifTrueEdge.target)?.id;
        stepParam.filterExpression = step.data.actionValue;
        stepParam.nextStepIdIfFalse = ifFalseStepId;
        stepParam.nextStepIdIfTrue = ifTrueStepId;
        stepParam.type = 'either_or_param';
      } else if (stepType === 'FIXED_DELAY') {
        stepParam.delayAmount =
          (step.data.actionAmount && parseInt(step.data.actionAmount, 10)) || 0;
        stepParam.timeUnit = step.data.actionValue;
        stepParam.type = 'fixed_delay_param';
      } else if (stepType === 'FIELD_DELAY') {
        stepParam.missingDateFieldAction = step.data.missingDateFieldAction;
        stepParam.type = 'field_delay_param';
        stepParam.dateFieldId = step.data.actionValue;
      } else if (stepType === 'UPDATE_FIELD') {
        stepParam.fieldId = step.data.actionValue;
        stepParam.value = step.data.actionFieldValue;
        stepParam.type = 'field_value_param';
      } else if (step.data?.actionValue) {
        stepParam.type = 'id_param';
        stepParam.id = step.data.actionValue;
      }

      return stepType === 'UNSUBSCRIBE'
        ? {
            id: step.id,
            stepType,
          }
        : {
            id: step.id,
            stepType,
            stepParam,
          };
    });
    config.startStepId = startStepId;
    config.triggers = triggers;
    config.steps = steps;

    setAutomationData(config);
  };

  useMemo(() => {
    const elements = getLayoutedElements(nodes, edges);
    const maxX = Math.max(...elements.nodes.map((node) => node.position.x + nodeWidth));

    reactFlowInstance.fitBounds({
      x: (maxX - wrapperRef.current?.offsetWidth) / 2,
      y: 0,
      width: wrapperRef.current?.offsetWidth,
      height: wrapperRef.current?.offsetHeight,
    });

    updateConfig();

    // @TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes.length]);

  useEffect(() => {
    if (isConfigUpdate) {
      updateConfig();
      setIsConfigUpdate(false);
    }
    // @TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigUpdate]);

  useEffect(() => {
    return () => {
      resetAutomationState();
    };
    // @TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !fieldsLoading && (
      <div style={{width: '100%', height: '100%'}} ref={wrapperRef}>
        <AutomationUpdateAlert />
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeMouseEnter={(event, node) => {
            let updatedNodes = [...nodes];
            const nodeIndex = updatedNodes.findIndex((item) => item.id === node.id);
            updatedNodes = updatedNodes.map((n, index) =>
              index === nodeIndex ? {...n, selected: true} : {...n, selected: false}
            );
            setNodes(updatedNodes);
          }}
          connectionLineType={ConnectionLineType.SmoothStep}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={{hideAttribution: true}}
          nodesDraggable={false}
        >
          <Controls showInteractive={false} />
        </ReactFlow>
      </div>
    )
  );
};

export default Automation;
