import cx from 'clsx';
import styles from '../Field.module.scss';

const FieldError = ({errors}) => (
  <>
    {errors.map((error, i) => (
      <div key={i} className={cx(styles.error, 'text-error')}>
        {error}
      </div>
    ))}
  </>
);

export default FieldError;
