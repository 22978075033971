import PropTypes from 'prop-types';

import styles from './CheckoutAgreement.module.scss';

function CheckoutAgreement({children}) {
  return <div className={styles.agreement}>{children}</div>;
}

CheckoutAgreement.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CheckoutAgreement;
