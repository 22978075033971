import PropTypes from 'prop-types';
import {
  useCreateAutomationDraft,
  useCreateRevisionWithRedirectedRefs,
} from 'domain/automation/hooks/services';
import {useAutomationRevisionErrorHandler} from 'domain/automation/hooks/handleError';
import AutomationList, {AutomationListPropTypes} from './AutomationList';
import AlertModal from '../AlertModal';

const AutomationWithReferencesAlert = ({
  isOpen,
  automationInitiators,
  onClose,
  onSuccess,
  onError,
}) => {
  const createAutomationDraftService = useCreateAutomationDraft();
  const createRevisionWithRedirectedRefsService = useCreateRevisionWithRedirectedRefs();
  const handleAutomationRevisionError = useAutomationRevisionErrorHandler();

  const handleCreateRevisionWithRedirectedRefs = async () => {
    try {
      await createRevisionWithRedirectedRefsService.handler();
      onSuccess();
    } catch (error) {
      handleAutomationRevisionError(error);
      onError(error);
    }
  };

  const handleCreateAutomationDraft = async () => {
    try {
      await createAutomationDraftService.handler();
      onSuccess();
    } catch (error) {
      handleAutomationRevisionError(error);
      onError(error);
    }
  };

  return (
    <AlertModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Resolve Revision Issues"
      agreeColor="green"
      agreeText="Update References"
      onAgree={handleCreateRevisionWithRedirectedRefs}
      agreeLoading={createRevisionWithRedirectedRefsService.loading}
      agreeDisabled={
        createRevisionWithRedirectedRefsService.loading || createAutomationDraftService.loading
      }
      cancelColor="green"
      cancelText="Save as Draft"
      onCancel={handleCreateAutomationDraft}
      cancelLoading={createAutomationDraftService.loading}
      cancelDisabled={
        createRevisionWithRedirectedRefsService.loading || createAutomationDraftService.loading
      }
    >
      <p>
        Notice: You&apos;ve chosen to create a new revision of an active automation flow. Conflicts
        have been identified with other automations linked to this flow that need resolution.
      </p>
      <AutomationList items={automationInitiators} className="text" />
      <p>Choose an option:</p>
      <p>
        <b>Save as Draft</b>: Keep the current version active. Save your changes as a draft,
        allowing you to manually resolve connections to affected automations later.
      </p>
      <p>
        <b>Update References</b>: Adjust all affected automations to point to the new revision of
        this automation.
      </p>
    </AlertModal>
  );
};

AutomationWithReferencesAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  automationInitiators: AutomationListPropTypes,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AutomationWithReferencesAlert;
