import cx from 'clsx';
import Icon from '../../../components/Icon';
import styles from './Tag.module.scss';

function Tag({id, name, onClick, selectable, selected}) {
  return (
    <div
      className={cx(styles.tag, {
        [styles.selectable]: selectable,
        [styles.selected]: selected,
      })}
    >
      {name}
      <Icon
        name={selected ? 'close-from-plus' : 'plus'}
        className={styles.icon}
        onClick={() => onClick(id, selected)}
      />
    </div>
  );
}

export default Tag;
