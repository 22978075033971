import PropTypes from 'prop-types';
import cx from 'clsx';
import Input from '../../../../../components/forms/Input';
import FieldError from '../FieldError';
import fieldStyles from '../Field.module.scss';

const TextField = ({label, value, onChange, className, errors, ...props}) => (
  <div className={cx(fieldStyles.field, className)}>
    <label className={fieldStyles.labelContainer}>
      <span className={fieldStyles.label}>{label}</span>
    </label>
    <div>
      <Input onChange={onChange} value={value} noBottomSpace dense {...props} />
    </div>
    {errors && <FieldError errors={errors} />}
  </div>
);

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  paramPath: PropTypes.string,
};

export default TextField;
