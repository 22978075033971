import PropTypes from 'prop-types';
import {Children, cloneElement} from 'react';

import Modal from '../../../components/Modal';
import useAuth from '../../auth/useAuth';
import styles from './EmailModal.module.scss';

function EmailModal({isOpen, onRequestClose, children}) {
  const {state} = useAuth();

  function renderContent() {
    const {email} = state.user;
    return Children.map(children, (child) => cloneElement(child, {email, onRequestClose}));
  }
  return (
    <Modal onRequestClose={onRequestClose} isOpen={isOpen} headerLeft className={styles.modal}>
      {renderContent()}
    </Modal>
  );
}

EmailModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default EmailModal;
