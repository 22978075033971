import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './Subtitle.module.scss';

function Subtitle({children, topSpace, className}) {
  return (
    <h4 className={cx(styles.subtitle, {[styles.topSpace]: topSpace}, className)}>{children}</h4>
  );
}

Subtitle.propTypes = {
  children: PropTypes.node.isRequired,
  topSpace: PropTypes.bool,
  className: PropTypes.string,
};

export default Subtitle;
