import {useEffect, useState} from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Icon from '../../../components/Icon';
import styles from './MenuItem.module.scss';
import useRouter from '../../../hooks/useRouter';
import Menu from '../Menu';

function MenuItem({pathname, active, icon, SvgIcon, childRoutes, text, hideInvoice}) {
  const {location} = useRouter();
  const [subMenuOpen, setSubMenuOpen] = useState(
    location.pathname.includes(pathname) && location.pathname !== '/settings/subscription-flow'
  );
  // @TODO improve this
  const isActive =
    (childRoutes &&
      location.pathname.includes(pathname) &&
      location.pathname !== '/settings/subscription-flow') ||
    active?.test(location.pathname) ||
    location.pathname === pathname;

  function handleClick(e) {
    if (childRoutes) {
      e.preventDefault();
      setSubMenuOpen(!subMenuOpen);
    }
  }

  useEffect(() => {
    if (
      location.pathname.includes(pathname) &&
      location.pathname !== '/settings/subscription-flow'
    ) {
      setSubMenuOpen(true);
    }
  }, [location.pathname]);

  const getChildRoutes = () => {
    const subRoutes = hideInvoice
      ? childRoutes.filter((route) => route.label !== 'Invoices')
      : childRoutes;

    //   const prodHostname = 'app.maildroppa.com';

    //   if (window.location.hostname == prodHostname)
    //     subRoutes = subRoutes.filter((route) => route.label !== 'Affiliate');
    // I will leave this logic here maybe it should be useful in  the future

    return subRoutes;
  };

  return (
    <li
      className={cx({
        [styles.active]: isActive,
        [styles.submenuOpen]: subMenuOpen,
        [styles.hasChildren]: !!childRoutes,
      })}
    >
      <Link
        className={styles.content}
        to={pathname}
        title="Click to navigate"
        onClick={handleClick}
      >
        {icon && <Icon name={icon} className={styles.icon} />}
        {SvgIcon && <SvgIcon className={styles.icon} />}
        <span className={styles.text}>{text}</span>
      </Link>
      {childRoutes && subMenuOpen && (
        <Menu menuItems={getChildRoutes()}>
          {(item) => (
            <MenuItem
              key={item.label}
              text={item.label}
              label={item.label}
              icon={item.icon}
              childRoutes={item.children}
              pathname={item.pathname}
              active={item.active}
            />
          )}
        </Menu>
      )}
    </li>
  );
}

MenuItem.propTypes = {
  icon: PropTypes.string,
  SvgIcon: PropTypes.func,
  pathname: PropTypes.string,
  active: PropTypes.instanceOf(RegExp),
  text: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  childRoutes: PropTypes.arrayOf(PropTypes.object),
};

export default MenuItem;
