import React from 'react';

import ToolbarSection from './ToolbarSection';
import TextField from './TextField';
import styles from './Toolbar.module.scss';
import {SubscriptionFormBuilderContext} from '../../context';
import RadioField from './RadioField';
import {PROGRESS_BAR_OPTIONS} from '../../constants';
import FontOptionsSection from './FontOptionsSection';
import SubscriptionColorField from './SubscriptionColorField';

const ProgressBarTypes = ({fontStylesToolbar = false, isSection = false}) => {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);

  const titleProps = isSection ? {sectionName: 'Progress Bar Styles'} : {title: 'Progress Bar'};

  if (!formState.jsonConfig.body.progressBarType) return null;

  return (
    <>
      <ToolbarSection {...titleProps} className={styles.toolbarContent}>
        <RadioField options={PROGRESS_BAR_OPTIONS} paramPath="jsonConfig.body.progressBarType" />
        {formState.jsonConfig.body.progressBarType === '50%' && (
          <>
            <TextField
              label="Text"
              paramPath="jsonConfig.body.progressBarPercentStyle.text.value"
            />
            <SubscriptionColorField
              label="Background Color"
              paramPath="jsonConfig.body.progressBarPercentStyle.backgroundColor"
            />
          </>
        )}
        {formState.jsonConfig.body.progressBarType === '1/2' && (
          <>
            <TextField
              label="Step 1"
              paramPath="jsonConfig.body.progressBarStepStyle.text.stepOneValue"
            />
            <TextField
              label="Step 2"
              paramPath="jsonConfig.body.progressBarStepStyle.text.stepTwoValue"
            />

            <SubscriptionColorField
              label="Active step color"
              paramPath="jsonConfig.body.progressBarStepStyle.backgroundColorActive"
            />
            <SubscriptionColorField
              label="Inactive step color"
              paramPath="jsonConfig.body.progressBarStepStyle.backgroundColorInactive"
            />
          </>
        )}
      </ToolbarSection>
      {formState.jsonConfig.body.progressBarType === '50%' && fontStylesToolbar && (
        <FontOptionsSection
          pathPrefix="jsonConfig.body.progressBarPercentStyle.text"
          sectionTitle="Slider Text Styles"
        />
      )}
    </>
  );
};

export default ProgressBarTypes;
