import PropTypes from 'prop-types';
import FlowTooltip from '../FlowTooltip';
import {Icon} from '../../../../../components/SlateEditor/components';
import styles from './ActionButtonsTooltip.module.scss';

const ActionButtonsTooltip = ({zoomLevel, buttons, className}) => {
  return (
    <FlowTooltip zoomLevel={zoomLevel} className={className}>
      <div className={styles.buttons}>
        {buttons.map(({icon, handler}) => (
          <Icon
            key={icon}
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              handler();
            }}
          >
            {icon}
          </Icon>
        ))}
      </div>
    </FlowTooltip>
  );
};

ActionButtonsTooltip.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

export default ActionButtonsTooltip;
