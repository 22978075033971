import {Helmet} from 'react-helmet';
import NewsletterTemplatesContainer from './NewsletterTemplatesContainer';

const NewsletterTemplatesPage = () => {
  return (
    <>
      <Helmet title="Campaign Templates" />
      <NewsletterTemplatesContainer />
    </>
  );
};

export default NewsletterTemplatesPage;
