import {useEffect, useState} from 'react';
import format from 'date-fns/format';
import cx from 'clsx';
import DatePicker from './DatePicker';
import styles from './StatsTabs.module.scss';
import SubscribersReportChart from '../tabsContent/SubscribersReportChart';
import {REPORT_TYPES} from '../constants';
import {fetchSubscribersReportTotalService, fetchSubscribersReportDeltaService} from '../service';
import useFetch from '../../../hooks/useFetch';
import {BLUE_COLOR, GREEN_COLOR} from '../../../components/chart/config';
import IntervalPicker from './IntervalPicker';
import StatsSummaryShort from './StatsSummaryShort';

const tabs = [
  {
    key: 'total',
    name: 'Subscriber Total',
    dataKey: 'confirmed',
    tooltipKeys: [],
    color: BLUE_COLOR,
  },
  {
    key: 'delta',
    name: 'Subscriber Growth',
    dataKey: 'confirmed',
    tooltipKeys: ['pending', 'unsubscribed', 'bounced', 'blocked', 'deleted'],
    color: GREEN_COLOR,
  },
];

function StatsTabs() {
  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const [reportType, setReportType] = useState(REPORT_TYPES[0]);
  const [fetchParams, setFetchParams] = useState(null);
  const [reportRange, setReportRange] = useState(null);

  const {data: dataTotal, loading: loadingTotal, run: runTotal} = useFetch(
    fetchSubscribersReportTotalService
  );
  const {data: dataDelta, loading: loadingDelta, run: runDelta} = useFetch(
    fetchSubscribersReportDeltaService
  );

  const data = reportType === REPORT_TYPES[0] ? dataTotal : dataDelta;

  const getLoading = () => {
    return reportType === REPORT_TYPES[0] ? loadingTotal : loadingDelta;
  };

  const getFetchRequest = () => {
    return reportType === REPORT_TYPES[0] ? runTotal : runDelta;
  };

  useEffect(() => {
    if (fetchParams) {
      getFetchRequest()(fetchParams);
    }
  }, [reportType, fetchParams]);

  useEffect(() => {
    setReportType(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (!data?.reportPeriod) {
      return;
    }
    const {fromDate, toDate} = data.reportPeriod;
    setReportRange([new Date(Date.parse(fromDate)), new Date(Date.parse(toDate))]);
  }, [data?.reportPeriod]);

  const handleIntervalChange = ({interval}) => {
    setFetchParams({
      ...fetchParams,
      interval,
    });
  };

  const handleDatesChange = ({startDate, endDate}) => {
    setFetchParams({
      ...fetchParams,
      fromDate: format(startDate, 'yyyy-MM-dd'),
      toDate: format(endDate, 'yyyy-MM-dd'),
    });
  };

  return (
    <section className={styles.main}>
      <div className={styles.tab_list}>
        {tabs.map((tab) => (
          <button
            key={tab.key}
            type="button"
            className={cx(styles.tab, activeTab === tab.key && styles.tab_active)}
            style={{'--color': tab.color}}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.name}
          </button>
        ))}
        <IntervalPicker classes={styles.intervalpicker} handleChange={handleIntervalChange} />
        <DatePicker
          classes={styles.datepicker}
          handleChange={handleDatesChange}
          reportRange={reportRange}
        />
      </div>
      <div>
        <StatsSummaryShort />
        {tabs
          .filter((tab) => activeTab === tab.key)
          .map((tab) => (
            <article key={tab.key}>
              <SubscribersReportChart
                entryType={tab}
                reportType={reportType}
                data={data}
                loading={getLoading()}
              />
            </article>
          ))}
      </div>
    </section>
  );
}

export default StatsTabs;
