import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './CreditCardTile.module.scss';
import Label from '../../Label';
import ActionsDropdown from '../../ActionsDropdown';
import ActionDropdownItem from '../../ActionsDropdown/ActionDropdownItem';

function CreditCardTile({
  className,
  number,
  defaultMethod,
  onSetDefault,
  transparent,
  smallHeight,
  onDelete,
  presentational,
  type,
  expiryDate,
  noBorder,
  expired,
  id,
}) {
  function handleDelete() {
    onDelete(id);
  }

  function handleSetDefault() {
    onSetDefault(id);
  }

  function renderActions() {
    if (defaultMethod) {
      return <span className={styles.selectedAction}>primary</span>;
    }

    return (
      <ActionsDropdown>
        <ActionDropdownItem onSelect={handleSetDefault}>Make Primary</ActionDropdownItem>
        <ActionDropdownItem onSelect={handleDelete}>Remove</ActionDropdownItem>
      </ActionsDropdown>
    );
  }

  return (
    <div
      className={cx(
        styles.cc,
        {
          [styles[type]]: type,
          [styles.smallHeight]: smallHeight,
          [styles.transparent]: transparent,
          [styles.noBorder]: noBorder,
        },
        className
      )}
    >
      <div className={styles.main}>
        <span className={styles.ico} />
        <span className={styles.info}>
          <span className={styles.number}>
            <span>&#42;&#42;&#42;&#42;</span>
            <span>&#42;&#42;&#42;&#42;</span>
            <span>&#42;&#42;&#42;&#42;</span>
            {number}
          </span>
          <span className={styles.cardInfo}>{expired ? 'Expired' : `Expires ${expiryDate}`}</span>
        </span>
      </div>
      {!presentational && (
        <>
          <div className={styles.status}>
            <Label color={expired ? 'grey' : 'blue'}>{expired ? 'inactive' : 'active'}</Label>
          </div>
          <div className={styles.actions}>{renderActions()}</div>
        </>
      )}
    </div>
  );
}

CreditCardTile.propTypes = {
  expiryDate: PropTypes.string,
  expired: PropTypes.bool,
  onSetDefault: PropTypes.func,
  onDelete: PropTypes.func,
  type: PropTypes.oneOf(['visa', 'mastercard', 'discover', 'amex']),
  id: PropTypes.string,
  className: PropTypes.string,
  transparent: PropTypes.bool,
  smallHeight: PropTypes.bool,
  defaultMethod: PropTypes.bool,
  presentational: PropTypes.bool,
  noBorder: PropTypes.bool,
  number: PropTypes.string,
};

export default CreditCardTile;
