import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './FormRow.module.scss';
import FormRowTitle from './FormRowTitle';

function FormRow({children, title, hasText, extraSpace, className}) {
  return (
    <div
      className={cx(styles.row, className, {
        [styles.hasText]: hasText,
        [styles.hasTitle]: !!title,
        [styles.extraSpace]: extraSpace,
      })}
    >
      {title && <FormRowTitle>{title}</FormRowTitle>}
      {children}
    </div>
  );
}

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  hasText: PropTypes.bool,
  extraSpace: PropTypes.bool,
  className: PropTypes.string,
};

export default FormRow;
