import PropTypes from 'prop-types';

import {billingPeriodPropType} from '../../../../utils/prop-types';
import styles from './ProductOverview.module.scss';

function SelectedPlan({
  name,
  billingPeriod,
  description,
  price,
  subscriberLimitTo,
  displayPrice = true,
}) {
  return (
    <div className={styles.plan}>
      <div className={styles.info}>
        <div className={styles.titleWrapper}>
          <h4 className={styles.title}>{name}</h4>
          <span>{description}</span>
        </div>
        {displayPrice && (
          <div className={styles.priceWrapper}>
            <strong>{price}</strong>
            <span>{billingPeriod.unit}</span>
            <span className={styles.vat}>(VAT may apply)</span>
          </div>
        )}
      </div>
      <ul className={styles.features}>
        <li className={styles.item}>
          Up to <strong>{subscriberLimitTo}</strong> subscribers
        </li>
        <li className={styles.item}>Unlimited Emails</li>
        <li className={styles.item}>Up to 99% Deliverability</li>
      </ul>
    </div>
  );
}

SelectedPlan.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.string,
  subscriberLimitTo: PropTypes.string.isRequired,
  billingPeriod: billingPeriodPropType,
  displayPrice: PropTypes.bool,
};

export default SelectedPlan;
