import {post} from '../../../utils/api';
import {ENDPOINTS} from '../constants';

async function refreshAccessToken(apiSettings) {
  const data = {
    grant_type: 'refresh_token',
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    refresh_token: apiSettings.refreshToken,
  };

  return post(ENDPOINTS.refreshToken, data, {...apiSettings, isAuthenticated: false});
}

export default refreshAccessToken;
