import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import SendTestEmailForm from './SendTestEmailForm';
import img from '../../../img/envelope-test.svg';
import StatusMessage from '../../../components/StatusMessage/StatusMessage';
import ModalClosableContent from '../../../components/Modal/ModalClosableContent';

function SendTestEmail({
  onRequestClose,
  onSubmit,
  sentEmail,
  defaultEmail,
  errorMessage,
  isSubmitted,
}) {
  const {formState, errors, setError, handleSubmit} = useForm({
    mode: 'onChange',
    defaultValues: {
      email: defaultEmail,
    },
  });

  const handleFormSubmit = async () => {
    return onSubmit({email: defaultEmail}, setError);
  };

  return (
    <ModalClosableContent
      title="Test your newsletter"
      onRequestClose={onRequestClose}
      hideCloseButton={!isSubmitted}
      img={<img src={img} alt="email letter with with a test text label" loading="lazy" />}
    >
      <StatusMessage message={errorMessage} />
      {isSubmitted ? (
        <p className="text-centered">
          We have sent a test email to <strong>{sentEmail}</strong>. Please check your inbox to make
          sure it looks as expected.
        </p>
      ) : (
        <SendTestEmailForm
          handleSubmit={handleSubmit}
          errors={errors}
          formState={formState}
          onSubmit={handleFormSubmit}
          email={defaultEmail}
        />
      )}
    </ModalClosableContent>
  );
}

SendTestEmail.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sentEmail: PropTypes.string,
  defaultEmail: PropTypes.string,
  errorMessage: PropTypes.string,
  isSubmitted: PropTypes.bool,
};

export default SendTestEmail;
