export const updateFullState = (newState) => ({
  type: 'UPDATE_STATE',
  payload: newState,
});

export const setFormType = (formType) => ({
  type: 'SET_FORM_TYPE',
  payload: formType,
});

export const updateConfigProp = (path, value) => ({
  type: 'SET_FORM_CONFIG_PARAM',
  payload: {
    path,
    value,
  },
});

export const addField = (name, label, dataType, placeholder, isRequired = false) => ({
  type: 'ADD_NEW_FIELD',
  payload: {
    name,
    label,
    dataType,
    placeholder,
    isRequired,
  },
});

export const addTag = (name, id) => ({
  type: 'ADD_NEW_TAG',
  payload: {
    name,
    id,
  },
});

export const updateFieldsList = (newInputFields) => ({
  type: 'UPDATE_FIELDS_LIST',
  payload: newInputFields,
});

export const removeField = (fieldName) => ({
  type: 'REMOVE_FIELD',
  payload: fieldName,
});

export const removeTag = (fieldName) => ({
  type: 'REMOVE_TAG',
  payload: fieldName,
});
