import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';

function CheckboxLink({children, path, ...props}) {
  return (
    <a
      href={path}
      className={styles.link}
      title="Click to view"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  );
}

CheckboxLink.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default CheckboxLink;
