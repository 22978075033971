import {Route, Redirect, Switch} from 'react-router-dom';
import ROUTES from './routes';
import LoginPage from '../domain/auth/pages/LoginPage';
import ForgotPasswordPage from '../domain/auth/pages/ForgotPasswordPage';
import PublicLayout from '../layouts/PublicLayout';
import SignupPage from '../domain/auth/pages/SignupPage';
import useRouter from '../hooks/useRouter';

function UnauthenticatedApp() {
  const {location} = useRouter();

  return (
    <PublicLayout>
      <Switch>
        <Route path={ROUTES.auth.login}>
          <LoginPage />
        </Route>
        <Route path={ROUTES.auth.signup}>
          <SignupPage />
        </Route>
        <Route path={ROUTES.auth.forgotPassword}>
          <ForgotPasswordPage />
        </Route>
        <Route path="*">
          <Redirect
            to={{
              pathname: ROUTES.auth.login,
              state: {from: location},
            }}
          />
        </Route>
      </Switch>
    </PublicLayout>
  );
}

export default UnauthenticatedApp;
