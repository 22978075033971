import {
  useElements,
  useStripe,
  CardNumberElement as StripeCardNumberElement,
} from '@stripe/react-stripe-js';
import useFetch from './useFetch';
import {
  addPaymentMethodService,
  fetchSetupIntentService,
} from '../components/payments/PaymentMethods/service';

function useAddNewCard(options = {}) {
  const stripe = useStripe();
  const elements = useElements();
  const {run: fetchSetupIntentRequest} = useFetch(fetchSetupIntentService, {
    hideAlert: options.hideAlert,
    throwError: true,
  });
  const {run: addPaymentMethodRequest} = useFetch(addPaymentMethodService, {
    throwError: options.throwError,
  });

  async function fetchSetupIntent(cardElement) {
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (result.error) {
      throw result.error;
    }

    const setupIntent = await fetchSetupIntentRequest(result.paymentMethod.id);
    return setupIntent?.clientSecret;
  }

  async function addNewCardRequest() {
    if (!stripe || !elements) {
      return undefined;
    }

    const cardElement = elements.getElement(StripeCardNumberElement);
    const setupIntentSecret = await fetchSetupIntent(cardElement);
    const result = await stripe.confirmCardSetup(setupIntentSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (result.error) {
      throw result.error;
    }

    await addPaymentMethodRequest(result.setupIntent.payment_method);

    return result.setupIntent.payment_method;
  }

  return {addNewCardRequest, stripe};
}

export default useAddNewCard;
