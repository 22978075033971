import PropTypes from 'prop-types';
import {useState} from 'react';

import HighlightedText from '../../../components/HighlightedText';
import Subtitle from '../../../components/Subtitle';
import ResendEmailButton from '../components/ResendEmailButton';
import SuccessResendText from '../components/SuccessResendText';
import styles from './EmailConfirmationModalContent.module.scss';

function EmailConfirmationModalContent({email}) {
  const [isResend, setIsResend] = useState(false);

  const onResend = () => setIsResend(true);
  return (
    <>
      <Subtitle noMarginTop>We still need some information from you!</Subtitle>
      {!isResend && (
        <HighlightedText>
          Welcome to Maildroppa! Your account setup is almost complete. We have sent a confirmation
          link to your <b>{email}</b> inbox. Please check for this mail and click the link provided
          to confirm your email address and start sending emails with Maildroppa!
          <div className={styles.textWrapper}>
            If you didn’t get our confirmation email, please
            <ResendEmailButton onResend={onResend} />
            and we will resend it.
          </div>
        </HighlightedText>
      )}
      {isResend && <SuccessResendText email={email} />}
    </>
  );
}

EmailConfirmationModalContent.propTypes = {
  email: PropTypes.string,
};

export default EmailConfirmationModalContent;
