import {useFreePlan} from '../domain/free-plan-info/context/FreePlanContext';

const useContentItem = () => {
  const {state} = useFreePlan();
  const {billingAddressCompleted, postalAddressCompleted} = state;

  const isItemDisplayed = (item) => {
    switch (true) {
      case item.requirePostalAddress && !postalAddressCompleted:
      case item.requireBilling && !billingAddressCompleted:
        return false;
      default:
        return true;
    }
  };

  return {
    isItemDisplayed,
  };
};

export default useContentItem;
