import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, REMEMBER_ME_KEY} from '../constants';

export function setTokensToLocalStorage(tokens) {
  localStorage.setItem(ACCESS_TOKEN_KEY, tokens.accessToken);
  localStorage.setItem(REMEMBER_ME_KEY, tokens.rememberMe);

  if (tokens.rememberMe) {
    localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refreshToken);
  }
}

export function getTokensFromLocalStorage() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const rememberMe = localStorage.getItem(REMEMBER_ME_KEY);

  return {accessToken, refreshToken, rememberMe: rememberMe === 'true'};
}

export function removeTokensFromLocalStorage() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(REMEMBER_ME_KEY);
}
