import PropTypes from 'prop-types';

import styles from './Panel.module.scss';

function Panel({children}) {
  return <div className={styles.panel}>{children}</div>;
}

Panel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Panel;
