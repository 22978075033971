import styles from './SegmentFilter.module.scss';
import Icon from '../../../../components/Icon';

function SegmentAddFilter({onClick, text, classNameButtonWrapper, addStyles = {}}) {
  return (
    <div className={classNameButtonWrapper} style={addStyles}>
      <button type="button" className={styles.addFilter} onClick={onClick}>
        <Icon name="plus-2" className={styles.addFilterIcon} />
        {text}
      </button>
    </div>
  );
}

export default SegmentAddFilter;
