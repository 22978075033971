import PropTypes from 'prop-types';
import styles from './PublicLayout.module.scss';

function PublicLayout({children}) {
  return (
    <div>
      <div className={styles.contentWrapper}>
        <div className={styles.layout}>{children}</div>
      </div>
    </div>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicLayout;
