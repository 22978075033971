import {get, put, post} from '../../../utils/api';
import {ENDPOINTS} from './constants';

export async function fetchBillingDetailsService(apiSettings) {
  return get(ENDPOINTS.fetchBillingDetails, null, apiSettings);
}

export async function updateBillingDetailsService(apiSettings, data) {
  return put(ENDPOINTS.updateBillingDetails, data, apiSettings);
}

export async function addBillingDetailsService(apiSettings, data) {
  return post(ENDPOINTS.addBillingDetails, data, apiSettings);
}

export async function fetchInvoicesService(apiSettings, userParams) {
  const defaultParams = {
    page: 1,
  };

  const params = {
    ...defaultParams,
    ...userParams,
  };

  return get(ENDPOINTS.fetchInvoices, params, apiSettings);
}

export async function fetchInvoicesCountService(apiSettings) {
  return get(ENDPOINTS.fetchInvoicesCount, null, apiSettings);
}

export async function downloadInvoiceService(apiSettings, data) {
  return get(ENDPOINTS.downloadInvoice(data), null, apiSettings, {
    responseType: 'blob',
  });
}
