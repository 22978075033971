import {useContext} from 'react';
import createDataContext from '../../../utils/createDataContext';
import createReducer from '../../../utils/createReducer';
import {
  BULK_DELETE,
  BULK_TOGGLE,
  CHANGE_PER_PAGE_SIZE,
  DESELECT_ROW,
  FORCE_PAGE_CHANGE,
  GO_TO_NEXT_PAGE,
  GO_TO_PREV_PAGE,
  SEARCH,
  SELECT_ROW,
  SET_DATA,
  SET_FETCHER,
  SET_FILTERS,
  SET_LOADING,
  SET_META,
  SET_MODE,
  SET_PROCESSING,
} from './actionTypes';
import {
  setData,
  setProcessing,
  setMeta,
  setMode,
  bulkDelete,
  bulkToggle,
  changePerPageSize,
  deselectRow,
  forcePageChange,
  goToNextPage,
  goToPrevPage,
  search,
  selectRow,
  setFetcher,
  setFilters,
  setLoading,
} from './actions';
import {
  bulkDeleteReducer,
  bulkToggleReducer,
  changePerPageSizeReducer,
  deselectRowReducer,
  forcePageChangeReducer,
  goToNextPageReducer,
  goToPrevPageReducer,
  initialState,
  searchReducer,
  selectRowReducer,
  setDataReducer,
  setFetcherReducer,
  setFilterReducer,
  setLoadingReducer,
  setMetaReducer,
  setModeReducer,
  setProcessingReducer,
} from './reducers';

const tableReducer = createReducer(initialState, {
  [SET_DATA]: setDataReducer,
  [SET_META]: setMetaReducer,
  [SEARCH]: searchReducer,
  [GO_TO_NEXT_PAGE]: goToNextPageReducer,
  [FORCE_PAGE_CHANGE]: forcePageChangeReducer,
  [GO_TO_PREV_PAGE]: goToPrevPageReducer,
  [CHANGE_PER_PAGE_SIZE]: changePerPageSizeReducer,
  [BULK_DELETE]: bulkDeleteReducer,
  [SELECT_ROW]: selectRowReducer,
  [DESELECT_ROW]: deselectRowReducer,
  [BULK_TOGGLE]: bulkToggleReducer,
  [SET_PROCESSING]: setProcessingReducer,
  [SET_FETCHER]: setFetcherReducer,
  [SET_MODE]: setModeReducer,
  [SET_FILTERS]: setFilterReducer,
  [SET_LOADING]: setLoadingReducer,
});

export const {Context, Provider} = createDataContext(
  tableReducer,
  {
    setData,
    setMeta,
    selectRow,
    setFilters,
    bulkToggle,
    deselectRow,
    setProcessing,
    setFetcher,
    setMode,
    search,
    goToNextPage,
    goToPrevPage,
    forcePageChange,
    changePerPageSize,
    bulkDelete,
    setLoading,
  },
  initialState
);

export const useDataTableContext = function () {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useDataTableContext must be used within a DataTableProvider`);
  }
  return context;
};
