import {Link} from 'react-router-dom';
import img from '../../img/404-err.svg';
import Button from '../../components/Button';
import styles from './errorPage.module.scss';
import ROUTES from '../../Router/routes';

function NotFound() {
  return (
    <div className={styles.error}>
      <img src={img} alt="404 - not found" className={styles.img} width="320" height="354" />
      <h1 className={styles.title}>Page not found</h1>
      <p className={styles.text}>
        The page you are looking for was moved, removed, renamed or might never existed.
      </p>
      <div className={styles.btns}>
        <Button
          as={Link}
          to={ROUTES.index}
          variant="filled"
          color="green"
          title="Click to navigate to home"
          className={styles.btn}
        >
          Go back
        </Button>
      </div>
    </div>
  );
}

export default NotFound;
