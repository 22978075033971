import {useCallback, useEffect, useState} from 'react';

import Modal from '../../../../components/Modal';
import {generateSliderCodeSnippet, generateSliderHostedUrl} from '../../generators';
import styles from './SubscriptionFormListPage.module.scss';
import Button from '../../../../components/Button';
import shareImageIcon from '../../../../img/icon-share.svg';
import {Tab, TabList, TabPanel, Tabs} from '../../../../components/Tabs';
import Subtitle from '../../../../components/Subtitle';
import {loadGeneratedFormById} from '../../service';
import useFetch from '../../../../hooks/useFetch';

const SubscriptionFormCodeModal = ({formId, formType, onRequestClose}) => {
  const code = generateSliderCodeSnippet(formId || '');
  const hostedUrl = generateSliderHostedUrl(formId || '');
  const [formHtml, setFormHtml] = useState();
  const [isCoped, setCopied] = useState(false);
  const [isIdCopied, setIdCopied] = useState(false);

  const handleCopy = useCallback(
    (type) => {
      let codeToCopy;
      switch (type) {
        case 'link':
          codeToCopy = hostedUrl;
          break;
        case 'html':
          codeToCopy = formHtml;
          break;
        default:
          codeToCopy = code;

          break;
      }
      navigator.clipboard.writeText(codeToCopy).then(() => {
        setCopied(true);
      });
    },
    [setCopied, code, formHtml, hostedUrl]
  );

  const handleIdCopy = useCallback(
    (id) => {
      navigator.clipboard.writeText(`maildroppa-popup-btn-${id}`).then(() => {
        setIdCopied(true);
      });
    },
    [setIdCopied]
  );

  const {run: loadForm, loading} = useFetch(loadGeneratedFormById);

  useEffect(() => {
    const getForm = async () => {
      const formData = await loadForm(formId);

      // eslint-disable-next-line no-underscore-dangle
      if (!window.__MD__RENDER_TO_STRING) {
        throw new Error('NO window.__MD__RENDER_TO_STRING');
      }

      // eslint-disable-next-line no-underscore-dangle
      setFormHtml(window.__MD__RENDER_TO_STRING?.(formData));
    };
    if (formId) {
      getForm();
    }
  }, [loadForm, formId]);

  const handleClose = useCallback(() => {
    setCopied(false);
    onRequestClose();
  }, [setCopied, onRequestClose]);

  return (
    <Modal
      size="medium"
      isOpen={!!formId}
      title="Embed Form"
      smallPadding
      headerLeft
      onRequestClose={handleClose}
    >
      <div className={styles.cardBody}>
        <Tabs noTopMargin>
          <TabList>
            <Tab>share</Tab>
            <Tab>javascript</Tab>
            <Tab>HTML</Tab>
          </TabList>
          <TabPanel>
            <Subtitle>Use this link to share your form</Subtitle>
            <div className={styles.codeSnippet}>
              <a href={hostedUrl} rel="noreferrer" target="_blank">
                {hostedUrl}
              </a>
            </div>
            <div className={styles.copyModalButton}>
              <Button
                size="small"
                color="green"
                variant="filled"
                onClick={() => handleCopy('link')}
              >
                <img src={shareImageIcon} className={styles.copyModalButtonImage} alt="Copy Code" />
                {isCoped ? 'Copied!' : 'Copy Code'}
              </Button>
            </div>
          </TabPanel>
          <TabPanel>
            <Subtitle>Share this form to have more subscribers</Subtitle>
            <p>
              Signup-Forms are the beginning of your relationship with customers. All you need to do
              is copy this code snippet and put it wherever you want to display the form on your
              site:
            </p>
            <div className={styles.codeSnippet}>{code}</div>
            <div className={styles.copyModalButton}>
              <Button
                size="small"
                color="green"
                variant="filled"
                onClick={() => handleCopy('snippet')}
              >
                <img src={shareImageIcon} className={styles.copyModalButtonImage} alt="Copy Code" />
                {isCoped ? 'Copied!' : 'Copy Code'}
              </Button>
            </div>
            {formType === 'POPUP' && formId && (
              <div>
                <Subtitle topSpace={50}>
                  Add this ID to element from which you want to manually trigger Popup
                </Subtitle>
                <div className={styles.codeSnippet}>{`maildroppa-popup-btn-${formId}`}</div>
                <div className={styles.copyModalButton}>
                  <Button
                    size="small"
                    color="green"
                    variant="filled"
                    onClick={() => handleIdCopy(formId)}
                  >
                    <img
                      src={shareImageIcon}
                      className={styles.copyModalButtonImage}
                      alt="Copy ID"
                    />
                    {isIdCopied ? 'Copied!' : 'Copy ID'}
                  </Button>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <Subtitle>Share this form to have more subscribers</Subtitle>
            <p>
              If you want to create your own signup-form design, copy this code snippet and
              customize it to design your own form. You can also use the code as it is, by adding to
              your website code.
            </p>
            <div className={styles.codeSnippet}>{!loading && formHtml}</div>
            <div className={styles.copyModalButton}>
              <Button
                size="small"
                color="green"
                variant="filled"
                onClick={() => handleCopy('html')}
              >
                <img src={shareImageIcon} className={styles.copyModalButtonImage} alt="Copy Code" />
                {isCoped ? 'Copied!' : 'Copy Code'}
              </Button>
            </div>
            {formType === 'POPUP' && formId && (
              <div>
                <Subtitle topSpace={50}>
                  Add this ID to element from which you want to manually trigger Popup
                </Subtitle>
                <div className={styles.codeSnippet}>{`maildroppa-popup-btn-${formId}`}</div>
                <div className={styles.copyModalButton}>
                  <Button
                    size="small"
                    color="green"
                    variant="filled"
                    onClick={() => handleIdCopy(formId)}
                  >
                    <img
                      src={shareImageIcon}
                      className={styles.copyModalButtonImage}
                      alt="Copy ID"
                    />
                    {isIdCopied ? 'Copied!' : 'Copy ID'}
                  </Button>
                </div>
              </div>
            )}
          </TabPanel>
        </Tabs>
      </div>
    </Modal>
  );
};

export default SubscriptionFormCodeModal;
