import useFetch from './useFetch';
import {getFreePlanInfoService} from '../domain/free-plan-info/service';
import {useFreePlan} from '../domain/free-plan-info/context/FreePlanContext';

const useUpdateFreePlanInfo = () => {
  const {run: getFreePlanInfoRequest} = useFetch(getFreePlanInfoService);
  const {setFreePlanInfo} = useFreePlan();

  async function updateFreePlanInfo() {
    const response = await getFreePlanInfoRequest();
    setFreePlanInfo(response);
  }

  return {updateFreePlanInfo};
};

export default useUpdateFreePlanInfo;
