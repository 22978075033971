import {Redirect} from 'react-router-dom';
import useRouter from '../../../hooks/useRouter';
import Spinner from '../../../components/Spinner';
import ROUTES from '../../../Router/routes';
import FailedNewsletter from './FailedNewsletter';
import useFetchNewsletter from '../hooks/useFetchNewsletter';

function FailedNewsletterContainer() {
  const {history, query} = useRouter();
  const [data] = useFetchNewsletter();

  async function handleCreateFromDraft(id) {
    history.push(`${ROUTES.newsletters.create}?fromDraft=${id}`);
  }

  if (Object.keys(query).length && !data) {
    return <Spinner />;
  }

  if (data.status === 'DRAFT') {
    return <Redirect to={`${ROUTES.newsletters.edit}/${data.id}`} />;
  }

  return <FailedNewsletter data={data} createFromDraft={handleCreateFromDraft} />;
}

export default FailedNewsletterContainer;
