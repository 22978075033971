import {useEffect, useState} from 'react';
import {useDataTableContext} from './context/TableContext';
import CheckBox from '../forms/Checkbox';

function BulkSelect() {
  const {bulkToggle, state} = useDataTableContext();
  const [checked, setChecked] = useState(state.bulkSelected);

  useEffect(
    function () {
      setChecked(state.bulkSelected);
    },
    [state.bulkSelected]
  );

  function handleChange() {
    setChecked(!checked);
    bulkToggle(!checked);
  }

  return <CheckBox id="bulk-select" name="bulk-select" checked={checked} onChange={handleChange} />;
}

export default BulkSelect;
