import styles from './Hero.module.scss';

import img from '../../../../img/laptop.svg';

function Hero() {
  return (
    <div className={styles.hero}>
      <img
        src={img}
        alt="Opened laptop with maildroppa.com website opened"
        width="283"
        height="160"
        className={styles.img}
        loading="lazy"
      />
      <h2 className={styles.subtitle}>The easiest way to write and grow an engaging newsletter</h2>
      <ul className={styles.list}>
        <li className={styles.item}>GDPR compliant by default</li>
        <li className={styles.item}>Optimized for engagement & conversions</li>
        <li className={styles.item}>Excellent delivery rates</li>
      </ul>
    </div>
  );
}

export default Hero;
