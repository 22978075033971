import Modal from '../../../../components/Modal';
import styles from './FormCreatedModal.module.scss';
import HeaderImg from '../../../../img/hooray-header.png';
import Thumbsup from '../../../../img/huge-thumbsup.png';
import Button from '../../../../components/Button';
import {LANDING_URL} from '../../../../utils/constants';
import useRouter from '../../../../hooks/useRouter';

export default ({isOpen, onRequestClose}) => {
  const {history} = useRouter();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modalWrapper}
      whiteCloseButton
    >
      <div className={styles.header} style={{backgroundImage: `url(${HeaderImg})`}}>
        <div className={styles.headerText}>YOU&apos;RE ALL SET!!</div>
      </div>
      <div className={styles.thumbsupWrapper}>
        <img src={Thumbsup} alt="thumbsup" />
      </div>
      <div className={styles.congratsText}>
        Congratulations, your custom form was created.
        <br />
        Сlick the button below if you want to edit the form texts.
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          color="green"
          variant="filled"
          onClick={() => history.push('/settings/subscription-flow')}
        >
          Edit Form Settings
        </Button>
      </div>
      <div className={styles.getHelpText}>
        If you have any questions or need help getting started contact us{' '}
        <a
          href={`${LANDING_URL}/contact-us`}
          title="Click here forhelp"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </div>
    </Modal>
  );
};
