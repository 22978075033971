import img from '../../../img/export-list.svg';
import ModalClosableContent from '../../../components/Modal/ModalClosableContent';

function ExportModalSuccess({onRequestClose}) {
  return (
    <ModalClosableContent
      title="Export Subscribers"
      onRequestClose={onRequestClose}
      img={
        <img
          src={img}
          width="190"
          height="195"
          alt="Placeholder list icon with a users list"
          loading="lazy"
        />
      }
    >
      <p className="text-centered">
        We&apos;re exporting your list of subscribers. You will receive an email when the export is
        available.
      </p>
    </ModalClosableContent>
  );
}

export default ExportModalSuccess;
