import {
  Area,
  Bar,
  ComposedChart,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import Spinner from '../../../components/Spinner';
import {CARTESIAN_GRID_COLOR} from '../../../components/chart/config';
import SubscribersReportTooltipContent from './SubscribersReportTooltipContent';
import {camel2title} from '../../../utils/strings';
import {REPORT_ENTRIES_MIN_COUNT_FOR_AREA} from '../constants';
import styles from './SubscribersReportChart.module.scss';

function SubscribersReportChart({entryType, data, loading}) {
  if (loading) {
    return <Spinner height={352} />;
  }

  if (!data) {
    return null;
  }

  if (!data.entries || !data.entries.length) {
    return <p className={styles.no_data}>There is no data for the selected period.</p>;
  }

  const CartesianComp = data.entries.length > REPORT_ENTRIES_MIN_COUNT_FOR_AREA ? Area : Bar;

  const getYAxisDomainMinMax = (minMax, sign) => {
    if (!Number.isFinite(minMax)) return sign > 0 ? 2 : -1;

    if (minMax === 0) {
      return minMax + sign;
    }
    const additionalRange = Math.max(Math.ceil(Math.log(Math.abs(minMax))), 1);
    return minMax + sign * additionalRange;
  };

  return (
    <ResponsiveContainer width="100%" height={352}>
      <ComposedChart data={data.entries} margin={{top: 40, right: 50, left: 25, bottom: 40}}>
        <defs>
          <linearGradient id="subscribers-report" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0% " stopColor={entryType.color} stopOpacity={0.95} />
            <stop offset="100%" stopColor={entryType.color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} stroke={CARTESIAN_GRID_COLOR} />
        <XAxis dataKey="logDateTime" axisLine={false} tickLine={false} dy={15} />
        <YAxis
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          domain={[
            (dataMin) => getYAxisDomainMinMax(dataMin, -1),
            (dataMax) => getYAxisDomainMinMax(dataMax, 1),
          ]}
          dx={-10}
        />
        <Tooltip
          content={
            <SubscribersReportTooltipContent
              nameFormatter={camel2title}
              keys={entryType.tooltipKeys}
            />
          }
          cursor={{stroke: entryType.color, strokeWidth: 1}}
        />
        <CartesianComp
          strokeWidth={2}
          dataKey={entryType.dataKey}
          stroke={entryType.color}
          maxBarSize={24}
          fill="url(#subscribers-report)"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default SubscribersReportChart;
