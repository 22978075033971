import {Link} from 'react-router-dom';
import logo from '../../img/logo.svg';
import ROUTES from '../../Router/routes';
import styles from './auth.module.scss';

function Logo() {
  return (
    <div className={styles.logo}>
      <Link to={{pathname: ROUTES.salesPage}} target="_blank">
        <img src={logo} width="206" height="30" alt="Maildroppa logo" />
      </Link>
    </div>
  );
}

export default Logo;
