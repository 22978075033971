import cx from 'clsx';
import styles from '../errorPage.module.scss';
import img from '../../../img/5xx-err.svg';
import PublicLayout from '../../PublicLayout';

const Maintenance = () => {
  return (
    <PublicLayout>
      <div className={styles.error}>
        <img
          src={img}
          alt="Maintanance - something went wrong!"
          className={cx(styles.img, styles.img5xx)}
          width="400"
          height="504"
        />
        <h1 className={styles.title}>We’ve got something special for you</h1>
        <p className={styles.text}>
          Maildroppa is down for a planned maintenance. <br />
          Please check back soon.
        </p>
      </div>
    </PublicLayout>
  );
};

export default Maintenance;
