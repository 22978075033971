import Subtitle from '../../../components/Subtitle';
import Button from '../../../components/Button';
import useUpgradePlan from '../../../hooks/useUpgradePlan';
import styles from './SubscribersLimitAlert.module.scss';

function SubscribersLimitAlertContent() {
  const {upgradePlan, loading} = useUpgradePlan();
  return (
    <>
      <Subtitle className={styles.subtitle}>You have reached the limits of your plan!</Subtitle>
      <div className={styles.root}>
        You have reached the maximum subscribers amount. To add new subscribers please delete
        existing subscribers or{' '}
        <Button
          type="submit"
          variant="link"
          color="blue"
          onClick={upgradePlan}
          className={styles.buttonWrapper}
          loading={loading}
        >
          Upgrade Your Plan
        </Button>
      </div>
    </>
  );
}

export default SubscribersLimitAlertContent;
