import PropTypes from 'prop-types';
import styles from './PanelHeader.module.scss';
import {useBoardingWizard} from '../../../context/WizardContext';

function PanelHeader({title, subtitle, hasDetails}) {
  const {state, openPanelPage} = useBoardingWizard();

  function handleAction(event) {
    event.preventDefault();
    openPanelPage(state.activeStep);
  }

  function renderSubTitle() {
    if (typeof subtitle === 'function') {
      return subtitle();
    }

    return subtitle;
  }

  return (
    <div>
      <h2 className={styles.title}>{title}</h2>
      {subtitle && <p className={styles.text}>{renderSubTitle()}</p>}
      {hasDetails && (
        <p className={styles.text}>
          Click{' '}
          <a href="/" title="Click to read more" onClick={handleAction}>
            here
          </a>{' '}
          for more info.
        </p>
      )}
    </div>
  );
}

PanelHeader.defaultProps = {
  subtitle: '',
};

PanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  hasDetails: PropTypes.bool,
};

export default PanelHeader;
