import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './Switch.module.scss';

const Switch = ({onChange, value = false, className}) => {
  const handleChange = () => onChange?.(!value);

  return (
    <button
      type="button"
      className={cx([styles.switch, {[styles.on]: value}, className])}
      onClick={handleChange}
    >
      <div className={styles.track}>
        <div className={styles.slider} />
      </div>
    </button>
  );
};

Switch.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.bool,
};

export default Switch;
