import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import FormRow from '../../forms/FormRow/FormRow';
import Input from '../../forms/Input';
import {CardCvcElement, CardExpiryElement, CardNumberElement} from '../../forms/StripeElements';
import {fieldRequired, showError} from './validation';

import styles from './CreditCardForm.module.scss';

function handleStripeInputChange(event) {
  return event;
}

function CreditCardForm({errors, control}) {
  return (
    <div className={styles.form}>
      <Input label="Card Number" error={errors?.cardNumber} creditCardInput required>
        <Controller
          name="cardNumber"
          as={CardNumberElement}
          control={control}
          rules={{
            validate: {
              required: fieldRequired,
              error: showError,
            },
          }}
          onChange={handleStripeInputChange}
          defaultValue={{}}
        />
      </Input>
      <FormRow>
        <Input label="Expiry Date" error={errors?.cardExpire} required>
          <Controller
            name="cardExpire"
            as={CardExpiryElement}
            control={control}
            rules={{
              validate: {
                required: fieldRequired,
                error: showError,
              },
            }}
            onChange={handleStripeInputChange}
            defaultValue={{}}
            options={{
              style: {
                base: {
                  textAlign: 'center',
                },
              },
            }}
          />
        </Input>
        <Input label="CVC" error={errors?.cardCVC} required>
          <Controller
            name="cardCVC"
            as={CardCvcElement}
            control={control}
            rules={{
              validate: {
                required: fieldRequired,
                error: showError,
              },
            }}
            onChange={handleStripeInputChange}
            defaultValue={{}}
            options={{
              style: {
                base: {
                  textAlign: 'center',
                },
              },
            }}
          />
        </Input>
      </FormRow>
    </div>
  );
}

CreditCardForm.propTypes = {
  errors: PropTypes.objectOf(PropTypes.object).isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CreditCardForm;
