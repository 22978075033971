import useFetch from '../../../hooks/useFetch';
import {
  saveAutomationConfigService,
  fetchAutomationConfigService,
  checkAutomationReferencesService,
  fetchAutomationInitiators,
  createAutomationRevisionWithRedirectedRefsService,
  updateAutomationConfigService,
} from '../service';

export const useFetchAutomation = (fetchOptions = {}) => {
  return useFetch(fetchAutomationConfigService, fetchOptions);
};

export const useSaveAutomation = (fetchOptions = {}) => {
  return useFetch(saveAutomationConfigService, fetchOptions);
};

export const useCheckReferences = (fetchOptions = {}) => {
  return useFetch(checkAutomationReferencesService, fetchOptions);
};

export const useAutomationInitiators = (fetchOptions = {}) => {
  return useFetch(fetchAutomationInitiators, fetchOptions);
};

export const useCreateAutomationRevisionWithRedirectedRefs = (fetchOptions = {}) => {
  return useFetch(createAutomationRevisionWithRedirectedRefsService, fetchOptions);
};

export const useUpdateAutomationConfig = (fetchOptions = {}) => {
  return useFetch(updateAutomationConfigService, fetchOptions);
};
