import PropTypes from 'prop-types';
import styles from './Toolbar.module.scss';
import Input from '../../../../components/forms/Input';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';

const TextField = ({paramPath, label, handler, ...props}) => {
  const [value, handleChange] = handler ? handler(paramPath) : useHandleConfigParam(paramPath);
  return (
    <div className={styles.toolbarFieldContainer}>
      <div className={styles.toolbarFieldLabelContainer}>
        <span className={styles.toolbarFieldLabel}>{label}</span>
      </div>
      <Input onChange={handleChange} value={value} noBottomSpace dense {...props} />
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  paramPath: PropTypes.string,
};

export default TextField;
