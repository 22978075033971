import {Provider as DataTableProvider} from './context/TableContext';
import DataTableContainer from './DataTableContainer';

function Provider(props) {
  return (
    <DataTableProvider>
      <DataTableContainer {...props} />
    </DataTableProvider>
  );
}

export default Provider;
