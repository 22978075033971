import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './Card.module.scss';
import {Icon} from '../SlateEditor/components';

function CardHeader({
  children,
  title,
  text,
  centered,
  noBottomBorder,
  className,
  cardTitleIcon,
  onIconClick,
}) {
  return (
    <div
      className={cx(styles.header, className, {
        [styles.centered]: centered,
        [styles.noBottomBorder]: noBottomBorder,
      })}
    >
      <div className={styles.headerInner}>
        <div className={styles.headerInner__item}>
          <div style={{display: 'flex'}}>
            <h1 className={styles.title}>{title}</h1>
            {cardTitleIcon && (
              <div onClick={onIconClick}>
                <Icon style={{color: 'grey', marginLeft: 5, cursor: 'pointer'}}>
                  {cardTitleIcon}
                </Icon>
              </div>
            )}
          </div>
          {text && (
            <div className={styles.text}>{typeof text === 'string' ? <p>{text}</p> : text}</div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

CardHeader.defaultProps = {
  centered: false,
};

CardHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  centered: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
  children: PropTypes.node,
};

export default CardHeader;
