import Button from '../../../components/Button';
import Input from '../../../components/forms/Input';

function SendTestEmailForm({onSubmit, handleSubmit, formState, errors, email}) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input type="email" name="email" error={errors.email} value={email} readOnly />
      <Button
        type="submit"
        variant="filled"
        color="green"
        full
        disabled={formState.isSubmitting}
        loading={formState.isSubmitting}
        size="big"
      >
        Send
      </Button>
    </form>
  );
}

export default SendTestEmailForm;
