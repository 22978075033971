import {Children, cloneElement} from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import ReactModal from 'react-modal';
import styles from './Modal.module.scss';

function Modal({
  isOpen,
  title,
  footerText,
  size,
  overlayClassName,
  children,
  className,
  onRequestClose,
  smallPadding,
  headerLeft,
  headerSmallText,
  hideCloseButton,
  whiteCloseButton,
  titleStyle,
  titleClassName,
  bodyClassName,
  ...props
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={cx(
        styles.modal,
        {
          [styles[size]]: size,
          [styles.smallPadding]: smallPadding,
        },
        overlayClassName
      )}
      className={cx(styles.wrapper, className)}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
      {...props}
    >
      <>
        {!hideCloseButton && (
          <button
            type="button"
            className={whiteCloseButton ? styles.closeIconWhite : styles.closeIcon}
            onClick={onRequestClose}
            title="Click to close modal"
          />
        )}
        {title && (
          <div
            className={cx(
              styles.header,
              {
                [styles.headerLeft]: headerLeft,
                [styles.headerSmallText]: headerSmallText,
              },
              titleClassName
            )}
            style={titleStyle}
          >
            <strong className={styles.title}>{title}</strong>
          </div>
        )}
        <div className={cx(styles.body, bodyClassName)}>
          {Children.map(children, (child) => {
            const childDrops = typeof child.type !== 'string' ? {onRequestClose} : {};
            return cloneElement(child, childDrops);
          })}
        </div>
        {footerText && (
          <div className={styles.footer}>
            <p className={styles.footerText}>{footerText}</p>
          </div>
        )}
      </>
    </ReactModal>
  );
}

Modal.defaultProps = {
  footerText: '',
  title: '',
  size: 'small',
};

Modal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  headerLeft: PropTypes.bool,
  smallPadding: PropTypes.bool,
  headerSmallText: PropTypes.bool,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  footerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
};

export default Modal;
