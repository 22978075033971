/**
 * Global CSS override to conditionally disable dashboard layout top gutter
 */
export default function DashboardFullscreenCssOverride() {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
          .content-wrapper__main {
            padding-top: 0!important;
          }

          .content-wrapper__footer {
            display: none;
          }

          .content-wrapper__wrapper {
            min-height: 100vh;
            padding-bottom: 0;
          }
        `,
      }}
    />
  );
}
