import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'clsx';
import {usePopperTooltip} from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import InfoIcon from '../icons/InfoIcon';
import styles from './Tooltip.module.scss';

const OFFSET_Y = 10;

function Tooltip({
  children,
  trigger,
  content,
  iconTrigger,
  placement = 'top',
  interactive = true,
  ...props
}) {
  const {getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible} = usePopperTooltip({
    placement,
    trigger,
    interactive,
    offset: [0, OFFSET_Y],
    ...props,
  });

  return (
    <>
      <span ref={setTriggerRef}>{iconTrigger ? <InfoIcon /> : children}</span>
      {visible &&
        ReactDOM.createPortal(
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: cx('tooltip-container', styles.main),
            })}
          >
            <div
              {...getArrowProps({
                className: cx('tooltip-arrow', styles.arrow),
              })}
            />
            <div className={styles.body}>{content}</div>
          </div>,
          document.body
        )}
    </>
  );
}

Tooltip.propTypes = {
  content: PropTypes.node.isRequired,
  children: PropTypes.node,
  placement: PropTypes.string,
  trigger: PropTypes.string,
  iconTrigger: PropTypes.bool,
};

export default Tooltip;
