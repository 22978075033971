import {useEffect, useState} from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/Select';
import useFetch from '../../../hooks/useFetch';
import {fetchTagsService} from '../../settings/subscriber-fields/service';

export default ({onUpdateLink, onRequestClose, tagName, linkUrl, linkId}) => {
  const {run: fetchTags} = useFetch(fetchTagsService, {
    throwError: true,
  });
  const [tags, setTags] = useState();
  const [tagOptions, setTagOptions] = useState();
  const [selectedTagId, setSelectedTagId] = useState();
  const [selectedTag, setSelectedTag] = useState(tagName);

  const [link, setLink] = useState(linkUrl);

  useEffect(() => {
    const getTags = async () => {
      const resp = await fetchTags();
      const formattedOptions =
        resp.length > 0 && resp.map((tag) => ({label: tag.name, value: tag.name}));
      setTagOptions(formattedOptions);

      setTags(resp);
    };
    getTags();
  }, []);

  return (
    <div>
      <Input label="URL" value={link} onChange={(e) => setLink(e.target.value)} />

      {tags && tags.length > 0 && (
        <Select
          label="Add a tag"
          options={tagOptions}
          onChange={(value) => {
            const foundTag = tags.find((tag) => tag.name === value);
            setSelectedTag(foundTag);
            setSelectedTagId(foundTag?.id);
          }}
          value={selectedTag}
          dense
          isClearable
        />
      )}
      <Button
        color="green"
        variant="filled"
        full
        onClick={() => {
          onUpdateLink(
            selectedTagId
              ? {id: linkId, target: link, tagTypeDto: {id: selectedTagId}}
              : {id: linkId, target: link}
          );
          onRequestClose();
        }}
      >
        Update
      </Button>
    </div>
  );
};
