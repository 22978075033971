import {useState} from 'react';
import cx from 'clsx';
import ForgotPasswordForm from './ForgotPasswordForm';
import FooterText from './FooterText';
import ForgotPasswordConfirm from './ForgotPasswordConfirm';
import useFetch from '../../../hooks/useFetch';
import resetPasswordService from '../services/resetPassword';
import StatusMessage from '../../../components/StatusMessage/StatusMessage';

import styles from '../auth.module.scss';
import Hero from '../Hero';
import Logo from '../Logo';
import Footer from '../../../components/Footer';

function ForgotPassword() {
  const [status, setStatus] = useState('');
  const [sentEmail, setSentEmail] = useState('');
  const {run: resetPassword} = useFetch(resetPasswordService, {throwError: true});
  const [errorMessage, setErrorMessage] = useState('');

  async function handleFormSubmit(values) {
    setErrorMessage('');
    try {
      await resetPassword(values);
      setSentEmail(values.email);
      setStatus('submitted');
    } catch (error) {
      setErrorMessage(error.message);
    }
  }
  return (
    <div className={styles.auth}>
      <div className={styles.inner}>
        <div className={styles.form}>
          <Logo />
          {status === 'submitted' ? (
            <>
              <h1 className={styles.title}>Password Reset Email Sent!</h1>
              <ForgotPasswordConfirm email={sentEmail} />
            </>
          ) : (
            <>
              <h1 className={styles.title}>Forgot Your Password?</h1>
              <p className={cx(styles.text, styles.textBig)}>
                Don&apos;t worry. Resenting your password is easy, just tell us email address you
                registered with Maildroppa.
              </p>
              <StatusMessage message={errorMessage} />
              <ForgotPasswordForm onSubmit={handleFormSubmit} />
              <FooterText />
            </>
          )}
        </div>
        <Hero />
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
