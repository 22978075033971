const PASSWORD_MAX_LENGTH = 8;

export function minLength(value) {
  return value.length >= PASSWORD_MAX_LENGTH;
}

export function containsSpecialChar(value) {
  return /(.*[!@#$%^&*].*)/.test(value);
}

export function lowercase(value) {
  return /(.*[a-z].*)/.test(value);
}

export function uppercase(value) {
  return /(.*[A-Z].*)/.test(value);
}

export function containsDigit(value) {
  return /(.*\d.*)/.test(value);
}
