import {get, put, post, del} from '../../../utils/api';
import {ENDPOINTS} from './constants';

export async function fetchTransactionalEmailsService(apiSettings, userParams) {
  const defaultParams = {
    page: 1,
    limit: 10,
  };

  const params = {
    ...defaultParams,
    ...userParams,
  };
  return get(ENDPOINTS.transactionalEmailsOverview, params, apiSettings);
}

export async function saveTransactionalEmailsService(apiSettings, data) {
  return post(ENDPOINTS.transactionalEmails, data, apiSettings);
}

export async function updateTransactionalEmailsService(apiSettings, data) {
  return put(ENDPOINTS.transactionalEmails, data, apiSettings);
}

export async function getTransactionalEmailsService(apiSettings, data) {
  return get(ENDPOINTS.getTransactionalEmails(data), null, apiSettings);
}

export async function deleteTransactionalEmailsService(apiSettings, data) {
  return del(ENDPOINTS.deleteTransactionalEmails(data), null, apiSettings);
}

// export async function fetchAutomationConfigService(apiSettings, id) {
//   return get(ENDPOINTS.getAutomationConfig(id), null, apiSettings);
// }

// export async function saveAutomationConfigService(apiSettings, data) {
//   return post(ENDPOINTS.automationConfig, data, apiSettings);
// }

// export async function updateAutomationConfigService(apiSettings, data) {
//   return put(ENDPOINTS.automationConfig, data, apiSettings);
// }
