import PropTypes from 'prop-types';
import cx from 'clsx';

const buttonIconMargin = 15;

function Icon({name, buttonIcon, onClick, className, size}) {
  return (
    <span
      className={cx(className, [`icon-${name}`])}
      style={{fontSize: size, marginRight: buttonIcon && buttonIconMargin}}
      onClick={onClick}
    />
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  buttonIcon: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;
