import useFetch from '../../../hooks/useFetch';
import {sendTestEmailService} from '../service';

function useSendTestEmail(newsletterID) {
  const {run: sendTestEmailRequest} = useFetch(sendTestEmailService, {throwError: true});

  async function sendTestEmail(values) {
    await sendTestEmailRequest({id: newsletterID, values});
  }

  return {sendTestEmail};
}

export default useSendTestEmail;
