import PropTypes from 'prop-types';
import cx from 'clsx';
import DoubleChevron from '../../icons/DoubleShevron';
import styles from './SidebarToggle.module.scss';

const SidebarToggle = ({isOpened, onToggle, className}) => {
  return (
    <button
      type="button"
      onClick={onToggle}
      className={cx([styles.sidebarToggle, {[styles.opened]: isOpened}, className])}
    >
      <DoubleChevron className={cx([styles.sidebarToggleIcon])} />
      {isOpened ? 'Hide Settings' : 'Show Settings'}
    </button>
  );
};

SidebarToggle.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SidebarToggle;
