import PropTypes from 'prop-types';
import clsx from 'clsx';
import Tile from '../Tile';

import styles from './Tiles.module.scss';

const Tiles = ({items = [], className, onTileClick}) => (
  <div className={clsx(styles.tiles, className)}>
    {items.map(({value, label, img, SVGComponent}) => (
      <Tile
        key={value}
        className={styles.item}
        onClick={() => onTileClick(value)}
        img={img}
        SVGComponent={SVGComponent}
        label={label}
      />
    ))}
  </div>
);

Tiles.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      img: PropTypes.string,
      SVGComponent: PropTypes.elementType,
    })
  ),
  className: PropTypes.string,
  onTileClick: PropTypes.func,
};

export default Tiles;
