import {useContext} from 'react';
import createDataContext from '../../../utils/createDataContext';
import createReducer from '../../../utils/createReducer';
import {
  CLOSE_INTRO,
  CLOSE_PANEL_PAGE,
  COMPLETE_STEP,
  DESTROY,
  OPEN_INTRO,
  OPEN_PANEL_PAGE,
  SAVE_STEP_DATA,
  SET_ACTIVE_STEP,
  UNCOMPLETE_STEP,
} from './actionTypes';
import {
  initialState,
  saveStepDataReducer,
  setActiveStepReducer,
  openPanelPageReducer,
  closePanelPageReducer,
  destroyReducer,
  openIntroReducer,
  closeIntroReducer,
  completeStepReducer,
  uncompleteStepReducer,
} from './reducers';

const wizardReducer = createReducer(initialState, {
  [SET_ACTIVE_STEP]: setActiveStepReducer,
  [SAVE_STEP_DATA]: saveStepDataReducer,
  [OPEN_INTRO]: openIntroReducer,
  [CLOSE_INTRO]: closeIntroReducer,
  [DESTROY]: destroyReducer,
  [OPEN_PANEL_PAGE]: openPanelPageReducer,
  [CLOSE_PANEL_PAGE]: closePanelPageReducer,
  [COMPLETE_STEP]: completeStepReducer,
  [UNCOMPLETE_STEP]: uncompleteStepReducer,
});

// TODO when doing refactor with Typescript, remove action creators to use dispatch only
function setActiveStep(dispatch) {
  return function (nextStep) {
    dispatch({type: SET_ACTIVE_STEP, payload: nextStep});
  };
}

function setStepData(dispatch) {
  return function (data, step) {
    dispatch({type: SAVE_STEP_DATA, payload: data, meta: step});
  };
}

function openIntro(dispatch) {
  return function (location) {
    dispatch({type: OPEN_INTRO, payload: location});
  };
}

function closeIntro(dispatch) {
  return function () {
    dispatch({type: CLOSE_INTRO});
  };
}

function destroyWizard(dispatch) {
  return function () {
    dispatch({type: DESTROY});
  };
}

function openPanelPage(dispatch) {
  return function (panelPage) {
    dispatch({type: OPEN_PANEL_PAGE, payload: panelPage});
  };
}

function closePanelPage(dispatch) {
  return function () {
    dispatch({type: CLOSE_PANEL_PAGE});
  };
}

function completeStep(dispatch) {
  return function (stepId) {
    dispatch({type: COMPLETE_STEP, payload: stepId});
  };
}

function uncompleteStep(dispatch) {
  return function (stepId) {
    dispatch({type: UNCOMPLETE_STEP, payload: stepId});
  };
}

export const {Context, Provider} = createDataContext(
  wizardReducer,
  {
    setActiveStep,
    setStepData,
    openIntro,
    closeIntro,
    destroyWizard,
    openPanelPage,
    closePanelPage,
    completeStep,
    uncompleteStep,
  },
  initialState
);

export const useBoardingWizard = function () {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useBoardingWizard must be used within a BoardingWizardProvider`);
  }
  return context;
};
