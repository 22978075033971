import {createContext, useReducer, useMemo} from 'react';
import PropTypes from 'prop-types';

export default function createDataContext(reducer, actions, defaultValue, init) {
  const Context = createContext();

  function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, defaultValue, init);

    const boundActions = Object.entries(actions).reduce((acc, [key, action]) => {
      return {
        ...acc,
        [key]: action(dispatch),
      };
    }, {});

    const value = useMemo(() => {
      return {
        state,
        ...boundActions,
      };
    }, [state]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  Provider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return {Context, Provider};
}

export const composeProviders = (providers, children) =>
  providers.reduce((acc, Provider) => {
    return <Provider>{acc}</Provider>;
  }, children);
