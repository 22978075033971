import {useState} from 'react';
import Button from '../../../components/Button';
import FormButtons from '../../../components/forms/FormButtons';
import Tag from './Tag';
import styles from './AddTag.module.scss';

function AddTagsForm({tags, selectedTags, onSubmit, onRequestClose}) {
  const [selected, setSelected] = useState(selectedTags);

  const toggleSelected = (tagId, wasSelected) => {
    if (wasSelected) {
      setSelected(selected.filter((v) => v !== tagId));
    } else {
      setSelected([...selected, tagId]);
    }
  };

  const handleSubmit = () => {
    onSubmit(selected);
    onRequestClose();
  };

  return (
    <div>
      <div className={styles.addTagsList}>
        {tags.map((tag) => (
          <Tag
            key={tag.id}
            id={tag.id}
            name={tag.name}
            selectable
            selected={selected.indexOf(tag.id) > -1}
            onClick={toggleSelected}
          />
        ))}
      </div>
      <FormButtons>
        <Button type="submit" variant="filled" color="green" size="big" onClick={handleSubmit} full>
          Add
        </Button>
        <Button onClick={onRequestClose} variant="outlined" color="grey" full size="big">
          Cancel
        </Button>
      </FormButtons>
    </div>
  );
}

export default AddTagsForm;
