import {useMemo} from 'react';

import {TRIGGER_NODE_VALUES_CUSTOM_MAPPING} from '../constants';

const useTriggerTypeByOption = (optionType) => {
  return useMemo(() => {
    const triggerTypeByOption = TRIGGER_NODE_VALUES_CUSTOM_MAPPING(
      [
        'FORM_SUBMISSION',
        'DATE_ANNIVERSARY',
        'TAG_ADDED',
        'TAG_REMOVED',
        'FIELD_ADDED',
        'FIELD_UPDATED',
        'FIELD_REMOVED',
      ],
      'GENERAL_FORM_SUBMISSION'
    );

    return triggerTypeByOption[optionType] || triggerTypeByOption.defaultValue;
  }, [optionType]);
};

export default useTriggerTypeByOption;
