import {useMemo} from 'react';

const useValidStatus = (status) => {
  return useMemo(() => {
    if (
      (status && status === 'ACTIVE') ||
      (status && status === 'PAUSED') ||
      (status && status === 'DRAFT')
    ) {
      return true;
    }

    return false;
  }, [status]);
};

export default useValidStatus;
