import PropTypes from 'prop-types';

import styles from './ProgressBar.module.scss';

const MAX_PROGRESS_BOUNDARY = 100;

function ProgressBar({width, title}) {
  const parsedWidth = parseInt(width, 10);
  const formattedWidth = parsedWidth >= MAX_PROGRESS_BOUNDARY ? MAX_PROGRESS_BOUNDARY : parsedWidth;
  return (
    <div className={styles.progress}>
      <div className={styles.info}>
        <span className={styles.label}>{title}</span>
        <span className={styles.val}>
          <span>{width}</span>
        </span>
      </div>
      <div className={styles.barWrapper}>
        <div
          className={styles.bar}
          role="progressbar"
          style={{width: `${formattedWidth}%`}}
          aria-valuenow={parsedWidth}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  width: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ProgressBar;
