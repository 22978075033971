import {Handle, Position} from 'reactflow';
import styles from './EndNode.module.scss';

const EndNode = () => {
  return (
    <div>
      <Handle type="target" position={Position.Top} />
      <div className={styles.end}>End Of Automation</div>
    </div>
  );
};

export default EndNode;
