import {Helmet} from 'react-helmet';
import SubscriberContainer from './SubscriberContainer';

function SubscriberPage() {
  return (
    <>
      <Helmet title="Subscriber" />
      <SubscriberContainer />
    </>
  );
}

export default SubscriberPage;
