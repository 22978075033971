import {useCallback, useEffect, useReducer} from 'react';
import useAuth from '../domain/auth/useAuth';
import useAlert from '../domain/alerts/useAlert';
import {
  API_REQUEST_ERROR_MESSAGE,
  API_REQUEST_SUCCESS_MESSAGE,
  UNAUTHORIZED_STATUS_CODE,
} from '../utils/api/constants';

function reducer(state, action) {
  switch (action.type) {
    case 'request_start':
      return {
        loading: true,
      };
    case 'request_success':
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case 'request_failure':
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      throw new Error('Unknown action');
  }
}

function handleSuccess(response, dispatch, options, showAlert, successMessage) {
  dispatch({type: 'request_success', payload: response});

  if (options.showSuccessAlert) {
    showAlert(successMessage, {
      variant: 'success',
    });
  }

  return response;
}

function handleError(error, {dispatch, options, notifyOnError, hasRefreshToken}) {
  dispatch({type: 'request_failure', payload: error});

  if (error.status === UNAUTHORIZED_STATUS_CODE && hasRefreshToken) {
    return;
  }

  if (error.error === 'validation' || options.throwError) {
    throw error;
  }

  if (!options.hideAlert) {
    notifyOnError(error);
  }
}

function useFetch(asyncFunction, options = {}, apiConfig = {}) {
  const initialState = {
    loading: !!options.immediate,
    data: null,
    error: null,
  };

  const {state: authState, logout, updateTokens} = useAuth();
  const {showAlert} = useAlert();
  const [state, dispatch] = useReducer(reducer, initialState, undefined);

  if (typeof asyncFunction !== 'function') {
    throw new Error('You must provide a fetcher function into useFetch.');
  }

  const apiSettings = {
    logout,
    updateTokens,
    accessToken: authState.accessToken,
    refreshToken: authState.refreshToken,
    rememberMe: authState.rememberMe,
    ...apiConfig,
  };

  const errorMessage = options.errorMessage || API_REQUEST_ERROR_MESSAGE;
  const successMessage = options.successMessage || API_REQUEST_SUCCESS_MESSAGE;

  const notifyOnError = useCallback(
    function (error) {
      if (error.message) {
        showAlert(errorMessage, {
          variant: 'error',
          details: error.message,
        });
      } else {
        showAlert(errorMessage, {variant: 'error'});
      }
    },
    [showAlert]
  );

  const run = useCallback(
    function (data) {
      dispatch({type: 'request_start'});
      return asyncFunction(apiSettings, options.data ?? data)
        .then(function (response) {
          return handleSuccess(response, dispatch, options, showAlert, successMessage);
        })
        .catch(function (error) {
          handleError(error, {
            dispatch,
            options,
            notifyOnError,
            hasRefreshToken: !!apiSettings.refreshToken,
          });
        });
    },
    [asyncFunction]
  );

  useEffect(
    function () {
      if (options.immediate) {
        run();
      }
    },
    [run, options.immediate]
  );

  const setData = (newData) => {
    dispatch({type: 'request_success', payload: newData});
  };

  return {
    run,
    setData,
    loading: state.loading,
    error: state.error,
    data: state.data,
  };
}

export default useFetch;
