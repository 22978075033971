import cx from 'clsx';
import PropTypes from 'prop-types';
import {Tabs as ReactTabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import styles from './Tabs.module.scss';

export {default as Tab} from './Tab';
export {default as TabPanel} from './TabPanel';
export {default as TabList} from './TabList';

export function Tabs({children, noTopMargin, className, ...props}) {
  return (
    <ReactTabs
      className={cx(styles.tabs, {[styles.noTopMargin]: noTopMargin}, className)}
      {...props}
    >
      {children}
    </ReactTabs>
  );
}

Tabs.propTypes = {
  children: PropTypes.node,
  noTopMargin: PropTypes.bool,
};
