import PropTypes from 'prop-types';
import styles from './Toolbar.module.scss';
import RangeInput from '../../../../components/forms/RangeInput';
import {DEFAULT_SIZE_UNIT} from '../../constants';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';

const SizeField = ({label, paramPath, min = 0, max, handler, ...props}) => {
  const [value, handleChange] = handler ? handler(paramPath) : useHandleConfigParam(paramPath);

  return (
    <div className={styles.toolbarFieldContainer}>
      <div className={styles.toolbarFieldLabelContainer}>
        <span className={styles.toolbarFieldLabel}>{label}</span>
        <div className={styles.sizeContainer}>
          <input
            type="number"
            value={value}
            onChange={handleChange}
            className={styles.size}
            min={min}
            max={max}
          />
          <span>{DEFAULT_SIZE_UNIT}</span>
        </div>
      </div>
      <RangeInput
        value={Number(value)}
        className={styles.toolbarFieldInput}
        onChange={handleChange}
        min={min}
        max={max}
        {...props}
      />
    </div>
  );
};

SizeField.propTypes = {
  label: PropTypes.string.isRequired,
  paramPath: PropTypes.string.isRequired,
};

export default SizeField;
