import PropTypes from 'prop-types';
import cx from 'clsx';
import CardHeader from './CardHeader';

import styles from './Card.module.scss';
import Spinner from '../Spinner';

function Card({
  children,
  title,
  loading,
  overflowVisible,
  subtitle,
  header,
  noBorder,
  noBottomBorder,
  noBg,
  narrow,
  inline,
  noMargin,
  noPadding,
  smallPadding,
  marginYauto,
  className,
  classNameInner,
  centeredHeader,
  classNameHeader,
  backgroundColor,
  noMaxWidth,
  cardStyles = {},
  cardInnerStyles = {},
  fullWidth,
}) {
  return (
    <section
      className={cx(styles.card, className, {
        [styles.noBorder]: noBorder,
        [styles.narrow]: narrow,
        [styles.noPadding]: noPadding,
        [styles.noBg]: noBg,
        [styles.inline]: inline,
        [styles.noMargin]: noMargin,
        [styles.overflowVisible]: overflowVisible,
        [styles.smallPadding]: smallPadding,
        [styles.marginYauto]: marginYauto,
        [styles.noMaxWidth]: noMaxWidth,
        [styles.fullWidth]: fullWidth,
      })}
      style={cardStyles}
    >
      {header}
      {title && !header && (
        <CardHeader
          title={title}
          text={subtitle}
          noBottomBorder={noBottomBorder}
          centered={centeredHeader}
          className={classNameHeader}
        />
      )}
      {children && (
        <div
          className={cx(styles.inner, classNameInner)}
          style={{...cardInnerStyles, backgroundColor: backgroundColor || 'white'}}
        >
          {loading ? <Spinner /> : children}
        </div>
      )}
    </section>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
  narrow: PropTypes.bool,
  inline: PropTypes.bool,
  noPadding: PropTypes.bool,
  overflowVisible: PropTypes.bool,
  noBg: PropTypes.bool,
  noMargin: PropTypes.bool,
  marginYauto: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  header: PropTypes.node,
  centeredHeader: PropTypes.bool,
  noMaxWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  cardStyles: PropTypes.shape({}),
  cardInnerStyles: PropTypes.shape({}),
};

export default Card;
