import {useCallback} from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';

import FieldError from '../FieldError';

import iconGrey from '../../../img/file-icon-grey.svg';
import iconGreen from '../../../img/file-icon-green.svg';

import styles from './DragUpload.module.scss';

function DragUpload({children, options, helpText, name, onChange, error, setError, value}) {
  const handleDropRejected = useCallback(
    (fileRejections) => {
      setError(name, {
        type: 'manual',
        message: fileRejections[0].errors[0].message,
      });
    },
    [setError, name]
  );

  const handleDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles[0]);
  }, []);

  const {getRootProps, getInputProps, open} = useDropzone({
    noKeyboard: true,
    ...options,
    multiple: false,
    onDropAccepted: handleDrop,
    onDropRejected: handleDropRejected,
  });

  function renderFileName() {
    if (value) {
      return <p className={cx('text-bold', styles.name)}>{value.path}</p>;
    }

    return (
      <>
        {helpText && <p className={styles.title}>{helpText}</p>}
        <span className={styles.textDivider}>or</span>
        <span className={cx('text text-small text-bold link', styles.subTitle)} onClick={open}>
          choose a file from your computer
        </span>
      </>
    );
  }

  return (
    <>
      <div {...getRootProps({className: styles.dropzone})}>
        <input {...getInputProps()} />
        {children || (
          <img
            src={value ? iconGreen : iconGrey}
            className={styles.ico}
            width="62"
            height="82"
            alt="File icon"
            loading="lazy"
          />
        )}
        {renderFileName()}
      </div>
      <div className={styles.errors}>{error && <FieldError absolute error={error} />}</div>
    </>
  );
}

DragUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  helpText: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool]),
};

export default DragUpload;
