const bulMail = '/bulk-mail';
const template = '/template';
const storage = '/internal/storage';

export const ENDPOINTS = {
  send: `${bulMail}/send`,
  sendTest: (id) => `${bulMail}/test/${id}`,
  fetchOverview: '/bulk-mail-overview',
  updateDraft: bulMail,
  saveDraft: bulMail,
  fetchNewsletter: (id) => `${bulMail}/${id}`,
  delete: (id) => `${bulMail}/${id}`,
  fetchReport: (id) => `/bulk-mail-report/${id}`,
  unschedule: (id) => `${bulMail}/unschedule/${id}`,
  reschedule: `${bulMail}/reschedule`,
  tracking: '/tracking',
  fetchLinks: (id) => `/internal/link/${id}`,
  updateLink: `/internal/link`,
  createTemplate: template,
  fetchTemplates: `${template}/overview`,
  fetchTemplate: (templateId) => `${template}/${templateId}`,
  updateTemplate: template,
  deleteTemplate: (templateId) => `${template}/${templateId}`,
  uploadImage: `${storage}/upload`,
  deleteImage: storage,
  fetchNextScheduleTime: `${bulMail}/next-schedule-time`,
  fetchFormattedSheduleTime: (timestamp) => `${bulMail}/scheduleTime/${timestamp}`,
};

export const SEND_TO_GROUP__SELECT = 'select';
export const SEND_TO_SELECT__DEFAULT = 'default';
export const SEND_TO_GROUP__FILTER = 'filter';

export const FILTER_MODE = {
  SEGMENT: 'SEGMENT',
  NONE: 'NONE',
};

export const SCHEDULING_MODE = {
  NOW: 'NOW',
  SCHEDULED: 'SCHEDULED',
};
