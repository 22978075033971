import React from 'react';
import {SubscriptionFormBuilderContext} from '../../context';
import styles from './FormPositionSelector.module.scss';
import toolbarStyles from '../Toolbar/Toolbar.module.scss';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';

const FormPositionSelector = () => {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);
  const [position, handleChange] = useHandleConfigParam('jsonConfig.sliderPopupPosition');

  if (formState.type !== 'SLIDER') {
    return null;
  }

  return (
    <div className={toolbarStyles.toolbarFieldContainer}>
      <div className={toolbarStyles.toolbarFieldLabelContainer}>
        <span className={toolbarStyles.toolbarFieldLabel}>Form Position</span>
      </div>
      <div className={styles.positionGrid}>
        <div
          className={position === 'TOP_LEFT' ? styles.activePosition : ''}
          onClick={() => handleChange('TOP_LEFT')}
        >
          Top Left
        </div>
        <div
          className={position === 'TOP_CENTER' ? styles.activePosition : ''}
          onClick={() => handleChange('TOP_CENTER')}
        >
          Top Center
        </div>
        <div
          className={position === 'TOP_RIGHT' ? styles.activePosition : ''}
          onClick={() => handleChange('TOP_RIGHT')}
        >
          Top Right
        </div>
        <div
          className={position === 'CENTER_LEFT' ? styles.activePosition : ''}
          onClick={() => handleChange('CENTER_LEFT')}
        >
          Center Left
        </div>
        <div />
        <div
          className={position === 'CENTER_RIGHT' ? styles.activePosition : ''}
          onClick={() => handleChange('CENTER_RIGHT')}
        >
          Center Right
        </div>
        <div
          className={position === 'BOTTOM_LEFT' ? styles.activePosition : ''}
          onClick={() => handleChange('BOTTOM_LEFT')}
        >
          Bottom Left
        </div>
        <div
          className={position === 'BOTTOM_CENTER' ? styles.activePosition : ''}
          onClick={() => handleChange('BOTTOM_CENTER')}
        >
          Bottom Center
        </div>
        <div
          className={position === 'BOTTOM_RIGHT' ? styles.activePosition : ''}
          onClick={() => handleChange('BOTTOM_RIGHT')}
        >
          Bottom Right
        </div>

        {/* <input
          type="radio"
          value="TOP_LEFT"
          id="pos-1"
          name="pos-grid"
          checked={position === 'TOP_LEFT'}
          onChange={handleChange}
        />
        <label htmlFor="pos-1" />
        <input
          type="radio"
          value="TOP_CENTER"
          id="pos-2"
          name="pos-grid"
          checked={position === 'TOP_CENTER'}
          onChange={handleChange}
        />
        <label htmlFor="pos-2" />
        <input
          type="radio"
          value="TOP_RIGHT"
          id="pos-3"
          name="pos-grid"
          checked={position === 'TOP_RIGHT'}
          onChange={handleChange}
        />
        <label htmlFor="pos-3" />
        <input
          type="radio"
          value="CENTER_LEFT"
          id="pos-4"
          name="pos-grid"
          checked={position === 'CENTER_LEFT'}
          onChange={handleChange}
        />
        <label htmlFor="pos-4" />
        <input type="radio" disabled />
        <label />
        <input
          type="radio"
          value="CENTER_RIGHT"
          checked={position === 'CENTER_RIGHT'}
          id="pos-6"
          name="pos-grid"
          onChange={handleChange}
        />
        <label htmlFor="pos-6" />
        <input
          type="radio"
          value="BOTTOM_LEFT"
          id="pos-7"
          name="pos-grid"
          checked={position === 'BOTTOM_LEFT'}
          onChange={handleChange}
        />
        <label htmlFor="pos-7" />
        <input
          type="radio"
          value="BOTTOM_CENTER"
          id="pos-8"
          name="pos-grid"
          checked={position === 'BOTTOM_CENTER'}
          onChange={handleChange}
        />
        <label htmlFor="pos-8" />
        <input
          type="radio"
          value="BOTTOM_RIGHT"
          id="pos-9"
          name="pos-grid"
          checked={position === 'BOTTOM_RIGHT'}
          onChange={handleChange}
        />
        <label htmlFor="pos-9" /> */}
      </div>
    </div>
  );
};

export default FormPositionSelector;
