import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useRouteMatch} from 'react-router';
import Navbar from './Navbar';
import Footer from '../components/Footer';
import styles from './DashboardLayout.module.scss';
import Alerts from '../domain/alerts/Alerts';

function DashboardLayout({children}) {
  const isBuilderSlider = useRouteMatch('/signup-forms/slider/');
  const isBuilderInline = useRouteMatch('/signup-forms/inline/');
  const isBuilderPopup = useRouteMatch('/signup-forms/popup/');

  const isBuilder = isBuilderSlider || isBuilderInline || isBuilderPopup;

  return (
    <div className={styles.layout}>
      <div className={clsx('content-wrapper', isBuilder ? 'content-wrapper--fixed' : '')}>
        <Navbar />
        <main className={clsx([styles.main, 'content-wrapper__main'])}>
          <Alerts />
          <section className={clsx([(styles.wrapper, 'content-wrapper__wrapper')])}>
            {children}
            <Footer />
          </section>
        </main>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
