import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {useCallback, useState} from 'react';

import Label from '../../../components/Label';
import ActionsDropdown from '../../../components/ActionsDropdown';
import ActionDropdownItem from '../../../components/ActionsDropdown/ActionDropdownItem';
import styles from './OverviewList.module.scss';
import useRouter from '../../../hooks/useRouter';
import ROUTES from '../../../Router/routes';
import {newslettersPropType} from '../../../utils/prop-types/newsletter';
import Modal from '../../../components/Modal';
import ScheduleNewsletter from '../ScheduleNewsletter';
import TrackingOptions from '../tracking-options/TrackingOptions';
import OverviewFailure from './OverviewFailure';
import SaveAsTemplateModal from '../../../components/SaveAsTemplateModal';
import {TEMPLATE_CATEGORY} from '../templates/constants';
import useFetch from '../../../hooks/useFetch';
import {fetchNewsletterService} from '../service';

function OverviewList({
  newsletters,
  onMarkDraft,
  onDelete,
  onUnschedule,
  handleReschedule,
  isTrackingOptionsOpen,
  setTrackingOptionsOpen,
  trackingOptions,
  handleTrackingOptionsUpdate,
}) {
  const {history} = useRouter();
  const hasData = !!newsletters?.length;
  const [rescheduleId, setRescheduleId] = useState();
  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [isOpenTemplateModal, setOpenTemplateModal] = useState(false);
  const {run: fetchNewsletterRequest} = useFetch(fetchNewsletterService, {throwError: true});

  function handleOpenNewsletter(id, status) {
    if (status === 'SENT') {
      history.push(`${ROUTES.newsletters.sent}/${id}`);
      return;
    }

    if (status === 'FAILED') {
      history.push(`${ROUTES.newsletters.failed}/${id}`);
      return;
    }

    history.push(`${ROUTES.newsletters.edit}/${id}`);
  }

  function handleOpenReport(id) {
    history.push(`${ROUTES.newsletters.report}/${id}`);
  }

  const handleSaveAsTemplate = useCallback(
    (id) => {
      fetchNewsletterRequest(id).then((d) => {
        setOpenTemplateModal(d);
      });
    },
    [fetchNewsletterRequest]
  );

  function renderActions(item, isSent, isScheduled, isProcessing) {
    return (
      <>
        {isScheduled && (
          <ActionDropdownItem
            onSelect={() => {
              setRescheduleId(item.id);
              setScheduleModalOpen(true);
            }}
          >
            Reschedule
          </ActionDropdownItem>
        )}
        {!isScheduled && !isProcessing && (
          <ActionDropdownItem onSelect={() => handleOpenNewsletter(item.id, item.status)}>
            View Campaign
          </ActionDropdownItem>
        )}
        {isSent && (
          <>
            <ActionDropdownItem onSelect={() => onMarkDraft(item.id)}>
              Use as draft
            </ActionDropdownItem>
            <ActionDropdownItem onSelect={() => handleOpenReport(item.id)}>
              View Report
            </ActionDropdownItem>
          </>
        )}
        {isScheduled && (
          <>
            <ActionDropdownItem onSelect={() => onMarkDraft(item.id)}>
              Use as draft
            </ActionDropdownItem>
            <ActionDropdownItem onSelect={() => onUnschedule(item.id)}>
              Unschedule
            </ActionDropdownItem>
          </>
        )}
        <ActionDropdownItem onSelect={() => handleSaveAsTemplate(item.id)}>
          Save as Template
        </ActionDropdownItem>
        {!isSent && !isScheduled && (
          <ActionDropdownItem onSelect={() => onDelete(item.id)}>Delete</ActionDropdownItem>
        )}
      </>
    );
  }

  return (
    <div className={styles.list}>
      {Boolean(isOpenTemplateModal) && (
        <SaveAsTemplateModal
          isOpen={Boolean(isOpenTemplateModal)}
          defaultTemplateName={isOpenTemplateModal?.subject ?? ''}
          defaultTemplateCategory={TEMPLATE_CATEGORY.BULK_MAIL}
          onClose={() => setOpenTemplateModal(null)}
          html={isOpenTemplateModal?.htmlContent ?? ''}
          css={isOpenTemplateModal?.cssContent ?? ''}
          onAfterRequest={() => {
            setOpenTemplateModal(null);
            history.push(ROUTES.newsletters.templates);
          }}
        />
      )}
      <Modal
        isOpen={isTrackingOptionsOpen}
        onRequestClose={() => {
          setTrackingOptionsOpen(false);
        }}
        title="Tracking Options"
      >
        <TrackingOptions
          trackingOptions={trackingOptions}
          setTrackingOptionsOpen={setTrackingOptionsOpen}
          handleTrackingOptionsUpdate={handleTrackingOptionsUpdate}
        />
      </Modal>
      <Modal
        isOpen={isScheduleModalOpen}
        onRequestClose={() => {
          setScheduleModalOpen(false);
          setRescheduleId(null);
        }}
      >
        <ScheduleNewsletter
          onScheduleSelect={(formattedDateTime) => {
            handleReschedule(rescheduleId, formattedDateTime.transmissionTimestamp);
            setRescheduleId(null);
            setScheduleModalOpen(false);
          }}
          scheduleTime={newsletters.find((i) => i.id === rescheduleId)?.scheduleTimeDto}
          title="Select time to reschedule this email"
          onlyReschedule
        />
      </Modal>
      {hasData ? (
        newsletters.map((item) => {
          const isSent = item.status === 'SENT';
          const isScheduled = item.status === 'SCHEDULED';
          const isProcessing = item.status === 'PROCESSING';

          return (
            <div key={item.id} className={styles.item}>
              <div className={styles.labelWrapper}>
                <Label color={isSent || isScheduled ? 'green' : 'grey'}>{item.status}</Label>
              </div>
              <div className={styles.content}>
                <button
                  type="button"
                  className={styles.title}
                  onClick={() => {
                    if (!isProcessing) handleOpenNewsletter(item.id, item.status);
                  }}
                  style={{cursor: isProcessing && 'inherit'}}
                  title="Click to view this newsletter"
                >
                  {item.subject}
                </button>
                {item.scheduleTimeDto && (
                  <span className={styles.date}>
                    {item.scheduleTimeDto.formattedDateTime}{' '}
                    {item.scheduleTimeDto.formattedTimezone}
                  </span>
                )}
                {item.status === 'SENT' && (
                  <span className={styles.recipients}>
                    {item.recipientCount}{' '}
                    {pluralize('recipient', parseInt(item.recipientCount, 10))}
                  </span>
                )}
              </div>
              <OverviewFailure failure={item.failure} />
              {item.report?.recipientCount && (
                <div className={styles.stats}>
                  <strong className={styles.statsTitle}>{item.report.recipientCount}</strong>
                  <span className={styles.statsDetails}>Recipients</span>
                </div>
              )}
              {isSent && (
                <>
                  {item.report?.deliveryRate && (
                    <div className={styles.stats}>
                      <strong className={styles.statsTitle}>{item.report.deliveryRate}</strong>
                      <span className={styles.statsDetails}>Delivery Rate</span>
                    </div>
                  )}
                  {item.report?.openRate && (
                    <div className={styles.stats}>
                      <strong className={styles.statsTitle}>{item.report.openRate}</strong>
                      <span className={styles.statsDetails}>Open Rate</span>
                    </div>
                  )}
                  {item.report?.clickRate && (
                    <div className={styles.stats}>
                      <strong className={styles.statsTitle}>{item.report.clickRate}</strong>
                      <span className={styles.statsDetails}>Click Rate</span>
                    </div>
                  )}
                  {item.report?.unsubscribedCount && (
                    <div className={styles.stats}>
                      <strong className={styles.statsTitle}>{item.report.unsubscribeCount}</strong>
                      <span className={styles.statsDetails}>Unsubscribers</span>
                    </div>
                  )}
                </>
              )}
              <div className={styles.actions}>
                <ActionsDropdown disabled={isProcessing}>
                  {renderActions(item, isSent, isScheduled, isProcessing)}
                </ActionsDropdown>
              </div>
            </div>
          );
        })
      ) : (
        <aside className={styles.emptyItem}>
          <p className={styles.emptyItemText}>You don&apos;t have any data yet.</p>
        </aside>
      )}
    </div>
  );
}

OverviewList.propTypes = {
  newsletters: newslettersPropType,
  onMarkDraft: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default OverviewList;
