import useFetch from 'hooks/useFetch';
import {fetchFormattedScheduleTime} from '../service';

function useFormattedScheduleTime() {
  return useFetch(fetchFormattedScheduleTime, {
    hideAlert: true,
    throwError: true,
  });
}

export default useFormattedScheduleTime;
