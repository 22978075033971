import {Helmet} from 'react-helmet';
import Card from '../../components/Card';
import SubscribersTableContainer from './components/SubscribersTableContainer';
import {Provider as ImportSubscribersProvider} from '../import-subscribers/context/Context';
import {useFreePlan} from '../free-plan-info/context/FreePlanContext';
import SubscribersLimitAlert from '../free-plan-info/subscribers-limit-alert/SubscribersLimitAlert';
import {LANDING_URL} from '../../utils/constants';
import styles from './SubscribersPage.module.scss';
import stylesReports from './reports/SubscribersReports.module.scss';
import SubscribersReportsContainer from './reports/SubscribersReportsContainer';

function SubscribersPage() {
  const {state} = useFreePlan();
  const {planLimitReached} = state;
  return (
    <ImportSubscribersProvider>
      <Helmet title="Subscribers" />
      {planLimitReached && <SubscribersLimitAlert />}
      <Card
        noPadding
        title="Subscribers"
        subtitle={
          <>
            <p>
              Here you can manage your subscriber list. Add, edit, or delete individual subscribers.
              You can also import or export subscribers and search your subscriber list.
            </p>
            <p>
              To find out more, please read our{' '}
              <a
                href={`${LANDING_URL}/guides/subscribers`}
                title="Click here for more details"
                target="_blank"
                rel="noopener noreferrer"
              >
                About Your Subscribers tutorial
              </a>{' '}
              for more information.
            </p>
          </>
        }
        className={styles.reports_card}
        classNameInner={styles.reports_card_inner}
      >
        <SubscribersReportsContainer />
        <hr className={stylesReports.hr} />
        <SubscribersTableContainer />
      </Card>
    </ImportSubscribersProvider>
  );
}

export default SubscribersPage;
