import ToolbarSection from './ToolbarSection';
import FontOptionsSection from './FontOptionsSection';
import styles from './Toolbar.module.scss';
import TextareaField from './TextareaField';

const Footer = () => {
  return (
    <>
      <ToolbarSection sectionName="Footer Content" className={styles.toolbarContent}>
        <TextareaField label="Text" paramPath="jsonConfig.footer.text.value" />
      </ToolbarSection>
      <FontOptionsSection pathPrefix="jsonConfig.footer.text" sectionTitle="Footer Text Styles" />
    </>
  );
};

export default Footer;
