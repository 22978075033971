import PropTypes from 'prop-types';
import successIco from '../../../img/import-subscribers-done.svg';
import Button from '../../../components/Button';
import styles from '../ImportSubscribers.module.scss';

function ImportSuccess({onRequestClose}) {
  return (
    <div className={styles.success}>
      <img src={successIco} width="130" height="120" alt="Import icon" loading="lazy" />
      <strong className={styles.successTitle}>Your file is being processed!</strong>
      <p className="text-centered">We will let you know when this process will complete.</p>
      <Button type="button" variant="filled" color="green" onClick={onRequestClose}>
        Return to Subscribers
      </Button>
    </div>
  );
}

ImportSuccess.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default ImportSuccess;
