import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import Button from '../../../../components/Button';
import DashboardFullscreenCssOverride from '../../../../components/DashboardFullscreenCssOverride';
import Spinner from '../../../../components/Spinner';

import styles from './SubscriptionFormBuilder.module.scss';
import Toolbar from '../../components/Toolbar';
import FormPreview from '../../components/FormPreview';
import FormPreviewModal from '../../components/FormPreviewModal';

import {getFormName} from '../../service';

import {
  useSubscriptionFormBuilderContextFabric,
  SubscriptionFormBuilderContext,
} from '../../context';
import FormCreatedModal from '../../components/FormCreatedModal/FormCreatedModal';
import InlineFormPreviewModal from '../../components/FormPreviewModal/InlineFormPreviewModal';

const SubscriptionFormBuilder = () => {
  const formBuilderContext = useSubscriptionFormBuilderContextFabric();

  const [formData, setFormData] = useState();
  const [showSaveButton, setShowSaveButton] = useState(false);

  const formLoaded = !formBuilderContext.formState.id || !formBuilderContext.loading;

  const handleOpenPreview = React.useCallback(() => {
    formBuilderContext.setFormPreviewShowModal(true);
  }, [formBuilderContext]);

  const handleCloseFormPreview = React.useCallback(() => {
    formBuilderContext.setFormPreviewShowModal(false);
  }, [formBuilderContext]);

  useEffect(() => {
    setFormData(formBuilderContext.formState);

    return () => {
      setFormData(null);
    };
  }, [formBuilderContext.formState.id]);

  useEffect(() => {
    if (!formData || !formBuilderContext.formState) return;
    const trimmedDataJson = JSON.stringify(formData).trim();
    const trimmedFormConfig = JSON.stringify(formBuilderContext.formState).trim();
    const isChanged = trimmedDataJson !== trimmedFormConfig;
    setShowSaveButton(isChanged);
  }, [formBuilderContext.formState]);

  return (
    <SubscriptionFormBuilderContext.Provider value={formBuilderContext}>
      <DashboardFullscreenCssOverride />
      <Helmet title="Signup-Forms" />

      {formLoaded && !formBuilderContext.serverFormDataError && (
        <div>
          <div className={styles.editHeader}>
            <h1 className={styles.editHeaderHeading}>
              {getFormName(formBuilderContext.formState.type)}
            </h1>
            <Button color="green" variant="outlined" onClick={handleOpenPreview}>
              Form Preview
            </Button>
          </div>
          <div className={styles.editMain}>
            <div className={styles.editPreview}>
              <div className={styles.editPreviewFormWrapper}>
                <FormPreview />
              </div>
            </div>
            <div className={styles.toolbarWrapper}>
              <div className={styles.toolbar}>
                <Toolbar />
              </div>
              {formBuilderContext.selectedElement !== 'add_new_field' && (
                <div className={styles.editPreviewSave}>
                  {formBuilderContext.formState.id && (
                    <Button
                      color="blue"
                      variant="filled"
                      onClick={async () => {
                        const resetData = await formBuilderContext.handleFormReset();
                        setFormData(resetData);
                      }}
                    >
                      Reset
                    </Button>
                  )}
                  <Button
                    color="green"
                    variant="filled"
                    onClick={async () => {
                      await formBuilderContext.handleFormSave();
                      setFormData(formBuilderContext.formState);
                      setShowSaveButton(false);
                    }}
                    loading={formBuilderContext.loadingUpdate || formBuilderContext.loadingCreate}
                  >
                    Save Changes
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <FormPreviewModal
        isOpen={
          formBuilderContext.formState.type !== 'INLINE' && formBuilderContext.showFormPreviewModal
        }
        onRequestClose={handleCloseFormPreview}
        formId={formBuilderContext.formState.id}
      />
      <InlineFormPreviewModal
        isOpen={
          formBuilderContext.formState.type === 'INLINE' && formBuilderContext.showFormPreviewModal
        }
        onRequestClose={handleCloseFormPreview}
        formId={formBuilderContext.formState.id}
      />
      <FormCreatedModal
        isOpen={formBuilderContext.showFormCreatedModal}
        onRequestClose={() => formBuilderContext.setShowFormCreatedModal(false)}
      />

      {!formLoaded && !formBuilderContext.serverFormDataError && <Spinner />}
    </SubscriptionFormBuilderContext.Provider>
  );
};

export default SubscriptionFormBuilder;
