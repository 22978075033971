import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './Label.module.scss';

function Label({children, color, style}) {
  return (
    <span
      className={cx(styles.label, [styles[color]])}
      style={{...{backgroundColor: `${color}4D`, color}, ...style}}
    >
      {children}
    </span>
  );
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Label;
