import useSubscriberFieldsFetcher from '../../../subscriber-fields/hooks/useSubscriberFieldsFetcher';
import SelectField from './SelectField';
import {selectMapper} from '../../../../../utils/arrays';

function TagsSelectField(props) {
  const {dataTags, loading} = useSubscriberFieldsFetcher({immediate: true});

  return (
    <SelectField
      {...props}
      placeholder={loading ? 'Loading..' : undefined}
      options={dataTags.map(selectMapper('id', 'name'))}
    />
  );
}

export default TagsSelectField;
