import clsx from 'clsx';
import alignmentStyles from '../TextAlignmentInput/TextAlignmentInput.module.scss';
import toolbarStyles from './Toolbar.module.scss';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';

import TextAlignmentInputField from '../TextAlignmentInput/TextAlignmentInputField';

export default ({label, paramPath, handler}) => {
  const [value, handleChange] = handler ? handler(paramPath) : useHandleConfigParam(paramPath);

  return (
    <div className={toolbarStyles.toolbarFieldContainer}>
      <div className={toolbarStyles.toolbarFieldLabelContainer}>
        <span className={toolbarStyles.toolbarFieldLabel}>{label}</span>
      </div>
      <div className={toolbarStyles.toolbarFieldInputContainer}>
        <div className={alignmentStyles.mainContainer} data-testid={paramPath}>
          <TextAlignmentInputField
            value={value}
            onChange={handleChange}
            name={paramPath}
            fieldValue="30%"
          >
            <span
              className={clsx(alignmentStyles.label)}
              style={{fontWeight: 500, paddingBottom: 10}}
            >
              Small
            </span>
          </TextAlignmentInputField>
          <TextAlignmentInputField
            name={paramPath}
            onChange={handleChange}
            value={value}
            fieldValue="65%"
          >
            <span
              className={clsx(alignmentStyles.label)}
              style={{fontWeight: 500, paddingBottom: 10}}
            >
              Medium
            </span>
          </TextAlignmentInputField>
          <TextAlignmentInputField
            name={paramPath}
            onChange={handleChange}
            value={value}
            fieldValue="100%"
          >
            <span
              className={clsx(alignmentStyles.label)}
              style={{fontWeight: 500, paddingBottom: 10}}
            >
              Full
            </span>
          </TextAlignmentInputField>
        </div>
      </div>
    </div>
  );
};
