import {Controller} from 'react-hook-form';

import FormRow from '../../../components/forms/FormRow/FormRow';
import Input from '../../../components/forms/Input';
import {REQUIRED_FIELD_MESSAGE} from '../../../components/forms/constants';
import Select from '../../../components/forms/Select';

function PostalAddressFields({countries, errors, control, register, isFormReadOnly}) {
  return (
    <>
      <FormRow>
        <Input
          name="name"
          label="Name/Company"
          required
          readOnly={isFormReadOnly}
          error={errors.name}
          inputRef={register({required: REQUIRED_FIELD_MESSAGE})}
        />
      </FormRow>
      <FormRow>
        <Input
          name="address1"
          label="Address 1 (Street address,  P.O box, company name)"
          required
          readOnly={isFormReadOnly}
          error={errors.address1}
          inputRef={register({required: REQUIRED_FIELD_MESSAGE})}
        />
        <Input
          name="address2"
          label="Address 2 (Apartment, suite, unit, building, floor, etc.)"
          readOnly={isFormReadOnly}
          error={errors.address2}
          inputRef={register}
        />
      </FormRow>
      <FormRow>
        <Input
          name="city"
          label="City"
          required
          readOnly={isFormReadOnly}
          error={errors.city}
          inputRef={register({required: REQUIRED_FIELD_MESSAGE})}
        />
        <Input name="province" label="State/Province" error={errors.province} inputRef={register} />
      </FormRow>
      <FormRow>
        <Input
          name="zipCode"
          label="Zip/Postal Code"
          required
          readOnly={isFormReadOnly}
          error={errors.zipCode}
          inputRef={register({required: REQUIRED_FIELD_MESSAGE})}
        />
        <Controller
          name="countryCode"
          error={errors.countryCode}
          control={control}
          rules={{required: REQUIRED_FIELD_MESSAGE}}
          as={Select}
          label="Country"
          options={countries}
          required
          disabled={isFormReadOnly}
          menuPlacement="auto"
        />
      </FormRow>
    </>
  );
}

export default PostalAddressFields;
