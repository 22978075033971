import cx from 'clsx';
import PropTypes from 'prop-types';

import styles from './ReportCard.module.scss';

function ReportCard({children, category, title, value, active, text, onClick}) {
  function handleClick() {
    if (children && onClick) {
      onClick(category);
    }
  }

  return (
    <>
      <button
        type="button"
        className={cx(styles.card, {[styles.active]: active, [styles.hasChildren]: !!children})}
        onClick={handleClick}
      >
        <strong className={styles.title}>{title}</strong>
        <strong className={styles.val}>{value}</strong>
        {text && <span className="text">{text}</span>}
        {children && <span className={styles.arrow}>Click to expand</span>}
      </button>
      {children && active ? children : null}
    </>
  );
}

ReportCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  active: PropTypes.bool,
  text: PropTypes.string,
  category: PropTypes.string,
  children: PropTypes.node,
};

export default ReportCard;
