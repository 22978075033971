import cx from 'clsx';
import Select from '../../../../../components/forms/Select';
import fieldStyles from '../Field.module.scss';

const SelectField = ({className, label, ...rest}) => (
  <div className={cx(fieldStyles.field, className)}>
    <label className={fieldStyles.labelContainer}>
      <span className={fieldStyles.label}>{label}</span>
    </label>
    <Select dense noBottomSpace {...rest} />
  </div>
);

export default SelectField;
