import {Route, Switch} from 'react-router-dom';
import useAuth from '../domain/auth/useAuth';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import PublicRouter from './PublicRouter';
import ROUTES from './routes';
import Maintenance from '../layouts/errorPages/Maintenance';

function Router() {
  const {state: authState} = useAuth();

  return (
    <Switch>
      {process.env?.REACT_APP_MAINTANANCE === 'true' ? (
        <Route path="*">
          <Maintenance />
        </Route>
      ) : (
        <>
          <Route path={ROUTES.publicPagesPrefix}>
            <PublicRouter />
          </Route>
          {authState.isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </>
      )}
    </Switch>
  );
}

export default Router;
