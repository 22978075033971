import {useState} from 'react';
import LoginForm from './LoginForm';
import FooterText from './FooterText';
import StatusMessage from '../../../components/StatusMessage/StatusMessage';
import ROUTES from '../../../Router/routes';
import useRouter from '../../../hooks/useRouter';
import useAuth from '../useAuth';
import useFetch from '../../../hooks/useFetch';
import loginService from '../services/login';
import styles from '../auth.module.scss';
import Hero from '../Hero';
import Logo from '../Logo';
import Footer from '../../../components/Footer';

function Login() {
  const {history, location} = useRouter();
  const {login} = useAuth();
  const {run: loginRequest} = useFetch(loginService, {throwError: true});
  const [errorMessage, setErrorMessage] = useState();

  const redirectAfterLogin = () => {
    if (location.state && location.state.from) {
      history.push(location.state.from);
    } else {
      history.push(ROUTES.index);
    }
  };

  async function handleFormSubmit(values) {
    setErrorMessage('');
    try {
      const response = await loginRequest(values);
      login(response.access_token, response.refresh_token, values.rememberMe);
      redirectAfterLogin();
    } catch (error) {
      setErrorMessage(error.message);
    }
  }

  return (
    <div className={styles.auth}>
      <div className={styles.inner}>
        <div className={styles.form}>
          <Logo />
          <h1 className={styles.title}>Log Into Your Account</h1>
          <StatusMessage message={errorMessage} />
          <LoginForm onSubmit={handleFormSubmit} />
          <FooterText />
        </div>
        <Hero />
      </div>
      <Footer />
    </div>
  );
}

export default Login;
