import {useReactFlow} from 'reactflow';
import AlertModal from '../AlertModal/AlertModal';
import {removeNode} from '../../utils/automationFlow';

const DeleteConfirmationModal = ({nodeData, isOpened, onClose}) => {
  const {data} = nodeData;

  const reactFlowInstance = useReactFlow();

  const handleRemoveStep = async () => {
    const nodes = reactFlowInstance.getNodes();
    const edges = reactFlowInstance.getEdges();

    const updatedFlow = removeNode(nodes, edges, nodeData);

    reactFlowInstance.setEdges(updatedFlow.edges);
    reactFlowInstance.setNodes(updatedFlow.nodes);
  };

  return (
    <AlertModal
      isOpen={isOpened}
      onRequestClose={onClose}
      title="Are you sure?"
      agreeColor="red"
      cancelColor="green"
      agreeVariant="filled"
      cancelVariant="outlined"
      cancelText="Cancel"
      agreeText="Confirm"
      onCancel={onClose}
      onAgree={async () => {
        handleRemoveStep();
        onClose();
      }}
      confirmText="DELETE"
    >
      <div>
        {data.actionTypeValue === 'EITHER_OR_CONDITION' ? (
          <div className="text-centered">
            <span>
              This action will permanently delete the &quot;Either Or Condition&quot; step, as well
              as all subsequent steps in both the &lsquo;True&rsquo; and &lsquo;False&rsquo;
              branches and their associated data. <br /> To confirm, please type <b>DELETE</b> in
              the text box below.
            </span>
          </div>
        ) : (
          <div className="text-centered">
            <span>
              This action will permanently delete the selected automation step.
              <br /> To confirm, please type <b>DELETE</b> in the text box below.
            </span>
          </div>
        )}
      </div>
    </AlertModal>
  );
};

export default DeleteConfirmationModal;
