import {forwardRef, useState} from 'react';
import {formatISO} from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import 'flatpickr/dist/themes/material_blue.css';
import calendarIco from '../../../img/icon-calendar.svg';
import styles from './DateFieldInput.module.scss';
import Label from '../Label';
import FieldError from '../FieldError';

const DateFieldInput = ({
  classes,
  value,
  onChange,
  inputStyle,
  inputRef,
  error,
  hideError,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(value && new Date(value));

  const handleDatePick = (date) => {
    setSelectedDate(date);
    onChange(date ? formatISO(date, {representation: 'date'}) : '');
  };

  const CustomInput = forwardRef(({onClick}) => (
    <>
      <button
        type="button"
        className={[styles.main, classes].join(' ')}
        onClick={onClick}
        style={{...inputStyle, ...{borderColor: error ? '#f26b86' : ''}}}
      >
        <input
          ref={inputRef}
          style={{display: 'none', height: 55}}
          value={selectedDate ? formatISO(selectedDate, {representation: 'date'}) : ''}
          type="hidden"
          {...props}
        />
        <span className={styles.text}>{value}</span>
        <span className={styles.icon_wrapper}>
          <img width="15" height="17" src={calendarIco} alt="Choose date" />
        </span>
      </button>
      {error && !hideError && <FieldError error={error} absolute />}
    </>
  ));

  return (
    <div style={{width: '100%', position: 'relative'}}>
      <Label>{props.label}</Label>
      <ReactDatePicker
        selected={selectedDate}
        onChange={handleDatePick}
        customInput={<CustomInput />}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        calendarClassName={styles.wrapper}
        popperClassName={styles.popperWrapper}
        className={styles.wrapper}
        isClearable
        clearButtonClassName={styles.clearButton}
      />
    </div>
  );
};

export default DateFieldInput;
