import {useEffect, useState} from 'react';
import useRouter from '../../hooks/useRouter';
import useAuth from '../auth/useAuth';
import ROUTES from '../../Router/routes';
import useFetch from '../../hooks/useFetch';
import {confirmProfileEmailService} from '../settings/user-profile/service';
import {confirmSenderEmailService} from '../settings/email-sender/service';
import EmailConfirm from './ConfirmEmail';

const REDIRECT_TIMEOUT = 5000;

function ConfirmEmailContainer() {
  const {query, history} = useRouter();
  const {state: authState} = useAuth();
  const [processing, setProcessing] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const redirectUrl = authState.isLoggedIn ? ROUTES.index : ROUTES.auth.login;

  const {run: confirmProfileEmailRequest} = useFetch(
    confirmProfileEmailService,
    {
      throwError: true,
    },
    {
      isAuthenticated: false,
    }
  );

  const {run: confirmSenderEmailRequest} = useFetch(
    confirmSenderEmailService,
    {
      throwError: true,
    },
    {
      isAuthenticated: false,
    }
  );

  useEffect(
    function () {
      async function confirmEmail() {
        setErrorMessage('');
        try {
          if (query.type === 'user-profile') {
            await confirmProfileEmailRequest(query.confirmationId);
          }

          if (query.type === 'sender-email') {
            await confirmSenderEmailRequest(query.confirmationId);
          }

          setSuccessMessage('Your email was confirmed. You can continue using the application.');
          setProcessing(false);
          setTimeout(function () {
            history.replace(redirectUrl);
          }, REDIRECT_TIMEOUT);
        } catch (error) {
          setErrorMessage(
            "Sorry, we couldn't confirm your e-mail address. Please try again later."
          );
          setProcessing(false);
        }
      }

      confirmEmail();
    },
    [query.confirmationId, query.type]
  );

  return (
    <EmailConfirm errorMessage={errorMessage} processing={processing} title={successMessage} />
  );
}

export default ConfirmEmailContainer;
