import Modal from '../../components/Modal';
import ImportSubscribersContainer from './ImportSubscribersContainer';
import {useImportSubscribersContext} from './useImportSubscribersContext';

function ImportSubscribersWizard() {
  const {state, closeWizard} = useImportSubscribersContext();
  return (
    <Modal
      isOpen={!!state.activeStep}
      onRequestClose={closeWizard}
      shouldCloseOnOverlayClick={false}
      size="full"
    >
      <ImportSubscribersContainer onRequestClose={closeWizard} />
    </Modal>
  );
}

export default ImportSubscribersWizard;
