import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import useRouter from '../../hooks/useRouter';

import img from '../../img/subscription-confirmation.svg';
import ConfirmationCard from '../../components/ConfirmationCard';
import useFetch from '../../hooks/useFetch';
import {confirmAccountEmail} from '../auth/services/signup';

function ConfirmEmail({errorMessage, title, processing}) {
  const {query} = useRouter();

  const {run: confirmAccountEmailRequest} = useFetch(confirmAccountEmail, {throwError: true});

  useEffect(() => {
    const sendConfirmAccount = async () => {
      try {
        if (query.type === 'account') {
          await confirmAccountEmailRequest({confirmationId: query.confirmationId});
        }
      } catch (error) {
        console.log(error);
      }
    };
    sendConfirmAccount();
  }, []);

  if (!Object.keys(query).length) {
    return <Redirect to="/" />;
  }

  return (
    <ConfirmationCard
      processing={processing}
      errorMessage={errorMessage}
      title={query.message || title}
      image={img}
    />
  );
}

ConfirmEmail.propTypes = {
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  processing: PropTypes.bool,
};

export default ConfirmEmail;
