export const AUTH0_CONNECTION = 'Username-Password-Authentication';
export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const REMEMBER_ME_KEY = 'remember_me';

export const ENDPOINTS = {
  login: `${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
  forgotPassword: `${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
  signup: `${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/signup`,
  refreshToken: `${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
  signupRequest: `/signup-request`,
  confirmAccountRequest: (confirmationId) => `/confirm-account-email/${confirmationId}`,
};
