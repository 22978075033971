import {useForm} from 'react-hook-form';

import useAlert from '../../alerts/useAlert';
import usePostalAddress from '../../../hooks/usePostalAddress';
import {handleErrorAlert} from '../../../utils/handleErrorAlert';

function usePostalAddressInfo(submitCallback) {
  const {showAlert} = useAlert();
  const {submitSenderDetails} = usePostalAddress({throwError: true});
  const {formState, errors, control, handleSubmit, setError, register} = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  async function handleFormSubmit(values, setFormError) {
    submitCallback();
    try {
      await submitSenderDetails(values);
      showAlert('Postal Address has been successfully updated!', {
        variant: 'success',
      });
    } catch (error) {
      handleErrorAlert(error, showAlert, setFormError);
    }
  }

  return {formState, errors, control, handleSubmit, setError, register, handleFormSubmit};
}

export default usePostalAddressInfo;
