import PropTypes from 'prop-types';
import styles from './StepIcon.module.scss';

import ActionIcon from '../../../../../img/action-automation-action-icon.png';
import DelayIcon from '../../../../../img/delay-automation-action-icon.png';
import EmailIcon from '../../../../../img/email-automation-action-icon.png';
import ConditionalIcon from '../../../../../img/conditional-automation-action-icon.png';

const StepIcon = ({stepType}) => {
  const iconByStep = {
    Action: {icon: ActionIcon, bg: '#E4E1FF'},
    Delay: {icon: DelayIcon, bg: '#F4E1FD'},
    Email: {icon: EmailIcon, bg: '#FFF3EA'},
    Condition: {icon: ConditionalIcon, bg: '#E2FAF5'},
  };

  const icon = iconByStep[stepType] || iconByStep.Action;

  return (
    <div
      className={styles.icon}
      style={{
        backgroundColor: icon.bg,
      }}
    >
      <img src={stepType && icon.icon} className={styles.iconImg} alt="Step icon" />
    </div>
  );
};

StepIcon.propTypes = {
  stepType: PropTypes.oneOf(['Action', 'Delay', 'Email', 'Condition']),
};

export default StepIcon;
