import PropTypes from 'prop-types';
import {MenuItem} from '@reach/menu-button';
import styles from './ActionsDropdown.module.scss';

function ActionDropdownItem({children, onSelect}) {
  return (
    <MenuItem className={styles.item} onSelect={onSelect}>
      {children}
    </MenuItem>
  );
}

ActionDropdownItem.propTypes = {
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ActionDropdownItem;
