import PropTypes from 'prop-types';
import cx from 'clsx';
import Confetti from 'react-confetti';
import useMeasure from 'react-use-measure';
import styles from './OrderSuccess.module.scss';

function OrderSuccess({wizard, initial, message, button, subtitle = 'Happy emailing!'}) {
  const [ref, {width, height}] = useMeasure();
  return (
    <div ref={ref} className={cx(styles.msg, {[styles.minWidth70vw]: wizard})}>
      <Confetti width={width} height={height} />
      <div className={styles.content}>
        <h1 className={cx(styles.title, styles.capitalized)}>
          {initial ? "You're all set!" : 'Plan Change Complete!'}
        </h1>
        <p className={styles.text}>{message}</p>
        <h2 className={styles.subtitle}>{subtitle}</h2>
        {button && <div className={styles.btnWrapper}>{button}</div>}
      </div>
    </div>
  );
}

OrderSuccess.propTypes = {
  message: PropTypes.node,
  wizard: PropTypes.bool,
  initial: PropTypes.bool,
  subtitle: PropTypes.string,
  button: PropTypes.node,
};

export default OrderSuccess;
