import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './RangeInput.module.scss';

const RangeInput = forwardRef(({step = 1, min, max, value, onChange, className}, ref) => {
  function handleChange(e) {
    onChange(e.target.value);
  }

  return (
    <input
      type="range"
      className={clsx(styles.range, className)}
      min={min}
      max={max}
      value={value}
      onChange={handleChange}
      step={step}
      ref={ref}
    />
  );
});

RangeInput.propTypes = {
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default RangeInput;
