import {Helmet} from 'react-helmet';
import Signup from '../Signup';

function SignupPage() {
  return (
    <div>
      <Helmet title="Sign up" />
      <Signup />
    </div>
  );
}

export default SignupPage;
