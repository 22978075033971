import createDataContext from '../../../utils/createDataContext';
import createReducer from '../../../utils/createReducer';
import {CLOSE_ALERT, CLOSE_ALL, SHOW_ALERT} from './actionTypes';
import {showAlert, closeAlert, closeAllAlerts} from './actions';
import {showAlertReducer, closeAlertReducer, initialState, closeAllReducer} from './reducers';

const alertReducer = createReducer(initialState, {
  [SHOW_ALERT]: showAlertReducer,
  [CLOSE_ALERT]: closeAlertReducer,
  [CLOSE_ALL]: closeAllReducer,
});

export const {Context, Provider} = createDataContext(
  alertReducer,
  {
    showAlert,
    closeAlert,
    closeAllAlerts,
  },
  initialState
);
