import Button from '../Button';
import Input from '../forms/Input';
import Modal from '../Modal';
import ModalClosableContent from '../Modal/ModalClosableContent';
import PropTypes from 'prop-types';
import styles from '../Modal/Modal.module.scss';
import trash from '../../img/ico-trash.svg';
import {useForm} from 'react-hook-form';
import warning from '../../img/modal-warning.svg';

export function validate(value) {
  return value === 'DELETE ALL';
}

function ConfirmBulkDeleteModal({isOpen, deleteId, onRequestClose, onDelete, successContent}) {
  const {formState, errors, register, handleSubmit} = useForm({
    mode: 'onChange',
    defaultValues: {
      confirm: '',
    },
  });

  function handleCancel() {
    onRequestClose();
  }

  async function handleFormSubmit() {
    try {
      await onDelete(deleteId);
      if (!successContent) {
        onRequestClose();
      }
    } catch (error) {
      onRequestClose();
    }
  }

  const isSuccessContentShown = successContent && formState.isSubmitSuccessful;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalClosableContent
        title={isSuccessContentShown ? 'Deleting...' : 'Are you sure?'}
        onRequestClose={onRequestClose}
        hideCloseButton={!isSuccessContentShown ?? true}
        img={
          !isSuccessContentShown ? (
            <img
              className={styles.infoImg}
              src={warning}
              width="307"
              height="263"
              alt="Confirm delete warning"
            />
          ) : (
            <img
              className={styles.infoImg}
              src={trash}
              width="140"
              height="200"
              alt="Confirm delete warning"
            />
          )
        }
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {isSuccessContentShown ? (
            successContent
          ) : (
            <>
              <p className={styles.text}>
                This action will permanently delete all subscribers from your account.
              </p>

              <p className={styles.text}>
                To confirm, please type <b>DELETE ALL</b> in the text box below.
              </p>

              <div className={styles.formWrapper}>
                <Input
                  name="confirm"
                  noBottomSpace
                  inputRef={register({
                    validate,
                  })}
                />
                <div className={styles.btns}>
                  <Button variant="outlined" color="grey" full onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="filled"
                    color="red"
                    full
                    disabled={!formState.isDirty || !!errors.confirm || formState.isSubmitting}
                    loading={formState.isSubmitting}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </>
          )}
        </form>
      </ModalClosableContent>
    </Modal>
  );
}

ConfirmBulkDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func,
  deleteId: PropTypes.string,
  successContent: PropTypes.node,
};

export default ConfirmBulkDeleteModal;
