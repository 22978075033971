import {useState} from 'react';
import {AutomationReferencesError} from 'domain/automation/errors/errors';
import {useAutomation} from 'domain/automation/stores/automation';
import AutomationWithActiveUsersAlert from './AutomationWithActiveUsersAlert';
import AutomationWithReferencesAlert from './AutomationWithReferencesAlert';

const AutomationUpdateAlert = () => {
  const [referencesModalIsOpened, setReferencesModalIsOpened] = useState(false);
  const [automationInitiators, setAutomationInitiators] = useState([]);
  const {showUpdateWarning, setShowUpdateWarning} = useAutomation();

  const closeAlerts = () => {
    setReferencesModalIsOpened(false);
    setShowUpdateWarning(false);
    setAutomationInitiators([]);
  };

  const handleErrorOnAlertWithActiveUsers = (error) => {
    if (error instanceof AutomationReferencesError) {
      setReferencesModalIsOpened(true);
      setAutomationInitiators(error.automationInitiators);
    } else {
      closeAlerts();
    }
  };

  const closeAlertWithReferences = () => {
    setReferencesModalIsOpened(false);
  };

  return (
    <>
      <AutomationWithActiveUsersAlert
        isOpen={showUpdateWarning}
        onClose={closeAlerts}
        onSuccess={closeAlerts}
        onError={handleErrorOnAlertWithActiveUsers}
      />
      <AutomationWithReferencesAlert
        isOpen={referencesModalIsOpened}
        automationInitiators={automationInitiators}
        onClose={closeAlertWithReferences}
        onSuccess={closeAlerts}
        onError={closeAlerts}
      />
    </>
  );
};

export default AutomationUpdateAlert;
