import {useState} from 'react';
import useFetch from '../../../hooks/useFetch';
import useAlert from '../../alerts/useAlert';
import useRouter from '../../../hooks/useRouter';
import {useAutomation} from '../stores/automation';
import {automationStatus} from '../utils/automationFlow';
import {handleErrorAlert} from '../../../utils/handleErrorAlert';
import {updateAutomationConfigService, createAutomationRevisionService} from '../service';
import {
  useFetchAutomation,
  useSaveAutomation,
  useAutomationInitiators,
  useUpdateAutomationConfig,
  useCreateAutomationRevisionWithRedirectedRefs,
} from './queries';
import {AutomationReferencesError} from '../errors/errors';
import {copyAutomation, incrementOrAddVersionToName} from '../utils/automation';

export const useCreateAutomation = () => {
  const {showAlert} = useAlert();
  const {history} = useRouter();
  const {automationData, setValidationErrors, initAutomationData} = useAutomation();
  const {run: saveAutomationRequest, loading, error} = useSaveAutomation({throwError: true});

  const handler = async () => {
    try {
      const resp = await saveAutomationRequest(automationData);
      initAutomationData(resp);

      const msg =
        automationData.status === automationStatus.DRAFT
          ? 'Automation has been successfully saved as draft'
          : 'Automation has been successfully published';
      showAlert(msg, {variant: 'success'});

      setTimeout(() => history.push('/automation'), 2000);
    } catch (e) {
      if (e.response?.validationErrors) {
        setValidationErrors(e.response?.validationErrors);
      } else {
        handleErrorAlert(e, showAlert);
      }
    }
  };

  return {handler, loading, error};
};

export const useUpdateAutomation = () => {
  const {showAlert} = useAlert();
  const {history} = useRouter();
  const {
    automationData,
    setValidationErrors,
    initialAutomationData,
    initAutomationData,
  } = useAutomation();
  const {run: updateAutomationRequest, loading, error} = useFetch(updateAutomationConfigService, {
    throwError: true,
  });

  const handler = async () => {
    try {
      const resp = await updateAutomationRequest({...automationData, isGeneral: true});
      initAutomationData(resp);

      const msg =
        initialAutomationData.status === automationStatus.DRAFT &&
        automationData.status === automationStatus.ACTIVE
          ? 'Automation has been successfully published'
          : 'Automation has been updated';
      showAlert(msg, {variant: 'success'});

      setValidationErrors();
      setTimeout(() => history.push('/automation'), 2000);
    } catch (e) {
      if (e.response?.validationErrors) {
        setValidationErrors(e.response?.validationErrors);
      } else {
        handleErrorAlert(e, showAlert);
      }
    }
  };

  return {handler, loading, error};
};

export const useUpdateAutomationWithAciveSubscribersCheck = () => {
  const {showAlert} = useAlert();
  const {automationData, setShowUpdateWarning, setValidationErrors} = useAutomation();
  const fetchAutomationQuery = useFetchAutomation();
  const {
    handler: updateAutomation,
    loading: isUpdaing,
    error: updateAutomationErorr,
  } = useUpdateAutomation();

  const handler = async () => {
    try {
      const onTimeData = await fetchAutomationQuery.run(automationData.id);

      if (onTimeData?.report?.activeSubscriberCount >= 1) {
        setShowUpdateWarning(true);
        return;
      }

      await updateAutomation();
    } catch (e) {
      if (e.response?.validationErrors) {
        setValidationErrors(e.response?.validationErrors);
      } else {
        handleErrorAlert(e, showAlert);
      }
    }
  };

  return {
    handler,
    loading: fetchAutomationQuery.loading || isUpdaing,
    error: fetchAutomationQuery.error || updateAutomationErorr,
  };
};

export const useCreateAutomationRevision = () => {
  const {history} = useRouter();
  const {showAlert} = useAlert();
  const initiatorsQuery = useAutomationInitiators({throwError: true});
  const newRevisionQuery = useFetch(createAutomationRevisionService, {throwError: true});
  const {automationOverviewData, automationData, setShowUpdateWarning} = useAutomation();
  const [loading, setLoading] = useState(false);

  const handler = async () => {
    try {
      setLoading(true);

      const newAutomationRevision = {
        ...copyAutomation(automationData),
        name: incrementOrAddVersionToName(automationData.name, automationOverviewData),
      };

      const automationInitiators = await initiatorsQuery.run(automationData.id);

      if (automationInitiators.length > 0) {
        throw new AutomationReferencesError(automationInitiators);
      }

      await newRevisionQuery.run({
        automation: newAutomationRevision,
        id: automationData.id,
      });

      showAlert(`Revision has been successfully created!`, {
        variant: 'success',
      });
      setShowUpdateWarning(false);

      setTimeout(() => history.push('/automation'), 2000);
    } finally {
      setLoading(false);
    }
  };

  return {
    handler,
    loading,
    error: initiatorsQuery.error || newRevisionQuery.error,
  };
};

export const useCreateAutomationDraft = () => {
  const {history} = useRouter();
  const {showAlert} = useAlert();
  const {automationOverviewData, automationData} = useAutomation();
  const saveAutomationQuery = useSaveAutomation({throwError: true});

  const handler = async () => {
    const automationDraft = {
      ...copyAutomation(automationData),
      status: automationStatus.DRAFT,
      name: incrementOrAddVersionToName(automationData.name, automationOverviewData),
    };
    await saveAutomationQuery.run(automationDraft);
    showAlert(`Draft has been successfully created!`, {
      variant: 'success',
    });
    setTimeout(() => history.push('/automation'), 2000);
  };

  return {
    handler,
    loading: saveAutomationQuery.loading,
    error: saveAutomationQuery.error,
  };
};

export const useCreateRevisionWithRedirectedRefs = () => {
  const {history} = useRouter();
  const {showAlert} = useAlert();
  const {automationOverviewData, automationData} = useAutomation();
  const createAutomationWithRefsRedirectionQuery = useCreateAutomationRevisionWithRedirectedRefs({
    throwError: true,
  });

  const handler = async () => {
    const automationCopy = {
      ...copyAutomation(automationData),
      name: incrementOrAddVersionToName(automationData.name, automationOverviewData),
    };
    await createAutomationWithRefsRedirectionQuery.run({
      automation: automationCopy,
      id: automationData.id,
    });
    showAlert(`Revision has been successfully created!`, {
      variant: 'success',
    });
    setTimeout(() => history.push('/automation'), 2000);
  };

  return {
    handler,
    loading: createAutomationWithRefsRedirectionQuery.loading,
    error: createAutomationWithRefsRedirectionQuery.error,
  };
};

export const useForceUpdateAutomation = () => {
  const {showAlert} = useAlert();
  const {automationData, initAutomationData} = useAutomation();
  const updateAutomationQuery = useUpdateAutomationConfig({throwError: true});

  const handler = async () => {
    const updatedAutomation = await updateAutomationQuery.run(automationData);
    initAutomationData(updatedAutomation);
    showAlert(`Automation has been successfully updated!`, {
      variant: 'success',
    });
  };

  return {
    handler,
    loading: updateAutomationQuery.loading,
    error: updateAutomationQuery.error,
  };
};
