import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useBoardingWizard} from '../context/WizardContext';
import {Panel, PanelContent, PanelHeader} from '../components/Panel';
import PlansComp from '../../../components/Plans';
import Plan from '../../../components/Plans/Plan';
import useFetch from '../../../hooks/useFetch';
import {fetchSubscriptionPlansService} from '../../settings/subscription-plans/service';
import {handleError} from '../../../utils/handleError';
import useRouter from '../../../hooks/useRouter';
import ROUTES from '../../../Router/routes';
import {fetchActiveSubscriptionService} from '../../settings/subscription/service';
import {useFreePlan} from '../../free-plan-info/context/FreePlanContext';
import useBillingDetails from '../../../hooks/useBillingDetails';

function Plans() {
  const [plans, setPlans] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const {setStepData, completeStep} = useBoardingWizard();
  const {history} = useRouter();
  const freePlan = useFreePlan();
  const {billingAddressCompleted} = freePlan.state;
  const {fetchBillingDetailsRequest} = useBillingDetails({
    throwError: true,
    immediate: false,
    throwOnFetch: true,
  });

  const {run: fetchSubscriptionPlansRequest, loading} = useFetch(fetchSubscriptionPlansService, {
    throwError: true,
  });
  const {data: activeSubscription} = useFetch(fetchActiveSubscriptionService, {
    immediate: true,
  });

  useEffect(
    function () {
      async function fetchData() {
        try {
          const response = await fetchSubscriptionPlansRequest();
          setPlans(response);
        } catch (error) {
          handleError(error, setErrorMessage);
        }
      }

      fetchData();
    },
    [fetchSubscriptionPlansRequest]
  );

  async function handleSelectPlan(selectedPlan) {
    setStepData({id: selectedPlan.id});
    completeStep();
    if (billingAddressCompleted) {
      const resp = await fetchBillingDetailsRequest();
      setStepData(resp, 'billing');
      completeStep('billing');
    }
    history.push(billingAddressCompleted ? ROUTES.boarding.checkout : ROUTES.boarding.billing);
  }

  function renderPlans(subscriptionPlan) {
    const subscriptionPlanId = subscriptionPlan?.subscriptionPlan.id;
    if (!plans || !subscriptionPlanId) {
      return null;
    }

    return (
      <PlansComp small plans={plans} activePlanId={subscriptionPlanId}>
        {(plan, i, corporatePlans) => (
          <Plan
            activePlanId={subscriptionPlanId}
            key={plan.id}
            data={plan}
            onSelect={handleSelectPlan}
            corporatePlans={corporatePlans}
          />
        )}
      </PlansComp>
    );
  }

  return (
    <>
      <Helmet title="Select Plan" />
      <Panel>
        <PanelHeader
          title="Select Plan"
          subtitle="Enjoy simple and effective email marketing with Maildroppa — at
            an affordable price! We are offering reduced plan rates while our beta version
            is running and each plan comes with a 14-day free trial. You can cancel anytime
            before your trial ends and you won't be charged a cent!"
        />
        <PanelContent errorMessage={errorMessage} loading={loading}>
          {renderPlans(activeSubscription)}
        </PanelContent>
      </Panel>
    </>
  );
}

export default Plans;
