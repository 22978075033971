import {ENDPOINTS} from './constants';
import {post, get, put} from '../../../utils/api';

export async function addPostalAddressService(apiSettings, data) {
  return post(ENDPOINTS.add, data, apiSettings);
}

export async function fetchPostalAddressService(apiSettings) {
  return get(ENDPOINTS.fetch, null, apiSettings);
}

export async function updatePostalAddressService(apiSettings, data) {
  return put(ENDPOINTS.update, data, apiSettings);
}
