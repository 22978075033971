import {useMemo} from 'react';
import {useParams, useLocation, useHistory, useRouteMatch} from 'react-router-dom';
import queryString from 'query-string';

function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(
    function () {
      return {
        push: history.push,
        replace: history.replace,
        pathname: location.pathname,
        query: {
          ...queryString.parse(location.search),
          ...params,
        },
        match,
        location,
        history,
      };
    },
    [params, match, location, history]
  );
}

export default useRouter;
