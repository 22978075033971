import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './Label.module.scss';
import Asterisk from '../../Asterisk';

function Label({children, required, className = ''}) {
  return (
    <span className={cx(styles.label, className)}>
      {children}
      {required && <Asterisk />}
    </span>
  );
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default Label;
