import queryString from 'query-string';

import {ENDPOINTS} from './constants';
import {del, post, put, get} from '../../utils/api';

export async function deleteSubscriberService(apiSettings, data) {
  if (!data) {
    return del(ENDPOINTS.deleteAll, null, apiSettings);
  }

  if (Array.isArray(data)) {
    return del(ENDPOINTS.deleteSelected, data, apiSettings);
  }

  return del(ENDPOINTS.deleteSingle(data), null, apiSettings);
}

export async function fetchSubscribersService(apiSettings, data = {status: 'CONFIRMED'}) {
  const defaultParams = {
    page: 1,
    limit: 20,
  };

  const status = data.status === 'all' ? undefined : data.status;

  const params = {
    ...defaultParams,
    ...data,
    status,
  };

  return get(ENDPOINTS.fetchAll, params, apiSettings);
}

export async function fetchConfirmedSubscribersFilteredCountService(apiSettings, data) {
  return post(
    `${ENDPOINTS.fetchAll}/filtered-count?${queryString.stringify({status: 'CONFIRMED'})}`,
    data,
    apiSettings
  );
}

export async function fetchSubscribersFilteredService(apiSettings, data) {
  return post(`${ENDPOINTS.fetchAll}/filtered`, data, apiSettings);
}

export async function addSubscriberService(apiSettings, data) {
  return post(ENDPOINTS.add, data, apiSettings);
}

export async function editSubscriberService(apiSettings, data) {
  return put(ENDPOINTS.update, data, apiSettings);
}

export async function fetchSubscribersReportTotalService(apiSettings, data) {
  return get(ENDPOINTS.fetchReportTotal, data, apiSettings);
}

export async function fetchSubscribersReportDeltaService(apiSettings, data) {
  return get(ENDPOINTS.fetchReportDelta, data, apiSettings);
}

export async function fetchSubscribersReportTotalOverviewService(apiSettings) {
  return get(ENDPOINTS.fetchReportTotalOverview, null, apiSettings);
}

export async function exportSubscribersService(apiSettings, data) {
  if (Array.isArray(data)) {
    return post(ENDPOINTS.export, data, apiSettings);
  }

  return post(ENDPOINTS.exportAll, null, apiSettings);
}

export async function fetchConfirmedSubscribersService(apiSettings) {
  return get(ENDPOINTS.fetchConfirmedCount, {status: 'CONFIRMED'}, apiSettings);
}
