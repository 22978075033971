import PropTypes from 'prop-types';
import {TabList as ReactTabList} from 'react-tabs';
import styles from './Tabs.module.scss';

function TabList({children, ...props}) {
  return (
    <ReactTabList className={styles.list} {...props}>
      {children}
    </ReactTabList>
  );
}

TabList.tabsRole = 'TabList';
TabList.propTypes = {
  children: PropTypes.node,
};

export default TabList;
