import React from 'react';

import ToolbarSection from './ToolbarSection';
import CheckboxField from './CheckboxField';
import TextField from './TextField';
import styles from './Toolbar.module.scss';
import {SubscriptionFormBuilderContext} from '../../context';
import FontOptionsSection from './FontOptionsSection';

const SubscriptionCount = ({fontStylesToolbar = false, isSection = false}) => {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);

  const titleProps = isSection ? {sectionName: 'Subscriber Count'} : {title: 'Subscriber Count'};

  return (
    <>
      <ToolbarSection {...titleProps} className={styles.toolbarContent}>
        <CheckboxField paramPath="subscriberCounter.enabled" label="Show on Signup-Form" />
        {formState.subscriberCounter.enabled ? (
          <>
            <TextField
              label="Counter Offset"
              helpText="Start showing subscribers count from a specific number"
              paramPath="subscriberCounter.offset"
              type="number"
            />
            <TextField
              label="Counter Label"
              helpText="Text before subscribers counter"
              paramPath="jsonConfig.body.subscriberCounterStyle.text.value"
            />
          </>
        ) : null}
      </ToolbarSection>
      {fontStylesToolbar && formState.subscriberCounter.enabled ? (
        <FontOptionsSection
          pathPrefix="jsonConfig.body.subscriberCounterStyle.text"
          sectionTitle="Subscriber Count Text Styles"
        />
      ) : null}
    </>
  );
};

export default SubscriptionCount;
