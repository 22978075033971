import {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import img from '../../img/modal-warning.svg';
import styles from '../Modal/Modal.module.scss';
import Modal from '../Modal';
import ModalClosableContent from '../Modal/ModalClosableContent';

function ConfirmDeleteModal({
  isOpen,
  children,
  deleteId,
  onRequestClose,
  onDelete,
  successContent,
  title = 'Are you sure?',
}) {
  const [status, setStatus] = useState('idle');

  async function handleDelete() {
    setStatus('processing');
    try {
      await onDelete(deleteId);
      setStatus('completed');
      if (!successContent) {
        onRequestClose();
      }
    } catch (error) {
      setStatus('error');
      onRequestClose();
    }
  }

  const isSuccessContentShown = successContent && status === 'completed';

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalClosableContent
        title={title}
        hideImage={isSuccessContentShown}
        hideTitle={isSuccessContentShown}
        onRequestClose={onRequestClose}
        hideCloseButton={!isSuccessContentShown ?? true}
        img={
          <img
            className={styles.infoImg}
            src={img}
            width="307"
            height="263"
            alt="Confirm delete exclamation point"
          />
        }
      >
        {isSuccessContentShown ? (
          successContent
        ) : (
          <>
            <p className={styles.text}>
              {children || (
                <>
                  This process can&apos;t be undone, so are you sure you want to proceed? Do you
                  really want to proceed?
                </>
              )}
            </p>
            <div className={styles.btns}>
              <Button variant="outlined" color="grey" full onClick={onRequestClose}>
                Cancel
              </Button>
              <Button
                variant="filled"
                color="red"
                full
                onClick={handleDelete}
                disabled={status === 'processing'}
                loading={status === 'processing'}
              >
                Delete
              </Button>
            </div>
          </>
        )}
      </ModalClosableContent>
    </Modal>
  );
}

ConfirmDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
  deleteId: PropTypes.string,
  title: PropTypes.string,
  successContent: PropTypes.node,
};

export default ConfirmDeleteModal;
