import {Redirect, Route, Switch} from 'react-router-dom';
import ROUTES from './routes';
import BoardingWizardContainer from '../domain/BoardingWizard/components/boarding-wizard/BoardingWizardContainer';

function BoardingWizardRouter() {
  return (
    <Switch>
      <Route path={`${ROUTES.boarding.index}/:step`}>
        <BoardingWizardContainer />
      </Route>
      <Route path="*">
        <Redirect to={ROUTES.index} />
      </Route>
    </Switch>
  );
}

export default BoardingWizardRouter;
