import React from 'react';
import Button from '../../../../components/Button';
import ButtonWidthField from '../../../../components/SlateEditor/elements/LinkButton/buttonWidthField';
import SizeField from '../../../subscription-form/components/Toolbar/SizeField';
import TextAlignmentsField from '../../../subscription-form/components/Toolbar/TextAlignmentsField';
import TextField from '../../../subscription-form/components/Toolbar/TextField';

import NewsletterContext from '../../context';
import {useHandleButtonParam} from '../../useHandleButtonParam';
import NewsletterColorField from './NewsletterColorField';
import {BUILDER_MAX_BORDER_RADIUS, BUILDER_MAX_FONT_SIZE} from './constants';

import styles from './Toolbar.module.scss';
import ToolbarSection from './ToolbarSection';

const Toolbar = () => {
  const {showToolbar, setToolbarOpen} = React.useContext(NewsletterContext);

  return (
    showToolbar && (
      <div className={styles.toolbarWrapper}>
        <div className={styles.toolbar}>
          <ToolbarSection title="Button Styles" className={styles.toolbarContent}>
            <TextField label="Url" paramPath="url" handler={useHandleButtonParam} />

            <NewsletterColorField label="Text Color" paramPath="color" />
            <NewsletterColorField label="Background Color" paramPath="backgroundColor" />
            <NewsletterColorField label="Border Color" paramPath="borderColor" />

            <SizeField
              label="Font size"
              type="number"
              paramPath="fontSize"
              handler={useHandleButtonParam}
              min={4}
              max={BUILDER_MAX_FONT_SIZE}
            />

            <SizeField
              label="Border radius"
              type="number"
              paramPath="borderRadius"
              handler={useHandleButtonParam}
              min={0}
              max={BUILDER_MAX_BORDER_RADIUS}
            />

            <ButtonWidthField paramPath="width" />
            <TextAlignmentsField
              label="Alignment"
              paramPath="textAlign"
              handler={useHandleButtonParam}
            />
          </ToolbarSection>
        </div>

        <div className={styles.footer}>
          <Button variant="outlined" color="grey" onClick={() => setToolbarOpen(false)}>
            Close
          </Button>
        </div>
      </div>
    )
  );
};

export default Toolbar;
