import cx from 'clsx';
import Button from '../../../../components/Button';
import styles from './Toolbar.module.scss';

function ToolbarSection({sectionName, contentClassName, title, children}) {
  return (
    <div className={styles.editSidebarGroup}>
      <h4 className={styles.editSidebarGroupHeading} style={{borderTop: '1px solid #dde3e8'}}>
        {sectionName ? (
          <>
            <Button
              type="button"
              variant="link"
              //   onClick={resetSelectedElement}
              className={styles.sectionHeaderButton}
            >
              General Styles
            </Button>
            {` > ${sectionName}`}
          </>
        ) : (
          title
        )}
      </h4>
      <div className={cx(styles.editSidebarGroupContent, contentClassName)}>{children}</div>
    </div>
  );
}

export default ToolbarSection;
