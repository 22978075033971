import {useCallback, useEffect, useState, useMemo} from 'react';
import useFetch from '../../../hooks/useFetch';
import {fetchTemplatesService} from '../service';

const useFetchTemplate = (type, categories, search = null) => {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [templates, setTemplates] = useState([]);
  const {run: fetchTemplatesRequest, loading} = useFetch(fetchTemplatesService);

  const hasMore = useMemo(() => page < totalPages, [page, totalPages]);

  const handleFetchMore = useCallback(() => {
    if (loading || !hasMore) return;
    const newPage = page + 1;
    fetchTemplatesRequest({
      page: newPage,
      type,
      categories,
      templateNameQuery: search,
    }).then((response) => {
      setTemplates((prev) => [...prev, ...(response?.data ?? [])]);
      setTotalPages(Number(response?.meta?.totalPages ?? 0));
      setPage(newPage);
    });
  }, [loading, fetchTemplatesRequest, page, type, categories, search, hasMore]);

  const handlerDelete = useCallback((id) => {
    setTemplates((prev) => prev?.filter((item) => item.id !== id));
  }, []);

  useEffect(() => {
    if (!loading) {
      fetchTemplatesRequest({
        page: 1,
        type,
        categories,
        templateNameQuery: search,
      }).then((response) => {
        setTemplates(response?.data);
        setTotalPages(Number(response?.meta?.totalPages ?? 0));
        setPage(1);
      });
    }
  }, [fetchTemplatesRequest, search, categories, type]);

  return {templates, page, handleFetchMore, loading, hasMore, handlerDelete};
};

export default useFetchTemplate;
