import PropTypes from 'prop-types';

export const moneyPropType = PropTypes.shape({
  value: PropTypes.number,
  formattedValue: PropTypes.string,
});

export const paymentMethodPropType = PropTypes.shape({
  brandName: PropTypes.oneOf(['visa', 'mastercard', 'discover', 'amex']),
  expiryMonth: PropTypes.number,
  expiryYear: PropTypes.number,
  last4Digits: PropTypes.string,
  defaultPayment: PropTypes.bool,
  stripePaymentMethodId: PropTypes.string,
});

export const subscriptionStatusProp = PropTypes.oneOf(['TRIAL', 'ACTIVE', 'EXPIRING']);

export const paginatedMetaPropType = PropTypes.shape({
  first: PropTypes.shape({
    page: PropTypes.string,
    limit: PropTypes.string,
    rel: PropTypes.string,
    url: PropTypes.string,
  }),
  last: PropTypes.shape({
    page: PropTypes.string,
    limit: PropTypes.string,
    rel: PropTypes.string,
    url: PropTypes.string,
  }),
  totalPages: PropTypes.number,
  totalCount: PropTypes.number,
});

export const subscriptionPlanPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  limitTo: PropTypes.string,
  planStatus: PropTypes.string,
  unitAmount: PropTypes.shape({
    formattedValue: PropTypes.string,
    value: PropTypes.number,
  }),
  trialDays: PropTypes.shape({
    value: PropTypes.number,
    formattedValue: PropTypes.string,
  }),
});

export const domainVerificationStatusPropType = PropTypes.oneOf(['INIT', 'UNVERIFIED', 'VERIFIED']);

export const billingPeriodPropType = PropTypes.shape({
  unit: PropTypes.string,
  frequency: PropTypes.string,
});

export const subscriberFieldTypePropType = PropTypes.oneOf(['TEXT', 'NUMBER', 'DATE', 'TAG']);

export const subscriberFieldPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: subscriberFieldTypePropType,
  hidden: PropTypes.bool,
});
