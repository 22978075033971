import useRouter from './useRouter';
import ROUTES from '../Router/routes';
import useFetch from './useFetch';
import {fetchDefaultMethodService} from '../components/payments/PaymentMethods/service';

const useUpgradePlan = () => {
  const {history} = useRouter();
  const {run: fetchDefaultMethodRequest, loading} = useFetch(fetchDefaultMethodService);

  async function upgradePlan() {
    const response = await fetchDefaultMethodRequest();
    const isUpgrade = Object(response).length !== 0 && !response.expired;

    if (isUpgrade) {
      history.push(ROUTES.settings.plans);
    } else {
      history.push(ROUTES.boarding.plans);
    }
  }

  return {
    upgradePlan,
    loading,
  };
};

export default useUpgradePlan;
