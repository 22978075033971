import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import ROUTES from 'Router/routes';
import calendarIco from 'img/icon-calendar.svg';
import styles from './ScheduleNewsletter.module.scss';

const CustomInput = forwardRef(({onClick, formattedDatetime, timezoneLabel}, ref) => (
  <button type="button" className={styles.datePickerInput} onClick={onClick} ref={ref}>
    <div className={styles.datePickerText}>
      {formattedDatetime} <Link to={{pathname: ROUTES.settings.userProfile}}>{timezoneLabel}</Link>
    </div>
    <div className={styles.datePickerIcon}>
      <img width="15" height="17" src={calendarIco} alt="Choose date" />
    </div>
  </button>
));

CustomInput.propTypes = {
  onClick: PropTypes.func,
  timezoneLabel: PropTypes.string.isRequired,
  formattedDatetime: PropTypes.string.isRequired,
};

export default CustomInput;
