import {v4 as uuidv4} from 'uuid';
import {createEdge, createConditionalEdge, CONDITION} from './edges';
import {
  createNewTriggerNode,
  createStartStepNode,
  createTriggerNodeFromServerTriggerData,
} from './nodes';
import {flowIsEditable, stepIsFinal} from './automationFlow';

export const position = {x: 0, y: 0};

const getStepType = (stepType) => {
  if (stepType === 'FIXED_DELAY' || stepType === 'FIELD_DELAY') {
    return 'Delay';
  }
  if (stepType === 'EITHER_OR_CONDITION') {
    return 'Condition';
  }
  if (stepType === 'SEND_EMAIL') {
    return 'Email';
  }

  return 'Action';
};

export const sortSteps = (steps, initialStepId) => {
  const stepsById = steps.reduce((acc, step) => {
    acc[step.id] = step;
    return acc;
  }, {});

  const sortedSteps = [];

  const addStepById = (currentStepId) => {
    const currentStep = stepsById[currentStepId];

    sortedSteps.push(currentStep);

    if (currentStep?.stepParam || currentStep?.stepType === 'EITHER_OR_CONDITION') {
      if (currentStep?.stepType === 'EITHER_OR_CONDITION') {
        if (currentStep.stepParam.nextStepIdIfTrue) {
          addStepById(currentStep.stepParam.nextStepIdIfTrue);
        }
        if (currentStep.stepParam.nextStepIdIfFalse) {
          addStepById(currentStep.stepParam.nextStepIdIfFalse);
        }
      } else if (currentStep.stepParam.nextStepId) {
        addStepById(currentStep.stepParam.nextStepId);
      }
    }
  };

  addStepById(initialStepId);

  const sortedStepsWithIndex = sortedSteps.map((step, index) => {
    return {...step, index};
  });

  return sortedStepsWithIndex;
};

export const convertJsonToGraph = (json, status) => {
  const nodes = [];
  const edges = [];

  if (!json) return;

  const newTrigger = createNewTriggerNode(uuidv4());
  const addFirstStepNode = createStartStepNode(uuidv4());
  const newTriggerToAddFirstStepEdge = createEdge(newTrigger.id, addFirstStepNode.id);
  const addFirstStepToFirstStepEdge = createEdge(addFirstStepNode.id, json.startStepId);

  json.triggers?.forEach((trigger) => {
    nodes.push(createTriggerNodeFromServerTriggerData(trigger));
    const toNodeId = flowIsEditable(status) ? addFirstStepNode.id : json.startStepId;
    edges.push(createEdge(trigger.id, toNodeId));
  });

  if (flowIsEditable(status)) {
    nodes.push(newTrigger, addFirstStepNode);
    edges.push(newTriggerToAddFirstStepEdge, addFirstStepToFirstStepEdge);
  }

  const sortedSteps = sortSteps(json.steps, json.startStepId);

  // on each iteration we add: node + conditional edge + conditional node + next step edge
  // it can vary depending on the flow status and node type
  // but general logic is to add necessary nodes and edges up to next node
  sortedSteps.forEach((step) => {
    let node;
    const stepType = getStepType(step.stepType);
    let isConditional = false;
    // create step node
    if (step.stepType === 'FIXED_DELAY') {
      node = {
        id: String(step.id),
        type: 'stepNode',
        data: {
          height: 90,
          actionAmount: step.stepParam?.delayAmount,
          actionValue: step.stepParam?.timeUnit,
          actionTypeValue: step.stepType,
        },
        position,
      };
    } else if (step.stepType === 'FIELD_DELAY') {
      node = {
        id: String(step.id),
        type: 'stepNode',
        data: {
          height: 90,
          actionTypeValue: step.stepType,
          actionValue: step.stepParam?.dateFieldId,
          missingDateFieldAction: step.stepParam?.missingDateFieldAction,
        },
      };
    } else if (step.stepType === 'UPDATE_FIELD') {
      node = {
        id: String(step.id),
        type: 'stepNode',
        data: {
          height: 90,
          actionFieldValue: step.stepParam?.value,
          actionValue: step.stepParam?.fieldId,
          actionTypeValue: step.stepType,
        },
        position,
      };
    } else if (step.stepType === 'EITHER_OR_CONDITION') {
      isConditional = true;
      node = {
        id: String(step.id),
        type: 'stepNode',
        data: {
          height: 90,
          actionValue: step.stepParam?.filterExpression,
          actionTypeValue: step.stepType,
        },
        position,
      };
    } else {
      node = {
        id: String(step.id),
        type: 'stepNode',
        data: {
          height: 90,
          actionTypeValue: step.stepType,
          actionValue: step.stepParam?.id,
        },
        position,
      };
    }
    // set general/group/step type for step node
    node.data.stepType = stepType;
    if (!isConditional) {
      const conditionalNode = {
        id: String(uuidv4()),
        type: 'conditionalNode',
        data: {height: 30},
        position,
      };
      // @TODO: fix this
      // eslint-disable-next-line no-shadow
      const conditionalEdge = createEdge(node.id, conditionalNode.id);
      const nextStepEdge =
        step.stepParam?.nextStepId && createEdge(conditionalNode.id, step.stepParam.nextStepId);

      // if automation can be modified and step is not final
      // then append (node)--[conditional edge]->(conditional node)--[next step edge]->
      // else add (node)--[next step edge]->
      if (!stepIsFinal(step.stepType) && flowIsEditable(status)) {
        nodes.push(node, conditionalNode);

        // @TODO: fix this
        // eslint-disable-next-line no-unused-expressions
        nextStepEdge ? edges.push(conditionalEdge, nextStepEdge) : edges.push(conditionalEdge);
      } else {
        if (step.stepParam?.nextStepId) {
          const edge = createEdge(node.id, step.stepParam.nextStepId);
          edges.push(edge);
        }
        nodes.push(node);
      }
    } else {
      // if the node we try to convert to react flow is condition then

      // create conditional yes & no nodes and edges (e.g. add extra step node)
      const conditionalYesNode = {
        id: String(uuidv4()),
        type: 'conditionalNode',
        data: {height: 30},
        position,
      };
      const conditionalNoNode = {
        id: String(uuidv4()),
        type: 'conditionalNode',
        data: {height: 30},
        position,
      };
      const conditionalYesEdge = createConditionalEdge(
        node.id,
        conditionalYesNode.id,
        CONDITION.TRUE
      );
      const conditionalNoEdge = createConditionalEdge(
        node.id,
        conditionalNoNode.id,
        CONDITION.FALSE
      );
      node.data.trueEdgeSourceId = conditionalYesNode.id;
      node.data.falseEdgeSourceId = conditionalNoNode.id;

      if (flowIsEditable(status)) {
        nodes.push(node, conditionalYesNode, conditionalNoNode);
        edges.push(conditionalYesEdge, conditionalNoEdge);
        if (step.stepParam?.nextStepIdIfTrue) {
          edges.push(createEdge(conditionalYesNode.id, step.stepParam.nextStepIdIfTrue));
        }

        if (step.stepParam?.nextStepIdIfFalse) {
          edges.push(createEdge(conditionalNoNode.id, step.stepParam.nextStepIdIfFalse));
        }
      } else {
        nodes.push(node);

        // since we can't modify the flow we don't need conditional (a.k.a. add extra step) nodes and edges
        // here we add edge between "condition" node and next step node OR
        // empty "end node" and edge between condition and this empty node
        if (step.stepParam?.nextStepIdIfTrue) {
          edges.push(
            createConditionalEdge(node.id, step.stepParam.nextStepIdIfTrue, CONDITION.TRUE)
          );
        } else {
          const endNode = {
            id: String(uuidv4()),
            type: 'endNode',
            position,
            data: {height: 220},
          };
          nodes.push(endNode);
          edges.push(createConditionalEdge(node.id, endNode.id, CONDITION.TRUE));
        }
        if (step.stepParam?.nextStepIdIfFalse) {
          edges.push(
            createConditionalEdge(node.id, step.stepParam.nextStepIdIfFalse, CONDITION.FALSE)
          );
        } else {
          const endNode = {
            id: String(uuidv4()),
            type: 'endNode',
            data: {height: 220},
          };
          nodes.push(endNode);
          edges.push(createConditionalEdge(node.id, endNode.id, CONDITION.FALSE));
        }
      }
    }
  });

  // @TODO: fix this
  // eslint-disable-next-line consistent-return
  return {n: nodes, e: edges};
};
