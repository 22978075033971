import {Helmet} from 'react-helmet';
import OverviewContainer from './OverviewContainer';

function OverviewPage() {
  return (
    <>
      <Helmet title="Email Campaigns" />
      <OverviewContainer />
    </>
  );
}

export default OverviewPage;
