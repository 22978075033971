import {useEffect, useState} from 'react';
import Bugsnag from '@bugsnag/js';

const scriptCache = new Map();

const loadScript = (src, attr) => {
  if (scriptCache.has(src)) {
    return scriptCache.get(src);
  }

  const script = document.createElement('script');
  script.src = src;
  script.async = true;

  Object.keys(attr).forEach((key) => {
    script.setAttribute(key, attr[key]);
  });

  const scriptPromise = new Promise((resolve, reject) => {
    script.onload = () => {
      resolve(script);
    };
    script.onerror = () => {
      scriptCache.delete(src);
      document.body.removeChild(script);
      const error = new Error(`Failed to load script: ${src}`);
      Bugsnag.notify(error);
      reject(error);
    };
  });

  scriptCache.set(src, scriptPromise);
  document.body.appendChild(script);

  return scriptPromise;
};

const useScript = (src, attr = {}) => {
  const [state, setState] = useState({
    loaded: false,
    error: false,
    loading: true,
  });

  useEffect(() => {
    setState({
      loaded: false,
      error: false,
      loading: true,
    });

    loadScript(src, attr)
      .then(() => {
        setState({
          loaded: true,
          error: false,
          loading: false,
        });
      })
      .catch(() => {
        setState({
          loaded: false,
          error: true,
          loading: false,
        });
      });
  }, [src]);

  return state;
};

export default useScript;
