import {getComponentStyle, getTextStyles} from '../../service';

import FormPreviewCollapseButton from './FormPreviewCollapseButton';
import FormPreviewContent from './FormPreviewContent';
import FormPreviewSliderClosed from './FormPreviewSliderClosed';
import {REACT_APP_FORM_URL} from '../../../../utils/constants';
import React from 'react';
import {SubscriptionFormBuilderContext} from '../../context';
import styles from './FormPreview.module.scss';

function FormPreview() {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);

  const formVariantClass = formState.type === 'SLIDER' ? 'slider' : 'default';

  const [isSliderOpen, setSliderOpen] = React.useState(true);

  const getFormWrapperBorderRadius = () => {
    return formState.type === 'SLIDER'
      ? {
          borderTopRightRadius: `${formState.jsonConfig.mainBorder.radius}px`,
          borderTopLeftRadius: `${formState.jsonConfig.mainBorder.radius}px`,
        }
      : {
          borderRadius: `${formState.jsonConfig.mainBorder.radius}px`,
        };
  };

  return (
    <div className={styles.formPreviewWrapper}>
      <link rel="stylesheet" type="text/css" href={`${REACT_APP_FORM_URL}/index.css`} />
      <div
        className={formState.type === 'SLIDER' ? styles.formPreviewPositionedSliderElement : ''}
        style={{height: isSliderOpen ? '100%' : '85vh'}}
      >
        {formState.type === 'SLIDER' && (
          <FormPreviewCollapseButton isSliderOpen={isSliderOpen} setSliderOpen={setSliderOpen} />
        )}
        {formState.type === 'SLIDER' && !isSliderOpen && <FormPreviewSliderClosed />}
        {(formState.type !== 'SLIDER' || isSliderOpen) && (
          <div
            className={`md-embedded-form md-embedded-form--variant-${formVariantClass} ${
              formState.type === 'SLIDER' ? 'md-form--slider-open' : ''
            }`}
            style={{
              ...{
                display: 'block',
                maxWidth: formState.type === 'POPUP' && 600,
                boxShadow: '0px 0px 50px rgba(43, 50, 84, 0.15)',
              },
              ...getFormWrapperBorderRadius(),
            }}
          >
            <div
              className="md-embedded-form--body"
              style={{
                ...getComponentStyle({
                  border: formState.jsonConfig.mainBorder,
                  backgroundColor: formState.jsonConfig.mainBackgroundColor,
                }),
                ...getTextStyles({
                  font: {
                    fontFamily: formState.jsonConfig.mainFontFamily,
                  },
                }),
                marginBottom: 0,
              }}
            >
              {/* {(formState.type === 'SLIDER' || formState.type === 'POPUP') && (
                <img
                  src={`${REACT_APP_FORM_URL}/assets/close-image.png`}
                  className="md-embedded-form--close"
                  alt="Close"
                />
              )} */}

              <FormPreviewContent />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FormPreview;
