import React, {useState} from 'react';
import {getComponentStyle, getTextStyles} from '../../service';
import {
  getExpandedAnimationClass,
  getFooterBackground,
  getMinimizedAnimationClass,
  renderFormSubmitButton,
  renderOneTwoProgressBar,
  renderPercentProgressBar,
} from '../../helper';

import FormField from '../FormPreview/FormField';
import {REACT_APP_FORM_URL} from '../../../../utils/constants';
import Modal from '../../../../components/Modal';
import {SubscriptionFormBuilderContext} from '../../context';
import backgroundImg from '../../../../img/form-preview-background.png';
import clsx from 'clsx';
import logo from '../../../../img/logolight_small.svg';
import styles from './FormPreviewModal.module.scss';
import {useFreePlan} from '../../../free-plan-info/context/FreePlanContext';

const FormPreviewModal = ({isOpen, onRequestClose, formId}) => {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);
  const {state} = useFreePlan();
  const [sliderKey, setSliderKey] = useState('sliderState1');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {jsonConfig, inputFields, type, subscriberCounter} = formState;
  const {header, body, button, footer} = jsonConfig;

  const formVariantClass = type === 'SLIDER' ? 'slider' : 'default';
  const [isSliderOpen, setSliderOpen] = React.useState(false);

  const handleOpenSlider = React.useCallback(() => {
    setSliderKey('sliderState2');
    setTimeout(() => {
      setSliderOpen(true);
    }, 500);
  }, [setSliderOpen]);

  React.useEffect(() => {
    if (!isOpen) {
      setSliderOpen(false);
    }
    return () => {
      setSliderOpen(false);
      setSliderKey('sliderState1');
      setIsSubmitted(false);
    };
  }, [isOpen, setSliderOpen]);

  const getSliderPosition = () => {
    switch (jsonConfig.sliderPopupPosition) {
      case 'BOTTOM_RIGHT':
        return {
          bottom: 0,
          right: '16px',
          borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
        };

      case 'CENTER_RIGHT':
        return {
          bottom: 'unset',
          right: 0,
          borderRadius: `${jsonConfig.mainBorder.radius}px 0 0 ${jsonConfig.mainBorder.radius}px`,
        };

      case 'TOP_RIGHT':
        return {
          bottom: 'unset',
          right: '16px',
          top: 0,
          borderRadius: `0 0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px`,
        };

      case 'TOP_LEFT':
        return {
          bottom: 'unset',
          right: 'unset',
          top: 0,
          left: '16px',
          borderRadius: `0 0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px`,
        };

      case 'TOP_CENTER':
        return {
          bottom: 'unset',
          right: 'unset',
          top: 0,
          borderRadius: `0 0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px`,
        };

      case 'CENTER_LEFT':
        return {
          bottom: 'unset',
          right: 'unset',
          left: 0,
          borderRadius: `0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0`,
        };

      case 'BOTTOM_LEFT':
        return {
          bottom: 0,
          right: 'unset',
          left: '16px',
          borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
        };

      case 'BOTTOM_CENTER':
        return {
          bottom: 0,
          right: 'unset',
          borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
        };
      default:
        return {
          bottom: 0,
          right: '16px',
          borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
        };
    }
  };

  const getInlineStyling = () => {
    return {margin: 0, position: 'absolute', maxWidth: 400, top: 410, right: 125};
  };

  const getGetFormBodyBorderProperties = () => {
    switch (jsonConfig.sliderPopupPosition) {
      case 'BOTTOM_RIGHT':
        return {
          border: `${jsonConfig.mainBorder.size}px solid ${jsonConfig.mainBorder.color}`,
          otherBorder: {property: 'border-bottom', value: 'none'},
          borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
        };

      case 'CENTER_RIGHT':
        return {
          border: `${jsonConfig.mainBorder.size}px solid ${jsonConfig.mainBorder.color}`,
          otherBorder: {property: 'border-right', value: 'none'},
          borderRadius: `${jsonConfig.mainBorder.radius}px 0 0 ${jsonConfig.mainBorder.radius}px`,
        };

      case 'TOP_RIGHT':
        return {
          border: `${jsonConfig.mainBorder.size}px solid ${jsonConfig.mainBorder.color}`,
          otherBorder: {property: 'border-top', value: 'none'},
          borderRadius: `0 0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px`,
        };

      case 'TOP_LEFT':
        return {
          border: `${jsonConfig.mainBorder.size}px solid ${jsonConfig.mainBorder.color}`,
          otherBorder: {property: 'border-top', value: 'none'},
          borderRadius: `0 0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px`,
        };

      case 'TOP_CENTER':
        return {
          border: `${jsonConfig.mainBorder.size}px solid ${jsonConfig.mainBorder.color}`,
          otherBorder: {property: 'border-top', value: 'none'},
          borderRadius: `0 0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px`,
        };

      case 'CENTER_LEFT':
        return {
          border: `${jsonConfig.mainBorder.size}px solid ${jsonConfig.mainBorder.color}`,
          otherBorder: {property: 'border-left', value: 'none'},
          borderRadius: `0 ${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0`,
        };

      case 'BOTTOM_LEFT':
        return {
          border: `${jsonConfig.mainBorder.size}px solid ${jsonConfig.mainBorder.color}`,
          otherBorder: {property: 'border-bottom', value: 'none'},
          borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
        };

      case 'BOTTOM_CENTER':
        return {
          border: `${jsonConfig.mainBorder.size}px solid ${jsonConfig.mainBorder.color}`,
          otherBorder: {property: 'border-bottom', value: 'none'},
          borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
        };
      default:
        return {
          border: `${jsonConfig.mainBorder.size}px solid ${jsonConfig.mainBorder.color}`,
          otherBorder: {property: 'border-bottom', value: 'none'},
          borderRadius: `${jsonConfig.mainBorder.radius}px ${jsonConfig.mainBorder.radius}px 0 0`,
        };
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      size="full"
      hideCloseButton={jsonConfig.sliderPopupPosition === 'TOP_RIGHT'}
    >
      <div
        className={styles.container}
        style={{
          background: type === 'POPUP' && 'rgba(0, 0, 0, 0.5)',
          backgroundImage: `url(${backgroundImg})`,
          backgroundPosition: 'center',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundBlendMode: 'darken',
        }}
      >
        <link rel="stylesheet" type="text/css" href={`${REACT_APP_FORM_URL}/index.css`} />
        <div />

        <div
          className={clsx(
            'md-embedded-form',
            `md-embedded-form--variant-${formVariantClass}`,
            'md-form--ready',
            isSliderOpen ? 'md-form--slider-open' : ''
          )}
          style={
            type === 'SLIDER'
              ? {...getSliderPosition(), margin: 0}
              : type === 'INLINE'
              ? getInlineStyling()
              : {margin: 0, maxWidth: type === 'POPUP' && 600}
          }
        >
          {type === 'SLIDER' && !isSliderOpen && (
            <div
              ref={(node) => {
                if (node) {
                  const borderProperties = getGetFormBodyBorderProperties();
                  node.style.setProperty('border', borderProperties?.border, 'important');
                  node.style.setProperty(
                    'border-radius',
                    borderProperties.borderRadius,
                    'important'
                  );
                  borderProperties?.otherBorder &&
                    node.style.setProperty(
                      borderProperties.otherBorder.property,
                      borderProperties.otherBorder.value,
                      'important'
                    );
                }
              }}
              className={clsx(
                'md-embedded-form--slider-body',
                getMinimizedAnimationClass(jsonConfig, styles, sliderKey)
              )}
              style={getComponentStyle({
                // border: jsonConfig.mainBorder,
                backgroundColor: jsonConfig.mainBackgroundColor,
              })}
              onClick={handleOpenSlider}
              id="sliderForm"
              key={sliderKey}
            >
              <img
                src={`${REACT_APP_FORM_URL}/assets/close-image.png`}
                className="md-embedded-form--close"
                alt="Close"
                onClick={onRequestClose}
              />
              <p className="md-embedded-form--text" style={getTextStyles(body.text)}>
                {body.text.value}
              </p>
            </div>
          )}
          <div
            ref={(node) => {
              if (node && type === 'SLIDER') {
                const borderProperties = getGetFormBodyBorderProperties();
                node.style.setProperty('border', borderProperties?.border, 'important');
                node.style.setProperty('border-radius', borderProperties.borderRadius, 'important');
                borderProperties?.otherBorder &&
                  node.style.setProperty(
                    borderProperties.otherBorder.property,
                    borderProperties.otherBorder.value,
                    'important'
                  );
              }
            }}
            className={clsx(
              'md-embedded-form--body',
              type === 'POPUP' && styles.popupForm,
              sliderKey == 'sliderState2' && getExpandedAnimationClass(jsonConfig, styles)
            )}
            style={{
              ...getComponentStyle({
                border: type === 'POPUP' && formState.jsonConfig.mainBorder,
                backgroundColor: formState.jsonConfig.mainBackgroundColor,
              }),
              ...getTextStyles({
                font: {
                  fontFamily: formState.jsonConfig.mainFontFamily,
                },
              }),
              marginBottom: 0,
            }}
          >
            {(type === 'SLIDER' || type === 'POPUP') && (
              <img
                src={`${REACT_APP_FORM_URL}/assets/close-image.png`}
                className="md-embedded-form--close"
                alt="Close"
                style={{cursor: 'pointer'}}
                onClick={onRequestClose}
              />
            )}
            {body.progressBarType === '50%' && renderPercentProgressBar(body, styles)}
            {body.progressBarType === '1/2' && renderOneTwoProgressBar(body, styles)}
            {isSubmitted ? (
              <>
                <h1 className="md-embedded-form--title" style={getTextStyles(header.text)}>
                  {header.text.value}
                </h1>
                <p className="md-embedded-form--text" style={getTextStyles(body.text)}>
                  Thanks for your subscription!
                </p>
                <p className="md-embedded-form--msg md-embedded-form--msg-success">
                  Thank you! To confirm your registration, please check your inbox and click the
                  link provided.
                </p>
              </>
            ) : (
              <>
                {' '}
                <h1 className="md-embedded-form--title" style={getTextStyles(header.text)}>
                  {header.text.value}
                </h1>
                <p className="md-embedded-form--text" style={getTextStyles(body.text)}>
                  {body.text.value}
                </p>
                <form
                  className="md-embedded-form--form"
                  data-md-form={formId}
                  style={{marginBottom: state.freePlan ? 80 : 0}}
                >
                  {inputFields
                    .filter(({name}) => name)
                    .map((field) => (
                      <FormField key={field.name} field={field} previewMode />
                    ))}
                  {subscriberCounter.enabled && type === 'POPUP' && (
                    <p
                      className={clsx(
                        'md-embedded-form--subscriber-counter--text',
                        styles.subscriberCount
                      )}
                      style={getTextStyles(body.subscriberCounterStyle.text)}
                    >
                      {`${subscriberCounter?.offset || '0'} ${
                        body.subscriberCounterStyle.text.value
                      }`}
                    </p>
                  )}
                  {renderFormSubmitButton(button, formState)}
                  {subscriberCounter.enabled && type !== 'POPUP' && (
                    <p
                      className={clsx(
                        'md-embedded-form--subscriber-counter--text',
                        styles.subscriberCount
                      )}
                      style={getTextStyles(body.subscriberCounterStyle.text)}
                    >
                      {`${subscriberCounter?.offset || '0'} ${
                        body.subscriberCounterStyle.text.value
                      }`}
                    </p>
                  )}
                  <p className="md-embedded-form--footer--text" style={getTextStyles(footer.text)}>
                    {footer.text.value}
                  </p>
                </form>
              </>
            )}
            {state.freePlan && (
              <div className={clsx(styles.brandingFrame)} style={getFooterBackground(formState)}>
                <div className={styles.brandingName}>POWERED BY</div>
                <img width="126" src={logo} className={styles.logo} alt="Maildroppa Logo" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormPreviewModal;
