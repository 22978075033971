const endpoint = '/internal/user-profile';
const changeEmailEndpoint = '/change-account-email';

export const ENDPOINTS = {
  fetch: endpoint,
  update: endpoint,
  cancel: '/cancel',
  apiKey: `${endpoint}/api-key`,
  confirmEmail: (confirmationId) => `/confirm-user-profile-email/${confirmationId}`,
  findPendingProfileEmail: changeEmailEndpoint,
  initProfileEmailChange: (email) => `${endpoint}/${email}`,
};
