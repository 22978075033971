import {useState, useCallback} from 'react';
import {TEMPLATE_CATEGORY, TEMPLATE_TYPE} from '../../domain/newsletters/templates/constants';
import useFetch from '../../hooks/useFetch';
import {saveAsTemplateService, updateTemplateService} from '../../domain/newsletters/service';

const useSaveAsTemplateModal = ({
  html,
  css,
  templateId,
  defaultTemplateName,
  defaultTemplateCategory,
  onClose,
  onAfterRequest,
  onNameUpdate,
}) => {
  const {run: saveAsTemplateRequest, loading: loadingSaveTemplate} = useFetch(
    saveAsTemplateService,
    {throwError: true}
  );

  const {
    run: updateTemplateRequest,
    loading: loadingUpdateTemplate,
  } = useFetch(updateTemplateService, {throwError: true});
  const [templateName, setTemplateName] = useState(defaultTemplateName ?? '');
  const [templateCategory, setTemplateCategory] = useState(
    defaultTemplateCategory ?? TEMPLATE_CATEGORY.BULK_MAIL
  );

  const handleChangeTemplateName = useCallback(
    (e) => {
      setTemplateName(e.target.value);
      onNameUpdate?.(e.target.value);
    },
    [onNameUpdate]
  );
  const handleChangeTemplateCategory = useCallback((e) => {
    setTemplateCategory(e);
  }, []);

  const handleOnRequestClose = useCallback(() => {
    setTemplateName('');
    setTemplateCategory(defaultTemplateCategory ?? TEMPLATE_CATEGORY.BULK_MAIL);
    onClose?.();
  }, [defaultTemplateCategory, onClose]);

  const handleSaveTemplate = useCallback(async () => {
    await saveAsTemplateRequest({
      name: templateName,
      htmlContent: html,
      cssContent: css,
      type: TEMPLATE_TYPE.CUSTOM,
      category: templateCategory,
    });

    onAfterRequest?.();
  }, [css, html, onAfterRequest, saveAsTemplateRequest, templateCategory, templateName]);

  const handleUpdateTemplate = useCallback(async () => {
    await updateTemplateRequest({
      id: templateId,
      name: templateName,
      htmlContent: html,
      cssContent: css,
      type: TEMPLATE_TYPE.CUSTOM,
      category: templateCategory,
    });
    onAfterRequest?.();
  }, [
    css,
    html,
    onAfterRequest,
    templateCategory,
    templateId,
    templateName,
    updateTemplateRequest,
  ]);

  return {
    templateName,
    templateCategory,
    handleChangeTemplateName,
    handleChangeTemplateCategory,
    handleOnRequestClose,
    handleSaveTemplate,
    handleUpdateTemplate,
    loading: loadingSaveTemplate || loadingUpdateTemplate,
  };
};

export default useSaveAsTemplateModal;
