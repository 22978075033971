import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './Checkbox.module.scss';

function CheckBox({
  label,
  className,
  onChange,
  error,
  inputRef,
  size,
  id,
  color,
  middle,
  bottomSpace,
  info,
  containerStyle,
  value,
  ...props
}) {
  return (
    <div>
      <div
        className={cx(
          styles.checkbox,
          color && styles[color],
          styles[size],
          {
            [styles.bottomSpace]: bottomSpace,
            [styles.middle]: middle,
          },
          className
        )}
        style={containerStyle}
      >
        <div className={styles.elWrapper}>
          <input
            ref={inputRef}
            id={id}
            type="checkbox"
            checked={value}
            className={styles.el}
            onChange={(e) => {
              e.stopPropagation();
              onChange?.(e);
            }}
            value={value}
            {...props}
          />
        </div>
        {label && (
          <label htmlFor={id} className={styles.content}>
            <span
              className={styles.text}
              onClick={(e) => {
                e.stopPropagation();

                onChange?.(e);
              }}
            >
              {label}
            </span>
            {error && <small className={styles.error}>{error.message}</small>}
          </label>
        )}
      </div>
      {info && (
        <div htmlFor={id} className={styles.info}>
          <span className={styles.text}>{info}</span>
        </div>
      )}
    </div>
  );
}

CheckBox.defaultProps = {
  bottomSpace: false,
  size: 'default',
  label: '',
  info: '',
};

CheckBox.propTypes = {
  onChange: PropTypes.func,
  bottomSpace: PropTypes.bool,
  middle: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['grey', 'default']),
  size: PropTypes.oneOf(['big', 'default']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  info: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]),
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool]),
};

export default CheckBox;
