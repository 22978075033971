export const initialState = {
  freePlan: true,
  planLimitReached: false,
  profileEmailVerified: false,
  postalAddressCompleted: false,
  billingAddressCompleted: false,
};

export function setFreePlanInfoReducer(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}
