import PropTypes from 'prop-types';
import img from '../../../img/thinking-man.svg';
import ModalClosableContent from '../../../components/Modal/ModalClosableContent';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import {LANDING_URL} from '../../../utils/constants';

function AuditNewsletterSendModal({isOpen, onRequestClose}) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalClosableContent
        title="Your account is currently under review"
        onRequestClose={onRequestClose}
        img={<img src={img} alt="thinking man" height="210" loading="lazy" />}
        hideCloseButton
      >
        <p className="text-centered">
          We can&apos;t send out this newsletter at the moment, because your account is currently
          under review. As we mention in our{' '}
          <a
            href={`${LANDING_URL}/terms`}
            title="Click here for more details"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>{' '}
          this is one of many measures that help us to ensure the high deliverability of our
          service. We will complete the review as soon as possible and inform you by{' '}
          <strong>email</strong> when the review is successfully completed. Until then, we
          appreciate your patience. Thank you!
        </p>
        <div className="text-centered">
          <Button variant="filled" color="green" widePadding onClick={onRequestClose}>
            OK
          </Button>
        </div>
      </ModalClosableContent>
    </Modal>
  );
}

AuditNewsletterSendModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
};

AuditNewsletterSendModal.defaultProps = {
  isOpen: true,
};

export default AuditNewsletterSendModal;
