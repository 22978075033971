import PropTypes from 'prop-types';
import styles from './FormSubmit.module.scss';

function FormSubmit({children}) {
  return <div className={styles.wrapper}>{children}</div>;
}

FormSubmit.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormSubmit;
