import {getComponentStyle, getTextStyles} from '../../service';
import {
  getFooterBackground,
  renderFormSubmitButton,
  renderOneTwoProgressBar,
  renderPercentProgressBar,
} from '../../helper';

import FormField from '../FormPreview/FormField';
import {REACT_APP_FORM_URL} from '../../../../utils/constants';
import Modal from '../../../../components/Modal';
import React from 'react';
import {SubscriptionFormBuilderContext} from '../../context';
import clsx from 'clsx';
import logo from '../../../../img/logolight_small.svg';
import styles from './FormPreviewModal.module.scss';
import {useFreePlan} from '../../../free-plan-info/context/FreePlanContext';

const InlineFormPreviewModal = ({isOpen, onRequestClose, formId}) => {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);
  const {state} = useFreePlan();

  const {jsonConfig, inputFields, subscriberCounter} = formState;
  const {header, body, button, footer} = jsonConfig;

  const InlineForm = () => {
    return (
      <div className={styles.container}>
        <link rel="stylesheet" type="text/css" href={`${REACT_APP_FORM_URL}/index.css`} />
        <div />

        <div
          className={clsx(
            'md-embedded-form',
            `md-embedded-form--variant-default`,
            'md-form--ready'
          )}
        >
          <div
            className={clsx('md-embedded-form--body')}
            style={{
              ...getComponentStyle({
                border: formState.jsonConfig.mainBorder,
                backgroundColor: formState.jsonConfig.mainBackgroundColor,
              }),
              ...getTextStyles({
                font: {
                  fontFamily: formState.jsonConfig.mainFontFamily,
                },
              }),
              marginBottom: 0,
            }}
          >
            {body.progressBarType === '50%' && renderPercentProgressBar(body, styles)}
            {body.progressBarType === '1/2' && renderOneTwoProgressBar(body, styles)}
            <>
              {' '}
              <h1 className="md-embedded-form--title" style={getTextStyles(header.text)}>
                {header.text.value}
              </h1>
              <p className="md-embedded-form--text" style={getTextStyles(body.text)}>
                {body.text.value}
              </p>
              <form
                className="md-embedded-form--form"
                data-md-form={formId}
                style={{marginBottom: state.freePlan ? 80 : 0}}
              >
                {inputFields
                  .filter(({name}) => name)
                  .map((field) => (
                    <FormField key={field.name} field={field} previewMode />
                  ))}
                {renderFormSubmitButton(button, formState)}
                {subscriberCounter.enabled && (
                  <p
                    className={clsx(
                      'md-embedded-form--subscriber-counter--text',
                      styles.subscriberCount
                    )}
                    style={getTextStyles(body.subscriberCounterStyle.text)}
                  >
                    {`${subscriberCounter?.offset || '0'} ${
                      body.subscriberCounterStyle.text.value
                    }`}
                  </p>
                )}
                <p className="md-embedded-form--footer--text" style={getTextStyles(footer.text)}>
                  {footer.text.value}
                </p>
              </form>
            </>
            {state.freePlan && (
              <div className={clsx(styles.brandingFrame)} style={getFooterBackground(formState)}>
                <div className={styles.brandingName}>POWERED BY</div>
                <img width="126" src={logo} className={styles.logo} alt="Maildroppa Logo" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} size="full">
      <div style={{height: '100vh', overflow: 'scroll', display: 'flex', flexDirection: 'column'}}>
        <div
          style={{
            width: '80vw',
            height: 100,
            backgroundColor: '#F1F1F1',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div style={{marginTop: 65, marginRight: 30}}>
            <div
              style={{
                backgroundColor: '#F1F1F1',
                width: '55vw',
                height: '40%',
                marginBottom: 65,
              }}
            />
            <div
              style={{
                backgroundColor: '#F1F1F1',
                width: '55vw',
                height: '30%',
                marginBottom: 45,
              }}
            />
            <div style={{backgroundColor: '#F1F1F1', width: '55vw', height: '30%'}} />
          </div>
          <div>
            <InlineForm />
            <div style={{backgroundColor: '#F1F1F1', width: '100%', height: '30%'}} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InlineFormPreviewModal;
