import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import useFetch from '../../../hooks/useFetch';
import {resendEmailConfirmationService} from '../service';
import styles from './ResendEmailButton.module.scss';

function ResendEmailButton({onResend}) {
  const {run: resendEmailConfirmationRequest} = useFetch(resendEmailConfirmationService);

  async function handleResend() {
    await resendEmailConfirmationRequest();
    onResend();
  }

  return (
    <Button
      type="submit"
      variant="link"
      color="blue"
      onClick={handleResend}
      className={styles.buttonWrapper}
    >
      click here
    </Button>
  );
}

ResendEmailButton.propTypes = {
  onResend: PropTypes.func.isRequired,
};

export default ResendEmailButton;
