import {forwardRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactSelectCreatable from 'react-select/creatable';
import styles from './TokenizedInput.module.scss';
import FieldError from '../FieldError';
import Asterisk from '../../Asterisk';
import Button from '../../Button';

const noop = () => null;

const TokenizedInput = forwardRef(
  (
    {
      label,
      helpText,
      disabled,
      required,
      error,
      bottomSpace,
      errorAbsolute,
      hideError,
      noBottomSpace,
      inputClassName,
      value,
      onChange,
      dataType,
      ...props
    },
    ref
  ) => {
    const [values, setValues] = useState(value);
    const constrainsEndRef = useRef(null);

    const scrollToBottom = () => {
      constrainsEndRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
      setValues(value ? value : [{value: '', label: ''}]);
    }, [value]);

    const handleUpdatingValue = (e, index) => {
      const newValues = [...values];
      newValues[index] = {label: e.target.value, value: e.target.value};
      onChange(newValues);
    };

    useEffect(() => {
      scrollToBottom();
    }, [values?.length]);

    return (
      <div
        className={clsx(styles.input, {
          [styles.disabled]: disabled,
          [styles.elErr]: error,
          [styles.bottomSpace]: !noBottomSpace,
        })}
      >
        <label className={styles.label}>
          {label && (
            <span className={styles.labelText}>
              {label}
              {required && <Asterisk />}
            </span>
          )}

          {/* <ReactSelectCreatable
            isMulti
            noOptionsMessage={noop}
            components={{
              DropdownIndicator: noop,
              IndicatorSeparator: noop,
            }}
            ref={ref}
            classNamePrefix="tokenizedInput"
            className={clsx(styles.tokenizedInput, inputClassName)}
            {...props}
          /> */}
          <div className={styles.optionValuesContainer}>
            {values &&
              values.map((v, index) => (
                <input
                  key={index}
                  className={[styles.main, props.classes].join(' ')}
                  value={v.value}
                  onChange={(e) => handleUpdatingValue(e, index)}
                  type="text"
                />
              ))}
            <div ref={constrainsEndRef} />
          </div>
          <Button
            color="green"
            noPadding
            onClick={() => {
              setValues([...values, {label: '', value: ''}]);
            }}
          >
            <span style={{fontSize: 28, marginRight: 10, fontWeight: 400}}>+</span> Add Value
          </Button>
        </label>
        {error && !hideError && <FieldError error={error} absolute />}
        {helpText && <span className={styles.helpText}>{helpText}</span>}
      </div>
    );
  }
);

TokenizedInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.string, label: PropTypes.string})),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  bottomSpace: PropTypes.bool,
  errorAbsolute: PropTypes.bool,
  hideError: PropTypes.bool,
  noBottomSpace: PropTypes.bool,
  label: PropTypes.string,
  helpText: PropTypes.string,
  inputClassName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool]),
};

export default TokenizedInput;
