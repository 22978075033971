import {useState} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import Input from '../../components/forms/Input';
import Button from '../../components/Button';
import FormButtons from '../../components/forms/FormButtons';
import StatusMessage from '../../components/StatusMessage/StatusMessage';
import {REQUIRED_FIELD_MESSAGE} from '../../components/forms/constants';
import {handleError} from '../../utils/handleError';
import CheckBox from '../../components/forms/Checkbox';
import Select from '../../components/forms/NativeSelect';
import {subscriberStatuses} from '../../utils/constants';

function ActionForm({onRequestClose, edit, onSubmit, defaultValues}) {
  const [errorMessage, setErrorMessage] = useState('');

  const {register, formState, handleSubmit, setError, errors} = useForm({
    mode: 'onChange',
    defaultValues,
  });

  async function handleFormSubmit(values) {
    setErrorMessage('');
    try {
      const formValues = omit(values, ['permissions']);
      await onSubmit(formValues);
      onRequestClose();
    } catch (error) {
      handleError(error, setErrorMessage, setError);
    }
  }

  return (
    <>
      <StatusMessage message={errorMessage} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {edit && <input type="hidden" name="id" ref={register} />}
        {!edit && <input type="hidden" name="subscriberStatus" ref={register} value="CONFIRMED" />}
        <Input
          type="email"
          name="email"
          label="Email"
          placeholder="your@email.com"
          required
          error={errors.email}
          inputRef={register({required: REQUIRED_FIELD_MESSAGE})}
        />
        <Input
          name="firstName"
          label="First Name"
          placeholder="Your first name"
          error={errors.firstName}
          inputRef={register}
        />
        {edit && (
          <Select
            name="subscriberStatus"
            label="Status"
            defaultValue="CONFIRMED"
            options={subscriberStatuses}
            full
            required
            error={errors.subscriberStatus}
            inputRef={register({required: REQUIRED_FIELD_MESSAGE})}
          />
        )}
        {!edit && (
          <>
            <p>Do you have permission to add this email to your subscriber list?</p>
            <CheckBox
              id="permissions"
              name="permissions"
              size="big"
              bottomSpace
              error={errors.permissions}
              inputRef={register({required: 'Please confirm that you have permission.'})}
              label="Yes, I have permission from the email user."
            />
          </>
        )}
        <FormButtons>
          <Button
            type="submit"
            variant="filled"
            color="green"
            size="big"
            loading={formState.isSubmitting}
            disabled={formState.isSubmitting}
            full
          >
            {edit ? 'Change' : 'Add'}
          </Button>
          <Button onClick={onRequestClose} variant="outlined" color="grey" full size="big">
            Cancel
          </Button>
        </FormButtons>
      </form>
    </>
  );
}

ActionForm.defaultProps = {
  edit: false,
};

ActionForm.propTypes = {
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.objectOf(PropTypes.string),
  edit: PropTypes.bool,
};

export default ActionForm;
