import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from '../ImportSubscribers.module.scss';

function StepSummary({children, title, highlighted}) {
  return (
    <div className={styles.stepSummary}>
      {title && (
        <span className={cx(styles.stepSummaryTitle, {[styles.highlighted]: highlighted})}>
          {title}
        </span>
      )}
      {children}
    </div>
  );
}

StepSummary.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  highlighted: PropTypes.bool,
};

export default StepSummary;
