import {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useBoardingWizard} from '../context/WizardContext';
import {Panel, PanelContent, PanelHeader} from '../components/Panel';
import useAddNewCard from '../../../hooks/useAddNewCard';
import useFetch from '../../../hooks/useFetch';
import {
  createSubscriptionService,
  fetchChangeOrderPreviewService,
} from '../../settings/subscription/service';
import {handleError} from '../../../utils/handleError';
import Checkout from '../../../components/payments/checkout/Checkout';
import styles from '../components/boarding-wizard/BoardingWizard.module.scss';
import Card from '../../../components/Card';
import {STEPS} from '../constants';
import Spinner from '../../../components/Spinner';
import useRouter from '../../../hooks/useRouter';
import ROUTES from '../../../Router/routes';

function CheckoutContainer() {
  const [orderPreview, setOrderPreview] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const {history} = useRouter();
  const {state} = useBoardingWizard();
  const {addNewCardRequest} = useAddNewCard({
    hideAlert: true,
  });

  const selectedPlanId = state.steps[STEPS.plans].data.id;

  const {run: fetchChangeOrderPreviewRequest, loading} = useFetch(fetchChangeOrderPreviewService, {
    throwError: true,
  });

  useEffect(
    function () {
      async function fetchData() {
        try {
          const response = await fetchChangeOrderPreviewRequest(selectedPlanId);
          setOrderPreview(response);
        } catch (error) {
          handleError(error, setErrorMessage);
        }
      }

      fetchData();
    },
    [fetchChangeOrderPreviewRequest]
  );

  const {run: createSubscriptionRequest} = useFetch(createSubscriptionService, {throwError: true});
  const billingDetails = state.steps[STEPS.billing].data;

  async function handleSubmit() {
    setErrorMessage('');
    try {
      await addNewCardRequest();
      await createSubscriptionRequest({
        subscriptionPlanId: orderPreview.recurringCharge.product.id,
        enrollmentTimestamp: orderPreview.enrollmentTimestamp,
      });

      history.push(ROUTES.boarding.success);
    } catch (error) {
      handleError(error, setErrorMessage);
    }
  }

  function renderCheckout() {
    if (!billingDetails || !orderPreview) {
      return <Spinner />;
    }

    return (
      <Checkout
        onSubmit={handleSubmit}
        orderPreview={orderPreview}
        billingDetails={billingDetails}
        previewLoading={loading}
        wizard
        chargeNotice={
          <>
            Zero risk, 100% transparency, and fairness guaranteed. <br />
            We&apos;re happy if you&apos;re happy!
          </>
        }
      />
    );
  }

  return (
    <>
      <Helmet title="Check Out" />
      <Panel>
        <PanelHeader title="Check Out" subtitle="Please review your details below." />
        <PanelContent errorMessage={errorMessage} loading={!orderPreview || loading}>
          <Card noMargin noBorder>
            {orderPreview?.billingCycle.remainingTrialDays > 0 ? (
              <h3 className={styles.importantText}>
                Your Trial is FREE for {orderPreview.billingCycle.remainingTrialDays} Days
              </h3>
            ) : null}
            {renderCheckout()}
          </Card>
        </PanelContent>
      </Panel>
    </>
  );
}

export default CheckoutContainer;
