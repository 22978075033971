import {useEffect, useMemo, useState} from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import {Controller, useForm} from 'react-hook-form';
import {Link, useLocation} from 'react-router-dom';
import moment from 'moment';
import StripoEditor from 'components/StripoEditor';

import Card from '../../../components/Card';
import Button from '../../../components/Button';
import BackButton from '../../../components/BackButton';
import ROUTES from '../../../Router/routes';
import Modal from '../../../components/Modal';
import Select from '../../../components/forms/Select';
import SendTestEmailContainer from '../send-test-email/SendTestEmailContainer';
import Tooltip from '../../../components/Tooltip';
import {REQUIRED_FIELD_MESSAGE} from '../../../components/forms/constants';
import Spinner from '../../../components/Spinner';
import Icon from '../../../components/Icon';
import {domainVerificationStatusPropType} from '../../../utils/prop-types';
import SubscribersLimitAlert from '../../free-plan-info/subscribers-limit-alert/SubscribersLimitAlert';
import {useFreePlan} from '../../free-plan-info/context/FreePlanContext';
import AuditNewsletterSendModal from '../../audit-status/newsletter-send-modal/AuditNewsletterSendModal';
import {SEND_TO_GROUP__FILTER, SEND_TO_GROUP__SELECT, SEND_TO_SELECT__DEFAULT} from '../constants';
import SegmentForm from '../../settings/segments/components/SegmentForm';
import Emoji from '../../../components/Emoji';
import readyForSendingIco from '../../../img/ico-ready-for-sending.svg';
import dkimSignedIco from '../../../img/ico-verified.svg';
import useSegmentsFetcher from '../../settings/segments/hooks/useSegmentsFetcher';
import {selectMapper} from '../../../utils/arrays';
import useSubscriberFieldsFetcher from '../../settings/subscriber-fields/hooks/useSubscriberFieldsFetcher';
import ScheduleNewsletter from '../ScheduleNewsletter';

import styles from './Newsletter.module.scss';
import {useNewsletter} from '../context';
import emptyEmailTemplate from '../../../components/StripoEditor/templates/empty';
import useRouter from '../../../hooks/useRouter';

// TODO: check the commented lines and if they are not needed then delete them
function Newsletter({
  onSubmit,
  id,
  data,
  draftProcessing,
  sendProcessing,
  confirmedSubscribers,
  testModalOpen,
  closeTestModal,
  senderOptions,
  domainStatus,
  isFreePlanModalOpen,
  FreePlanModal,
  isAuditModalOpen,
  toggleAuditModalOpen,
  isScheduleModalOpen,
  setScheduleModalOpen,
}) {
  const {editorIsInitialized, initEditor} = useNewsletter();
  const location = useLocation();
  const {history} = useRouter();

  const template = useMemo(() => {
    if (location?.state?.template) {
      return {
        html: location?.state?.template.htmlContent,
        css: location?.state?.template.cssContent,
      };
    }

    if (data?.cssContent || data?.htmlContent) {
      return {html: data?.htmlContent ?? '', css: data?.cssContent ?? ''};
    }

    return emptyEmailTemplate;
  }, [data?.cssContent, data?.htmlContent, location?.state?.template]);

  const [sendToGroup, setSendToGroup] = useState(SEND_TO_GROUP__SELECT);
  const [scheduleTime, setScheduleTime] = useState(data?.scheduleTimeDto);

  const {register, control, setValue, setError, handleSubmit, errors, getValues} = useForm({
    defaultValues: {
      id,
      sendToGroup: SEND_TO_GROUP__SELECT,
      sendToSelect: SEND_TO_SELECT__DEFAULT,
      filterGroups: {},
      subject: data?.subject,
    },
  });
  const {state} = useFreePlan();
  const {data: dataSegments} = useSegmentsFetcher({immediate: true});
  const {dataTags} = useSubscriberFieldsFetcher({immediate: true});

  const {planLimitReached} = state;

  useEffect(() => {
    setValue('sendToGroup', sendToGroup, {shouldValidate: false});
  }, [sendToGroup, setValue]);

  const setFilterGroups = (newFilterGroups) => {
    setValue('filterGroups', newFilterGroups, {shouldValidate: false});
  };

  async function handleSaveDraft(values) {
    return onSubmit(values, 'save-draft', setError);
  }

  async function handleSubmitSend(values) {
    return onSubmit(
      scheduleTime ? {...values, scheduleTime: scheduleTime.transmissionTimestamp} : values,
      'send',
      setError
    );
  }

  async function handleTestSend(values) {
    return onSubmit(values, 'send-test', setError);
  }

  if (!senderOptions || !domainStatus || confirmedSubscribers === undefined) {
    return <Spinner height={400} />;
  }

  const getSendToSelectOptions = () => {
    const res = [{value: 'default', label: `${confirmedSubscribers} confirmed subscribers`}];
    if (dataSegments?.length) {
      res.push({
        label: 'Segments',
        options: dataSegments.map(selectMapper('id', 'name')),
      });
    }
    if (dataTags?.length) {
      res.push({
        label: 'Tags',
        options: dataTags.map(selectMapper('id', 'name')),
      });
    }
    return res;
  };

  const isDomainVerified = domainStatus === 'VERIFIED';

  const getSendButtonText = () => {
    if (sendProcessing) return scheduleTime ? 'Processing' : 'Sending';
    if (scheduleTime) {
      return `Schedule Sending for ${moment(scheduleTime.transmissionTimestamp).format('DD/MM')}`;
    }
    return 'Send Now';
  };

  const handleScheduleSelect = (dateTimeDto) => {
    setScheduleTime(dateTimeDto);
    setScheduleModalOpen(false);
    setValue('scheduleTime', dateTimeDto?.transmissionTimestamp || '', {shouldValidate: false});
  };

  const handleInitializationOfEditor = () => {
    initEditor();
  };

  const handleBrowseTemplates = () => {
    return history.push({
      pathname: ROUTES.newsletters.templateSelect,
      state: {
        isDirty: true,
        back: data?.id ? `${ROUTES.newsletters.edit}/${data?.id}` : ROUTES.newsletters.create,
      },
    });
  };

  return (
    <>
      {planLimitReached && <SubscribersLimitAlert />}
      {isFreePlanModalOpen && <FreePlanModal />}
      {isAuditModalOpen && <AuditNewsletterSendModal onRequestClose={toggleAuditModalOpen} />}
      <div>
        <BackButton noMargin to={ROUTES.newsletters.index}>
          Newsletter Campaigns
        </BackButton>
        <Card noPadding noBorder noBg overflowVisible backgroundColor="transparent">
          <div className={cx(styles.nav, {[styles.fixed]: false})}>
            <Button
              onClick={handleSubmit(handleSubmitSend)}
              variant="filled"
              color="green"
              disabled={!editorIsInitialized}
            >
              <>
                <Icon name="send" buttonIcon size="20px" />
                {getSendButtonText()}
              </>
            </Button>
            <Button
              onClick={() => setScheduleModalOpen(true)}
              variant="outlined"
              color="green"
              disabled={!editorIsInitialized}
            >
              Schedule
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="green"
              // disabled={sendTestProcessing || planLimitReached}
              onClick={handleSubmit(handleTestSend)}
              disabled={!editorIsInitialized}
            >
              Send Test Email
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="green"
              // disabled={draftProcessing}
              onClick={handleSubmit(handleSaveDraft)}
              disabled={!editorIsInitialized}
            >
              {draftProcessing ? 'Saving...' : 'Save'}
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="green"
              onClick={handleBrowseTemplates}
              disabled={!editorIsInitialized}
            >
              Browse Templates
            </Button>
          </div>
        </Card>
        <Card>
          {id && <input type="hidden" name="id" value={id} ref={register} />}
          <div className={cx(styles.row, styles.noBg, styles.rowSendToGroup)}>
            <input type="hidden" {...register('sendToGroup')} />
            <span className={styles.rowTitle}>to:</span>
            {sendToGroup === SEND_TO_GROUP__SELECT && (
              <div className={styles.rowSendToGroupVariants}>
                <div className={styles.sendToGroupSelectWrapper}>
                  <Controller
                    name="sendToSelect"
                    error={errors.sendToSelect}
                    control={control}
                    rules={{required: REQUIRED_FIELD_MESSAGE}}
                    as={Select}
                    options={getSendToSelectOptions()}
                    noBottomSpace
                    required
                    selectInputClassName={styles.sendToGroupSelect}
                    disabled={sendToGroup !== SEND_TO_GROUP__SELECT}
                  />
                </div>
                {/* <div className={styles.sendToGroupSelectWrapper}> */}
                {/*  {confirmedSubscribers} confirmed subscribers */}
                {/* </div> */}

                <button
                  type="button"
                  onClick={() => setSendToGroup(SEND_TO_GROUP__FILTER)}
                  className={styles.sendToGroupFilterButton}
                >
                  <Icon name="filter" className={styles.sendToGroupFilterButtonIcon} />
                  Filter
                </button>
              </div>
            )}
          </div>
          <div
            className={styles.sendToGroupCustomFiltersWrapper}
            hidden={sendToGroup !== SEND_TO_GROUP__FILTER}
          >
            <Icon
              name="close-2"
              className={styles.sendToGroupCustomFiltersClose}
              onClick={() => setSendToGroup(SEND_TO_GROUP__SELECT)}
            />
            <SegmentForm {...{filterGroups: [], setFilterGroups}} />
            <input type="hidden" {...register('filterGroups')} />
          </div>
          <div className={styles.row}>
            <span className={styles.rowTitle}>Sender-Name:</span>
            <span>{senderOptions.fromName}</span>
          </div>
          <div className={styles.row}>
            <span className={styles.rowTitle}>Sender-Email:</span>
            <span>{isDomainVerified ? senderOptions.fromEmail : 'noreply@mail.mydroppa.com'}</span>
            <span className={styles.icon}>
              <Tooltip content="You are ready to send emails">
                <img src={readyForSendingIco} width="30" height="30" alt="Ready for sending icon" />
              </Tooltip>
            </span>
            {isDomainVerified && (
              <span className={styles.icon}>
                <Tooltip content="You are ready to send DKIM signed emails">
                  <img src={dkimSignedIco} width="35" height="35" alt="DKIM verified icon" />
                </Tooltip>
              </span>
            )}
          </div>
          <p
            className="text text-small m-0"
            style={{textAlign: 'left', marginBottom: 10, paddingLeft: 160}}
          >
            To change the Sender-Name or Sender-Email click{' '}
            <Link to={ROUTES.settings.emailSender}>here</Link>
          </p>
          {!isDomainVerified && (
            <div className={styles.row}>
              <span className={styles.rowTitle}>Reply-To-Email:</span>
              <span>{senderOptions.fromEmail}</span>
            </div>
          )}
          <div className={cx(styles.row, styles.rowBoBottomBorder)}>
            <label htmlFor="create-email-subject" className={styles.rowTitle}>
              email subject:
            </label>
            <input
              name="subject"
              type="text"
              placeholder="Enter your subject"
              id="create-email-subject"
              className={styles.inputText}
              ref={register({required: REQUIRED_FIELD_MESSAGE})}
            />
            <Emoji
              className={styles.emoji}
              onEmojiClick={(emojiData) => {
                const subjectValue = getValues('subject');
                setValue('subject', subjectValue + emojiData.emoji);
              }}
            />
          </div>
          <StripoEditor
            template={template}
            emailId={id}
            className={styles.emailEditor}
            onInit={handleInitializationOfEditor}
          />
        </Card>
      </div>
      <Modal onRequestClose={closeTestModal} isOpen={testModalOpen}>
        <SendTestEmailContainer newsletterID={id} />
      </Modal>
      <Modal isOpen={isScheduleModalOpen} onRequestClose={() => setScheduleModalOpen(false)}>
        <ScheduleNewsletter onScheduleSelect={handleScheduleSelect} scheduleTime={scheduleTime} />
      </Modal>
    </>
  );
}

Newsletter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string,
  data: PropTypes.shape({
    // content: PropTypes.string,
    cssContent: PropTypes.string.isRequired,
    htmlContent: PropTypes.string.isRequired,
    id: PropTypes.string,
    status: PropTypes.string,
    subject: PropTypes.string,
    scheduleTimeDto: PropTypes.shape({
      formattedDateTime: PropTypes.string.isRequired,
      formattedTimezone: PropTypes.string.isRequired,
      transmissionTimestamp: PropTypes.string.isRequired,
    }),
  }),
  draftProcessing: PropTypes.bool,
  sendProcessing: PropTypes.bool,
  testModalOpen: PropTypes.bool,
  closeTestModal: PropTypes.func,
  disabled: PropTypes.bool,
  domainStatus: domainVerificationStatusPropType,
  confirmedSubscribers: PropTypes.number,
  senderOptions: PropTypes.shape({
    fromName: PropTypes.string,
    fromEmail: PropTypes.string,
  }),
  isFreePlanModalOpen: PropTypes.bool,
  FreePlanModal: PropTypes.func,
  isAuditModalOpen: PropTypes.bool,
  toggleAuditModalOpen: PropTypes.func,
  isScheduleModalOpen: PropTypes.bool,
  setScheduleModalOpen: PropTypes.func,
};

export default Newsletter;
