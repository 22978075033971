/**
 * Validates hex value
 * @param  {String} color hex color value
 * @return {Boolean}
 */
export const isValidHexColor = (color) => {
  if (!color || typeof color !== 'string') return false;

  // Validate hex values
  if (color.substring(0, 1) !== '#') {
    return false;
  }

  const colorHex = color.substring(1);

  switch (colorHex.length) {
    case 3:
      return /^[0-9A-F]{3}$/i.test(colorHex);
    case 6:
      return /^[0-9A-F]{6}$/i.test(colorHex);
    case 8:
      return /^[0-9A-F]{8}$/i.test(colorHex);
    default:
      return false;
  }
};
