import cx from 'clsx';
import Switch from '../../../../../components/forms/Switch';
import fieldStyles from '../Field.module.scss';

const SwitchField = ({className, label, ...rest}) => (
  <div className={cx([className])}>
    <label className={fieldStyles.labelContainer}>
      <span className={fieldStyles.label}>{label}</span>
      <Switch {...rest} />
    </label>
  </div>
);

export default SwitchField;
