import PropTypes from 'prop-types';
import {MenuItem as ReactMenuItem} from '@reach/menu-button';

import '@reach/menu-button/styles.css';
import styles from './ButtonDropdown.module.scss';

function ButtonDropdownItem({children, ...props}) {
  return (
    <ReactMenuItem className={styles.option} {...props}>
      {children}
    </ReactMenuItem>
  );
}

ButtonDropdownItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonDropdownItem;
