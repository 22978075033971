import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import Button from '../../../components/Button';
import FormSubmit from '../../../components/forms/FormSubmit';
import FormRow from '../../../components/forms/FormRow/FormRow';
import {REQUIRED_FIELD_MESSAGE} from '../../../components/forms/constants';
import Select from '../../../components/forms/Select';
import {subscriberStatuses} from '../../../utils/constants';
import useSubscriberFieldsFetcher from '../../settings/subscriber-fields/hooks/useSubscriberFieldsFetcher';
import Spinner from '../../../components/Spinner';
import FormCustomFields from './FormCustomFields';
import FormCustomTags from './FormCustomTags';
import useAlert from '../../alerts/useAlert';
import {handleErrorAlert} from '../../../utils/handleErrorAlert';
import CheckBox from '../../../components/forms/Checkbox';
import useRouter from '../../../hooks/useRouter';
import ROUTES from '../../../Router/routes';
import useFetch from '../../../hooks/useFetch';
import {addSubscriberService, editSubscriberService} from '../../subscribers/service';
import styles from '../SubscriberPage.module.scss';

const AFTER_CREATE_TIMEOUT = 1500;

const getCustomFieldValue = (data, fieldId) => {
  return data.values ? data.values[fieldId] : null;
};

const dataMapper = (data) => {
  const values = {};
  data.values?.forEach((v) => {
    values[v.id] = v.value || null;
  });
  return {...data, values};
};

const dataUnMapper = (data, selectedTags) => {
  const values = [];
  const tags = [];
  Object.keys(data.values || {}).forEach((id) => {
    const value = getCustomFieldValue(data, id);
    if (value) {
      values.push({id, value});
    }
  });
  selectedTags.forEach((id) => {
    tags.push(id);
  });
  return {...data, values, tags};
};

function SubscriberDetailsForm({data: propData, setData: propSetData}) {
  const [data, setData] = useState(dataMapper(propData));
  const [selectedTags, setSelectedTags] = useState([]);
  const {history} = useRouter();
  const {showAlert} = useAlert();
  const {formState, errors, setError, control, handleSubmit, reset, register} = useForm({
    defaultValues: data,
  });
  const {loading: fieldsLoading, dataCustomFields, dataTags} = useSubscriberFieldsFetcher({
    immediate: true,
  });
  const {run: editSubscriberRequest} = useFetch(editSubscriberService, {throwError: true});
  const {run: addSubscriberRequest} = useFetch(addSubscriberService, {throwError: true});

  useEffect(() => {
    if (!dataTags || !data.tags || !data.tags.length) {
      return;
    }
    const ids = dataTags.filter((v) => data.tags?.indexOf(v.id) > -1).map((v) => v.id);
    setSelectedTags(ids);
  }, [dataTags]);

  const edit = !!data.id;

  const onSubmit = edit ? editSubscriberRequest : addSubscriberRequest;

  async function handleFormSubmit(values) {
    const formValues = dataUnMapper(values, selectedTags);
    let response;
    try {
      response = await onSubmit(formValues);
    } catch (error) {
      handleErrorAlert(error, showAlert, setError);
      return;
    }
    const alertMessage = edit
      ? 'Subscriber details has been successfully updated!'
      : 'Subscriber has been successfully added!';
    showAlert(alertMessage, {
      variant: 'success',
    });
    propSetData(response);
    const newData = dataMapper(response);
    setData(newData);
    reset(newData);
    if (!edit) {
      await new Promise((resolve) => {
        setTimeout(() => {
          history.push(`${ROUTES.subscribers.index}/${response.id}`);
          resolve();
        }, AFTER_CREATE_TIMEOUT);
      });
    }
  }

  const SubmitButton = () => (
    <Button
      type="submit"
      variant="filled"
      color="green"
      disabled={formState.isSubmitting}
      loading={formState.isSubmitting}
    >
      {edit ? 'Update Details' : 'Add Subscriber'}
    </Button>
  );

  if (fieldsLoading) {
    return <Spinner />;
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
      {edit && <input type="hidden" name="id" ref={register} />}
      <FormCustomFields {...{errors, register, control}} fields={dataCustomFields} />
      <FormCustomTags {...{selectedTags, setSelectedTags}} tags={dataTags} />
      {edit && (
        <>
          <h3 className={styles.formSubtitle}>Status</h3>
          <FormRow>
            <Controller
              name="subscriberStatus"
              error={errors.subscriberStatus}
              control={control}
              rules={{required: REQUIRED_FIELD_MESSAGE}}
              defaultValue="CONFIRMED"
              as={Select}
              options={subscriberStatuses}
              required
              menuPlacement="auto"
            />
          </FormRow>
        </>
      )}
      {edit && data?.gdprAgreement && (
        <>
          <h3 className={styles.formSubtitle}>Marketing Permission</h3>
          <div dangerouslySetInnerHTML={{__html: data.gdprAgreement}} />
        </>
      )}
      {!edit && (
        <div>
          <p>Do you have permission to add this email to your subscriber list?</p>
          <CheckBox
            id="subscriberStatus"
            name="subscriberStatus"
            size="big"
            defaultValue="CONFIRMED"
            bottomSpace
            error={errors.subscriberStatus}
            inputRef={register({required: 'Please confirm that you have permission.'})}
            label="Yes, I have permission from the email user."
          />
        </div>
      )}
      <FormSubmit>
        <SubmitButton />
      </FormSubmit>
    </form>
  );
}

export default SubscriberDetailsForm;
