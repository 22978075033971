import fetchRetry from './fetchRetry';
import networkTimeout from './networkTimeout';
import {ACCEPT_CONTENT_TYPES, CONTENT_TYPE_JSON} from './constants';
import {
  handleNetworkLatency,
  makeFetchURL,
  formatRequestBody,
  parseSuccessfulResponse,
} from './utils';

import {handleError, handleFailedResponse} from './responseHandlers';

export default async function request(endpoint, userOptions = {}, apiSettings = {}) {
  const abortController = new AbortController();
  const {signal} = abortController;

  const defaultOptions = {
    signal,
  };

  const isFormData = userOptions.body instanceof FormData;
  const defaultHeaders = {
    Accept: ACCEPT_CONTENT_TYPES,
    ...(!isFormData ? {'Content-Type': CONTENT_TYPE_JSON} : {}),
  };

  const options = {
    ...defaultOptions,
    ...userOptions,
    headers: {
      ...defaultHeaders,
      ...userOptions.headers,
    },
  };

  const {accessToken} = apiSettings;
  const isAuthenticated = apiSettings.isAuthenticated ?? true;

  if (accessToken && isAuthenticated) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  let response = null;

  try {
    if (!isFormData) {
      formatRequestBody(options);
    }

    await handleNetworkLatency();

    const url = makeFetchURL(endpoint);
    response = await networkTimeout(
      fetchRetry(url, options),
      abortController,
      userOptions.networkTimeout
    );

    if (!response.ok) {
      await handleFailedResponse(response, {
        url,
        options,
        apiSettings,
        isAuthenticated,
      });
    }

    return parseSuccessfulResponse(response);
  } catch (error) {
    return handleError(error, response);
  }
}
