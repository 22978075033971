import {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {FILTER_OPERATIONS, FILTER_TYPES, getFieldByType} from '../constants';
import CustomFieldSelectField from './fields/CustomFieldSelectField';
import SelectField from './fields/SelectField';
import Icon from '../../../../components/Icon';
import styles from './SegmentFilter.module.scss';
import {getFilterType} from '../service';
import useSubscriberFieldsFetcher from '../../subscriber-fields/hooks/useSubscriberFieldsFetcher';
import ROUTES from '../../../../Router/routes';
import useSegmentsFetcher from '../hooks/useSegmentsFetcher';

function SegmentFilter({
  segmentId,
  filterGroups,
  setFilterGroups,
  gIndex,
  fIndex,
  canBeDeleted,
  deleteFilter,
}) {
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const [filterType, setFilterType] = useState(FILTER_TYPES[0]);
  const {register, control, getValues, setValue, watch, errors} = useForm({
    defaultValues: {
      type: 'attributeId',
      operation: null,
      customFieldId: null,
      value: null,
      ...filterGroups[gIndex].filters[fIndex],
    },
  });
  const watchForm = watch();
  const prevFieldId = usePrevious(watchForm.customFieldId);
  const prevType = usePrevious(watchForm.type);
  const {dataCustomFields, dataTags, dataMatchers, data, fetcher} = useSubscriberFieldsFetcher({
    immediate: true,
  });
  const {data: dataSegments} = useSegmentsFetcher({immediate: true});

  useEffect(() => {
    setFilterType(getFilterType(watchForm.type));
    if (watchForm.type !== prevType && prevType) {
      setValue('operation', '');
      setValue('customFieldId', '');
      setValue('value', '');
    }
  }, [watchForm.type, prevType]);

  useEffect(() => {
    const filterData = getValues();
    const newFilterGroups = filterGroups.map((v, i) => {
      if (i === gIndex) {
        return {
          ...v,
          filters: v.filters.map((v1, i1) => {
            if (i1 === fIndex) {
              return {...v1, ...filterData};
            }
            return v1;
          }),
        };
      }
      return v;
    });
    setFilterGroups(newFilterGroups);
  }, [watchForm.type, watchForm.operation, watchForm.customFieldId, watchForm.value]);

  useEffect(() => {
    if (prevFieldId && prevFieldId !== watchForm.customFieldId) {
      setValue('value', '');
      setValue('operation', '');
    }
  }, [watchForm.customFieldId, setValue, prevFieldId]);

  const renderCustomField = () => {
    return (
      filterType.isCustomField && (
        <div className={styles.filterType}>
          <CustomFieldSelectField
            name="customFieldId"
            error={errors.customFieldId}
            control={control}
            defaultValue={watchForm.customFieldId}
          />
        </div>
      )
    );
  };
  const renderOperation = () => {
    const isCustomField = filterType.isCustomField;
    const customFieldId = watchForm.customFieldId;
    let type = 'TEXT';

    if (isCustomField && customFieldId) {
      const found = dataCustomFields.find((v) => v.id === customFieldId);
      if (found) {
        type = found.dataType;
        if (type === 'CHECKBOX' || type === 'RADIO' || type === 'DROPDOWN') {
          type = 'CONSTRAINED';
        }
        if (type === 'TEXTAREA') {
          type = 'TEXT';
        }
      }
    }

    let operations = filterType.operations;

    if (filterType.isCustomField && dataMatchers && dataMatchers[type]) {
      operations = dataMatchers[type];
    }
    if (filterType.isTag && dataMatchers?.TAG) {
      operations = dataMatchers.TAG;
    }

    if (filterType.isSegment && dataMatchers?.SEGMENT) {
      operations = dataMatchers.SEGMENT;
    }

    return (
      <div className={styles.filterType}>
        {filterType.operations.length > 0 && (
          <SelectField
            name="operation"
            error={errors.operation}
            control={control}
            options={operations}
          />
        )}
      </div>
    );
  };
  const renderField = () => {
    return (
      <div className={styles.filterType}>
        {getFieldByType({
          type: watchForm.type,
          segmentId,
          register,
          control,
          name: 'value',
          error: errors.value,
          isCustomField: filterType.isCustomField,
          customFieldId: watchForm.customFieldId,
          filterValue: getValues('value'),
        })}
      </div>
    );
  };
  const renderDelete = () => {
    return (
      canBeDeleted && (
        <button type="button" className={styles.deleteFilter} onClick={deleteFilter}>
          <Icon name="trash" size="20" />
        </button>
      )
    );
  };

  const getFilterTypeName = () => {
    if (filterType.isCustomField) {
      return 'subscriber fields';
    }
    if (filterType.isTag) {
      return 'tags';
    }
    return 'other segments';
  };

  const isFilterAvailable =
    (filterType.isCustomField && dataCustomFields?.length) ||
    (filterType.isTag && dataTags?.length) ||
    (filterType.isSegment && dataSegments?.length);

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.filterDivider}>AND</div>
      <div className={styles.filterMain}>
        <div className={styles.filterType}>
          <SelectField name="type" error={errors.type} control={control} options={FILTER_TYPES} />
        </div>
        <div className={styles.filterControls}>
          {isFilterAvailable ? (
            <>
              {renderCustomField()}
              {(filterType.isTag ||
                filterType.isSegment ||
                (filterType.isCustomField && watchForm.customFieldId)) &&
                dataMatchers &&
                renderOperation()}
              {renderField()}
            </>
          ) : (
            <>
              <input type="hidden" {...register('value')} />
              <div className={styles.filterControlsUnavailable}>
                You don&apos;t have {getFilterTypeName()} yet.
                {!filterType.isSegment && (
                  <>
                    {' '}
                    To add them, go <Link to={ROUTES.settings.subscriberFields}>here</Link>.
                  </>
                )}
              </div>
            </>
          )}
          {renderDelete()}
        </div>
      </div>
    </div>
  );
}

export default SegmentFilter;
