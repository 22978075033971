import {BaseEdge, EdgeLabelRenderer, getBezierPath} from 'reactflow';

export default function conditionalEdge({
  id: _id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <div>
            <div
              style={{
                width: 60,
                height: 26,
                borderRadius: 5,
                border: `1px solid ${data.isYes ? '#0ED2AF' : '#F26B86'} `,
                color: data.isYes ? '#0ED2AF' : '#F26B86',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 12,
                backgroundColor: 'white',
              }}
            >
              {data.isYes ? 'Yes' : 'No'}
            </div>
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
