import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './PaymentMethodsIcon.module.scss';

function PaymentMethodsIcon({noMargin}) {
  return <div className={cx(styles.ico, {[styles.noMargin]: noMargin})} />;
}

PaymentMethodsIcon.propTypes = {
  noMargin: PropTypes.bool,
};

export default PaymentMethodsIcon;
