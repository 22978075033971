import React from 'react';

import styles from './FormTagsSelection.module.scss';
import toolbarStyles from '../Toolbar/Toolbar.module.scss';
import {SubscriptionFormBuilderContext} from '../../context';
import {removeTag} from '../../context/actions';
import IconTrash from '../../../../img/trash-grey.png';
import ToolbarSection from '../Toolbar/ToolbarSection';

const FormTagsSelection = () => {
  const {formState, dispatch, setShowAddNewTagModal} = React.useContext(
    SubscriptionFormBuilderContext
  );

  const sortedList = React.useMemo(() => {
    return formState.tags ? formState.tags.map((value) => ({value})) : [];
  }, [formState]);

  const removeItem = React.useCallback(
    (tagName) => {
      dispatch(removeTag(tagName));
    },
    [dispatch]
  );

  return (
    <ToolbarSection title="Tags" className={toolbarStyles.toolbarContent}>
      {sortedList.length ? (
        sortedList.map(({value}) => (
          <div className={styles.customTag} value={value.name} key={value.name} readOnly>
            <div className={styles.tag_tagIco}>#</div>
            <input type="text" className={styles.customTag__input} value={value.name} readOnly />

            <div className={styles.remove} onClick={() => removeItem(value.name)}>
              <img src={IconTrash} alt="Remove tag" />
            </div>
          </div>
        ))
      ) : (
        <div className={styles.noTagsAdded}>No tags added to the form</div>
      )}
      <button
        type="button"
        className={styles.tags__addTag}
        onClick={() => setShowAddNewTagModal(true)}
      >
        Add Tag
      </button>
    </ToolbarSection>
  );
};

export default FormTagsSelection;
