import {useEffect} from 'react';
import useRouter from '../../hooks/useRouter';
import useSubscriberFetcher from './hooks/useSubscriberFetcher';
import Spinner from '../../components/Spinner';
import Card from '../../components/Card';
import SubscriberHeader from './components/SubscriberHeader';
import SubscriberDetailsForm from './components/SubscriberDetailsForm';
import BackButton from '../../components/BackButton';
import ROUTES from '../../Router/routes';
import CardHeader from '../../components/Card/CardHeader';
import {LANDING_URL} from '../../utils/constants';

function SubscriberContainer() {
  const {query} = useRouter();
  const {data, loading, fetcher, setData} = useSubscriberFetcher();

  const edit = !!query.subscriberID;

  useEffect(() => {
    if (!loading && query.subscriberID && data?.id !== query.subscriberID) {
      fetcher(query.subscriberID);
    }
  }, [query.subscriberID]);

  if (edit && !data) {
    return (
      <>
        <BackButton to={ROUTES.index}>Subscribers List</BackButton>
        <Spinner height={250} />
      </>
    );
  }

  return (
    <>
      <BackButton to={ROUTES.index}>Subscribers List</BackButton>
      <Card
        title={edit ? 'Subscriber Details' : 'Add a New Subscriber'}
        header={
          <CardHeader
            title={
              edit ? 'Here you can edit subscriber details.' : 'Here you can add a new subscriber.'
            }
            text={
              edit ? (
                <p style={{marginTop: '12px'}}>
                  View and manage all information about a single subscriber here. For more
                  information, please read our{' '}
                  <a
                    href={`${LANDING_URL}/guides/subscriber-details`}
                    title="Click here for more details"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Subscriber-Details Guide
                  </a>
                  .
                </p>
              ) : (
                <p style={{marginTop: '12px'}}>
                  Add a single subscriber here. For more information, please read our{' '}
                  <a
                    href={`${LANDING_URL}/guides/subscriber-details`}
                    title="Click here for more details"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Subscriber-Details Guide
                  </a>
                  .
                </p>
              )
            }
          />
        }
      >
        <SubscriberHeader data={data} />
        <SubscriberDetailsForm data={data ?? {}} setData={setData} />
      </Card>
    </>
  );
}

export default SubscriberContainer;
