import {get, post} from '../../utils/api';
import {ENDPOINTS} from './constants';

export async function fetchImportPreviewService(apiSettings, data) {
  return post(ENDPOINTS.preview, data, apiSettings);
}

export async function importSubscribersService(apiSettings, data) {
  return post(ENDPOINTS.import, data, apiSettings);
}

export async function fetchSubscribersBulkLockService(apiSettings) {
  return get(ENDPOINTS.bulkLock, null, apiSettings);
}
