import {getComponentStyle, getTextStyles} from '../../service';

import {LANDING_URL} from '../../../../utils/constants';
import React from 'react';
import {SubscriptionFormBuilderContext} from '../../context';

function FormPreviewSliderClosed() {
  const {setSelectedElement, formState} = React.useContext(SubscriptionFormBuilderContext);

  return (
    <div className="md-embedded-form md-embedded-form--variant-slider" style={{display: 'block'}}>
      <div
        className="md-embedded-form--slider-body"
        style={getComponentStyle({
          border: formState.jsonConfig.mainBorder,
          backgroundColor: formState.jsonConfig.mainBackgroundColor,
        })}
      >
        <img
          src={`${LANDING_URL}/assets/close-image.png`}
          className="md-embedded-form--close"
          alt="Close"
        />
        <div
          className="md-embedded-form--text"
          style={getTextStyles(formState.jsonConfig.body.text)}
          onClick={() => setSelectedElement('body')}
        >
          {formState.jsonConfig.body.text.value}
        </div>
      </div>
    </div>
  );
}

export default FormPreviewSliderClosed;
