import {SET_FETCH_LOADING, SET_DATA, ADD_DATA_ITEM, REPLACE_DATA_ITEM} from './actionTypes';

export function setFetchLoading(dispatch) {
  return function (loading) {
    dispatch({type: SET_FETCH_LOADING, payload: loading});
  };
}

export function setData(dispatch) {
  return function (data) {
    dispatch({type: SET_DATA, payload: data});
  };
}

export function addDataItem(dispatch) {
  return function (newItem) {
    dispatch({type: ADD_DATA_ITEM, payload: newItem});
  };
}

export function replaceDataItem(dispatch) {
  return function (matcher, replacer) {
    dispatch({type: REPLACE_DATA_ITEM, payload: {matcher, replacer}});
  };
}
