import styles from './FormReport.module.scss';

export default (data, getColor, reportData) => {
  return data.label ? (
    <div className={styles.tooltipWrapper}>
      <div className={styles.tooltipLabel}>{data.label}</div>
      {reportData.map((form) => (
        <div>
          <div style={{color: getColor(form)}}>
            {form.formName}: Views:{' '}
            {form.entries.find((entry) => entry.logDate == data.label)?.viewCount}
          </div>
        </div>
      ))}{' '}
    </div>
  ) : (
    <></>
  );
};
