import {del, get, post, put} from '../../utils/api';
import {ENDPOINTS} from './constants';

export async function fetchNewsletterService(apiSettings, data) {
  return get(ENDPOINTS.fetchNewsletter(data), null, apiSettings);
}

export async function saveDraftService(apiSettings, data) {
  return post(ENDPOINTS.saveDraft, data, apiSettings);
}

export async function updateDraftService(apiSettings, data) {
  return put(ENDPOINTS.updateDraft, data, apiSettings);
}

export async function deleteNewsletterService(apiSettings, data) {
  return del(ENDPOINTS.delete(data), null, apiSettings);
}

export async function sendNewsletterService(apiSettings, data) {
  return post(ENDPOINTS.send, data, apiSettings);
}

export async function sendTestEmailService(apiSettings, data) {
  return post(ENDPOINTS.sendTest(data.id), data.values, apiSettings);
}

export async function unscheduleService(apiSettings, data) {
  return put(ENDPOINTS.unschedule(data), null, apiSettings);
}

export async function rescheduleNewsletterService(apiSettings, data) {
  return put(ENDPOINTS.reschedule, data, apiSettings);
}

export async function fetchTrackingService(apiSettings) {
  return get(ENDPOINTS.tracking, null, apiSettings);
}

export async function updateTrackingService(apiSettings, data) {
  return put(ENDPOINTS.tracking, data, apiSettings);
}

export async function fetchOverviewService(apiSettings, userParams) {
  const defaultParams = {
    page: 1,
    limit: 10,
  };

  const params = {
    ...defaultParams,
    ...userParams,
  };

  return get(ENDPOINTS.fetchOverview, params, apiSettings);
}

export async function fetchNewsletterReportService(apiSettings, data) {
  return get(ENDPOINTS.fetchReport(data), null, apiSettings);
}

export async function fetchNewsletterLinksService(apiSettings, data) {
  return get(ENDPOINTS.fetchLinks(data), null, apiSettings);
}
export async function updateLinkService(apiSettings, data) {
  return put(ENDPOINTS.updateLink, data, apiSettings);
}

export async function fetchTemplatesService(apiSettings, data) {
  const defaultParams = {page: 1, limit: 10};

  const params = {...defaultParams, ...data};

  return post(ENDPOINTS.fetchTemplates, params, apiSettings);
}
export async function fetchTemplateService(apiSettings, data) {
  return get(ENDPOINTS.fetchTemplate(data), null, apiSettings);
}

export async function saveAsTemplateService(apiSettings, data) {
  return post(ENDPOINTS.createTemplate, data, apiSettings);
}

export async function updateTemplateService(apiSettings, data) {
  return put(ENDPOINTS.updateTemplate, data, apiSettings);
}

export async function deleteTemplateService(apiSettings, data) {
  return del(ENDPOINTS.deleteTemplate(data), null, apiSettings);
}

export async function uploadImage(apiSettings, data) {
  return post(ENDPOINTS.uploadImage, data, apiSettings);
}

export async function deleteImage(apiSettings, data) {
  return del(ENDPOINTS.deleteImage, data, apiSettings);
}

export async function fetchNextScheduleTime(apiSettings) {
  return get(ENDPOINTS.fetchNextScheduleTime, null, apiSettings);
}

export async function fetchFormattedScheduleTime(apiSettings, iso8601DateTime) {
  return get(ENDPOINTS.fetchFormattedSheduleTime(iso8601DateTime), null, apiSettings);
}
