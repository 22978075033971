import PropTypes from 'prop-types';
import cx from 'clsx';
import {STEPS_SETTINGS, STEPS_SETTINGS_EXISTING_BILLING} from '../../constants';
import {useBoardingWizard} from '../../context/WizardContext';
import bgSrc from '../../../../img/plane-path.svg';
import styles from './Steps.module.scss';
import useRouter from '../../../../hooks/useRouter';
import ROUTES from '../../../../Router/routes';
import {useFreePlan} from '../../../free-plan-info/context/FreePlanContext';

function StepsList({disabled}) {
  const {state, uncompleteStep} = useBoardingWizard();
  const {history} = useRouter();
  const freePlan = useFreePlan();
  const {billingAddressCompleted} = freePlan.state;

  function handleClick(nextStep) {
    uncompleteStep();
    history.push(`${ROUTES.boarding.index}/${nextStep}`);
  }

  return (
    <div className={styles.steps}>
      <div className={styles.bg}>
        <img src={bgSrc} alt="Blue plane" width="155" height="366" />
      </div>
      <div className={styles.content}>
        <ul className={cx(styles.list, {[styles.disabled]: disabled})}>
          {Object.values(
            billingAddressCompleted ? STEPS_SETTINGS_EXISTING_BILLING : STEPS_SETTINGS
          ).map((item, i) => {
            const step = state.steps[item.key];
            return (
              <li
                key={i}
                className={cx(styles.step, {
                  [styles.active]: state.activeStep === item.key,
                  [styles.visited]: step.visited,
                })}
              >
                <button type="button" className={styles.btn} onClick={() => handleClick(item.key)}>
                  <span className={cx(styles.ico, styles[item.icon])} />
                  {item.label}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

StepsList.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default StepsList;
