import {del, get, put, post} from '../../../utils/api';
import {ENDPOINTS} from './constants';

export async function addPaymentMethodService(apiSettings, data) {
  return post(ENDPOINTS.addPaymentMethod(data), data, apiSettings);
}

export async function fetchPaymentMethodsService(apiSettings, data) {
  return get(ENDPOINTS.fetchPaymentMethods, data, apiSettings);
}

export async function fetchSetupIntentService(apiSettings, data) {
  return post(ENDPOINTS.fetchSetupIntent(data), data, apiSettings);
}

export async function setDefaultPaymentMethodService(apiSettings, data) {
  return put(ENDPOINTS.setDefaultPaymentMethod(data), data, apiSettings);
}

export async function deletePaymentMethodService(apiSettings, data) {
  return del(ENDPOINTS.deletePaymentMethod(data), data, apiSettings);
}

export async function fetchDefaultMethodService(apiSettings) {
  return get(ENDPOINTS.fetchDefaultMethod, null, apiSettings);
}
