import PropTypes from 'prop-types';
import cx from 'clsx';
import {useDataTableContext} from './context/TableContext';
import SearchInput from '../forms/SearchInput';
import Button from '../Button';
import Pagination from './Pagination';
import TableRow from './TableRow';
import TableHeading from './TableHeading';
import BulkSelect from './BulkSelect';
import Modal from '../Modal';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import ActionButton from '../ActionButton';
import styles from './DataTable.module.scss';
import ButtonDropdownItem from '../ButtonDropdown/ButtonDropdownItem';
import Icon from '../Icon';
import ButtonDropdown from '../ButtonDropdown';
import ConfirmBulkDeleteModal from './ConfirmBulkDeleteModal';

function DataTable({
  headerCells,
  createButtonText,
  hideSelection,
  children,
  customFilter,
  onDelete,
  onExport,
  createButtonTrigger,
  searchPlaceholder,
  actionModeContent,
  onActionSubmit,
  onBulkDelete,
  onPartialExport,
  onSearch,
  bulkDeleteSuccessContent,
  isBulkDeleteAllowed,
}) {
  const {state, setMode} = useDataTableContext();
  const {modalTitle, form: ModalForm} = actionModeContent;

  async function openDeleteModal() {
    if (state.selectedRows.length && (await isBulkDeleteAllowed())) {
      setMode('delete-partial');
    }
  }

  async function openDeleteAllModal() {
    if (await isBulkDeleteAllowed()) {
      setMode('delete-all');
    }
  }

  function renderModalForm() {
    if (!state.mode) {
      return null;
    }

    return (
      <ModalForm
        defaultValues={state.defaultValues}
        onSubmit={onActionSubmit}
        edit={state.mode === 'edit'}
      />
    );
  }

  function handleActionButtonClick() {
    setMode('create');
  }

  function handleCreate() {
    setMode('create');
  }

  function renderActionButton() {
    if (createButtonTrigger) {
      return createButtonTrigger(handleCreate);
    }

    if (!createButtonText) {
      return null;
    }

    return <ActionButton onClick={handleActionButtonClick}>{createButtonText}</ActionButton>;
  }

  function renderModals() {
    if (state.mode === 'delete-partial') {
      return (
        <ConfirmDeleteModal isOpen onDelete={onBulkDelete} onRequestClose={() => setMode('')}>
          This action will permanently delete the selected subscribers. Click on Delete if you want
          to proceed.
        </ConfirmDeleteModal>
      );
    }

    if (state.mode === 'delete-all') {
      return (
        <ConfirmBulkDeleteModal
          isOpen
          onDelete={onBulkDelete}
          onRequestClose={() => setMode('')}
          successContent={bulkDeleteSuccessContent || null}
        />
      );
    }

    return (
      <Modal isOpen={!!state.mode} onRequestClose={() => setMode('')} title={modalTitle}>
        {renderModalForm()}
      </Modal>
    );
  }

  const hasData = !!state.data?.length;

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.empty]: !hasData,
      })}
    >
      <div className={styles.filters}>
        <SearchInput onSearch={onSearch} placeholder={searchPlaceholder} name="" />
        {renderActionButton()}
      </div>
      <>
        <div className={styles.actions}>
          {!hideSelection && (
            <ButtonDropdown
              buttonText="Bulk Actions"
              as={Button}
              variant="outlined"
              disabled={!hasData}
              color="green"
              type="green"
            >
              <>
                {onExport && (
                  <ButtonDropdownItem onSelect={onExport}>
                    <Icon name="export" className="dropdown-ico" />
                    Export All
                  </ButtonDropdownItem>
                )}
                {onPartialExport && (
                  <ButtonDropdownItem onSelect={onPartialExport}>
                    <Icon name="export-selected" className="dropdown-ico" />
                    Export Selected
                  </ButtonDropdownItem>
                )}
              </>
              <ButtonDropdownItem onSelect={openDeleteAllModal}>
                <Icon name="trash" className="dropdown-ico" />
                Delete All
              </ButtonDropdownItem>
              <ButtonDropdownItem onSelect={openDeleteModal}>
                <Icon name="trash-selected" className="dropdown-ico dropdown-ico-bigger" />
                Delete Selected
              </ButtonDropdownItem>
            </ButtonDropdown>
          )}
          <Pagination customFilter={customFilter} />
        </div>
        <table
          className={cx(styles.table, {
            [styles.loading]: state.processing,
          })}
        >
          <thead>
            <tr className={styles.header}>
              {!hideSelection && hasData && (
                <TableHeading>
                  <BulkSelect />
                </TableHeading>
              )}
              {headerCells}
            </tr>
          </thead>

          {hasData ? (
            <tbody>
              {state.data.map((row, i) => (
                <TableRow rowData={row} key={row.id || i} onDelete={onDelete}>
                  {children(row)}
                </TableRow>
              ))}
            </tbody>
          ) : null}
        </table>

        {!hasData && (
          <aside className={styles.emptyInfo}>
            <p className={styles.emptyInfoText}>
              {state.loading ? 'Fetching data...' : "You don't have any data yet."}
            </p>
          </aside>
        )}
      </>

      {renderModals()}
    </div>
  );
}

DataTable.defaultProps = {
  hideSelection: false,
  searchPlaceholder: '',
};

DataTable.propTypes = {
  headerCells: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActionSubmit: PropTypes.func.isRequired,
  onBulkDelete: PropTypes.func.isRequired,
  onPartialExport: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  hideSelection: PropTypes.bool,
  createButtonText: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  createButtonTrigger: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onExport: PropTypes.func,
  customFilter: PropTypes.shape({
    targetFilter: PropTypes.string,
    node: PropTypes.node,
  }),
  actionModeContent: PropTypes.shape({
    modalTitle: PropTypes.string,
    form: PropTypes.func,
    submitHandler: PropTypes.func,
  }).isRequired,
  bulkDeleteSuccessContent: PropTypes.node,
  isBulkDeleteAllowed: PropTypes.func.isRequired,
};

export default DataTable;
