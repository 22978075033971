import {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import BillingForm from './BillingForm';
import {useBoardingWizard} from '../../context/WizardContext';
import StatusMessage from '../../../../components/StatusMessage/StatusMessage';
import {Panel, PanelContent, PanelHeader} from '../../components/Panel';
import {handleError} from '../../../../utils/handleError';
import useBillingDetails from '../../../../hooks/useBillingDetails';
import Card from '../../../../components/Card';
import useRouter from '../../../../hooks/useRouter';
import ROUTES from '../../../../Router/routes';
import usePostalAddress from '../../../../hooks/usePostalAddress';
import useCountries from '../../../settings/countries/hooks/useCountries';

function Billing() {
  const [billingDetailsData, setBillingDetailsData] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const {setStepData, completeStep} = useBoardingWizard();
  const {history} = useRouter();
  const {fetchBillingDetailsRequest, loading, submitBillingDetails} = useBillingDetails({
    throwError: true,
    immediate: false,
    throwOnFetch: true,
  });
  const {dataMapped: countries} = useCountries({immediate: true});
  const {data: postalAddressData} = usePostalAddress();

  useEffect(
    function () {
      async function fetchData() {
        try {
          const response = await fetchBillingDetailsRequest(null);
          setBillingDetailsData(response);
        } catch (error) {
          handleError(error, setErrorMessage);
        }
      }

      fetchData();
    },
    [fetchBillingDetailsRequest]
  );

  const formDefaultValues =
    billingDetailsData && Object.keys(billingDetailsData).length
      ? billingDetailsData
      : postalAddressData;

  async function handleFormSubmit(values, setFormError) {
    setErrorMessage('');
    try {
      const response = await submitBillingDetails(values);
      setStepData(response);
      completeStep();
      history.push(ROUTES.boarding.checkout);
    } catch (error) {
      handleError(error, setErrorMessage, setFormError);
    }
  }

  return (
    <>
      <Helmet title="Billing" />
      <Panel>
        <PanelHeader
          title="Billing"
          subtitle="Please provide your billing details below.
            You will only be charged when your free trial ends (we will send an email reminder).
            You are free to cancel anytime before then."
        />
        <StatusMessage message={errorMessage} />
        <PanelContent
          errorMessage={errorMessage}
          loading={!billingDetailsData || loading || !countries.length}
        >
          <Card noMargin noBorder>
            <BillingForm
              onSubmit={handleFormSubmit}
              defaultValues={formDefaultValues}
              countries={countries}
            />
          </Card>
        </PanelContent>
      </Panel>
    </>
  );
}

export default Billing;
