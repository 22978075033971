import {
  BULK_DELETE,
  BULK_TOGGLE,
  CHANGE_PER_PAGE_SIZE,
  DESELECT_ROW,
  FORCE_PAGE_CHANGE,
  GO_TO_NEXT_PAGE,
  GO_TO_PREV_PAGE,
  SEARCH,
  SELECT_ROW,
  SET_DATA,
  SET_FETCHER,
  SET_FILTERS,
  SET_LOADING,
  SET_META,
  SET_MODE,
  SET_PROCESSING,
} from './actionTypes';

export function search(dispatch) {
  return function (data) {
    dispatch({type: SEARCH, payload: data});
  };
}

export function setData(dispatch) {
  return function (data) {
    dispatch({type: SET_DATA, payload: data});
  };
}

export function setMeta(dispatch) {
  return function (data) {
    dispatch({type: SET_META, payload: data});
  };
}

export function selectRow(dispatch) {
  return function (rowId) {
    dispatch({type: SELECT_ROW, payload: rowId});
  };
}

export function deselectRow(dispatch) {
  return function (rowId) {
    dispatch({type: DESELECT_ROW, payload: rowId});
  };
}

export function bulkToggle(dispatch) {
  return function (checked) {
    dispatch({type: BULK_TOGGLE, payload: checked});
  };
}

export function setProcessing(dispatch) {
  return function (data) {
    dispatch({type: SET_PROCESSING, payload: data});
  };
}

export function setLoading(dispatch) {
  return function (data) {
    dispatch({type: SET_LOADING, payload: data});
  };
}

export function setFetcher(dispatch) {
  return function (fetcher) {
    dispatch({type: SET_FETCHER, payload: fetcher});
  };
}

export function setMode(dispatch) {
  return function (mode, data) {
    dispatch({type: SET_MODE, payload: {mode, data}});
  };
}

export function goToNextPage(dispatch) {
  return function (data) {
    dispatch({type: GO_TO_NEXT_PAGE, payload: data});
  };
}

export function goToPrevPage(dispatch) {
  return function (data) {
    dispatch({type: GO_TO_PREV_PAGE, payload: data});
  };
}

export function changePerPageSize(dispatch) {
  return function (data) {
    dispatch({type: CHANGE_PER_PAGE_SIZE, payload: data});
  };
}

export function forcePageChange(dispatch) {
  return function (data) {
    dispatch({type: FORCE_PAGE_CHANGE, payload: data});
  };
}

export function bulkDelete(dispatch) {
  return function (data) {
    dispatch({type: BULK_DELETE, payload: data});
  };
}

export function setFilters(dispatch) {
  return function (filters) {
    dispatch({type: SET_FILTERS, payload: filters});
  };
}
