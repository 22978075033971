import {useState} from 'react';
import ProgressBar from './components/ProgressBar';

import Spinner from '../../../components/Spinner';
import ReportCard from './components/ReportCard';
import {DetailsPanel, DetailsPanelCol, DetailsPanelRow} from './components/DetailsPanel';
import BouncesChart from './components/BouncesChart';
import {newsletterReportPropType} from '../../../utils/prop-types/newsletter';

import styles from './NewsletterReport.module.scss';

function NewsletterReport({data, loading}) {
  const [activeCategory, setActiveCategory] = useState('');

  function handleClick(category) {
    if (category === activeCategory) {
      setActiveCategory('');
    } else {
      setActiveCategory(category);
    }
  }

  if (!data || loading) {
    return <Spinner />;
  }

  const fullWidthStyle = {minWidth: '100%', maxWidth: '100%'};

  return (
    <>
      <div className={styles.rows}>
        <div className={styles.row}>
          <strong>Email-Subject:</strong>
          <span>{data.subject}</span>
        </div>
        <div className={styles.row}>
          <strong>Sent:</strong>
          <span>{data.publishDate}</span>
        </div>
        <div className={styles.row}>
          <strong>Recipients:</strong>
          <span>
            Sent to <strong>{data.recipientCount}</strong> confirmed subscribers
          </span>
        </div>
      </div>
      <div className={styles.cards}>
        {data.deliveryReport && (
          <ReportCard
            category="deliveryReport"
            title="Delivery Rate"
            onClick={handleClick}
            value={data.deliveryReport.deliveryRate}
            text={`${data.deliveryReport.deliveryCount} emails`}
            active={activeCategory === 'deliveryReport'}
          >
            <DetailsPanel title="Delivery Rate Report">
              <DetailsPanelCol style={fullWidthStyle}>
                <ProgressBar title="Delivery Rate" width={data.deliveryReport.deliveryRate} />
                <DetailsPanelRow label="Delivered:" value={data.deliveryReport.deliveryCount} />
                <DetailsPanelRow label="Delayed:" value={data.deliveryReport.delayCount} />
                <DetailsPanelRow label="Rejected:" value={data.deliveryReport.rejectionCount} />
              </DetailsPanelCol>
            </DetailsPanel>
          </ReportCard>
        )}
        <ReportCard
          category="openReport"
          title="Open Rate"
          onClick={handleClick}
          value={data.openReport?.uniqueTopOpenRate ? data.openReport?.uniqueTopOpenRate : 'N/A'}
          text={
            data.openReport?.uniqueTopOpenCount
              ? `${data.openReport.uniqueTopOpenCount} emails`
              : 'Open tracking is deactivated'
          }
          active={activeCategory === 'openReport'}
        >
          {data.openReport && (
            <DetailsPanel title="Open Rate Report">
              <DetailsPanelCol>
                <ProgressBar title="Unique Open Rate" width={data.openReport.uniqueTopOpenRate} />
                <DetailsPanelRow
                  label="Unique Opens (Top):"
                  value={data.openReport.uniqueTopOpenCount}
                />
                <DetailsPanelRow
                  label="Unique Opens (Bottom):"
                  value={data.openReport.uniqueBottomOpenCount}
                />
              </DetailsPanelCol>
              <DetailsPanelCol>
                <ProgressBar title="Total Open Rate" width={data.openReport.totalTopOpenRate} />
                <DetailsPanelRow
                  label="Total Opens (Top):"
                  value={data.openReport.totalTopOpenCount}
                />
                <DetailsPanelRow
                  label="Total Opens (Bottom):"
                  value={data.openReport.totalBottomOpenCount}
                />
              </DetailsPanelCol>
            </DetailsPanel>
          )}
        </ReportCard>
        <ReportCard
          category="clickReport"
          title="Click Rate"
          onClick={handleClick}
          value={data.clickReport?.clickRate ? data.clickReport?.clickRate : 'N/A'}
          text={
            data.clickReport?.uniqueClickCount
              ? `${data.clickReport?.uniqueClickCount} emails`
              : 'Click tracking is deactivated'
          }
          active={activeCategory === 'clickReport'}
        >
          {data.clickReport && (
            <DetailsPanel title="Click Rate Report">
              <DetailsPanelCol style={!data.clickReport?.clickToOpenRate ? fullWidthStyle : {}}>
                <ProgressBar title="Unique Click Rate" width={data.clickReport.clickRate} />
                <DetailsPanelRow label="Unique Clicks:" value={data.clickReport.uniqueClickCount} />
                <DetailsPanelRow label="Total Clicks:" value={data.clickReport.totalClickCount} />
              </DetailsPanelCol>
              {data.clickReport.clickToOpenRate && (
                <DetailsPanelCol>
                  <ProgressBar
                    title="Click to Open Rate"
                    width={data.clickReport.clickToOpenRate}
                  />
                </DetailsPanelCol>
              )}
            </DetailsPanel>
          )}
        </ReportCard>
        {data.bounceReport && (
          <ReportCard
            category="bounceReport"
            title="Bounce Rate"
            onClick={handleClick}
            value={data.bounceReport.totalBounceRate}
            text={`${data.bounceReport.totalBounceCount} Bounces`}
            active={activeCategory === 'bounceReport'}
          >
            <DetailsPanel title="Bounce Rate Report">
              <DetailsPanelCol style={data.bounceReport.totalBounceCount > 0 ? {} : fullWidthStyle}>
                <DetailsPanelRow
                  label="Total Bounces:"
                  value={data.bounceReport.totalBounceCount}
                  percent={data.bounceReport.totalBounceRate}
                  heading
                />
                <DetailsPanelRow
                  label="Soft Bounces:"
                  value={data.bounceReport.softBounceCount}
                  percent={data.bounceReport.softBounceRate}
                />
                <DetailsPanelRow
                  label="Block Bounces:"
                  value={data.bounceReport.blockBounceCount}
                  percent={data.bounceReport.blockBounceRate}
                />
                <DetailsPanelRow
                  label="Hard Bounces:"
                  value={data.bounceReport.hardBounceCount}
                  percent={data.bounceReport.hardBounceRate}
                />
                <DetailsPanelRow
                  label="Out Of Band Bounces:"
                  value={data.bounceReport.outOfBandBounceCount}
                  percent={data.bounceReport.outOfBandBounceRate}
                />
                <DetailsPanelRow
                  label="Admin Bounces:"
                  value={data.bounceReport.adminBounceCount}
                  percent={data.bounceReport.adminBounceRate}
                />
                <DetailsPanelRow
                  label="Undetermined Bounces:"
                  value={data.bounceReport.undeterminedBounceCount}
                  percent={data.bounceReport.undeterminedBounceRate}
                />
              </DetailsPanelCol>
              <DetailsPanelCol>
                <BouncesChart data={data.bounceReport} />
              </DetailsPanelCol>
            </DetailsPanel>
          </ReportCard>
        )}
        {data.spamReport && (
          <ReportCard
            title="Spam Report Rate"
            value={data.spamReport.spamComplaintRate}
            text={`${data.spamReport.spamComplaintCount} emails`}
          />
        )}
        {data.unsubscribeReport && (
          <ReportCard
            title="Unsubscribe Rate"
            value={data.unsubscribeReport.unsubscribeRate}
            text={`${data.unsubscribeReport.unsubscribeCount} unsubscribers`}
          />
        )}
      </div>
    </>
  );
}

NewsletterReport.propTypes = newsletterReportPropType;

export default NewsletterReport;
