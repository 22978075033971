export const UNAUTHORIZED_STATUS_CODE = 401;
export const FORBIDDEN_STATUS_CODE = 403;
export const CONTENT_TYPE_JSON = 'application/json';
export const CONTENT_TYPE_TEXT = 'text/plain';
export const CONTENT_TYPE_FORM_DATA = 'multipart/form-data';
export const CONTENT_TYPE_PDF = 'application/pdf';
export const CONTENT_TYPE_OCTET_STREAM = 'application/octet-stream';
export const FILE_CONTENT_TYPES = [CONTENT_TYPE_PDF, CONTENT_TYPE_OCTET_STREAM];
export const ACCEPT_CONTENT_TYPES = `${CONTENT_TYPE_JSON}, ${CONTENT_TYPE_TEXT}, ${CONTENT_TYPE_FORM_DATA}, ${CONTENT_TYPE_PDF}, ${CONTENT_TYPE_OCTET_STREAM}`;

export const API_REQUEST_ERROR_MESSAGE =
  'Unfortunately, there was an error while processing your request.';

export const API_REQUEST_SUCCESS_MESSAGE = 'Request was successfully submitted.';
