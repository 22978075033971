import cx from 'clsx';

import styles from './OrderPreview.module.scss';
import {
  billingCyclePropType,
  recurringChargePropType,
  todaysChargePropType,
} from '../../../../utils/prop-types/checkout';
import Tooltip from '../../../Tooltip';

function OrderPreview({billingCycle, recurringCharge, todaysCharge}) {
  const isTrial = billingCycle.remainingTrialDays > 0;

  function renderInvoiceItems() {
    if (!todaysCharge.invoiceItems.length) {
      return null;
    }

    return todaysCharge.invoiceItems.map((item, i) => (
      <div key={i} className={styles.row}>
        <span>{item.description}</span>
        <span>{item.amount.formattedValue}</span>
      </div>
    ));
  }

  return (
    <div className={styles.summary}>
      <div className={cx(styles.section, styles.sectionFullBorder)}>
        <h4 className={styles.title}>
          What you&#39;ll pay each {recurringCharge.billingPeriod.unit}:
        </h4>
        <div className={styles.row}>
          <span>{recurringCharge.product.name}</span>
          <span>{recurringCharge.amount.formattedValue}</span>
        </div>
        <div className={styles.row}>
          <span>
            Total VAT{' '}
            {recurringCharge.vat.rate.value > 0 && `(${recurringCharge.vat.rate.formattedValue})`}
          </span>
          <Tooltip
            iconTrigger
            content="The VAT rate is based on your current billing location. The actual VAT amount charged may vary accordingly."
          />
          <span>{recurringCharge.vat.amount.formattedValue}</span>
        </div>
        <div className={styles.row}>
          <strong>Total Charge</strong>
          <strong>{recurringCharge.total.formattedValue}</strong>
        </div>
      </div>

      {!isTrial && (
        <div className={cx(styles.section, styles.sectionSmallBottomPadding)}>
          <h4 className={styles.title}>What you&#39;ll pay today:</h4>
          {renderInvoiceItems()}
        </div>
      )}

      {!isTrial && (
        <div className={cx(styles.section, styles.sectionSmallPadding)}>
          <div className={styles.row}>
            <strong className={styles.subtitle}>Subtotal</strong>
            <strong className={styles.subtitle}>{todaysCharge.subtotal.formattedValue}</strong>
          </div>
          <div className={styles.row}>
            <span>
              Total VAT{' '}
              {todaysCharge.vat.rate.value > 0 && `(${todaysCharge.vat.rate.formattedValue})`}
            </span>
            <span>{todaysCharge.vat.amount.formattedValue}</span>
          </div>
        </div>
      )}

      <div className={cx(styles.section, styles.sectionFullBorder)}>
        {isTrial && <h4 className={styles.title}>What you&#39;ll pay today:</h4>}
        <div className={styles.row}>
          <h4 className={cx(styles.title, {[styles.titleNoTopMargin]: isTrial})}>Amount Due</h4>
          <h4 className={styles.title}>{todaysCharge.amountDue.formattedValue}</h4>
        </div>
        {todaysCharge.appliedBalance.value !== 0 && (
          <div className={styles.row}>
            <span>Applied balance</span>
            <span>{todaysCharge.appliedBalance.formattedValue}</span>
          </div>
        )}
        <div className={styles.row}>
          <span>
            All amounts shown in <strong>U.S. Dollars</strong>
          </span>
        </div>
      </div>

      <div className={styles.section}>
        {isTrial && (
          <div className={cx(styles.row, styles.rowLastGreen)}>
            <span>Remaining Trial</span>
            <span>{billingCycle.remainingTrialDays} days</span>
          </div>
        )}
        <div className={styles.row}>
          {isTrial ? (
            <span>
              Your paid subscription start date is <strong>{billingCycle.nextInvoiceDate}</strong>.
              No risk: You will not be charged if you cancel before that date.
            </span>
          ) : (
            <span>
              Your next invoice is due <strong>{billingCycle.nextInvoiceDate}</strong>. No risk: You
              can cancel your subscription any time.
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

OrderPreview.propTypes = {
  billingCycle: billingCyclePropType,
  recurringCharge: recurringChargePropType,
  todaysCharge: todaysChargePropType,
};

export default OrderPreview;
