import {useContext} from 'react';
import createDataContext from '../../../utils/createDataContext';
import createReducer from '../../../utils/createReducer';
import {SET_FREE_PLAN_INFO} from './actionTypes';
import {initialState, setFreePlanInfoReducer} from './reducers';

const freePlanReducer = createReducer(initialState, {
  [SET_FREE_PLAN_INFO]: setFreePlanInfoReducer,
});

function setFreePlanInfo(dispatch) {
  return function (data) {
    dispatch({type: SET_FREE_PLAN_INFO, payload: data});
  };
}

export const {Context, Provider} = createDataContext(
  freePlanReducer,
  {
    setFreePlanInfo,
  },
  initialState
);

export const useFreePlan = function () {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useFreePlanInfo must be used within a FreePlanInfoProvider`);
  }
  return context;
};
