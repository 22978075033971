import Table from 'components/Table';
import Label from 'components/Label';
import PropTypes from 'prop-types';
import {automationStatusToColor} from 'domain/automation/utils/automationFlow';

const AutomationList = ({items = [], className}) => {
  return (
    <>
      <p className="text-centered text-bold text-medium">Affected Automations:</p>
      <Table data={items} headerCells={['Name', 'Status']} className={className}>
        {(row) => (
          <>
            <td>{row.name}</td>
            <td>
              <Label color={automationStatusToColor[row.status]}>{row.status}</Label>
            </td>
          </>
        )}
      </Table>
    </>
  );
};

export const AutomationItemPropTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export const AutomationListPropTypes = PropTypes.arrayOf(PropTypes.shape(AutomationItemPropTypes));

AutomationList.propTypes = {
  items: AutomationListPropTypes,
};

export default AutomationList;
