import React from 'react';

import get from 'lodash.get';
import ToolbarSection from './ToolbarSection';
import TextField from './TextField';
import SelectField from './SelectField';
import styles from './Toolbar.module.scss';
import {SubscriptionFormBuilderContext} from '../../context';
import {useFormFieldIndex} from '../../hooks/useFormFieldIndex';
import CheckboxField from './CheckboxField';
import {useEnrichedField} from '../../hooks/useEnrichedField';
import {updateConfigProp} from '../../context/actions';

const CustomFieldStyles = ({field}) => {
  const fieldIndex = useFormFieldIndex(field.name);
  const enrichedField = useEnrichedField(field);

  const fieldsGroupName = `inputFields[${fieldIndex}]`;
  const {dispatch, subscriberFields, formState, setSelectedElement} = React.useContext(
    SubscriptionFormBuilderContext
  );

  const [hasError, setError] = React.useState(false);

  const customFieldsOptions = React.useMemo(() => {
    const isFieldUsed = (name) => formState.inputFields.find((i) => i.name === name);
    return subscriberFields
      .filter(
        ({personalizationTagName}) =>
          !isFieldUsed(personalizationTagName) || personalizationTagName === field.name
      )
      .map(({personalizationTagName, name}) => ({value: personalizationTagName, label: name}));
  }, [subscriberFields, formState, field]);

  const handleTypeChange = React.useCallback(
    (nextFieldName) => {
      const label = customFieldsOptions.find((i) => i.value === nextFieldName)?.label;
      dispatch(updateConfigProp(`inputFields.${fieldIndex}.placeholder`, label));
      dispatch(updateConfigProp(`inputFields.${fieldIndex}.label`, label));
      setSelectedElement(`field.${nextFieldName}`);
    },
    [setSelectedElement, dispatch, fieldIndex, customFieldsOptions]
  );

  React.useEffect(() => {
    const label = get(formState, `${fieldsGroupName}.label`);
    const placeholder = get(formState, `${fieldsGroupName}.placeholder`);
    setError(!label && !placeholder);
  }, [formState, setError, fieldsGroupName]);

  return (
    <ToolbarSection sectionName="Field styles" className={styles.toolbarContent}>
      <SelectField
        label="Subscriber field"
        paramPath={`${fieldsGroupName}.name`}
        options={customFieldsOptions}
        onChange={handleTypeChange}
        disabled={enrichedField.isMandatory}
        helpText={
          enrichedField.isMandatory ? 'This field is mandatory and type cannot be changed' : ''
        }
      />
      <TextField
        error={hasError && 'Either label or placeholder should be filled'}
        label="Label"
        paramPath={`${fieldsGroupName}.label`}
      />
      {!enrichedField?.optionValues?.length ? (
        <TextField
          error={hasError && 'Either label or placeholder should be filled'}
          label="Placeholder"
          paramPath={`inputFields.${fieldIndex}.placeholder`}
        />
      ) : null}
      <CheckboxField
        disabled={enrichedField.isMandatory}
        label="Required"
        paramPath={`inputFields.${fieldIndex}.isRequired`}
      />
    </ToolbarSection>
  );
};

export default CustomFieldStyles;
