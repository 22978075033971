import PropTypes from 'prop-types';

import styles from './HighlightedText.module.scss';

function HighlightedText({children}) {
  return <div className={styles.text}>{children}</div>;
}

HighlightedText.propTypes = {
  children: PropTypes.node,
};

export default HighlightedText;
