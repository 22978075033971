import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import NewsletterReportContainer from './NewsletterReportContainer';
import BackButton from '../../../components/BackButton';
import ROUTES from '../../../Router/routes';
import CardHeader from '../../../components/Card/CardHeader';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import useRouter from '../../../hooks/useRouter';

function NewsletterReportPage() {
  const {query} = useRouter();
  return (
    <>
      <Helmet title="Campaign Report" />
      <BackButton to={ROUTES.newsletters.index}>Email Campaigns</BackButton>
      <Card
        header={
          <CardHeader
            title="Campaign Report"
            text="View email statistics and assess each campaign's performance here."
          >
            <Button
              as={Link}
              to={`${ROUTES.newsletters.index}/${query.id}`}
              variant="outlined"
              color="green"
            >
              View Campaign
            </Button>
          </CardHeader>
        }
      >
        <NewsletterReportContainer id={query.id} />
      </Card>
    </>
  );
}

export default NewsletterReportPage;
