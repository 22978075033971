import PropTypes from 'prop-types';
import Button from '../Button';
import styles from './ActionButton.module.scss';

function ActionButton({children, onClick, ...props}) {
  return (
    <Button variant="filled" color="green" {...props} className={styles.btn} onClick={onClick}>
      {children}
    </Button>
  );
}

ActionButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default ActionButton;
