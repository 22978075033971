import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {Helmet} from 'react-helmet';
import PaginatedCard from 'components/PaginatedCard';
import Table from 'components/Table';
import useRouter from 'hooks/useRouter';
import ROUTES from 'Router/routes';
import CardHeader from 'components/Card/CardHeader';
import Button from 'components/Button';
import ActionsDropdown from 'components/ActionsDropdown';
import ActionDropdownItem from 'components/ActionsDropdown/ActionDropdownItem';
import Label from 'components/Label';
import Icon from 'components/Icon';
import useFetch from 'hooks/useFetch';
import {LANDING_URL} from 'utils/constants';
import {useAutomation} from '../stores/automation';
import {sortSteps} from '../utils/jsonToGraph';
import {deleteAutomationsService} from '../service';
import {automationStatus, automationStatusToColor} from '../utils/automationFlow';
import {useFetchAutomation} from '../hooks/queries';

import styles from '../Automation.module.scss';

const AutomationOverview = () => {
  const {automationOverviewData, fetchAutomationOverview, updateAutomationStatus} = useAutomation();
  const fetchAutomationQuery = useFetchAutomation();

  useEffect(() => {
    fetchAutomationOverview();

    // @TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerCells = ['', 'Name', '', '', ' '];
  const {history} = useRouter();

  const {run: deleteAutomationsOverviewRequest, loading} = useFetch(deleteAutomationsService);

  const onDelete = async (id) => {
    await deleteAutomationsOverviewRequest(id);
    await fetchAutomationOverview();
  };

  const onStatusUpdate = async (id, status) => {
    try {
      await updateAutomationStatus({
        status,
        id,
      });
    } catch (error) {
      console.log('error', error);
    }
    await fetchAutomationOverview();
  };

  const handleUseAsDraft = async (id) => {
    const automation = await fetchAutomationQuery.run(id);
    const draftCopy = {...automation.config};
    const oldToNewStepId = {};

    const sortedSteps = sortSteps(draftCopy.steps, draftCopy.startStepId);
    draftCopy.id = '';
    draftCopy.status = automationStatus.DRAFT;

    draftCopy.steps = sortedSteps.map((s) => {
      const step = {...s};
      const {stepParam} = step;

      if (stepParam?.nextStepId) {
        oldToNewStepId[stepParam.nextStepId] = uuidv4();
      }

      if (stepParam?.nextStepIdIfTrue) {
        oldToNewStepId[stepParam.nextStepIdIfTrue] = uuidv4();
      }

      if (stepParam?.nextStepIdIfFalse) {
        oldToNewStepId[stepParam.nextStepIdIfFalse] = uuidv4();
      }

      step.id = oldToNewStepId[step.id] || uuidv4();

      if (stepParam?.nextStepId) {
        stepParam.nextStepId = oldToNewStepId[stepParam.nextStepId] || uuidv4();
      }

      if (stepParam?.nextStepIdIfTrue) {
        stepParam.nextStepIdIfTrue = oldToNewStepId[stepParam.nextStepIdIfTrue] || uuidv4();
      }

      if (stepParam?.nextStepIdIfFalse) {
        stepParam.nextStepIdIfFalse = oldToNewStepId[stepParam.nextStepIdIfFalse] || uuidv4();
      }

      return step;
    });

    draftCopy.startStepId = draftCopy.steps[0].id;
    draftCopy.triggers = draftCopy.triggers.map((trigger) => ({
      ...trigger,
      id: uuidv4(),
    }));

    history.push({
      pathname: ROUTES.automation.createAutomation,
      state: {draft: draftCopy},
    });
  };

  const navigateToAutomation = (id) =>
    history.push({
      pathname: ROUTES.automation.createAutomation,
      state: {id},
    });

  const menuOption = {
    activate: {
      label: 'Activate',
      handler: (id) => onStatusUpdate(id, automationStatus.ACTIVE),
    },
    pause: {
      label: 'Pause',
      handler: (id) => onStatusUpdate(id, automationStatus.PAUSED),
    },
    disconnect: {
      label: 'Disconnect',
      handler: (id) => onStatusUpdate(id, automationStatus.DISCONNECTING),
    },
    edit: {
      label: 'Edit',
      handler: navigateToAutomation,
    },
    view: {
      label: 'View',
      handler: navigateToAutomation,
    },
    useAsDraft: {label: 'Use as draft', handler: handleUseAsDraft},
    delete: {
      label: 'Delete',
      handler: onDelete,
    },
  };

  const menuOptionsByAutomationStatus = {
    [automationStatus.ACTIVE]: [
      menuOption.pause,
      menuOption.disconnect,
      menuOption.edit,
      menuOption.useAsDraft,
      menuOption.delete,
    ],
    [automationStatus.PAUSED]: [
      menuOption.activate,
      menuOption.disconnect,
      menuOption.edit,
      menuOption.useAsDraft,
      menuOption.delete,
    ],
    [automationStatus.DISCONNECTING]: [
      menuOption.activate,
      menuOption.view,
      menuOption.useAsDraft,
      menuOption.delete,
    ],
    [automationStatus.ARCHIVED]: [menuOption.view, menuOption.useAsDraft, menuOption.delete],
    [automationStatus.DRAFT]: [menuOption.edit, menuOption.delete],
  };

  return (
    <>
      <Helmet title="Automations" />
      <PaginatedCard
        noPadding
        loading={loading}
        header={
          <CardHeader
            title="Automations"
            text={
              <p style={{maxWidth: 640}}>
                Here you can automate processes and create and manage email sequences such as email
                courses.For more information, please read our{' '}
                <a
                  href={`${LANDING_URL}/guides/automations`}
                  title="Click here for more details"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Email Automation Guide
                </a>
                .
              </p>
            }
          >
            <Button
              as={Link}
              to={ROUTES.automation.createAutomation}
              variant="filled"
              color="green"
            >
              <Icon name="plus" buttonIcon />
              Create Automation
            </Button>
          </CardHeader>
        }
      >
        {automationOverviewData?.length > 0 ? (
          <Table
            caption="commissions"
            data={automationOverviewData}
            //   loading={loading}
            headerCells={headerCells}
            showHead={false}
          >
            {(row) => (
              <>
                <td>
                  <Label color={automationStatusToColor[row.status]}>{row.status}</Label>
                </td>
                <td
                  style={{fontSize: 16, fontWeight: 600, cursor: 'pointer'}}
                  onClick={() =>
                    history.push({
                      pathname: ROUTES.automation.createAutomation,
                      state: {id: row.id},
                    })
                  }
                >
                  {row.name}
                </td>
                <td>
                  <div className={styles.reportItem}>
                    <div className={styles.reportValue}>{row.report?.activeSubscriberCount}</div>
                    <div className={styles.reportName}>Active</div>
                  </div>
                </td>
                <td>
                  <div className={styles.reportItem}>
                    <div className={styles.reportValue}>{row.report?.completedSubscriberCount}</div>
                    <div className={styles.reportName}>Completed</div>
                  </div>
                </td>
                <td>
                  <ActionsDropdown>
                    {menuOptionsByAutomationStatus[row.status].map((option) => (
                      <ActionDropdownItem
                        onSelect={() => option.handler(row.id)}
                        key={option.label}
                      >
                        {option.label}
                      </ActionDropdownItem>
                    ))}
                  </ActionsDropdown>
                </td>
              </>
            )}
          </Table>
        ) : (
          <aside className={styles.emptyInfo}>
            <p className={styles.emptyInfoText}>You don&apos;t have any data yet.</p>
          </aside>
        )}
      </PaginatedCard>
    </>
  );
};

export default AutomationOverview;
