import {useContext} from 'react';
import {Context} from './AuthContext';

export default function useAuth() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthenticationProvider`);
  }
  return context;
}
