import PropTypes from 'prop-types';
import {Controller, useForm} from 'react-hook-form';
import Input from '../../../../components/forms/Input';
import {REQUIRED_FIELD_MESSAGE} from '../../../../components/forms/constants';
import FormRow from '../../../../components/forms/FormRow/FormRow';
import Button from '../../../../components/Button';
import Select from '../../../../components/forms/Select';
import {isEuropeanCountry, validateVATCode} from '../../../../utils/validation/payments';
import useAuth from '../../../auth/useAuth';
import FormSubmit from '../../../../components/forms/FormSubmit';

function BillingForm({onSubmit, defaultValues, countries}) {
  const {state: authState} = useAuth();
  const {formState, errors, control, setError, watch, handleSubmit, register} = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const countryCode = watch('countryCode');

  async function handleFormSubmit(values) {
    return onSubmit(values, setError);
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <FormRow>
        <Controller
          name="countryCode"
          error={errors.countryCode}
          control={control}
          rules={{required: REQUIRED_FIELD_MESSAGE}}
          as={Select}
          label="Country"
          required
          options={countries}
        />
        {isEuropeanCountry(countryCode) && (
          <Input
            name="vatNumber"
            label="VAT Number (Optional)"
            error={errors.vatNumber}
            inputRef={register({
              async validate(vatNumber) {
                return validateVATCode({vatNumber, countryCode}, authState);
              },
            })}
          />
        )}
      </FormRow>
      <Input name="name" label="Name/Company" error={errors.name} inputRef={register} />
      <FormRow>
        <Input
          name="address1"
          label="Address 1 (Street address,  P.O box, company name, c/o)"
          error={errors.address1}
          inputRef={register}
        />
        <Input
          name="address2"
          label="Address 2 (Apartment, suite, unit, building, floor, etc.)"
          error={errors.address2}
          inputRef={register}
        />
      </FormRow>
      <FormRow>
        <Input name="zipCode" label="Zip/Postal Code" error={errors.zipCode} inputRef={register} />
        <Input name="province" label="State/Province" error={errors.province} inputRef={register} />
        <Input name="city" label="City" error={errors.city} inputRef={register} />
      </FormRow>
      <FormSubmit>
        <Button
          type="submit"
          variant="filled"
          color="green"
          disabled={formState.isSubmitting}
          loading={formState.isSubmitting}
        >
          Next
        </Button>
      </FormSubmit>
    </form>
  );
}

BillingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.objectOf(PropTypes.string),
  countries: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

export default BillingForm;
