import PropTypes from 'prop-types';
import cx from 'clsx';

import FieldError from '../FieldError';
import Asterisk from '../../Asterisk';
import styles from './Select.module.scss';

function NativeSelect({
  className,
  options,
  placeholder,
  error,
  required,
  inputRef,
  label,
  full,
  disabled,
  small,
  ...props
}) {
  return (
    <div
      className={cx(styles.select, className, {
        [styles.full]: full,
        [styles.disabled]: disabled,
        [styles.small]: small,
      })}
    >
      <label className={styles.label}>
        <span className={styles.labelText}>
          {label}
          {required && <Asterisk />}
        </span>
        <select {...props} className={styles.el} disabled={disabled} ref={inputRef}>
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
      {error && <FieldError error={error} />}
    </div>
  );
}

NativeSelect.defaultProps = {
  full: false,
  label: '',
  disabled: false,
  error: undefined,
  placeholder: '',
  inputRef: undefined,
};

NativeSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      selected: PropTypes.bool,
    })
  ).isRequired,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]),
};

export default NativeSelect;
