import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {cx, css} from '@emotion/css';
import {ReactEditor, useFocused, useSelected, useSlate} from 'slate-react';
import {Editor, Range, Transforms} from 'slate';

const rgbToHex = (rgb) => {
  const a = rgb.replace(/[^\d,]/g, '').split(',');
  return '#' + ((1 << 24) + (+a[0] << 16) + (+a[1] << 8) + +a[2]).toString(16).slice(1);
};

const InlineChromiumBugfix = () => (
  <span
    suppressContentEditableWarning={false}
    className={css`
      font-size: 0;
    `}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

export const Button = React.forwardRef(({className, active, reversed, ...props}, ref) => (
  <span
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        cursor: pointer;
        box-shadow: ${active && !reversed
          ? `0px 0px 0px 1px #a0a0a0, inset 0px 10px 27px -8px #a0a0a0, inset 0px -10px 27px -8px #fff, 2px -16px 0px 16px rgb(0 0 0 / 0%)`
          : ''};
        color: ${reversed ? (active ? 'white' : '#aaa') : 'black'};
        &:hover {
          outline: 1px solid #a0a0a0;
        }
      `
    )}
  />
));

export const ColorButton = React.forwardRef(
  ({className, active, reversed, onChangeColor, value, ...props}, ref) => {
    let checkedValue = value || '#000000';
    if (checkedValue.charAt(0) === 'r') {
      checkedValue = rgbToHex(value);
    }
    return (
      <label style={{position: 'relative'}}>
        <span
          {...props}
          ref={ref}
          className={cx(
            className,
            css`
              cursor: pointer;
              box-shadow: ${active && !reversed
                ? `0px 0px 0px 1px #a0a0a0, inset 0px 10px 27px -8px #a0a0a0, inset 0px -10px 27px -8px #fff, 2px -16px 0px 16px rgb(0 0 0 / 0%)`
                : ''};
              color: ${reversed ? (active ? 'white' : '#aaa') : 'black'};
              &:hover {
                outline: 1px solid #a0a0a0;
              }
            `
          )}
        />
        <input
          type="color"
          style={{visibility: 'hidden', position: 'absolute', bottom: 270}}
          onChange={(e) => onChangeColor(e)}
          value={checkedValue}
        />
      </label>
    );
  }
);

export const EditorValue = React.forwardRef(({className, value, ...props}, ref) => {
  const textLines = value.document.nodes
    .map((node) => node.text)
    .toArray()
    .join('\n');
  return (
    <div
      ref={ref}
      {...props}
      className={cx(
        className,
        css`
          margin: 30px -20px 0;
        `
      )}
    >
      <div
        className={css`
          font-size: 14px;
          padding: 5px 20px;
          color: #404040;
          border-top: 2px solid #eeeeee;
          background: #f8f8f8;
        `}
      >
        Slate's value as text
      </div>
      <div
        className={css`
          color: #404040;
          font: 12px monospace;
          white-space: pre-wrap;
          padding: 10px 20px;
          div {
            margin: 0 0 0.5em;
          }
        `}
      >
        {textLines}
      </div>
    </div>
  );
});

export const Icon = React.forwardRef(({className, ...props}, ref) => (
  <span
    {...props}
    ref={ref}
    className={cx(
      'material-icons',
      className,
      css`
        font-size: 18px;
        vertical-align: text-bottom;
      `
    )}
  />
));

export const LinkComponent = ({attributes, children, element}) => {
  const selected = useSelected();

  return (
    <a
      {...attributes}
      href={element.url}
      className={
        selected
          ? css`
              box-shadow: 0 0 0 3px #ddd;
            `
          : ''
      }
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};

export const ImageComponent = ({attributes, children, element, deleteImageRequest}) => {
  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  return (
    <table {...attributes} style={element.style}>
      {children}
      <tr
        contentEditable={false}
        className={css`
          user-select: none;
        `}
      >
        <td>
          <div
            contentEditable={false}
            className={css`
              position: relative;
            `}
          >
            <img
              src={element.url}
              className={css`
                display: block;
                box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
              `}
              style={{
                maxWidth: '100%',
                maxHeight: '300px',
              }}
            />
            <Button
              active
              onClick={async () => {
                Transforms.removeNodes(editor, {at: path});
                await deleteImageRequest({objectKey: element.objectKey});
              }}
              className={css`
                display: ${selected && focused ? 'inline' : 'none'};
                position: absolute;
                top: 0.5em;
                left: 0.5em;
                background-color: white;
              `}
            >
              <Icon>delete</Icon>
            </Button>
          </div>
        </td>
      </tr>
    </table>
  );
};

export const SplitLayoutComponent = ({attributes, children, element}) => {
  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  return (
    <table {...attributes}>
      <tr
      // contentEditable={false}
      // className={css`
      //   trosition: relative;
      //   user-select: none;
      // `}
      >
        {/* {children} */}
        <td>11111</td>
        <td>222222</td>
      </tr>
    </table>
  );
};

export const Instruction = React.forwardRef(({className, ...props}, ref) => (
  <div
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        white-space: pre-wrap;
        margin: 0 -20px 10px;
        padding: 10px 20px;
        font-size: 14px;
        background: #f8f8e8;
      `
    )}
  />
));

export const Menu = React.forwardRef(({className, ...props}, ref) => (
  <div
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        & > * {
          display: inline-block;
        }
        & > * + * {
          margin-left: 15px;
        }
      `
    )}
  />
));

export const Portal = ({children}) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};

export const Toolbar = React.forwardRef(({className, ...props}, ref) => (
  <Menu
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        /* position: relative; */
        padding: 10px 10px;
        /* margin: 0 -20px; */
        /* border-bottom: 2px solid #eee; */
        /* margin-bottom: 20px; */
      `
    )}
  />
));

export const HoveringToolbar = ({children}) => {
  const ref = useRef();
  const editor = useSlate();
  const inFocus = useFocused();

  useEffect(() => {
    const el = ref.current;
    const {selection} = editor;

    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === 'linkButton',
    });

    if (selection?.anchor?.path[0] === 0 || (selection && match)) {
      if (el) el.removeAttribute('style');
      return;
    }

    if (!el) {
      return;
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    el.style.opacity = '1';
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
  });

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          padding: 8px 7px 6px;
          position: absolute;
          z-index: 1;
          top: -10000px;
          left: -10000px;
          margin-top: -6px;
          opacity: 0;
          background-color: #222;
          border-radius: 4px;
          transition: opacity 0.75s;
        `}
        onMouseDown={(e) => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault();
        }}
      >
        {children}
      </Menu>
    </Portal>
  );
};
