import PropTypes from 'prop-types';
import StripeElementsProvider from './StripeElementsProvider';
import {composeProviders} from '../utils/createDataContext';
import {Provider as AuthProvider} from '../domain/auth/AuthContext';
import {Provider as BoardingWizardProvider} from '../domain/BoardingWizard/context/WizardContext';
import {Provider as AlertsProvider} from '../domain/alerts/context/AlertContext';
import {Provider as FreePlanProvider} from '../domain/free-plan-info/context/FreePlanContext';
import {Provider as SubscriberFieldsProvider} from '../domain/settings/subscriber-fields/context/SubscriberFieldsContext';
import {Provider as CountriesProvider} from '../domain/settings/countries/context/CountriesContext';
import {Provider as SegmentsProvider} from '../domain/settings/segments/context/SegmentsContext';

function ContextProviders({children}) {
  const providers = [
    StripeElementsProvider,
    AuthProvider,
    AlertsProvider,
    BoardingWizardProvider,
    FreePlanProvider,
    SubscriberFieldsProvider,
    CountriesProvider,
    SegmentsProvider,
  ];

  return composeProviders(providers, children);
}

ContextProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextProviders;
