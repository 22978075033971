import {Helmet} from 'react-helmet';
import SentNewsletterContainer from './SentNewsletterContainer';

function SentNewsletterPage() {
  return (
    <>
      <Helmet title="Email Campaign" />
      <SentNewsletterContainer />
    </>
  );
}

export default SentNewsletterPage;
