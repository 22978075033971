import InfiniteScroll from 'react-infinite-scroller';
import styles from '../../Templatel.Select.module.scss';
import NewsletterTemplateCard from '../../../NewsletterTemplateCard';
import useTemplatesList from './useTemplatesList';
import EmptyTable from '../../../../../settings/subscriber-fields/components/fields-table/EmptyTable';
import Modal from '../../../../../../components/Modal/index';
import Button from '../../../../../../components/Button/index';

const TemplatesList = ({title, ...rest}) => {
  const {
    open,
    templates,
    page,
    hasMore,
    isDirty,
    loading,
    handleFetchMore,
    handleClick,
    toggleModal,
  } = useTemplatesList(rest);

  return (
    <>
      <InfiniteScroll
        pageStart={page}
        hasMore={hasMore}
        loadMore={handleFetchMore}
        initialLoad={false}
        useWindow={false}
        getScrollParent={() => document.querySelector('#root')}
      >
        <h6 className={styles.label}>{title}</h6>
        <div className={styles.block}>
          {templates.map((template, index) => (
            <NewsletterTemplateCard
              className={styles.card}
              key={`${template.id}_idx_${index}`}
              template={template}
              onClick={isDirty ? toggleModal(template) : handleClick(template)}
            />
          ))}
        </div>
        {!loading && !templates?.length && <EmptyTable>No templates were found.</EmptyTable>}
      </InfiniteScroll>
      <Modal
        isOpen={Boolean(open)}
        onRequestClose={toggleModal(null)}
        hideCloseButton
        title="Are you sure you want to switch to this
template?"
      >
        <p>Your content will be replaced with the defaults from this template.</p>
        <div>
          <Button variant="filled" color="green" onClick={handleClick(open)}>
            Replace my content
          </Button>
          <Button onClick={toggleModal(null)} variant="filled" color="grey">
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TemplatesList;
