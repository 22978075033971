import {useState, useEffect, useMemo, forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './ColorPicker.module.scss';
import {isValidHexColor} from './validate-hex-color';

const ColorPicker = forwardRef((props, ref) => {
  const {value, onChange, isTransparent = false, ...rest} = props;
  const [internalColor, setInternalColor] = useState(value);

  useEffect(() => {
    if (value) {
      setInternalColor(value);
    }
  }, [value]);

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setInternalColor(newColor);

    if (isValidHexColor(newColor)) {
      onChange?.(e);
    }
  };

  const handleBlur = (e) => {
    if (!isValidHexColor(internalColor)) {
      setInternalColor(value);
    }
    props.onBlur?.(e);
  };

  const valid = useMemo(() => isValidHexColor(value), [value]);

  return (
    <label
      className={clsx(styles.colorPicker, !isTransparent && !valid && styles.colorPickerInvalid)}
    >
      {!isTransparent && <span className={styles.colorMarker} style={{backgroundColor: value}} />}
      <input
        type="color"
        {...rest}
        onBlur={handleBlur}
        value={internalColor}
        onChange={handleColorChange}
        ref={ref}
        className={styles.colorInput}
        disabled={isTransparent}
      />

      <input
        className={styles.colorHexText}
        onChange={handleColorChange}
        onBlur={handleBlur}
        type="text"
        maxLength={7}
        value={internalColor}
        disabled={isTransparent}
        style={isTransparent ? {width: '100%', textAlign: 'center'} : {}}
      />
    </label>
  );
});

ColorPicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isTransparent: PropTypes.bool,
};

export default ColorPicker;
