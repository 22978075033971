import {useMemo} from 'react';

export const NO_VALUE = 'NO_VALUE';

const useGetTriggerValueOptions = (valueType, data) => {
  const {dataCustomFields, dataTags, formData} = data;

  return useMemo(() => {
    if (valueType === 'Field') {
      return [
        {label: 'Any Field', value: NO_VALUE},
        ...dataCustomFields.map((field) => ({label: field.name, value: field.id})),
      ];
    }

    if (valueType === 'Date') {
      return [
        {label: 'Any Date', value: NO_VALUE},
        ...dataCustomFields
          .filter((field) => field.dataType === 'DATE')
          ?.map((field) => ({label: field.name, value: field.id})),
      ];
    }

    if (valueType === 'Tag') {
      return [
        {label: 'Any Tag', value: NO_VALUE},
        ...dataTags?.map((tag) => ({label: tag.name, value: tag.id})),
      ];
    }

    if (valueType === 'Form') {
      return [
        {label: 'Any Form', value: NO_VALUE},
        ...formData?.map((form) => ({label: form.name, value: form.id})),
      ];
    }
  }, [dataCustomFields, dataTags, formData, valueType]);
};

export default useGetTriggerValueOptions;
