import {BaseEdge, EdgeLabelRenderer, getBezierPath} from 'reactflow';
import {useAutomation} from '../stores/automation';

export default function addActionEdge({
  id: _id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data: _data,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  // @TODO: fix this
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {setIsAddModalOpen} = useAutomation();

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <div>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: 30,
                border: '1px solid #AAADC0',
                color: 'black',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 20,
                backgroundColor: '#f7f8fc',
              }}
              onClick={() => setIsAddModalOpen(true)}
            >
              +
            </div>
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
