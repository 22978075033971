export const initialState = {
  fetchLoading: false,
  data: null,
};

export function setFetchLoadingReducer(state, action) {
  return {
    ...state,
    fetchLoading: action.payload,
  };
}

export function setDataReducer(state, action) {
  const data = action.payload;
  return {
    ...state,
    data,
  };
}
