import {Link} from 'react-router-dom';
import {Controller} from 'react-hook-form';
import get from 'lodash.get';
import styles from '../SubscriberPage.module.scss';
import {chunk} from '../../../utils/arrays';
import FormRow from '../../../components/forms/FormRow/FormRow';
import ConstrainedValueField from './custom-fields-inputs/ConstrainedValueField';
import ROUTES from '../../../Router/routes';
import Input from '../../../components/forms/Input';
import {REQUIRED_FIELD_MESSAGE} from '../../../components/forms/constants';
import DateFieldInput from '../../../components/forms/DateFieldInput/DateFieldInput';
import TextArea from '../../../components/forms/Textarea';

const FIELDS_IN_A_ROW = 2;

function FormCustomFields({errors, register, control, fields, ...props}) {
  return (
    <>
      {fields.length === 0 && (
        <p className={styles.emptyText}>
          You don&apos;t have any data yet. To add them, go{' '}
          <Link to={ROUTES.settings.subscriberFields}>here</Link>.
        </p>
      )}
      {fields.length > 0 &&
        chunk(fields, FIELDS_IN_A_ROW).map((fieldsDataNoTagsChunk, chunkIndex) => (
          <FormRow key={chunkIndex}>
            {fieldsDataNoTagsChunk.map((property) => {
              const fieldPath = `values.${property.id}`;
              if (property.optionValues?.length) {
                return (
                  <ConstrainedValueField
                    optionValues={property.optionValues}
                    key={fieldPath}
                    error={get(errors, fieldPath)}
                    name={fieldPath}
                    control={control}
                    label={property.name}
                    inputRef={register({
                      required: property.isMandatory ? REQUIRED_FIELD_MESSAGE : false,
                    })}
                    {...props}
                  />
                );
              }

              let fieldType = property.name === 'Email' ? 'email' : 'text';

              if (property.dataType === 'TEXT') {
                fieldType = property.name === 'Email' ? 'email' : 'text';
              } else if (property.dataType === 'NUMBER') {
                fieldType = 'number';
              } else if (property.dataType === 'TEXTAREA') {
                fieldType = 'textarea';
                return (
                  <Controller
                    key={fieldPath}
                    type={fieldType}
                    error={get(errors, fieldPath)}
                    name={fieldPath}
                    label={property.name}
                    control={control}
                    inputStyle={{height: 55}}
                    inputRef={register({
                      required: property.isMandatory ? REQUIRED_FIELD_MESSAGE : false,
                    })}
                    as={<TextArea />}
                    {...props}
                  />
                );
              } else if (property.dataType === 'DATE') {
                fieldType = 'date';
                return (
                  <Controller
                    key={fieldPath}
                    type={fieldType}
                    error={get(errors, fieldPath)}
                    name={fieldPath}
                    label={property.name}
                    control={control}
                    inputStyle={{height: 55}}
                    inputRef={register({
                      required: property.isMandatory ? REQUIRED_FIELD_MESSAGE : false,
                    })}
                    as={<DateFieldInput />}
                    {...props}
                  />
                );
              }

              return (
                <Input
                  key={fieldPath}
                  type={fieldType}
                  error={get(errors, fieldPath)}
                  name={fieldPath}
                  label={property.name}
                  inputRef={register({
                    required: property.isMandatory ? REQUIRED_FIELD_MESSAGE : false,
                  })}
                  noLabelMarginTop
                />
              );
            })}
          </FormRow>
        ))}
    </>
  );
}

export default FormCustomFields;
