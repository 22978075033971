import {NETWORK_LATENCY} from '../constants';
import {CONTENT_TYPE_JSON, FILE_CONTENT_TYPES} from './constants';
import {parseBlobResponse, parseJSONResponse} from './responseHandlers';

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isContentTypeFile(contentType) {
  return !!FILE_CONTENT_TYPES.some(function (file) {
    return file.includes(contentType);
  });
}

export function isContentTypeJSON(contentType) {
  return contentType?.includes(CONTENT_TYPE_JSON);
}

export async function handleNetworkLatency() {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_ADD_NETWORK_LATENCY === 'true'
  ) {
    await delay(NETWORK_LATENCY);
  }
}

export async function parseErrorResponse(response) {
  const contentType = response.headers.get('content-type');
  if (isContentTypeJSON(contentType)) {
    return response.json();
  }

  return response.text();
}

export async function parseSuccessfulResponse(response) {
  const contentType = response.headers.get('content-type');
  if (isContentTypeJSON(contentType)) {
    return parseJSONResponse(response);
  }

  if (isContentTypeFile(contentType)) {
    return parseBlobResponse(response);
  }

  return response.text();
}

export function makeFetchURL(endpoint) {
  if (endpoint.includes('http')) {
    return endpoint;
  }

  return `${process.env.REACT_APP_BACKEND_API}${endpoint}`;
}

export function formatRequestBody(options) {
  if (options.body && typeof options.body === 'object') {
    // eslint-disable-next-line no-param-reassign
    options.body = JSON.stringify(options.body);
  }
}
