import {
  SelectRenderer,
  TextRenderer,
  OptionValuesRenderer,
} from './components/fields-form/renderers';
import {SelectField, TextField, OptionValuesField} from './components/fields-form/editors';

const customFieldsBaseUrl = '/field-type';
const tagsBaseUrl = '/tag-type';

export const ENDPOINTS = {
  fetchSubscriberFields: customFieldsBaseUrl,
  fetchFieldMatchers: `${customFieldsBaseUrl}/matchers`,
  deleteSubscriberField: (id) => `${customFieldsBaseUrl}/${id}`,

  fetchTags: tagsBaseUrl,
  deleteTag: (id) => `${tagsBaseUrl}/${id}`,
};

export const TYPE_FIELD_OPTIONS = [
  {value: 'TEXT', label: 'Text'},
  {value: 'TEXTAREA', label: 'Textarea'},
  {value: 'EMAIL', label: 'Email'},
  {value: 'NUMBER', label: 'Number'},
  {value: 'DATE', label: 'Date'},
  {value: 'RADIO', label: 'Radio'},
  {value: 'CHECKBOX', label: 'Checkbox'},
  {value: 'DROPDOWN', label: 'Dropdown'},
];

export const FIELDS_COLUMNS = [
  {name: 'name', label: 'Field Name', renderer: TextRenderer, editor: TextField},
  {name: 'personalizationTagName', label: 'Personalization Tag Name', renderer: TextRenderer},
  {
    name: 'dataType',
    label: 'Type',
    renderer: SelectRenderer,
    editor: SelectField,
    options: TYPE_FIELD_OPTIONS,
  },
  {
    name: 'optionValues',
    label: 'Options',
    renderer: OptionValuesRenderer,
    editor: OptionValuesField,
  },
];

export const TAGS_COLUMNS = [
  {name: 'name', label: 'Tag Name', renderer: TextRenderer, editor: TextField},
];
