import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Button from '../../../../components/Button';
import styles from './FormVariantPreview.module.scss';
import ROUTES from '../../../../Router/routes';

const FormVariantPreview = ({title, imageSrc, imageAlt, type, formData}) => {
  const path = formData
    ? `${ROUTES.signupForms.index}/${formData.id}/edit`
    : `${ROUTES.signupForms.index}/${type}/new`;

  return (
    <div className={styles.variantWrapper}>
      <div className={styles.variantBlock}>
        <h4 className={styles.variantHeader}>{title}</h4>
        <div className={styles.variantImageWrapper}>
          <img className={styles.variantImage} src={imageSrc} alt={imageAlt} />
        </div>
      </div>
      <Button to={path} as={Link} full color="green" variant={formData ? 'outlined' : 'filled'}>
        {formData ? 'Edit' : 'Create'} Form
      </Button>
    </div>
  );
};

FormVariantPreview.propTypes = {
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['slider', 'inline', 'popup']),
  formData: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default FormVariantPreview;
