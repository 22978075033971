import PropTypes from 'prop-types';
import NewsletterReport from './NewsletterReport';
import useFetch from '../../../hooks/useFetch';
import {fetchNewsletterReportService} from '../service';

function NewsletterReportContainer({id}) {
  const {data = {}, loading} = useFetch(fetchNewsletterReportService, {
    immediate: true,
    data: id,
  });

  return <NewsletterReport data={data} loading={loading} />;
}

NewsletterReportContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default NewsletterReportContainer;
