import TagManager from 'react-gtm-module';
import useRouter from './useRouter';

function usePageTracking() {
  const {location} = useRouter();

  const changeTagManager = (state) => {
    if (process.env.NODE_ENV === 'production' && state.title) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pagePath: location.pathname + location.search,
          pageTitle: state.title,
        },
      });
    }
  };

  return {changeTagManager};
}

export default usePageTracking;
