import {useEffect} from 'react';
import useRouter from './useRouter';
import useAlert from '../domain/alerts/useAlert';

function useAlertOnRedirect() {
  const {showAlert} = useAlert();
  const {location, history} = useRouter();

  useEffect(
    function () {
      const alert = location.state?.alert;
      if (alert) {
        showAlert(alert.message, alert.options);
        history.replace({...location, state: undefined});
      }
    },
    [location, showAlert, history]
  );
}

export default useAlertOnRedirect;
