import PropTypes from 'prop-types';

import EmailModal from '../components/EmailModal';
import EmailConfirmationModalContent from './EmailConfirmationModalContent';

function EmailConfirmationModal({isOpen, onRequestClose}) {
  return (
    <EmailModal onRequestClose={onRequestClose} isOpen={isOpen}>
      <EmailConfirmationModalContent />
    </EmailModal>
  );
}

EmailConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default EmailConfirmationModal;
