import {forwardRef} from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import {ListboxInput, ListboxButton, ListboxPopover, ListboxList} from '@reach/listbox';
import '@reach/listbox/styles.css';

import styles from './Listbox.module.scss';

const Listbox = forwardRef(({children, className, ...props}, ref) => (
  <>
    <ListboxInput {...props} className={cx(styles.main, className)} ref={ref}>
      <ListboxButton arrow={<span className={styles.arr} />} />
      <ListboxPopover className={styles.options}>
        <ListboxList>{children}</ListboxList>
      </ListboxPopover>
    </ListboxInput>
  </>
));

Listbox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Listbox;
