import TagsSelectField from './components/fields/TagsSelectField';
import SegmentsSelectField from './components/fields/SegmentsSelectField';
import TextField from './components/fields/TextField';
import VariantField from './components/fields/VariantField';
import {TextRenderer} from '../subscriber-fields/components/fields-form/renderers';

const segmentsBaseUrl = '/subscriber/segment';

export const ENDPOINTS = {
  fetchSegments: segmentsBaseUrl,
  deleteSegment: (id) => `${segmentsBaseUrl}/${id}`,
};

export const DEBOUNCE_TIMEOUT = 420;

export const TABLE_COLUMNS = [
  {name: 'name', label: 'Segment Name', renderer: TextRenderer, editor: TextField},
];

export const FILTER_OPERATIONS = {
  textEqualsTo: {
    key: 'equalsTo',
    field: ({...props}) => <VariantField {...props} />,
  },
  textContains: {
    key: 'contains',
    field: ({...props}) => <TextField {...props} />,
  },
  equalsToSelect: {
    key: 'equalsTo',
    field: () => 'equalsToSelect',
  },
  tagsContains: {
    key: 'contains',
    field: ({...props}) => <TagsSelectField {...props} />,
  },
  tagsNotContains: {
    key: 'not contains',
    field: ({...props}) => <TagsSelectField {...props} />,
  },
  segmentContains: {
    key: 'contains',
    field: ({...props}) => <SegmentsSelectField {...props} />,
  },
};

export const getFieldByType = (props) => {
  const {type, ...rest} = props;
  if (!type) {
    return null;
  }
  switch (type) {
    case 'attributeId':
      return <VariantField {...rest} />;
    case 'tags':
      return <TagsSelectField {...rest} />;
    case 'segmentId':
      return <SegmentsSelectField {...rest} />;
    default:
      return <TextField {...rest} />;
  }
};

export const FILTER_TYPES = [
  {
    value: 'attributeId',
    label: 'Subscriber Field',
    isCustomField: true,
    operations: [
      {
        value: 'textEqualsTo',
        label: 'equals to',
      },
    ],
  },
  {
    value: 'tags',
    label: 'Tags',
    isTag: true,
    operations: [
      {
        value: 'tagsContains',
        label: 'contains',
      },
    ],
  },
  {
    value: 'segmentId',
    label: 'Segment',
    isSegment: true,
    operations: [
      {
        value: 'segmentContains',
        label: 'matches',
      },
    ],
  },
];
