import RadioIcon from '../../../../img/radio-icon.png';
import RadioIconSelected from '../../../../img/radio-icon-selected.png';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';
import styles from './Toolbar.module.scss';

export default ({options, paramPath, handler, ...props}) => {
  const [value, handleChange] = handler ? handler(paramPath) : useHandleConfigParam(paramPath);

  return (
    <div className={styles.radiosWrapper}>
      {options.map((option) => (
        <div
          className={styles.radio}
          onClick={() => {
            handleChange(option);
          }}
          key={option}
        >
          <img
            src={option === value ? RadioIconSelected : RadioIcon}
            className={styles.radioImage}
            alt={`Select ${option}`}
          />
          <div className={styles.radioText}>{option}</div>
        </div>
      ))}
    </div>
  );
};
