import {useCallback, useState} from 'react';
import useRouter from '../../../../hooks/useRouter';
import {categoryOptions} from '../constants';

const useTemplateSelect = () => {
  const {history, location} = useRouter();
  const [search, setSearch] = useState('');

  const [categories, setCategories] = useState(
    location?.state?.data?.categories ?? categoryOptions.map(({value}) => value)
  );

  const handelSearch = useCallback((value) => {
    setSearch(value ?? null);
  }, []);

  const handelClickCategory = useCallback(
    (category) => () => {
      if (location?.state?.data?.hideChangeCategory) {
        return;
      }
      setCategories((prev) => {
        if (prev?.length === 1 && prev.includes(category)) {
          return prev;
        }

        return prev.includes(category)
          ? prev.filter((item) => item !== category)
          : [...prev, category];
      });
    },
    [location?.state?.data?.hideChangeCategory]
  );

  const isHideButton = useCallback(
    (value) => {
      return location?.state?.data?.hideChangeCategory && !categories.includes(value);
    },
    [categories, location?.state?.data?.hideChangeCategory]
  );

  return {
    categoryOptions,
    categories,
    search,
    location,
    history,
    isHideButton,
    handelClickCategory,
    handelSearch,
  };
};

export default useTemplateSelect;
