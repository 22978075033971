import {createContext, useEffect, useState, useContext, useCallback} from 'react';

import useFetch from '../../../hooks/useFetch';

import useSubscriberFieldsFetcher from '../../settings/subscriber-fields/hooks/useSubscriberFieldsFetcher';
import {loadAllActiveSignupForms} from '../../subscription-form/service';
import {fetchTransactionalEmailsService} from '../../settings/transactional-emails/service';

import {
  fetchAutomationOverviewService,
  fetchAutomationToolsetService,
  updateActiveStatusService,
} from '../service';

import {useFetchAutomation} from '../hooks/queries';

const AutomationContext = createContext();

/**
 * This Provider contains data related to all automations (for a component with automation list) and a selected automation.
 * You can see here the data like transactionalEmails, dataCustomFields (a.k.a. subscriber fields), tags, automationOverviewData (a.k.a. automation list),
 * formData (forms which are triggers) which are loaded for pages related to automations.
 * Besides these data which are common for all automations it also has states (mostly state and its setter) for specic automation:
 * automationData (automation flow config), automationReport, stepReportData
 * All logic for that manipulates these data is located in Automation component.
 */
// TODO: possible solution to reduce compexity of Automation component and this provider is to split
// the data and logic related to single automation into the different hook with context.
export const AutomationProvider = ({children}) => {
  const [transactionalEmails, setTransactionalEmails] = useState();

  const {run: automationOverviewDataRequest} = useFetch(fetchAutomationOverviewService, {
    immediate: true,
  });
  const {
    data: automationToolsetData,
    loading: toolsetIsLoading,
  } = useFetch(fetchAutomationToolsetService, {immediate: true});
  const {
    dataCustomFields,
    dataTags,
    loading: subscriberFieldsAreLoading,
  } = useSubscriberFieldsFetcher({
    immediate: true,
  });
  const {run: transactionalEmailsRequest, loading: emailsLoading} = useFetch(
    fetchTransactionalEmailsService
  );
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [shouldManuallyGetLayout, setShouldManuallyGetLayout] = useState(false);
  const [stepReportData, setStepReportData] = useState(null);
  const [automationReport, setAutomationReport] = useState(null);
  const [automationData, setAutomationData] = useState();
  const [automationOverviewData, setAutomationOverviewData] = useState();
  const [validationErrors, setValidationErrors] = useState();
  const [isConfigUpdate, setIsConfigUpdate] = useState(false);
  const [showUpdateWarning, setShowUpdateWarning] = useState(false);
  const [initialAutomationData, setInitialAutomationData] = useState();

  const {data: formData, loading: signupFormsAreLoading} = useFetch(loadAllActiveSignupForms, {
    immediate: true,
  });

  const {run: fetchAutomationConfig, data: fetchAutomationConfigData} = useFetchAutomation();
  const {run: updateAutomationStatus} = useFetch(updateActiveStatusService);

  useEffect(() => {
    setInitialAutomationData(fetchAutomationConfigData?.config);
  }, [fetchAutomationConfigData]);

  const fetchAutomationOverview = async () => {
    try {
      const response = await automationOverviewDataRequest();

      setAutomationOverviewData(response);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchTransactionalEmails = async () => {
      try {
        const response = await transactionalEmailsRequest({limit: 250});

        setTransactionalEmails(response?.data);
      } catch (error) {
        throw new Error(error);
      }
    };
    fetchTransactionalEmails();
    fetchAutomationOverview();

    // @TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initAutomationData = (automationConfig) => {
    setAutomationData(automationConfig);
    setInitialAutomationData(automationConfig);
  };

  const resetAutomationState = useCallback(() => {
    setAutomationData();
    setInitialAutomationData();
    setValidationErrors();
  }, []);

  const providerValue = {
    toolsetData: automationToolsetData, // actions, types / fetched on mount
    dataCustomFields, // subscribers sustom fields / fetched on mount
    dataTags, // tags / fetched on mount
    fetchAutomationConfig, // api call to get automation
    automationOverviewData, // automations list / fetched on mount
    formData, // forms (triggers)
    isAddModalOpen,
    setIsAddModalOpen,
    shouldManuallyGetLayout,
    setShouldManuallyGetLayout,
    fieldsLoading:
      subscriberFieldsAreLoading || emailsLoading || signupFormsAreLoading || toolsetIsLoading,
    stepReportData, // setStepReportData from automation data recived by fetchAutomationConfig call
    setStepReportData,
    automationReport, // automation status and report from data recived by fetchAutomationConfig call
    setAutomationReport,
    transactionalEmails, // fethced on mount
    updateAutomationStatus,
    automationData, // automation config from data recived by fetchAutomationConfig call
    setAutomationData,
    fetchAutomationOverview,
    validationErrors,
    setValidationErrors,
    isConfigUpdate,
    setIsConfigUpdate,
    showUpdateWarning,
    setShowUpdateWarning,
    resetAutomationState,
    initialAutomationData,
    setInitialAutomationData,
    initAutomationData,
  };

  return <AutomationContext.Provider value={providerValue}>{children}</AutomationContext.Provider>;
};

export const useAutomation = () => {
  const context = useContext(AutomationContext);

  if (context === undefined) {
    throw new Error('AutomationContext must be used within an AutomationProvider');
  }

  return context;
};

export default AutomationContext;
