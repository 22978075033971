import PropTypes from 'prop-types';
import styles from './FormButtons.module.scss';

function FormButtons({children}) {
  return <div className={styles.btns}>{children}</div>;
}

FormButtons.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormButtons;
