import styles from './StatsSummary.module.scss';
import {fetchConfirmedSubscribersService} from '../service';
import useFetch from '../../../hooks/useFetch';

function StatsSummaryShort() {
  const {data, loading} = useFetch(fetchConfirmedSubscribersService, {
    immediate: true,
  });

  if (loading || !data) {
    return null;
  }

  return (
    <aside className={styles.short}>
      Confirmed Subscriber Total: <span className={styles.shortNumber}>{data.formattedCount}</span>
    </aside>
  );
}

export default StatsSummaryShort;
