import {handleErrorAlert} from 'utils/handleErrorAlert';
import useAlert from 'domain/alerts/useAlert';
import {useAutomation} from '../stores/automation';
import {validationErrorsToString} from '../utils/validationErrors';

export const useAutomationErrorHandler = () => {
  const {showAlert} = useAlert();
  const {setValidationErrors} = useAutomation();

  return (error) => {
    if (error.response?.validationErrors) {
      setValidationErrors(error.response?.validationErrors);
    } else {
      handleErrorAlert(error, showAlert);
    }
  };
};

export const useAutomationRevisionErrorHandler = () => {
  const {showAlert} = useAlert();

  return (error) => {
    if (error.response?.validationErrors) {
      const errorMsg = {
        message: validationErrorsToString(error.response?.validationErrors),
      };
      handleErrorAlert(errorMsg, showAlert);
    } else {
      handleErrorAlert(error, showAlert);
    }
  };
};
