import {get, post} from '../../../utils/api';
import {ENDPOINTS, AUTH0_CONNECTION} from '../constants';
import {GENERIC_ERROR_MESSAGE} from '../../../utils/constants';

async function signup(apiSettings, values) {
  const data = {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    email: values.email,
    password: values.password,
    connection: AUTH0_CONNECTION,
    name: values.email,
    user_metadata: {
      affiliate_code: values.aid,
      entry_page: values.ep,
    },
  };

  return post(ENDPOINTS.signup, data, apiSettings).catch(function (error) {
    if (error.response?.description) {
      throw new Error(error.response?.description);
    }
    throw new Error(GENERIC_ERROR_MESSAGE);
  });
}

export async function signupRequestCount(apiSettings, data) {
  return get(ENDPOINTS.signupRequest, data, apiSettings);
}

export async function confirmAccountEmail(apiSettings, data) {
  return post(ENDPOINTS.confirmAccountRequest(data.confirmationId), null, apiSettings);
}

export default signup;
