import {useEffect, useMemo, useState} from 'react';
import {Menu, MenuButton, MenuPopover, MenuItem} from '@reach/menu-button';
import {positionRight} from '@reach/popover';
import cx from 'clsx';
import styles from '../../../subscribers/components/IntervalPicker.module.scss';
import stylesDropdown from '../../../../components/ButtonDropdown/ButtonDropdown.module.scss';

function FormSelector({classes, handleChange, selectedForm, allForms}) {
  const [form, setForm] = useState(selectedForm);

  useMemo(() => {
    handleChange(form);
  }, [form]);

  return (
    <Menu>
      <MenuButton className={cx(styles.main, classes)}>
        <span className={styles.label}>Selected Form:</span>
        <span>{form}</span>
        <span className={stylesDropdown.arr} aria-hidden />
      </MenuButton>
      <MenuPopover className={cx(stylesDropdown.options, styles.options)} position={positionRight}>
        {allForms.map((val) => (
          <MenuItem key={val} className={stylesDropdown.option} onSelect={() => setForm(val)}>
            {val}
          </MenuItem>
        ))}
      </MenuPopover>
    </Menu>
  );
}

export default FormSelector;
