import {BUILDER_MAX_FONT_SIZE, BUILDER_MIN_FONT_SIZE, FONT_WEIGHT_OPTIONS} from '../../constants';

import SubscriptionColorField from './SubscriptionColorField';
import SelectField from './SelectField';
import SizeField from './SizeField';
import TextAlignmentsField from './TextAlignmentsField';
import ToolbarSection from './ToolbarSection';
import styles from './Toolbar.module.scss';

const FontOptionsSection = ({pathPrefix, sectionTitle}) => {
  return (
    <ToolbarSection title={sectionTitle || 'Font Styles'} className={styles.toolbarContent}>
      <SizeField
        label="Font Size"
        paramPath={`${pathPrefix}.font.fontSize`}
        min={BUILDER_MIN_FONT_SIZE}
        max={BUILDER_MAX_FONT_SIZE}
      />
      <SubscriptionColorField label="Text Color" paramPath={`${pathPrefix}.font.color`} />
      <SelectField
        label="Font Weight"
        paramPath={`${pathPrefix}.font.fontWeight`}
        options={FONT_WEIGHT_OPTIONS}
        isClearable
      />
      <TextAlignmentsField label="Text Alignment" paramPath={`${pathPrefix}.textAlign`} />
    </ToolbarSection>
  );
};

export default FontOptionsSection;
