const AlignLeftIcon = (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="1" height="13" fill="currentColor" />
    <rect x="12" y="3" width="2" height="9" transform="rotate(90 12 3)" fill="currentColor" />
    <rect x="8" y="8" width="2" height="5" transform="rotate(90 8 8)" fill="currentColor" />
  </svg>
);

export default AlignLeftIcon;
