import {removeByIds} from '../../../utils/arrays';
import {findEdgesToNode, findEdgesFromNode, createEdge, removeEdgeByHandleId} from './edges';

// accepts nodes with edges and remove the specified node with reconnection parent to child
// it should be used only for nodes which have 1 input and 1 output
const removeSingleNodeWithReconnection = ({nodes, edges}, nodeIdToRemove) => {
  const incomingEdge = findEdgesToNode(edges, nodeIdToRemove)[0];
  const outgoingEdge = findEdgesFromNode(edges, nodeIdToRemove)[0];
  const parentToChildEdge = outgoingEdge
    ? createEdge(incomingEdge.source, outgoingEdge.target)
    : null;
  return {
    edges: removeByIds(edges, [incomingEdge.id, outgoingEdge?.id]).concat(parentToChildEdge || []),
    nodes: removeByIds(nodes, [nodeIdToRemove]),
  };
};

const removeMultipleNodesWithReconnection = (graph, nodeIdsToRemove) => {
  return nodeIdsToRemove.reduce(
    (updatedGraph, currNodeId) => removeSingleNodeWithReconnection(updatedGraph, currNodeId),
    graph
  );
};

// Helper function to get all nodes in the subtree rooted at a given node
function getSubtreeNodeIds(edges, rootNodeId) {
  const subtreeNodes = [rootNodeId];
  const outgoingEdges = findEdgesFromNode(edges, rootNodeId);
  outgoingEdges.forEach((edge) => {
    subtreeNodes.push(...getSubtreeNodeIds(edges, edge.target));
  });
  return subtreeNodes;
}

export const removeNode = (nodes, edges, nodeToRemove) => {
  let updatedNodes = [...nodes];
  let updatedEdges = [...edges];

  const outgoingEdges = findEdgesFromNode(edges, nodeToRemove.id);

  // if node has multiple direct child nodes then remove it with incoming edge and subtree entirely
  // else remove it with incoming and outgoing edges, child "add step" node (if it exists)
  // and insert new edge to connect ancestor with descendant nodes (if there is a descendant node)
  if (outgoingEdges.length > 1) {
    const subtreeNodes = getSubtreeNodeIds(updatedEdges, nodeToRemove.id);
    subtreeNodes.forEach((subtreeNodeId) => {
      updatedNodes = removeByIds(updatedNodes, [subtreeNodeId]);
      updatedEdges = removeEdgeByHandleId(updatedEdges, subtreeNodeId);
    });
  } else {
    const outgoingEdge = outgoingEdges[0];
    const nodesToRemove = [nodeToRemove.id, outgoingEdge?.target].filter(Boolean);
    const updatedGraph = removeMultipleNodesWithReconnection({nodes, edges}, nodesToRemove);
    updatedNodes = updatedGraph.nodes;
    updatedEdges = updatedGraph.edges;
  }

  return {
    nodes: updatedNodes,
    edges: updatedEdges,
  };
};

export const flowIsEditable = (status) => !['ARCHIVED', 'DISCONNECTING'].includes(status);

export const stepIsFinal = (stepType) => ['STOP_AUTOMATION', 'UNSUBSCRIBE'].includes(stepType);

export const automationStatus = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  DISCONNECTING: 'DISCONNECTING',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
};

export const automationStatusToColor = {
  [automationStatus.ACTIVE]: '#0ed2af',
  [automationStatus.ARCHIVED]: '#C46E54',
  [automationStatus.DISCONNECTING]: '#05CAE5',
  [automationStatus.DRAFT]: 'grey',
  [automationStatus.PAUSED]: '#FF9A53',
};
