import fetchRetryBuilder from 'fetch-retry';

const TIMEOUT = 1000;
const RETRIES = 2;

const fetchRetry = fetchRetryBuilder(fetch, {
  retries: RETRIES,
  retryDelay(attempt) {
    return RETRIES ** attempt * TIMEOUT;
  },
});

export default fetchRetry;
