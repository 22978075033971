import PropTypes from 'prop-types';
import TextAlignmentInput from '../TextAlignmentInput';
import clsx from 'clsx';
import styles from './Toolbar.module.scss';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';

const TextAlignmentsField = ({label, paramPath, handler}) => {
  const [value, handleChange] = handler ? handler(paramPath) : useHandleConfigParam(paramPath);

  return (
    <div className={clsx(styles.toolbarFieldContainer)}>
      <div className={styles.toolbarFieldLabelContainer}>
        <span className={styles.toolbarFieldLabel}>{label}</span>
      </div>
      <TextAlignmentInput
        className={styles.toolbarFieldInputContainer}
        value={value}
        onChange={handleChange}
        paramPath={paramPath}
      />
    </div>
  );
};

TextAlignmentsField.propTypes = {
  label: PropTypes.string.isRequired,
  paramPath: PropTypes.string.isRequired,
};

export default TextAlignmentsField;
