import PropTypes from 'prop-types';

import HighlightedText from '../../../components/HighlightedText';

function SuccessResendText({email}) {
  return (
    <HighlightedText>
      We have sent a confirmation email to your <b>{email}</b> inbox. Please click on the button in
      the email to confirm your email address.
    </HighlightedText>
  );
}

SuccessResendText.propTypes = {
  email: PropTypes.string,
};

export default SuccessResendText;
