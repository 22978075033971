import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './Plans.module.scss';
import {subscriptionPlanPropType} from '../../utils/prop-types';

const visiblePlanEndIndex = 2;
const corporatePlansStartIndex = 1;

function Plans({plans, small, children}) {
  const visiblePlans = plans.slice(0, visiblePlanEndIndex);
  const corporatePlans = plans.slice(corporatePlansStartIndex);
  return (
    <section className={cx(styles.plans, {[styles.small]: small})}>
      {visiblePlans.map((plan, i) => children(plan, i, corporatePlans))}
    </section>
  );
}

Plans.propTypes = {
  children: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(subscriptionPlanPropType).isRequired,
  small: PropTypes.bool,
};

export default Plans;
