import {useEffect} from 'react';
import useRouter from '../../hooks/useRouter';
import useAlert from '../../domain/alerts/useAlert';

function ScrollToTop() {
  const {pathname} = useRouter();
  const {state: stateAlert} = useAlert();

  const getRootElement = () => document.getElementById('root');

  useEffect(() => {
    getRootElement().scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (stateAlert.length > 0) {
      getRootElement().scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  }, [stateAlert]);

  return null;
}

export default ScrollToTop;
