import {del, get, put, post} from '../../../utils/api';
import {ENDPOINTS} from './constants';

export async function createSubscriptionService(apiSettings, data) {
  return post(ENDPOINTS.createSubscription, data, apiSettings);
}

export async function cancelSubscriptionService(apiSettings, data) {
  return del(ENDPOINTS.cancelSubscription, data, apiSettings);
}

export async function fetchActiveSubscriptionService(apiSettings) {
  return get(ENDPOINTS.fetchSubscription, null, apiSettings);
}

export async function fetchChangeOrderPreviewService(apiSettings, data) {
  return get(ENDPOINTS.changePreview(data), null, apiSettings);
}

export async function renewSubscriptionService(apiSettings) {
  return put(ENDPOINTS.renew, null, apiSettings);
}
