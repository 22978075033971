export const LANDING_URL = process.env.REACT_APP_LANDING_URL || 'https://maildroppa.com';
export const REACT_APP_FORM_URL = process.env.REACT_APP_FORM_URL || 'https://form.maildroppa.com';
export const NETWORK_LATENCY = 1000;
export const API_REQUEST_TIMEOUT = 600000;
export const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export const europeanCountryCodes = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'EL',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'DK',
  'EE',
  'FI',
  'GR',
];

export const subscriberStatuses = [
  {value: 'CONFIRMED', label: 'CONFIRMED'},
  {value: 'UNSUBSCRIBED', label: 'UNSUBSCRIBED'},
  {value: 'BLOCKED', label: 'BLOCKED'},
];
