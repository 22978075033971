import PropTypes from 'prop-types';
import styles from './Sidebar.module.scss';
import SidebarToggler from './SidebarToggle';

const Sidebar = ({children, isOpened, toggleSidebar}) => {
  return (
    <div className={styles.sidebar}>
      <SidebarToggler className={styles.toggle} isOpened={isOpened} onToggle={toggleSidebar} />
      {children}
    </div>
  );
};

Sidebar.propTypes = {
  isOpened: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

const Body = ({children}) => {
  return <div className={styles.body}>{children}</div>;
};

Sidebar.Body = Body;

const Footer = ({children}) => {
  return <div className={styles.footer}>{children}</div>;
};

Sidebar.Footer = Footer;

const Section = ({children, title}) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>{title}</div>
      <div className={styles.sectionBody}>{children}</div>
    </div>
  );
};

Sidebar.Section = Section;

export default Sidebar;
