import cx from 'clsx';
import PropTypes from 'prop-types';
import styles from './AutomationLayout.module.scss';

const AutomationLayout = ({main, sidebar, sidebarIsOpened}) => {
  return (
    <div className={styles.layout}>
      <div className={cx([styles.main, {[styles.full]: !sidebarIsOpened}])}>{main}</div>
      {sidebar && <div className={styles.sidebar}>{sidebar}</div>}
    </div>
  );
};

AutomationLayout.propTypes = {
  main: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
  sidebarIsOpened: PropTypes.bool.isRequired,
};

export default AutomationLayout;
