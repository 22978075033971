import {Cell, Legend, Pie, PieChart} from 'recharts';

import styles from './BouncesChart.module.scss';

const options = {
  hardBounceCount: {
    name: 'Hard Bounces',
    color: '#F26B86',
  },
  softBounceCount: {
    name: 'Soft Bounces',
    color: '#CE8AFB',
  },
  blockBounceCount: {
    name: 'Block Bounces',
    color: '#6F63F9',
  },
  outOfBandBounceCount: {
    name: 'Out Of Band Bounces',
    color: '#FF9A53',
  },

  undeterminedBounceCount: {
    name: 'Undetermined Bounces',
    color: '#109CF1',
  },
  adminBounceCount: {
    name: 'Admin Bounces',
    color: '#0ED2AF',
  },
};

function generateChartData(data) {
  return Object.entries(data).reduce((acc, [key, value]) => {
    const numericValue = Number(value);
    if (/^(?!total).*Count$/.test(key) && numericValue !== 0) {
      return [...acc, {...options[key], value: numericValue}];
    }

    return acc;
  }, []);
}

const renderLegend = (props) => {
  const {payload} = props;

  return (
    <ul className={styles.legend}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className={styles.legendItem}>
          <svg width="14" height="14" viewBox="0 0 32 32" className={styles.legendBullet}>
            <path
              fill={entry.color}
              transform="translate(16, 16)"
              d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
            />
          </svg>
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

function BouncesChart({data}) {
  const chartData = generateChartData(data);

  if (!chartData.length) {
    return null;
  }

  return (
    <PieChart width={430} height={380}>
      <Pie data={chartData} labelLine={false} outerRadius={115} dataKey="value">
        {chartData.map((entry, index) => (
          <Cell key={index} fill={entry.color} strokeWidth={2} />
        ))}
      </Pie>
      <Legend content={renderLegend} />
    </PieChart>
  );
}

export default BouncesChart;
