import {Link} from 'react-router-dom';
import styles from '../auth.module.scss';
import ROUTES from '../../../Router/routes';

function FooterText() {
  return (
    <p className={styles.text}>
      Don&apos;t have an account?{' '}
      <Link to={ROUTES.auth.signup} className={styles.link}>
        Sign up
      </Link>{' '}
      here.
    </p>
  );
}

export default FooterText;
