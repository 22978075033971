import {useEffect} from 'react';
import {REACT_APP_FORM_URL} from '../../../../utils/constants';

const script = document.createElement('script');
script.src = `${REACT_APP_FORM_URL}/md-form-loader.js`;
script.async = true;

const useFormSubmitScript = () => {
  useEffect(() => {
    document.head.appendChild(script);

    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);
};

export default useFormSubmitScript;
