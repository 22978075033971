import PropTypes from 'prop-types';
import {Menu, MenuButton, MenuList} from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import styles from './ActionsDropdown.module.scss';

function ActionsDropdown({children, disabled, buttonStyles, dotStyles, menuListStyles}) {
  return (
    <Menu>
      <MenuButton
        as="div"
        role="button"
        className={styles.actionsDropdown}
        style={{
          ...{opacity: disabled ? 0.3 : 1, cursor: disabled ? 'initial' : 'pointer'},
          ...buttonStyles,
        }}
      >
        <span className={styles.dots} style={dotStyles || {}} />
      </MenuButton>
      {!disabled && (
        <MenuList className={styles.list} style={menuListStyles}>
          {children}
        </MenuList>
      )}
    </Menu>
  );
}

ActionsDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default ActionsDropdown;
