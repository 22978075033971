import {useContext} from 'react';
import {Context} from './context/Context';

export const useImportSubscribersContext = function () {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useImportSubscribersContext must be used within a ImportSubscribersProvider`);
  }
  return context;
};
