import {useEffect, useState} from 'react';
import {useReactFlow} from 'reactflow';
import {v4 as uuidv4} from 'uuid';

import Select from '../../../../components/forms/Select';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import TriggerNodeOption from '../../CustomNodes/TriggerNode/TriggerNodeOption';

import {useAutomation} from '../../stores/automation';

import styles from './ConfigureTriggerModal.module.scss';

import {
  TRIGGER_NODE_OPTIONS,
  TRIGGER_STEP_TYPES,
  TRIGGER_NODE_OPTIONS_TO_TYPE,
} from '../../CustomNodes/constants';

import triggerOptionInfoByType from './triggerData';

import useGetValueType from '../../CustomNodes/hooks/use-get-value-type';
import useTriggerTypeByOption from '../../CustomNodes/hooks/use-trigger-type-by-option';
import useGetTriggerValueOptions, {
  NO_VALUE,
} from '../../CustomNodes/hooks/use-get-trigger-value-options';

const ConfigureTriggerModal = ({nodeData, isOpen, onClose}) => {
  const {id, type, data} = nodeData;

  const [triggerType, setTriggerType] = useState(data.triggerType); // "TAG_ADDED", "FORM_SUBMISSION", ...
  const [triggerValue, setTriggerValue] = useState(data.triggerValue); // undefined, :id, ...
  const [optionType, setOptionType] = useState(data.triggerLabel); // "Tag is Added", "Joins a Form", ...

  const valueType = useGetValueType(optionType); // Field, Tag, Form, ...
  const reactFlowInstance = useReactFlow();
  const {dataCustomFields, dataTags, formData, setIsConfigUpdate} = useAutomation();
  const triggerTypeByOption = useTriggerTypeByOption(optionType);

  const node = reactFlowInstance.getNode(id);
  const nodes = reactFlowInstance.getNodes();
  const edges = reactFlowInstance.getEdges();

  const triggerOptions = useGetTriggerValueOptions(valueType, {
    dataCustomFields,
    dataTags,
    formData,
  });

  useEffect(() => {
    const initState = () => {
      setTriggerType(data.triggerType);
      setTriggerValue(data.triggerValue);
      setOptionType(data.triggerLabel);
    };

    if (isOpen) {
      initState();
    }
  }, [isOpen, data]);

  const resetState = () => {
    setTriggerType();
    setTriggerValue();
    setOptionType();
  };

  const onAddTrigger = () => {
    const newTriggerNodeId = String(uuidv4());

    const startStep = nodes.find((n) => n.type === TRIGGER_STEP_TYPES.start) ||
      nodes.find((n) => n.type === TRIGGER_STEP_TYPES.node) || {
        id: String(uuidv4()),
        data: {isStartStep: true, width: 30, height: 30},
        position: {
          x: 0,
          y: 0,
        },
        type: TRIGGER_STEP_TYPES.start,
      };

    const startStepId =
      nodes.find((n) => n.type === TRIGGER_STEP_TYPES.node)?.id ||
      nodes.find((n) => n.type === TRIGGER_STEP_TYPES.start);

    const newTriggerNode = {
      id: newTriggerNodeId,
      data: {
        triggerType: triggerType || triggerTypeByOption,
        triggerValue: triggerValue !== NO_VALUE ? triggerValue : NO_VALUE,
        triggerLabel: optionType,
        width: 220,
        height: 50,
      },
      position: {
        x: 0,
        y: 0,
      },
      type: 'triggerNode',
    };

    const newTriggerEdge = {
      id: `e${startStep.id}${newTriggerNodeId}`,
      source: newTriggerNodeId,
      target: startStep.id,
      data: {type: 'trigger'},
    };
    if (!startStepId) {
      const startNodeEdge = {
        id: `e${startStep.id}${id}`,
        source: id,
        target: startStep.id,
        data: {type: 'start'},
      };

      reactFlowInstance.setNodes([newTriggerNode, startStep, node]);

      reactFlowInstance.setEdges((eds) => eds.concat([newTriggerEdge, startNodeEdge]));
    } else {
      const startNodeIndex = nodes.findIndex((n) => n.type === TRIGGER_STEP_TYPES.start);

      const updatedEdges = [...edges];
      updatedEdges.splice(startNodeIndex - 1, 0, newTriggerEdge);
      const updatedNodes = [...nodes];

      updatedNodes.splice(startNodeIndex, 0, newTriggerNode);

      reactFlowInstance.setNodes(updatedNodes);

      reactFlowInstance.setEdges(updatedEdges);
    }

    resetState();
    onClose?.();
  };

  const onUpdateTrigger = () => {
    const updatedTriggerNode = {
      ...node,
      data: {
        ...node.data,
        triggerType: triggerTypeByOption,
        triggerValue: triggerValue !== NO_VALUE ? triggerValue : NO_VALUE,
      },
    };
    reactFlowInstance.setNodes((nds) => nds.map((n) => (n.id === id ? updatedTriggerNode : n)));
    setIsConfigUpdate(true);
    onClose?.();
  };

  const onOptionClick = (option) => {
    setOptionType(option);
  };

  const triggerInfo = triggerOptionInfoByType[triggerTypeByOption];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        if (type === 'startNode') {
          resetState();
        }

        onClose?.();
      }}
      title={optionType ? triggerInfo.title : 'Select a Trigger'}
      titleStyle={{marginBottom: 0}}
    >
      {!optionType || !valueType ? (
        <div>
          <div className={styles.subtitle}>
            Select a Trigger from the list to start your automation. Each type works differently:
          </div>
          <div className={styles.options}>
            {TRIGGER_NODE_OPTIONS.map((option) => (
              <TriggerNodeOption
                key={option}
                option={option}
                onOptionClick={onOptionClick}
                subtitle={
                  triggerOptionInfoByType[TRIGGER_NODE_OPTIONS_TO_TYPE[option]]?.description
                }
              />
            ))}
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.subtitle}>{triggerInfo.subtitle}</div>
          <Select
            label={`Select ${valueType}`}
            options={triggerOptions}
            value={triggerValue}
            onChange={setTriggerValue}
            dense
            noBottomSpace
            getOptionLabel={(option) => {
              if (option.value === '') {
                return triggerOptions[0].label;
              }

              return option.label;
            }}
            getOptionValue={(option) => {
              if (option.value === '') {
                return triggerOptions[0].value;
              }

              return option.value;
            }}
            separateFirstOption={triggerOptions.length > 1}
          />
          {type === 'startNode' ? (
            <Button
              size="small"
              color="green"
              variant="filled"
              full
              onClick={onAddTrigger}
              disabled={!triggerValue && valueType === 'Date'}
            >
              Add trigger
            </Button>
          ) : (
            <Button
              size="small"
              color="green"
              variant="filled"
              full
              onClick={onUpdateTrigger}
              disabled={!triggerValue && valueType === 'Date'}
            >
              Update trigger
            </Button>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ConfigureTriggerModal;
