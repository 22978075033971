import PropTypes from 'prop-types';

import BulkInformationModalContent from './BulkInformationModalContent';
import EmailModal from '../components/EmailModal';

function BulkInformationModal({isOpen, onRequestClose}) {
  return (
    <EmailModal onRequestClose={onRequestClose} isOpen={isOpen}>
      <BulkInformationModalContent />
    </EmailModal>
  );
}

BulkInformationModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default BulkInformationModal;
