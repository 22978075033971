const subscribers = '/subscribers';

export const ENDPOINTS = {
  fetchSingle: (id) => `${subscribers}/${id}`,
  fetchAll: subscribers,
  add: subscribers,
  update: subscribers,
  deleteSelected: subscribers,
  deleteSingle: (id) => `${subscribers}/${id}`,
  deleteAll: `${subscribers}/all`,
  fetchReportTotal: '/report/subscriber-status-total',
  fetchReportDelta: '/report/subscriber-status-change',
  fetchReportTotalOverview: '/report/total/subscriber/overview',
  export: '/internal/subscriber-export',
  exportAll: `/internal/subscriber-export/all`,
  fetchConfirmedCount: `${subscribers}/count`,
};

export const REPORT_INTERVALS = ['DAY', 'WEEK', 'MONTH', 'QUARTER', 'YEAR'];
export const REPORT_TYPES = ['total', 'delta'];
export const REPORT_OVERVIEW_ENTRY_TYPES = [
  {
    key: 'totalCount',
    name: 'Total Subscribers',
  },
  {
    key: 'confirmedCount',
    name: 'Confirmed Subscribers',
  },
  {
    key: 'pendingCount',
    name: 'Pending Subscribers',
  },
  {
    key: 'unsubscribedCount',
    name: 'Total Unsubscribers',
  },
  {
    key: 'bouncedCount',
    name: 'Bounced Subscribers',
  },
  {
    key: 'blockedCount',
    name: 'Blocked Subscribers',
  },
];
export const REPORT_ENTRIES_MIN_COUNT_FOR_AREA = 2;
