import PropTypes from 'prop-types';
import {useEffect, useMemo} from 'react';
import Sidebar from '../../../../components/Sidebar';
import TextField from './TextField';
import SwichField from './SwichField';
import Button from '../../../../components/Button';
import {automationStatus} from '../../utils/automationFlow';
import {useAutomation} from '../../stores/automation';
import {useAutomationFlow} from '../../stores/automationFlow';
import {
  useCreateAutomation,
  useUpdateAutomationWithAciveSubscribersCheck,
} from '../../hooks/services';
import SelectField from './SelectField';
import styles from './AutomationSidebar.module.scss';

const statusOptions = [
  {
    value: automationStatus.DRAFT,
    label: 'Draft',
  },
  {
    value: automationStatus.ACTIVE,
    label: 'Active',
  },
  {
    value: automationStatus.PAUSED,
    label: 'Paused',
  },
  {
    value: automationStatus.DISCONNECTING,
    label: 'Disconnecting',
  },
];

const statusTransitions = {
  [automationStatus.DRAFT]: [automationStatus.DRAFT, automationStatus.ACTIVE],
  [automationStatus.ACTIVE]: [
    automationStatus.ACTIVE,
    automationStatus.PAUSED,
    automationStatus.DISCONNECTING,
  ],
  [automationStatus.PAUSED]: [
    automationStatus.PAUSED,
    automationStatus.ACTIVE,
    automationStatus.DISCONNECTING,
  ],
  [automationStatus.DISCONNECTING]: [automationStatus.DISCONNECTING, automationStatus.ACTIVE],
};

const isStatusEnabled = (fromSatus, toStatus) => statusTransitions[fromSatus]?.includes(toStatus);

const AutomationSidebar = ({sidebarIsOpened, toggleSidebar}) => {
  const {nodes} = useAutomationFlow();
  const {
    automationData,
    setAutomationData,
    validationErrors,
    initialAutomationData,
  } = useAutomation();
  const updateAutomationWithCheck = useUpdateAutomationWithAciveSubscribersCheck();
  const createAutomation = useCreateAutomation();

  useEffect(() => {}, [automationData]);

  const handleAutomationUpdate = (name, value) =>
    setAutomationData({...automationData, [name]: value});

  const handleSave = async () => {
    if (automationData?.id) {
      updateAutomationWithCheck.handler();
    } else {
      createAutomation.handler();
    }
  };

  const options = useMemo(
    () =>
      statusOptions.map((statusOption) => ({
        ...statusOption,
        isDisabled: !isStatusEnabled(initialAutomationData?.status, statusOption.value),
      })),
    [initialAutomationData?.status]
  );

  const isLoading = updateAutomationWithCheck.loading || createAutomation.loading;
  const hasSteps = nodes.some((node) => node.type === 'stepNode');

  return (
    <Sidebar isOpened={sidebarIsOpened} toggleSidebar={toggleSidebar}>
      <Sidebar.Section title="Automation Settings">
        <TextField
          label="Automation Name"
          className={styles.field}
          value={automationData?.name || ''}
          errors={validationErrors?.name}
          onChange={(e) => handleAutomationUpdate('name', e.target.value)}
        />
        <SelectField
          label="Stratus"
          className={styles.field}
          options={options}
          value={automationData?.status}
          onChange={(value) => handleAutomationUpdate('status', value)}
        />
        <SwichField
          label="Allow reentry"
          className={styles.field}
          value={Boolean(automationData?.allowReentry)}
          onChange={(value) => handleAutomationUpdate('allowReentry', value)}
        />
      </Sidebar.Section>
      <Sidebar.Footer>
        <Button
          full
          disabled={!hasSteps || isLoading || !automationData?.name}
          color="green"
          variant="filled"
          className={styles.footerButton}
          onClick={handleSave}
          loading={isLoading}
        >
          Save
        </Button>
        {automationData?.status === automationStatus.DISCONNECTING && (
          <p className={styles.text}>
            The automation will be automatically archived when all subscribers have completed their
            journey.
          </p>
        )}
      </Sidebar.Footer>
    </Sidebar>
  );
};

AutomationSidebar.propTypes = {
  sidebarIsOpened: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default AutomationSidebar;
