import {useCallback, useEffect, useState} from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import EmailPreview from 'domain/settings/edit-form-text/components/EmailPreview';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import BackButton from '../../../components/BackButton';
import ROUTES from '../../../Router/routes';

import {newsletterContentPropType} from '../../../utils/prop-types/newsletter';
import styles from './SentNewsletter.module.scss';
import useFetch from '../../../hooks/useFetch';
import {fetchNewsletterLinksService, updateLinkService} from '../service';
import Modal from '../../../components/Modal';
import LinkEditor from './LinkEditor';
import Table from '../../../components/Table';

const headerCells = ['URL', 'TAG', ''];

function SentNewsletter({createFromDraft, data}) {
  async function handleCreateFromDraft() {
    createFromDraft(data.id);
  }

  const {run: getLinksData} = useFetch(fetchNewsletterLinksService, {throwError: true});

  const {run: updateLinkRequest} = useFetch(updateLinkService, {throwError: true});

  const [linksData, setLinksData] = useState();
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState();

  const fetchLinksData = useCallback(async function () {
    try {
      const response = await getLinksData(data.id);
      setLinksData(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(function () {
    fetchLinksData();
  }, []);

  const onUpdateLink = async (options) => {
    await updateLinkRequest(options);
    await fetchLinksData();
  };

  return (
    <div>
      <BackButton noMargin to={ROUTES.newsletters.index}>
        Campaigns
      </BackButton>
      <div className={styles.btns}>
        <Button type="button" variant="outlined" color="green" onClick={handleCreateFromDraft}>
          Save as Draft
        </Button>
        <Button
          as={Link}
          to={`${ROUTES.newsletters.report}/${data.id}`}
          variant="outlined"
          color="green"
        >
          View Report
        </Button>
      </div>
      <Card noPadding>
        <div className={styles.info}>
          <div className={styles.row}>
            <strong>From:</strong>
            <span>{data.senderFrom}</span>
            <span className={cx(styles.supplementary, styles.supplementaryAside)}>
              {data.transmissionTime}
            </span>
          </div>
          <div className={styles.row}>
            <strong>Subject:</strong>
            <span>{data.subject}</span>
          </div>
          <div className={styles.row}>
            <strong>To:</strong>
            <span className={styles.supplementary}>
              {data.recipientCount} confirmed subscribers
            </span>
          </div>
        </div>
        <div className={styles.content}>
          <EmailPreview html={data.compiledContent} />
        </div>
        <Modal
          isOpen={isLinkModalOpen}
          onRequestClose={() => setIsLinkModalOpen(false)}
          title="Update link"
        >
          <LinkEditor
            onUpdateLink={onUpdateLink}
            onRequestClose={() => setIsLinkModalOpen(false)}
            tagName={selectedLink?.tagTypeDto?.name}
            linkUrl={selectedLink?.target}
            linkId={selectedLink?.id}
          />
        </Modal>
      </Card>
      {linksData && linksData.length > 0 && (
        <Card noPadding title="Links">
          <Table caption="Inclueded links" data={linksData} headerCells={headerCells}>
            {(link) => (
              <>
                <td width="60%">{link.target}</td>
                <td width="20%">{link.tagTypeDto?.name}</td>
                <td align="right" width="20%">
                  <Button
                    onClick={() => {
                      setSelectedLink(link);
                      setIsLinkModalOpen(true);
                    }}
                    dense
                    size="small"
                    color="green"
                  >
                    Edit
                  </Button>
                </td>
              </>
            )}
          </Table>
        </Card>
      )}
    </div>
  );
}

SentNewsletter.propTypes = {
  data: newsletterContentPropType,
  createFromDraft: PropTypes.func.isRequired,
};

export default SentNewsletter;
