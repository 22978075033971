export const DEFAULT_SIZE_UNIT = 'px';

export const FONT_SIZE_OPTIONS = Array.from(Array(10), (_, index) => index * 2 + 6).map((size) => ({
  label: `${size}${DEFAULT_SIZE_UNIT}`,
  value: size,
}));

export const BUILDER_MAX_BORDER_RADIUS = 32;
export const BUILDER_MAX_BORDER_SIZE = 12;
export const BUILDER_MIN_FONT_SIZE = 4;
export const BUILDER_MAX_FONT_SIZE = 48;
