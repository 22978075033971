import {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import ROUTES from '../../Router/routes';
import useRouter from '../../hooks/useRouter';
import useFetch from '../../hooks/useFetch';
import {downloadExportService} from './service';
import useAuth from '../auth/useAuth';
import img from '../../img/export-list.svg';
import downloadFile from '../../utils/downloadFile';
import ConfirmationCard from '../../components/ConfirmationCard';
import Spinner from '../../components/Spinner';

function ExportDownload() {
  const {query} = useRouter();
  const {state: authState} = useAuth();
  const [processing, setProcessing] = useState(true);
  const {run: downloadExportRequest} = useFetch(
    downloadExportService,
    {
      throwError: true,
    },
    {
      isAuthenticated: false,
    }
  );
  const [errorMessage, setErrorMessage] = useState('');
  const redirectUrl = authState.isLoggedIn ? ROUTES.index : ROUTES.auth.login;

  useEffect(function () {
    async function request() {
      setErrorMessage('');
      try {
        const response = await downloadExportRequest(query.id);
        setProcessing(false);
        downloadFile(response, 'application/zip', 'export.zip');
      } catch (error) {
        setErrorMessage("Sorry, we couldn't download your export. Please try again later.");
        setProcessing(false);
      }
    }

    request();
  }, []);

  if (!Object.keys(query).length) {
    return <Redirect to="/" />;
  }

  if (processing) {
    return <Spinner />;
  }

  return (
    <ConfirmationCard
      errorMessage={errorMessage}
      title="Thank you for downloading your export!"
      image={img}
    >
      <p className="text text-centered">
        <Link to={redirectUrl}>Return to {authState.isLoggedIn ? 'home' : 'login'} page</Link>
      </p>
    </ConfirmationCard>
  );
}

export default ExportDownload;
