import React from 'react';

import {BUILDER_MAX_BORDER_RADIUS, BUILDER_MAX_BORDER_SIZE} from '../../constants';
import SubscriptionColorField from './SubscriptionColorField';
import SizeField from './SizeField';
import ToolbarSection from './ToolbarSection';
import FontOptionsSection from './FontOptionsSection';
import {SubscriptionFormBuilderContext} from '../../context';

const FieldCommonStyles = () => {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);
  return (
    <>
      <ToolbarSection title="Input Styles">
        <SubscriptionColorField
          label="Background Color"
          paramPath="jsonConfig.body.inputFieldStyle.backgroundColor"
        />
        <SubscriptionColorField
          label="Text Color"
          paramPath="jsonConfig.body.inputFieldStyle.color"
        />
        <SizeField
          label="Border Radius"
          paramPath="jsonConfig.body.inputFieldStyle.border.radius"
          max={BUILDER_MAX_BORDER_RADIUS}
        />
        <SizeField
          label="Border Size"
          paramPath="jsonConfig.body.inputFieldStyle.border.size"
          max={BUILDER_MAX_BORDER_SIZE}
        />
        {formState.jsonConfig.body.inputFieldStyle.border.size > 0 ? (
          <SubscriptionColorField
            label="Border Color"
            paramPath="jsonConfig.body.inputFieldStyle.border.color"
          />
        ) : null}
      </ToolbarSection>
      <FontOptionsSection
        pathPrefix="jsonConfig.body.inputFieldStyle.label"
        sectionTitle="Input Label Styles"
      />
    </>
  );
};

export default FieldCommonStyles;
