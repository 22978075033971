import {Helmet} from 'react-helmet';
import moment from 'moment';
import {useCallback, useState, useEffect} from 'react';
import useFetch from '../../../../hooks/useFetch';
import {
  deleteSignupForm,
  loadAllSignupForms,
  loadReport,
  loadTotalReport,
  updateSignupFormStatus,
} from '../../service';
import ROUTES from '../../../../Router/routes';
import useRouter from '../../../../hooks/useRouter';
import {handleErrorAlert} from '../../../../utils/handleErrorAlert';
import useAlert from '../../../alerts/useAlert';
import SubscriptionFormListPage from './SubscriptionFormListPage';
import useAlertOnRedirect from '../../../../hooks/useAlertOnRedirect';

function SubscriptionFormListPageContainer() {
  const {history} = useRouter();
  const {showAlert} = useAlert();
  const [loading, setLoading] = useState(true);
  const [formList, setFormList] = useState(() => []);
  const [reportData, setReportData] = useState();
  const [totalReportData, setTotalReportData] = useState();

  useAlertOnRedirect();

  const {run: fetchAllForms} = useFetch(loadAllSignupForms, {throwError: true});
  const {run: deleteFormRequest} = useFetch(deleteSignupForm);
  const {run: fetchReport, loading: reportLoading} = useFetch(loadReport, {throwError: true});
  const {run: fetchTotalReport} = useFetch(loadTotalReport, {throwError: true});
  const {run: updateFormStatus} = useFetch(updateSignupFormStatus, {
    showSuccessAlert: true,
  });

  const fetchForms = useCallback(
    async function () {
      setLoading(true);
      try {
        const response = await fetchAllForms();
        setFormList(response);
      } catch (error) {
        handleErrorAlert(error, showAlert);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setFormList, fetchAllForms, showAlert]
  );

  const getReport = useCallback(async function (
    fromDate = moment().startOf('month').format('YYYY-MM-DD'),
    toDate = moment().format('YYYY-MM-DD'),
    interval = 'DAY'
  ) {
    const response = await fetchReport({fromDate, toDate, interval});
    setReportData(response);
  },
  []);

  const getTotalReport = useCallback(async function () {
    const response = await fetchTotalReport();
    setTotalReportData(response);
  }, []);

  useEffect(() => {
    fetchForms();
  }, []);

  useEffect(() => {
    getReport();
  }, [getReport]);

  useEffect(() => {
    getTotalReport();
  }, [getTotalReport]);

  const handleDelete = useCallback(
    async (id) => {
      await deleteFormRequest(id);
      await fetchForms();
      await getReport();
    },
    [deleteFormRequest, fetchForms]
  );

  const handleFormStatusUpdate = useCallback(
    async (formId, status) => {
      await updateFormStatus({formId, status});
      await fetchForms();
    },
    [updateFormStatus, fetchForms]
  );

  const handleActionBtnClick = useCallback(() => {
    history.push(ROUTES.signupForms.createTypeSelection);
  }, [history]);

  return (
    <>
      <Helmet title="Signup-Forms" />
      <SubscriptionFormListPage
        formList={formList}
        loading={loading}
        onDelete={handleDelete}
        onActionBtnClick={handleActionBtnClick}
        reportData={reportData}
        getReport={getReport}
        totalReportData={totalReportData}
        reportLoading={reportLoading}
        onStatusUpdate={handleFormStatusUpdate}
      />
    </>
  );
}

export default SubscriptionFormListPageContainer;
