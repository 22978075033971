import {STEPS} from '../constants';

function setInitialState() {
  const state = {
    completed: false,
    data: {},
    error: '',
  };
  return Object.keys(STEPS).reduce(
    (acc, step) => ({
      ...acc,
      [step]: state,
    }),
    {}
  );
}

export const initialState = {
  activeStep: '',
  steps: setInitialState(),
};

export function setActiveStepReducer(state, action) {
  const {nextStep, skipped} = action.payload;
  const prevStepId = state.activeStep;
  return {
    ...state,
    activeStep: nextStep,
    steps: {
      ...state.steps,
      [prevStepId]: {
        ...state.steps[prevStepId],
        completed: !skipped,
      },
    },
  };
}

export function saveStepDataReducer(state, action) {
  const step = action.meta || state.activeStep;
  const stepState = state.steps[step];
  return {
    ...state,
    steps: {
      ...state.steps,
      [step]: {
        ...stepState,
        data: action.payload,
      },
    },
  };
}

export function saveStepErrorReducer(state, action) {
  const step = action.meta || state.activeStep;
  const stepState = state.steps[step];
  return {
    ...state,
    steps: {
      ...state.steps,
      [step]: {
        ...stepState,
        error: action.payload,
      },
    },
  };
}

export function openWizardReducer(state) {
  return {
    ...state,
    activeStep: STEPS.upload,
    completed: false,
  };
}

export function closeWizardReducer() {
  return initialState;
}
