import useSubscriberFieldsFetcher from '../../../subscriber-fields/hooks/useSubscriberFieldsFetcher';
import SelectField from './SelectField';
import TextField from './TextField';

function VariantField({name, error, register, isCustomField, customFieldId, control, filterValue}) {
  const {dataCustomFields} = useSubscriberFieldsFetcher();
  let type = 'text';
  let selectOptions;
  if (isCustomField && customFieldId) {
    const found = dataCustomFields.find((v) => v.id === customFieldId);
    if (found) {
      type = found.dataType.toLowerCase();
      if (found.optionValues) {
        selectOptions = found.optionValues.map((option) => ({label: option, value: option}));
      }
    }
  }

  const getFieldType = () => {
    if (type === 'checkbox' || type === 'radio' || type === 'dropdown') {
      return 'select';
    }
    return 'text';
  };

  return getFieldType() === 'select' ? (
    <SelectField {...{name, error, register, type, control, filterValue}} options={selectOptions} />
  ) : (
    <TextField {...{name, error, register, type}} />
  );
}

export default VariantField;
