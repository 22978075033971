import React from 'react';

import PropTypes from 'prop-types';
import styles from './Toolbar.module.scss';
import Select from '../../../../components/forms/Select';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';

const SelectField = ({label, paramPath, options, onChange, ...props}) => {
  const [value, handleChange] = useHandleConfigParam(paramPath);

  const wrappedHandleChange = React.useCallback(
    (e) => {
      onChange?.(e);
      handleChange(e);
    },
    [handleChange, onChange]
  );

  return (
    <div className={styles.toolbarFieldContainer}>
      <div className={styles.toolbarFieldLabelContainer}>
        <span className={styles.toolbarFieldLabel}>{label}</span>
      </div>
      <Select
        value={value}
        onChange={wrappedHandleChange}
        options={options}
        noBottomSpace
        dense
        {...props}
      />
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  paramPath: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

export default SelectField;
