import {post, get} from '../../utils/api';
import {ENDPOINTS} from './constants';

export async function resendEmailConfirmationService(apiSettings) {
  return post(ENDPOINTS.resendEmailConfirmation, null, apiSettings);
}

export async function getFreePlanInfoService(apiSettings) {
  return get(ENDPOINTS.freePlanInfo, null, apiSettings);
}
