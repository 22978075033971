export const SET_DATA = 'SET_DATA';
export const SET_META = 'SET_META';
export const SEARCH = 'SEARCH';
export const GO_TO_NEXT_PAGE = 'GO_TO_NEXT_PAGE';
export const GO_TO_PREV_PAGE = 'GO_TO_PREV_PAGE';
export const FORCE_PAGE_CHANGE = 'FORCE_PAGE_CHANGE';
export const CHANGE_PER_PAGE_SIZE = 'CHANGE_PER_PAGE_SIZE';
export const BULK_DELETE = 'BULK_DELETE';
export const SELECT_ROW = 'SELECT_ROW';
export const DESELECT_ROW = 'DESELECT_ROW';
export const BULK_TOGGLE = 'BULK_TOGGLE';
export const SET_PROCESSING = 'SET_PROCESSING';
export const SET_LOADING = 'SET_LOADING';
export const SET_FETCHER = 'SET_FETCHER';
export const SET_MODE = 'SET_MODE';
export const SET_FILTERS = 'SET_FILTERS';
