import PropTypes from 'prop-types';
import cx from 'clsx';
import Icon from '../../../components/Icon';
import FieldError from '../../../components/forms/FieldError';
import styles from '../ImportSubscribers.module.scss';

function Step({children, error, active, description, completed, ico, title, summary}) {
  return (
    <li className={cx(styles.step, {[styles.active]: active, [styles.completed]: completed})}>
      <button type="button" className={styles.stepTitle}>
        <span className={styles.stepIco}>
          <Icon name={ico} />
        </span>
        <strong>{title}</strong>
      </button>
      <div className={styles.stepContent}>
        {description && <p className="text-semi-bold">{description}</p>}
        {error && (
          <div className={styles.error}>
            <FieldError absolute error={error} />
          </div>
        )}
        {completed ? summary : children}
      </div>
    </li>
  );
}

Step.propTypes = {
  children: PropTypes.node.isRequired,
  ico: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  error: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  completed: PropTypes.bool,
  summary: PropTypes.node,
};

export default Step;
