import styles from './Templatel.Select.module.scss';
import {TEMPLATE_TYPE} from '../constants';
import {TemplatesList} from './components';
import SearchInput from '../../../../components/forms/SearchInput';
import Button from '../../../../components/Button';
import useTemplateSelect from './useTemplateSelect';

const TemplateSelect = () => {
  const {
    location,
    history,
    categoryOptions,
    categories,
    search,
    isHideButton,
    handelClickCategory,
    handelSearch,
  } = useTemplateSelect();
  if (!location.state?.back) {
    return history.goBack();
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h5 className={styles.title}>Select a template to customize</h5>
        <p className={styles.subtitle}>
          Create your own personalized template! Choose which one to create.
        </p>
      </div>

      <div className={styles.content}>
        <div className={styles.filters}>
          <SearchInput onSearch={handelSearch} placeholder="Search for templates" name="" />
          <div>
            {categoryOptions.map(({label, value}) =>
              !isHideButton(value) ? (
                <Button
                  key={label}
                  variant="filled"
                  onClick={handelClickCategory(value)}
                  color={categories.includes(value) ? 'blue' : 'grey'}
                >
                  {label}
                </Button>
              ) : null
            )}
          </div>
        </div>
        <div className={styles.body}>
          <TemplatesList
            title="Your Templates"
            type={TEMPLATE_TYPE.CUSTOM}
            categories={categories}
            search={search}
          />
          <TemplatesList
            title="Standard Templates"
            type={TEMPLATE_TYPE.SYSTEM}
            categories={categories}
            search={search}
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateSelect;
