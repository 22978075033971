import PropTypes from 'prop-types';

import useContentItem from '../../../hooks/useContentItem';
import styles from './Menu.module.scss';

function Menu({menuItems, children}) {
  const {isItemDisplayed} = useContentItem();
  const items = menuItems.map((item) => (isItemDisplayed(item) ? children(item) : undefined));

  return <ul className={styles.list}>{items}</ul>;
}

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.func.isRequired,
};

export default Menu;
