import {post} from '../../../utils/api';
import {ENDPOINTS, AUTH0_CONNECTION} from '../constants';
import {GENERIC_ERROR_MESSAGE} from '../../../utils/constants';

async function resetPassword(apiSettings, values) {
  const data = {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    email: values.email,
    connection: AUTH0_CONNECTION,
  };
  return post(ENDPOINTS.forgotPassword, data, apiSettings).catch(function () {
    throw new Error(GENERIC_ERROR_MESSAGE);
  });
}

export default resetPassword;
