import styles from './NewNode.module.scss';

const NewNode = () => {
  return (
    <div>
      <div className={styles.newNode}>+</div>
    </div>
  );
};

export default NewNode;
