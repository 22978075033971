import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import styles from '../components/BodyContainer/BodyContainer.module.scss';
import Card from '../../../components/Card';
import OrderSuccess from '../../../components/payments/OrderSuccess';
import Button from '../../../components/Button';

function Success({onRequestClose}) {
  return (
    <>
      <Helmet title="Order complete" />
      <div className={styles.content}>
        <Card noMargin noPadding marginYauto>
          <OrderSuccess
            initial
            wizard
            message={
              <>
                You are now ready for email marketing success! <br />
                Thanks for joining — we&apos;re excited to have you with us...
              </>
            }
            button={
              <Button variant="filled" color="green" onClick={onRequestClose}>
                Continue
              </Button>
            }
          />
        </Card>
      </div>
    </>
  );
}

Success.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default Success;
