import {useState} from 'react';
import PropTypes from 'prop-types';
import {useDebouncedCallback} from 'use-debounce';
import styles from './SearchInput.module.scss';

const DEBOUNCE_DELAY = 300;

function SearchInput({onSearch, disabled, placeholder, ...rest}) {
  const [, setValue] = useState('');
  const debounced = useDebouncedCallback((val) => {
    const searchValue = val || undefined;
    setValue(searchValue);
    if (onSearch) {
      onSearch(searchValue);
    }
  }, DEBOUNCE_DELAY);

  function handleChange(e) {
    debounced.callback(e.target.value);
  }

  return (
    <label className={styles.search}>
      <span className={styles.ico} />
      <input
        className={styles.input}
        disabled={disabled}
        name="search"
        onChange={handleChange}
        placeholder={placeholder}
        {...rest}
      />
    </label>
  );
}

SearchInput.defaultProps = {
  placeholder: 'Index...',
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
};

export default SearchInput;
