import {REACT_APP_FORM_URL} from '../../utils/constants';

export function generateCodeSnippet(id) {
  return `<script async src="${REACT_APP_FORM_URL}/md-form-loader.js" data-maildroppa-formid="${id}"></script>`;
}

export function generateStaticFormUrl(id) {
  return `${REACT_APP_FORM_URL}/subscribe-slider.html?id=${id}`;
}

export function generateSliderCodeSnippet(id) {
  return `<script async src="${REACT_APP_FORM_URL}/md-form-loader.js" data-md-form="${id}"></script>`;
}

export function generateSliderHostedUrl(id) {
  return `${REACT_APP_FORM_URL}/?id=${id}`;
}
