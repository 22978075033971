import PropTypes from 'prop-types';
import Listbox from '../Listbox';
import {useDataTableContext} from './context/TableContext';
import useDataTableFetcher from './useDataTableFetcher';
import styles from './Pagination/Pagination.module.scss';

function CustomFilter({children, value, onChange}) {
  const {setData, setFilters} = useDataTableContext();
  const [tableFetcher] = useDataTableFetcher();

  async function handleChange(status) {
    setFilters({status});
    const response = await tableFetcher({
      status,
    });
    setData(response);
    if (onChange) {
      onChange();
    }
  }

  return (
    <Listbox value={value} onChange={handleChange} className={styles.dropdown}>
      {children}
    </Listbox>
  );
}

CustomFilter.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

export default CustomFilter;
