import PropTypes from 'prop-types';

import styles from './FormRow.module.scss';

function FormRowTitle({children}) {
  return <span className={styles.title}>{children}</span>;
}

FormRowTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default FormRowTitle;
