import useFetch from './useFetch';
import {
  addBillingDetailsService,
  fetchBillingDetailsService,
  updateBillingDetailsService,
} from '../domain/settings/billing/service';

function useBillingDetails(options = {}) {
  const {data, loading, run: fetchBillingDetailsRequest} = useFetch(fetchBillingDetailsService, {
    immediate: true,
    ...options,
    throwError: options.throwOnFetch,
  });
  const {run: addBillingDetailsRequest} = useFetch(addBillingDetailsService, options);
  const {run: updateBillingDetailsRequest} = useFetch(updateBillingDetailsService, options);

  async function submitBillingDetails(values) {
    const create = data && !Object.keys(data).length;
    if (create) {
      return addBillingDetailsRequest(values);
    }

    return updateBillingDetailsRequest(values);
  }

  return {loading, data, submitBillingDetails, fetchBillingDetailsRequest};
}

export default useBillingDetails;
