import useFetch from 'hooks/useFetch';
import {fetchNextScheduleTime} from '../service';

function useNextScheduleTime() {
  /**
   * data:
   * {
   *  formattedDateTime: "6 бер. 2024 р., 17:20",
   *  formattedTimezone: "за східноєвропейським часом +02:00",
   *  transmissionTimestamp: "2024-03-06T17:20:59.369540049" // user's local time
   * }
   */
  return useFetch(fetchNextScheduleTime, {immediate: true});
}

export default useNextScheduleTime;
