import PropTypes from 'prop-types';
import {Controller, useForm} from 'react-hook-form';
import NativeSelect from '../../../components/forms/NativeSelect';
import Table from '../../../components/Table';
import Button from '../../../components/Button';

import styles from '../ImportSubscribers.module.scss';

function MappingForm({onSubmit, data}) {
  const {formState, setValue, setError, getValues, control, handleSubmit} = useForm({
    mode: 'onChange',
  });

  if (!Object.keys(data).length) {
    return null;
  }

  const headerCells = ['Field', 'Sample Data'];

  if (data.skipFirstRow) {
    headerCells.unshift('Header');
  }

  const targetFields = data.targetFieldNames.reduce(
    (acc, item) => [...acc, {value: item.personalizationTagName, label: item.name}],
    []
  );

  function handleSelectChange(e, onChange) {
    const values = getValues();
    const existingIndex = Object.values(values).findIndex((value) => value === e.target.value);
    if (existingIndex !== -1) {
      setValue(String(existingIndex), '');
    }
    onChange(e.target.value);
  }

  async function handleFormSubmit(values) {
    return onSubmit(values, setError);
  }

  function renderContent() {
    return data.csvColumns?.map((item, i) => (
      <tr key={i}>
        {data.skipFirstRow ? <td>{item.fieldName}</td> : null}
        <td>
          <Controller
            control={control}
            name={String(item.columnIndex)}
            defaultValue=""
            render={({onChange, ...props}) => (
              <NativeSelect
                onChange={(e) => handleSelectChange(e, onChange)}
                placeholder="Select Field"
                options={targetFields}
                small
                full
                {...props}
              />
            )}
          />
        </td>
        <td>{item.fieldValue}</td>
      </tr>
    ));
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className={styles.table}>
        <Table noBorder caption="Match columns to contact information" headerCells={headerCells}>
          {renderContent()}
        </Table>
      </div>
      <Button
        type="submit"
        variant="filled"
        color="green"
        widePadding
        loading={formState.isSubmitting}
        disabled={formState.isSubmitting}
      >
        Import
      </Button>
    </form>
  );
}

MappingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    targetFieldNames: PropTypes.arrayOf(
      PropTypes.shape({
        personalizationTagName: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    csvColumns: PropTypes.arrayOf(
      PropTypes.shape({
        columnIndex: PropTypes.number,
        fieldName: PropTypes.string,
        fieldValue: PropTypes.string,
      })
    ),
    delimiter: PropTypes.string,
    skipFirstRow: PropTypes.bool,
    subscriberStatus: PropTypes.string,
  }),
};

export default MappingForm;
