import {getNewFilter} from '../service';
import SegmentFilter from './SegmentFilter';
import SegmentAddFilter from './SegmentAddFilter';
import styles from './SegmentFilterGroup.module.scss';

function SegmentFilterGroup({
  segmentId,
  filterGroups,
  setFilterGroups,
  gIndex,
  isLastGroup,
  deleteFilterGroup,
  classNameWrapper,
  classNameDivider,
  wrapperStyles = {},
}) {
  const {filters} = filterGroups[gIndex];

  const addFilter = () => {
    const newFilterGroups = filterGroups.map((v, i) => {
      if (i === gIndex) {
        return {
          ...v,
          filters: [...filters, getNewFilter()],
        };
      }
      return v;
    });
    setFilterGroups(newFilterGroups);
  };

  const deleteFilter = (filterId) => () => {
    const newFilters = filters.filter((v) => v.id !== filterId);
    if (newFilters.length === 0) {
      deleteFilterGroup();
      return;
    }
    const newFilterGroups = filterGroups.map((v, i) => {
      if (i === gIndex) {
        return {...v, filters: newFilters};
      }
      return v;
    });
    setFilterGroups(newFilterGroups);
  };

  return (
    <>
      <div
        className={[styles.filterGroupWrapper, classNameWrapper].filter(String).join(' ')}
        style={wrapperStyles}
      >
        <div className={[styles.filterGroupDivider, classNameDivider].filter(String).join(' ')}>
          <span>OR</span>
        </div>
        <div className={styles.filterGroupMain}>
          {filters.map((filter, fIndex) => (
            <SegmentFilter
              key={filter.id}
              {...{segmentId, filterGroups, setFilterGroups, gIndex, fIndex}}
              canBeDeleted={!isLastGroup || filters.length > 1}
              deleteFilter={deleteFilter(filter.id)}
            />
          ))}
          <SegmentAddFilter onClick={addFilter} text="Add Filter" />
        </div>
      </div>
    </>
  );
}

export default SegmentFilterGroup;
