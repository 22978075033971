import {useEffect, useRef, useState} from 'react';
import {addDays, startOfMonth, endOfMonth, startOfDay, isEqual, format} from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import 'flatpickr/dist/themes/material_blue.css';
import calendarIco from '../../../img/icon-calendar.svg';
import styles from './DatePicker.module.scss';

const MIN_DAYS_IN_CURRENT_MONTH = 14;
const DAYS_RANGE_FALLBACK = 30;

const getDateRangeStartDate = () => {
  const date = new Date();
  const dateRes = startOfMonth(date);
  if (date.getDate() < MIN_DAYS_IN_CURRENT_MONTH) {
    return addDays(date, -1 * DAYS_RANGE_FALLBACK);
  }
  return dateRes;
};
const getDateRangeEndDate = () => {
  const date = new Date();
  const dateRes = startOfDay(endOfMonth(date));
  if (date.getDate() < MIN_DAYS_IN_CURRENT_MONTH) {
    return date;
  }
  return dateRes;
};

function DatePicker({classes, handleChange, reportRange}) {
  const [dateRange, setDateRange] = useState([getDateRangeStartDate(), getDateRangeEndDate()]);
  const inputEl = useRef(null);

  useEffect(() => {
    handleChange({startDate: dateRange[0], endDate: dateRange[1]});
  }, []);

  useEffect(() => {
    if (reportRange) {
      setDateRange(reportRange);
    }
  }, [reportRange]);

  const handleDatePickerChange = (dates) => {
    const [start, end] = dates;
    setDateRange(dates);

    if (dates.length > 1 && dates[0] && dates[1]) {
      handleChange({
        startDate: start,
        endDate: end,
      });
    }
  };

  const handleOpenerClick = () => {
    if (inputEl && inputEl.current && inputEl.current.flatpickr) {
      inputEl.current.flatpickr.toggle();
    }
  };

  const [startDate, endDate] = dateRange;

  const getButtonText = () => {
    if (
      isEqual(startDate, startOfMonth(startDate)) &&
      isEqual(endDate, startOfDay(endOfMonth(endDate)))
    ) {
      return format(startDate, 'LLLL yyyy');
    }
    if (!endDate) {
      return `${format(startDate, 'MM/dd')} - ${format(startDate, 'MM/dd')}`;
    }
    if (startDate.getYear() === endDate?.getYear()) {
      return `${format(startDate, 'MM/dd')} - ${format(endDate, 'MM/dd')}`;
    }
    return `${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}`;
  };

  const handleClickOutside = () => {
    if (!dateRange[1]) {
      setDateRange([dateRange[0], dateRange[0]]);
      handleChange({startDate: dateRange[0], endDate: dateRange[0]});
    }
  };

  return (
    <>
      <ReactDatePicker
        selected={startDate}
        onChange={handleDatePickerChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        calendarClassName={styles.datepickerWrapper}
        wrapperClassName={styles.datepickerWrapper}
        onClickOutside={handleClickOutside}
        customInput={
          <button
            type="button"
            className={[styles.main, classes].join(' ')}
            onClick={handleOpenerClick}
          >
            <span className={styles.text}>{getButtonText()}</span>
            <span className={styles.icon_wrapper}>
              <img width="15" height="17" src={calendarIco} alt="Choose date" />
            </span>
          </button>
        }
      />
    </>
  );
}

export default DatePicker;
