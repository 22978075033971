import {useCallback, useEffect, useState} from 'react';
import logo from '../../img/logolight.svg';
import styles from './Navbar.module.scss';
import Menu from './Menu';
import MenuItem from './MenuItem';
import {MENU} from './menu-config';
import useAuth from '../../domain/auth/useAuth';
import stylesMenuItem from './MenuItem/MenuItem.module.scss';
import Icon from '../../components/Icon';
import useFetch from '../../hooks/useFetch';
import {fetchInvoicesCountService} from '../../domain/settings/billing/service';

function Navbar() {
  const {logout} = useAuth();
  const {run: fetchInvoicesCountRequest, loading} = useFetch(fetchInvoicesCountService);

  const [invoiceCount, setInvoiceCount] = useState();

  const fetchInvoicesCount = useCallback(async function () {
    try {
      const response = await fetchInvoicesCountRequest();
      setInvoiceCount(response.count === 0);
    } catch (err) {
      console.log('error', err);
    }
  }, []);

  useEffect(function () {
    fetchInvoicesCount();
  }, []);

  return (
    <nav className={styles.navbar}>
      <div className={styles.logoWrapper}>
        <img width="170" src={logo} className={styles.logo} alt="Maildroppa Logo" />
      </div>
      {!loading && (
        <Menu menuItems={MENU}>
          {(item) => (
            <MenuItem
              key={item.label}
              text={item.label}
              label={item.label}
              icon={item.icon}
              SvgIcon={item.SvgIcon}
              childRoutes={item.children}
              pathname={item.pathname}
              active={item.active}
              hideInvoice={invoiceCount}
              loading={loading}
            />
          )}
        </Menu>
      )}
      <button
        type="button"
        className={[stylesMenuItem.content, styles.logoutButton].join(' ')}
        onClick={logout}
      >
        <Icon name="icon-logout" className={stylesMenuItem.icon} />
        <span className={stylesMenuItem.text}>Log Out</span>
      </button>
    </nav>
  );
}

export default Navbar;
