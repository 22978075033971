import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import Loading from '../DataTable/Loading';
import styles from './Button.module.scss';

const Button = forwardRef(
  (
    {
      children,
      className,
      size,
      variant,
      disabled,
      onClick,
      full,
      widePadding,
      noPadding,
      type,
      color,
      as,
      loading,
      ...props
    },
    ref
  ) => {
    const Component = as || 'button';
    return (
      <Component
        {...props}
        ref={ref}
        type={!as && type ? type : undefined}
        disabled={disabled}
        onClick={onClick}
        className={cx(
          styles.btn,
          styles[variant],
          {
            [styles.full]: full,
            [styles[color]]: color,
            [styles[size]]: size,
            [styles.disabled]: disabled,
            [styles.widePadding]: widePadding,
            [styles.noPadding]: noPadding,
          },
          className
        )}
      >
        {loading ? <Loading /> : children}
      </Component>
    );
  }
);

Button.defaultProps = {
  type: 'button',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  full: PropTypes.bool,
  widePadding: PropTypes.bool,
  noPadding: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']),
  as: PropTypes.elementType,
  color: PropTypes.oneOf(['green', 'grey', 'red', 'blue']),
  size: PropTypes.oneOf(['extraSmall', 'small', 'big']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'link']),
};

export default Button;
