import InfiniteScroll from 'react-infinite-scroller';
import Card from '../../../../components/Card';
import ActionButton from '../../../../components/ActionButton';

import styles from './NewsletterTemplates.module.scss';
import CardHeader from '../../../../components/Card/CardHeader';
import NewsletterTemplateCard from '../NewsletterTemplateCard';
import useNewsletterTemplates from './useNewsletterTemplates';
import ConfirmDeleteModal from '../../../../components/modals/ConfirmDeleteModal';
import EmptyTable from '../../../settings/subscriber-fields/components/fields-table/EmptyTable';
import {categoryOptions} from '../constants';
import Button from '../../../../components/Button';
import SearchInput from '../../../../components/forms/SearchInput';
import {LANDING_URL} from '../../../../utils/constants';

const NewsletterTemplates = () => {
  const {
    templates,
    categories,
    loading,
    page,
    hasMore,
    templateToDelete,
    actions,
    handleCreateTemplate,
    handleTemplateDelete,
    handleCloseDeleteModal,
    handleFetchMore,
    handelClickCategory,
    handleSearch,
  } = useNewsletterTemplates();

  return (
    <div>
      <Card
        header={
          <CardHeader
            title="Email Templates"
            text={
              <p style={{marginTop: '12px'}}>
                Here you can manage your custom Email Templates to ensure your emails look
                professional <br /> and maintain a consistent look and feel. For detailed guidance,
                see our &nbsp;
                <a
                  href={`${LANDING_URL}/guides/templates`}
                  title="Click here for more details"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Email Templates Guide.
                </a>
              </p>
            }
          >
            <ActionButton onClick={handleCreateTemplate}>Create a new Template</ActionButton>
          </CardHeader>
        }
      >
        <div className={styles.container}>
          <div className={styles.filters}>
            <SearchInput onSearch={handleSearch} placeholder="Search for templates" name="" />
            <div>
              {categoryOptions.map(({label, value}) => (
                <Button
                  key={label}
                  variant="filled"
                  onClick={handelClickCategory(value)}
                  color={categories.includes(value) ? 'blue' : 'grey'}
                >
                  {label}
                </Button>
              ))}
            </div>
          </div>

          <InfiniteScroll
            pageStart={page}
            hasMore={hasMore}
            initialLoad={false}
            loadMore={handleFetchMore}
            useWindow={false}
            getScrollParent={() => document.querySelector('#root')}
          >
            <div className={styles.grid}>
              {templates.map((template) => (
                <NewsletterTemplateCard key={template.id} actions={actions} template={template} />
              ))}
            </div>
          </InfiniteScroll>

          {!loading && !templates?.length && (
            <EmptyTable>You don&apos;t have any data yet.</EmptyTable>
          )}
        </div>
        <ConfirmDeleteModal
          isOpen={Boolean(templateToDelete)}
          onRequestClose={handleCloseDeleteModal}
          deleteId={templateToDelete?.id}
          onDelete={handleTemplateDelete}
        />
      </Card>
    </div>
  );
};

export default NewsletterTemplates;
