// @TODO: fix this
// eslint-disable-next-line consistent-return
const getTriggerLabel = (triggerType) => {
  if (triggerType === 'FORM_SUBMISSION' || triggerType === 'GENERAL_FORM_SUBMISSION') {
    return 'Joins a Form';
  }
  if (triggerType === 'DATE_ANNIVERSARY') {
    return 'Date Anniversary';
  }
  if (triggerType === 'TAG_ADDED' || triggerType === 'GENERAL_TAG_ADDED') {
    return 'Tag is Added';
  }
  if (triggerType === 'TAG_REMOVED' || triggerType === 'GENERAL_TAG_REMOVED') {
    return 'Tag is Removed';
  }
  if (triggerType === 'FIELD_ADDED' || triggerType === 'GENERAL_FIELD_ADDED') {
    return 'Field is Added';
  }
  if (triggerType === 'FIELD_UPDATED' || triggerType === 'GENERAL_FIELD_UPDATED') {
    return 'Field is Updated';
  }
  if (triggerType === 'FIELD_REMOVED' || triggerType === 'GENERAL_FIELD_REMOVED') {
    return 'Field is Removed';
  }
};

const createNode = (id, options = {}) => ({
  id,
  position: {x: 0, y: 0},
  ...options,
});

export const createNewTriggerNode = (id) =>
  createNode(id, {
    type: 'startNode',
    data: {height: 50},
  });

export const createStartStepNode = (id) =>
  createNode(id, {
    type: 'startStep',
    data: {isStartStep: true, height: 30},
  });

export const createTriggerNodeFromServerTriggerData = (trigger) =>
  createNode(String(trigger.id), {
    type: 'triggerNode',
    data: {
      height: 50,
      idAssigned: true,
      triggerType: trigger.triggerType,
      triggerValue: trigger.triggerParam?.id,
      triggerLabel: getTriggerLabel(trigger.triggerType),
    },
  });
