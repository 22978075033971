import {useEffect} from 'react';
import useFetch from '../../../../hooks/useFetch';
import {handleErrorAlert} from '../../../../utils/handleErrorAlert';
import useAlert from '../../../alerts/useAlert';
import {fetchSegmentsService} from '../service';
import {useSegmentsContext} from '../context/SegmentsContext';

function useSegmentsFetcher(options = {}) {
  const {showAlert} = useAlert();
  const {
    state: {data, fetchLoading},
    setData,
    setFetchLoading,
  } = useSegmentsContext();
  const {run: fetchSegmentsRequest} = useFetch(fetchSegmentsService, {
    throwError: true,
  });

  async function fetcher() {
    setData(null);
    setFetchLoading(true);
    try {
      const response = await fetchSegmentsRequest(null);
      setData(response);
    } catch (error) {
      handleErrorAlert(error, showAlert);
    } finally {
      setFetchLoading(false);
    }
  }

  useEffect(() => {
    if (!fetchLoading && options.immediate && (options.force || !data)) {
      fetcher();
    }
  }, []);

  return {
    data,
    fetcher,
    loading: fetchLoading,
  };
}

export default useSegmentsFetcher;
