import {Helmet} from 'react-helmet';
import Login from '../Login';

function LoginPage() {
  return (
    <div>
      <Helmet title="Login" />
      <Login />
    </div>
  );
}

export default LoginPage;
