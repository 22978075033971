import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import Input from '../../../components/forms/Input';

import styles from '../auth.module.scss';

function ForgotPasswordForm({onSubmit}) {
  const {register, formState, handleSubmit, errors} = useForm({mode: 'onChange'});

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="email"
        type="email"
        label="Email"
        placeholder="Enter your email"
        required
        error={errors.email}
        inputRef={register({required: 'Email address is required.'})}
      />
      <div className={styles.btnWrapper}>
        <Button
          type="submit"
          variant="filled"
          color="green"
          size="big"
          full
          loading={formState.isSubmitting}
        >
          Reset Password
        </Button>
      </div>
    </form>
  );
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
