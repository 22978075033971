import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from '../auth.module.scss';

function ForgotPasswordConfirm({email}) {
  return (
    <div className={cx(styles.text, styles.textBig)}>
      We have sent a password reset email to <strong>{email}</strong>. Follow the instructions in
      the email to reset your password.
    </div>
  );
}

ForgotPasswordConfirm.propTypes = {
  email: PropTypes.string.isRequired,
};

export default ForgotPasswordConfirm;
