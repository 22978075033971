import cx from 'clsx';
import PropTypes from 'prop-types';
import styles from './FieldError.module.scss';

const formatError = (errorToFormat) => {
  if (Array.isArray(errorToFormat)) {
    return errorToFormat.flat().join(' ');
  }

  if (typeof errorToFormat === 'string') {
    return errorToFormat;
  }

  return errorToFormat.message;
};

function FieldError({error, absolute}) {
  return (
    <span className={cx(styles.msg, styles.red, {[styles.absolute]: absolute})}>
      <span>{formatError(error)}</span>
    </span>
  );
}

FieldError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool])
    .isRequired,
  absolute: PropTypes.bool,
};

export default FieldError;
