import {useEffect} from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import useStripoScript from 'hooks/useStripoScript';
import {useSubscruberFields} from 'hooks/queries/subscriber';
import Spinner from 'components/Spinner';
import Bugsnag from '@bugsnag/js';
import styles from './StripoEditor.module.scss';
import useStripoToken from './hooks/useStripoToken';
import * as cssVars from './styles/variables';
import './styles/stripo-overrides.scss';
import useAlert from '../../domain/alerts/useAlert';
import useFetch from '../../hooks/useFetch';
import {fetchTagsService} from '../../domain/settings/subscriber-fields/service';

const createPersonalizationTagsFromSubscriberFields = (fields = []) => {
  return fields.map((field) => ({
    label: field.name,
    value: `{{${field.personalizationTagName}}}`,
  }));
};

//
const editorCss = `
  .esd-empty-container {
    background: ${cssVars.colors.accent100};
    color: ${cssVars.colors.accent200};
    border-color: ${cssVars.colors.accent200};
    border-radius: 5px;
  }
  .esd-hidden-footer {
    display: none;
    overflow: hidden;
    width: 0;
    max-height: 0;
    line-height: 0;
    mso-hide: all;
  }
`;

// TODO: handle errors from stripo script loader, stripo auth
function StripoEditor({emailId, template, className, onInit, height = '95vh'}) {
  const stripoScript = useStripoScript();
  const stripoTokenQuery = useStripoToken();
  const subscriberQuery = useSubscruberFields();
  const {showAlert} = useAlert();

  const {data: tags, loading: tagsLoading} = useFetch(fetchTagsService, {
    throwError: true,
    immediate: true,
  });

  useEffect(() => {
    if (!stripoScript.loaded || subscriberQuery.loading || tagsLoading) {
      if (stripoScript.error && stripoScript.errorMessage) {
        showAlert('Failed to load editor', {
          variant: 'error',
          dismiss: false,
        });
      }
      return;
    }

    window.Stripo.init({
      settingsId: 'stripoSettingsContainer',
      previewId: 'stripoPreviewContainer',
      html: template.html,
      css: template.css,
      localePatch: {
        'mergeTags.label': {
          en: 'Personalization Tags',
        },
      },
      apiRequestData: {
        emailId,
      },
      externalLinkControlConfiguration: {
        getMarkup(controlName) {
          return `${
            controlName.startsWith('MenuItemHrefSettingsControl') ? '' : '<hr>'
          }<div class="form-group" id="linkTagAttrControl">
            <div class="col-xs-12">
             <label for="linkTagAttr">Tags</label>
             <div class="bootstrap-select dropdown">
                <button type="button" class="btn dropdown-toggle btn-default" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="linkTagAttr">
                  <span class="filter-option">[None]</span> &nbsp;
                  <span class="caret"></span>
                </button>
                <div class="dropdown-menu" aria-labelledby="linkTagAttr">
                    <div>
                       ${
                         !tags?.length
                           ? `<div class="no-results">Nothing found</div>`
                           : `<div class="dropdown-item" data-value="">[None]</div>${tags
                               ?.map(
                                 (tag) =>
                                   `<div class="dropdown-item" data-value="${tag.id}">${tag.name}</div>`
                               )
                               .join('')}`
                       }

                    </div>
                </div>
             </div>
           </div></div>
           <p class="small text-muted">Use tags to label and organize subscribers who click on this link.</p>
    `;
        },
        onRendered(getLinkDomElement, getControlDomContainer, applyChangesCallback) {
          const elements = getControlDomContainer().querySelectorAll(
            '#linkTagAttrControl .dropdown-item'
          );

          const btnText = getControlDomContainer().querySelector(
            '#linkTagAttrControl .btn.dropdown-toggle .filter-option'
          );

          elements.forEach((el) => {
            el?.addEventListener('click', () => {
              const val = el.getAttribute('data-value');
              if (val) {
                getLinkDomElement().setAttribute('data-id', val);
                elements.forEach((item) => item.classList.remove('selected'));
                el.classList.add('selected');
                btnText.innerText = el.innerText.trim();
              } else {
                getLinkDomElement().removeAttribute('data-id');
                el.classList.remove('selected');
                btnText.innerText = '[None]';
              }
              applyChangesCallback();
            });
          });
        },
        updateControlValues(getLinkDomElement, getControlDomContainer) {
          const value = getLinkDomElement().getAttribute('data-id');
          const elements = Array.from(
            getControlDomContainer().querySelectorAll('#linkTagAttrControl .dropdown-item')
          );
          const btnText = getControlDomContainer().querySelector(
            '#linkTagAttrControl .btn.dropdown-toggle .filter-option'
          );
          const selected = elements.find((item) => item.getAttribute('data-value') === value);

          elements.map((item) => item.classList.remove('selected'));
          if (selected) {
            btnText.innerText = selected.innerText.trim();
            selected.classList.add('selected');
          } else {
            btnText.innerText = '[None]';
          }
        },
      },
      mergeTags: [
        {
          category: '',
          entries: createPersonalizationTagsFromSubscriberFields(subscriberQuery.data),
        },
      ],
      getAuthToken(callback) {
        stripoTokenQuery
          .run()
          .then(callback)
          .catch((e) => {
            callback(null);
            showAlert('Failed to load editor', {
              variant: 'error',
              dismiss: false,
            });
            Bugsnag.notify(e);
          });
      },
      onTemplateLoaded: () => {
        onInit?.();
        window.EmpleApp.activateCustomViewStyles(true);
      },
      customViewStyles: editorCss,
    });

    return () => window.StripoApi?.stop();
  }, [
    emailId,
    template.html,
    template.css,
    stripoScript.loaded,
    subscriberQuery.loading,
    subscriberQuery.data,
    tagsLoading,
  ]);

  return (
    <div className={cx(styles.stripoContainer, 'stripo-editor', className)}>
      <div
        id="stripoPreviewContainer"
        className={styles.editorView}
        style={{minHeight: height, maxHeight: height}}
      >
        <div className={styles.loader}>
          <Spinner />
        </div>
      </div>
      <div
        id="stripoSettingsContainer"
        className={styles.editorSettings}
        style={{minHeight: height, maxHeight: height}}
      />
    </div>
  );
}

StripoEditor.propTypes = {
  template: PropTypes.shape({
    css: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
  }).isRequired,
  emailId: PropTypes.string.isRequired,
  className: PropTypes.string,
  onInit: PropTypes.func,
  height: PropTypes.string,
};

export default StripoEditor;
