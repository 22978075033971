import cx from 'clsx';
import styles from './NewsletterTemplateCard.module.scss';
import ActionsDropdown from '../../../../components/ActionsDropdown';
import ActionDropdownItem from '../../../../components/ActionsDropdown/ActionDropdownItem';
import PreviewImage from '../../../../components/PreviewImage';

const NewsletterTemplateCard = ({actions, template, className, onClick}) => {
  return (
    <div className={cx(styles.card, className)} onClick={onClick}>
      <div className={styles.imgWrapper}>
        <PreviewImage
          width={525}
          height={700}
          alt={template.name}
          imageClassName={styles.img}
          thumbnailUrl={template.thumbnailUrl}
          previewImageUrl={template.previewImageUrl}
        />
      </div>
      <div className={styles.cardFooter}>
        <div className={styles.titleWrapper}>
          <div className={styles.badge}>{template.category.replace('_', ' ').toLowerCase()}</div>
          <div className={styles.title}>{template.name}</div>
        </div>
        {Boolean(actions?.length) && (
          <ActionsDropdown
            buttonStyles={{
              width: 20,
              height: 26,
              paddingTop: 2,
              paddingBottom: 0,
              justifyContent: 'center',
              marginRight: -4,
              marginLeft: 5,
            }}
            dotStyles={{boxShadow: '0 8px #aaadc0, 0 16px #aaadc0', width: 4, height: 4}}
            menuListStyles={{zIndex: 1002}}
          >
            {actions.map(({label, action}) => (
              <ActionDropdownItem key={label} onSelect={action?.(template)}>
                {label}
              </ActionDropdownItem>
            ))}
          </ActionsDropdown>
        )}
      </div>
    </div>
  );
};

export default NewsletterTemplateCard;
