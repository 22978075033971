import {API_REQUEST_ERROR_MESSAGE} from './api/constants';
import {handleAsyncValidation} from './validation/handleAsyncValidation';

export function handleError(error, setErrorMessage, setFormError) {
  if (typeof error !== 'object' || !error) {
    throw new Error('The error must be an object.');
  }
  if (typeof setErrorMessage !== 'function') {
    throw new Error('You must provide a set error function to handleError.');
  }

  if (error.error === 'validation') {
    handleAsyncValidation(error, setFormError);
    return;
  }

  if (error.message) {
    setErrorMessage(error.message);
  } else {
    setErrorMessage(API_REQUEST_ERROR_MESSAGE);
  }
}
