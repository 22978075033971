import clsx from 'clsx';
import styles from './Footer.module.scss';
import {LANDING_URL} from '../../utils/constants';

function Footer() {
  return (
    <footer className={clsx([styles.footer, 'content-wrapper__footer'])}>
      <div className={styles.copy}>
        &copy; Copyright Maildroppa, 2020-{new Date().getFullYear()}
      </div>
      <ul className={styles.links}>
        <li>
          <a
            href={`${LANDING_URL}/privacy`}
            className={styles.link}
            target="_blank"
            title="Click to open in a new tab"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </li>
        <li>
          <a
            href={`${LANDING_URL}/terms`}
            className={styles.link}
            target="_blank"
            title="Click to open in a new tab"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>
        </li>
        <li>
          <a
            href={`${LANDING_URL}/disclaimer`}
            className={styles.link}
            target="_blank"
            title="Click to open in a new tab"
            rel="noopener noreferrer"
          >
            Disclaimer
          </a>
        </li>
        <li>
          <a
            href={`${LANDING_URL}/imprint`}
            className={styles.link}
            target="_blank"
            title="Click to open in a new tab"
            rel="noopener noreferrer"
          >
            Imprint
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
