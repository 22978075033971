import {useEffect} from 'react';
import useFetch from '../../../../hooks/useFetch';
import {handleErrorAlert} from '../../../../utils/handleErrorAlert';
import useAlert from '../../../alerts/useAlert';
import {
  fetchFieldMatchersService,
  fetchSubscriberFieldsService,
  fetchTagsService,
} from '../service';
import {useSubscriberFieldsContext} from '../context/SubscriberFieldsContext';

function useSubscriberFieldsFetcher(options = {}) {
  const {showAlert} = useAlert();
  const {
    state: {data, dataCustomFields, dataTags, dataMatchers, fetchLoading},
    setData,
    setFetchLoading,
  } = useSubscriberFieldsContext();
  const {run: fetchSubscriberFieldsRequest} = useFetch(fetchSubscriberFieldsService, {
    throwError: true,
  });
  const {run: fetchTagsRequest} = useFetch(fetchTagsService, {
    throwError: true,
  });

  const {run: fetchFieldMatchersRequest} = useFetch(fetchFieldMatchersService, {
    throwError: true,
  });

  async function fetcher() {
    setData(null);
    setFetchLoading(true);
    try {
      const [customFields, tags, matchers] = await Promise.all([
        fetchSubscriberFieldsRequest(null),
        fetchTagsRequest(null),
        fetchFieldMatchersRequest(null),
      ]);

      // setDataMatchers(hardcodedMatchers);

      setData([
        ...customFields,
        ...[{type: 'MATCHER', value: matchers}],
        ...tags.map((v) => ({...v, value: null, type: 'TAG'})),
      ]);
    } catch (error) {
      handleErrorAlert(error, showAlert);
    } finally {
      setFetchLoading(false);
    }
  }

  useEffect(() => {
    if (!fetchLoading && options.immediate && (options.force || !data)) {
      fetcher();
    }
  }, []);

  return {
    data,
    dataCustomFields,
    dataTags,
    dataMatchers,
    fetcher,
    loading: fetchLoading,
  };
}

export default useSubscriberFieldsFetcher;
