import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '../../../../../components/Button';
import LikeIcon from '../../../../../img/like.png';
import styles from './BranchSelector.module.scss';

const BranchSelector = ({onAddCondition}) => {
  return (
    <div className={styles.actions}>
      <Button onClick={() => onAddCondition(true)} color="green" variant="filled" widePadding>
        <img className={styles.icon} src={LikeIcon} alt="Like icon" />
      </Button>
      <Button onClick={() => onAddCondition(false)} color="red" variant="filled" widePadding>
        <img className={clsx(styles.icon, styles.down)} src={LikeIcon} alt="Like icon 2" />
      </Button>
    </div>
  );
};

BranchSelector.propTypes = {
  onAddCondition: PropTypes.func.isRequired,
};

export default BranchSelector;
