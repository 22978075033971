import {forwardRef, useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import cx from 'clsx';

import Asterisk from '../../Asterisk';
import FieldError from '../FieldError';

import styles from './Select.module.scss';

const firstOptionCustomStyle = (base) => ({
  ...base,

  ':first-of-type': {
    position: 'relative',

    '::before': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: '1px',
      left: 0,
      right: 0,
      bottom: '-1px',
      backgroundColor: '#DDE3E8',
      boxShadow: '0 -1px 0 #fff, 0 1px 0 #fff',
    },
  },
});

const getSelected = (value, options) => {
  if (Array.isArray(value)) {
    return options.filter((opt) => value.some((v) => v.value === opt.value));
  }
  return options.find((opt) => opt.value === value);
};

const Select = forwardRef(
  (
    {
      options,
      children,
      label,
      placeholder,
      helpText,
      disabled,
      required,
      error,
      hideError,
      onChange,
      noBottomSpace,
      selectInputClassName,
      value,
      dense,
      filterValue,
      isClearable = false,
      inputRef,
      separateFirstOption,
      isMulti,
      ...props
    },
    ref
  ) => {
    const [selected, setSelected] = useState(() => getSelected(value, options));

    const handleChange = (selectedOption) => {
      if (Array.isArray(selectedOption)) {
        onChange(selectedOption);
      } else {
        onChange(selectedOption ? selectedOption.value : undefined);
      }
    };

    useEffect(() => {
      if (!value) return setSelected({value: '', label: placeholder || 'Select...'});
      setSelected(getSelected(value, options));
    }, [value]);

    useEffect(() => {
      if (
        !filterValue &&
        (props.type === 'checkbox' || props.type === 'radio' || props.type === 'dropdown')
      ) {
        setSelected(null);
      }
    }, [filterValue]);

    return (
      <div
        className={cx(styles.input, {
          [styles.disabled]: disabled,
          [styles.elErr]: error,
          [styles.bottomSpace]: !noBottomSpace,
        })}
      >
        <label className={styles.label}>
          {label && (
            <span className={styles.labelText}>
              {label}
              {required && <Asterisk />}
              <input ref={inputRef} type="hidden" {...props} />
            </span>
          )}
          {children ?? (
            <ReactSelect
              menuPortalTarget={document.body}
              ref={ref}
              styles={{
                menuPortal: (provided) => ({...provided, zIndex: 2000}),
                option: separateFirstOption ? firstOptionCustomStyle : null,
              }}
              classNamePrefix="selectInput"
              className={cx(
                styles.selectInput,
                dense && styles.denseSelectInput,
                selectInputClassName
              )}
              options={options}
              isDisabled={disabled}
              value={selected}
              placeholder={placeholder}
              onChange={handleChange}
              isClearable={isClearable && !required}
              isMulti={isMulti}
              {...props}
            />
          )}
        </label>
        {error && !hideError && <FieldError error={error} absolute />}
        {helpText && <span className={styles.helpText}>{helpText}</span>}
      </div>
    );
  }
);

Select.propTypes = {
  separateFirstOption: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  bottomSpace: PropTypes.bool,
  dense: PropTypes.bool,
  errorAbsolute: PropTypes.bool,
  hideError: PropTypes.bool,
  noBottomSpace: PropTypes.bool,
  label: PropTypes.string,
  helpText: PropTypes.string,
  selectInputClassName: PropTypes.string,
  isClearable: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool]),
};

export default Select;
