import PropTypes from 'prop-types';

import styles from './PaginatedCard.module.scss';

function PaginatedCardHeader({children}) {
  return <div className={styles.header}>{children}</div>;
}

PaginatedCardHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PaginatedCardHeader;
