import {Helmet} from 'react-helmet';
import ExportDownload from './ExportDownload';

function ExportDownloadPage() {
  return (
    <div>
      <Helmet title="Download Export" />
      <ExportDownload />
    </div>
  );
}

export default ExportDownloadPage;
