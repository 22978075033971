import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import cx from 'clsx';
import Subtitle from '../../../Subtitle';
import SecureIcon from '../../../SecureIco';
import CreditCardTile from '../../CreditCardTile';
import Button from '../../../Button';
import CheckoutAgreement from '../CheckoutAgreement';
import SelectedPlan from '../ProductOverview';
import OrderPreview from '../OrderPreview';
import PaymentMethodsIcon from '../../PaymentMethods/PaymentMethodsIcon';
import BillingDetails from '../../../BillingDetails';
import {paymentMethodPropType} from '../../../../utils/prop-types';
import styles from './Checkout.module.scss';
import CreditCardForm from '../../CreditCardForm';
import ROUTES from '../../../../Router/routes';
import {orderPreviewProp} from '../../../../utils/prop-types/checkout';
import {LANDING_URL} from '../../../../utils/constants';

function Checkout({
  billingDetails,
  chargeNotice,
  onSubmit,
  orderPreview,
  wizard,
  defaultPaymentMethod,
}) {
  const {formState, errors, control, handleSubmit} = useForm({
    mode: 'onChange',
  });

  const {recurringCharge, billingCycle, todaysCharge} = orderPreview;

  function renderAgreementText() {
    if (billingCycle.remainingTrialDays > 0) {
      return (
        <>
          <p>
            By clicking on &quot;Buy Now&quot;, you are purchasing a {recurringCharge.product.name}{' '}
            subscription plan for the price of {recurringCharge.total.formattedValue} per month (VAT
            charges may vary, depending on your billing location). Due today is{' '}
            {todaysCharge.amountDue.formattedValue}. Your first invoice is due{' '}
            {billingCycle.nextInvoiceDate}. If you cancel before that date, you will not be charged.
            All subsequent payments will be charged on the {billingCycle.invoiceDayOfTheMonth} of
            each month. The <Link to={ROUTES.settings.subscription}>My Subscription</Link> page
            allows you to switch plans or cancel your subscription. Cancellations take effect at the
            end of the current billing cycle.{' '}
            <a href={`${LANDING_URL}/terms`} target="_blank" rel="noreferrer">
              Terms and conditions
            </a>{' '}
            apply.
          </p>
        </>
      );
    }
    return (
      <>
        <p>
          By clicking on &quot;Buy Now&quot;, you are purchasing a {recurringCharge.product.name}{' '}
          subscription plan for the price of {recurringCharge.total.formattedValue} per month (VAT
          charges may vary, depending on your billing location). Due today is{' '}
          {todaysCharge.amountDue.formattedValue}. Your next invoice is due{' '}
          {billingCycle.nextInvoiceDate}. All subsequent payments will be charged on the{' '}
          {billingCycle.invoiceDayOfTheMonth} of each month. The{' '}
          <Link to={ROUTES.settings.subscription}>My Subscription</Link> page allows you to switch
          plans or cancel your subscription. Cancellations take effect at the end of the current
          billing cycle.{' '}
          <a href={`${LANDING_URL}/terms`} target="_blank" rel="noreferrer">
            Terms and conditions
          </a>{' '}
          apply.
        </p>
      </>
    );
  }

  function renderPreview() {
    return (
      <OrderPreview
        billingCycle={billingCycle}
        todaysCharge={todaysCharge}
        recurringCharge={recurringCharge}
      />
    );
  }

  function renderCardTile() {
    if (!defaultPaymentMethod) {
      return null;
    }

    return (
      <CreditCardTile
        id={defaultPaymentMethod.stripePaymentMethodId}
        type={defaultPaymentMethod.brandName}
        number={defaultPaymentMethod.last4Digits}
        expiryDate={defaultPaymentMethod.expiryDate}
        expired={defaultPaymentMethod.expired}
        presentational
        smallHeight
        noBorder
      />
    );
  }

  const disableSubmit = !orderPreview || formState.isSubmitting;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cx(styles.row, {[styles.wizard]: wizard})}>
          <div className={styles.cell}>
            <SelectedPlan
              name={recurringCharge.product.name}
              subscriberLimitTo={recurringCharge.product.subscriberLimitTo}
              billingPeriod={recurringCharge.billingPeriod}
              description={recurringCharge.product.description}
              price={recurringCharge.amount.formattedValue}
            />
            <div className={styles.contentWrapper}>
              <div className={styles.inner}>
                <Subtitle>Billing Details</Subtitle>
                <BillingDetails data={billingDetails} />
              </div>
              <div className={styles.inner}>
                <Subtitle>
                  <SecureIcon />
                  Secure Credit Card
                </Subtitle>
                {wizard ? <CreditCardForm errors={errors} control={control} /> : renderCardTile()}
                <PaymentMethodsIcon />
              </div>
            </div>
          </div>
          <div className={styles.cell}>{renderPreview()}</div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerInner}>
            <p className="text-centered">{wizard && chargeNotice}</p>
            <Button
              type="submit"
              variant="filled"
              color="green"
              disabled={disableSubmit}
              loading={formState.isSubmitting}
            >
              Buy Now
            </Button>
          </div>
          <CheckoutAgreement>{renderAgreementText()}</CheckoutAgreement>
        </div>
      </form>
    </div>
  );
}

Checkout.propTypes = {
  orderPreview: orderPreviewProp.isRequired,
  onSubmit: PropTypes.func.isRequired,
  chargeNotice: PropTypes.node,
  wizard: PropTypes.bool,
  defaultPaymentMethod: paymentMethodPropType,
  billingDetails: PropTypes.objectOf(PropTypes.string),
};

export default Checkout;
