import React from 'react';
import ReactModal from 'react-modal';
import TagManager from 'react-gtm-module';
import {createRoot} from 'react-dom/client';

import {BrowserRouter} from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';

import App from './App';

import './custom.scss';

function Root() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

ReactModal.setAppElement('#root');

if (process.env.NODE_ENV === 'production') {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

Bugsnag.start({
  appVersion: process.env.REACT_APP_VERSION,
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
  enabledReleaseStages: ['staging', 'production'],
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function logError(error) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

const root = createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary onError={logError} FallbackComponent={ErrorBoundaryFallback}>
    <Root />
  </ErrorBoundary>
);
