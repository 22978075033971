import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import PostalAddressFields from '../../settings/postal-address/PostalAddressFields';
import usePostalAddressInfo from '../hooks/usePostalAddressInfo';
import styles from './PostalAddressContent.module.scss';
import useCountries from '../../settings/countries/hooks/useCountries';

function PostalAddressContent({submitCallback}) {
  const {
    formState,
    errors,
    control,
    handleSubmit,
    register,
    handleFormSubmit,
  } = usePostalAddressInfo(submitCallback);
  const {dataMapped: countries} = useCountries({immediate: true});

  if (!countries) {
    return null;
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(handleFormSubmit)}>
      <PostalAddressFields
        countries={countries}
        errors={errors}
        control={control}
        register={register}
      />
      <div className={styles.submitBtnWrapper}>
        <Button
          type="submit"
          variant="filled"
          color="green"
          disabled={formState.isSubmitting}
          loading={formState.isSubmitting}
        >
          Submit
        </Button>
      </div>
    </form>
  );
}

PostalAddressContent.propTypes = {
  submitCallback: PropTypes.func,
};

export default PostalAddressContent;
