import cx from 'clsx';
import PropTypes from 'prop-types';
import FieldError from '../FieldError';
import Label from '../Label';
import styles from './Input.module.scss';

function Input({
  children,
  creditCardInput,
  label,
  placeholder,
  helpText,
  inputRef,
  disabled,
  required,
  error,
  hideError,
  append,
  noBottomSpace,
  className,
  classNameEl,
  dense,
  noLabelMarginTop,
  helperTextStyles,
  readOnly,
  ...props
}) {
  return (
    <div
      className={cx(styles.input, className, {
        [styles.disabled]: disabled || readOnly,
        [styles.elErr]: error,
        [styles.creditCardInput]: creditCardInput,
        [styles.bottomSpace]: !noBottomSpace,
      })}
    >
      <label className={noLabelMarginTop ? styles.labelNoMargin : styles.label}>
        {label && <Label>{label}</Label>}
        {children ?? (
          <div className={styles.group}>
            <input
              type="text"
              {...props}
              ref={inputRef}
              disabled={disabled}
              readOnly={readOnly}
              required={required}
              placeholder={placeholder}
              className={cx(styles.el, dense && styles.denseInputEl, classNameEl)}
            />
            {append && <span className={styles.groupText}>{append}</span>}
          </div>
        )}
      </label>
      {error && !hideError && <FieldError error={error} absolute />}
      {helpText && (
        <span className={styles.helpText} style={helperTextStyles}>
          {helpText}
        </span>
      )}
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  creditCardInput: PropTypes.bool,
  hideError: PropTypes.bool,
  required: PropTypes.bool,
  dense: PropTypes.bool,
  noBottomSpace: PropTypes.bool,
  label: PropTypes.string,
  helpText: PropTypes.string,
  append: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool]),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]),
  className: PropTypes.string,
  classNameEl: PropTypes.string,
  noLabelMarginTop: PropTypes.bool,
};

export default Input;
