import {Controller} from 'react-hook-form';
import Select from '../../../../../components/forms/Select';
import {REQUIRED_FIELD_MESSAGE} from '../../../../../components/forms/constants';
import styles from '../SegmentFilter.module.scss';

function SelectField({options = [], placeholder, ...props}) {
  return (
    <Controller
      {...props}
      rules={{required: REQUIRED_FIELD_MESSAGE}}
      as={Select}
      {...{options, placeholder}}
      noBottomSpace
      selectInputClassName={styles.selectInput}
    />
  );
}

export default SelectField;
