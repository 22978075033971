import {useState} from 'react';
import {useFreePlan} from '../context/FreePlanContext';
import EmailConfirmationModal from '../email-confirmation-modal/EmailConfirmationModal';
import PostalAddressModal from '../postal-address-modal/PostalAddressModal';
import BulkInformationModal from '../bulk-information-modal/BulkInformationModal';

class FreePlanVerificationError extends Error {}

export const useFreePlanModals = () => {
  const {state} = useFreePlan();
  const {profileEmailVerified, postalAddressCompleted} = state;
  const isAllVerified = profileEmailVerified && postalAddressCompleted;
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  let FreePlanModal;

  function checkFreePlanVerified() {
    if (!isAllVerified) {
      handleToggle();
      throw new FreePlanVerificationError();
    }
  }

  switch (true) {
    case !profileEmailVerified && postalAddressCompleted:
      FreePlanModal = () => <EmailConfirmationModal isOpen onRequestClose={handleToggle} />;
      break;
    case profileEmailVerified && !postalAddressCompleted:
      FreePlanModal = () => <PostalAddressModal isOpen onRequestClose={handleToggle} />;
      break;
    case !profileEmailVerified && !postalAddressCompleted:
      FreePlanModal = () => <BulkInformationModal isOpen onRequestClose={handleToggle} />;
      break;
    default:
      FreePlanModal = () => null;
  }

  return {
    isFreePlanModalOpen: isOpen,
    FreePlanModal,
    checkFreePlanVerified,
    isAllVerified,
    FreePlanVerificationError,
  };
};
