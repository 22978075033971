import {useEffect, useState, lazy, Suspense} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import NewslettersRouter from '../domain/newsletters/NewslettersRouter';
import PageNotFound from './PageNotFound';
import ROUTES from './routes';
import useFetch from '../hooks/useFetch';
import {fetchProfileService} from '../domain/settings/user-profile/service';
import useAuth from '../domain/auth/useAuth';
import SubscribersPage from '../domain/subscribers/SubscribersPage';
import Spinner from '../components/Spinner';
import SubscriptionFormRouter from '../domain/subscription-form/SubscriptionFormRouter';
import DashboardLayout from '../layouts/DashboardLayout';
import BoardingWizardRouter from './BoardingWizardRouter';
import useUpdateFreePlanInfo from '../hooks/useUpdateFreePlanInfo';
import SubscribersRouter from '../domain/subscribers/SubscribersRouter';
import AutomationsRouter from '../domain/automation/AutomationsRouter';

const SettingsRouter = lazy(() => import('../domain/settings/SettingsRouter'));

function AuthenticatedApp() {
  const [loading, setLoading] = useState(true);
  const {run: fetchProfileRequest} = useFetch(fetchProfileService);
  const {state, logout, setAccountData} = useAuth();
  const {updateFreePlanInfo} = useUpdateFreePlanInfo();

  useEffect(function () {
    async function init() {
      try {
        const accountData = await fetchProfileRequest();
        setAccountData(accountData);
        await updateFreePlanInfo();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        logout();
      }
    }

    init();
  }, []);

  return (
    <DashboardLayout>
      {!state.user || loading ? (
        <Spinner height={250} />
      ) : (
        <Suspense fallback={null}>
          <Switch>
            <Route path={ROUTES.index} exact>
              <SubscribersPage />
            </Route>
            <Route path={ROUTES.subscribers.index}>
              <SubscribersRouter />
            </Route>
            <Route path={ROUTES.newsletters.index}>
              <NewslettersRouter />
            </Route>
            <Route path={ROUTES.automation.index}>
              <AutomationsRouter />
            </Route>
            <Route path={ROUTES.settings.index}>
              <SettingsRouter />
            </Route>
            <Route path={ROUTES.boarding.index}>
              <BoardingWizardRouter />
            </Route>
            <Route path={ROUTES.signupForms.index}>
              <SubscriptionFormRouter />
            </Route>
            <Route path={[ROUTES.auth.login, ROUTES.auth.signup, ROUTES.auth.forgotPassword]}>
              <Redirect to={ROUTES.index} />
            </Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </Suspense>
      )}
    </DashboardLayout>
  );
}

export default AuthenticatedApp;
