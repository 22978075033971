import {get, post, put, del} from '../../../utils/api';
import {ENDPOINTS} from './constants';

// custom fields / subscriber fields / subscriber values
export async function fetchSubscriberFieldsService(apiSettings) {
  return get(ENDPOINTS.fetchSubscriberFields, null, apiSettings);
}

export async function createSubscriberFieldService(apiSettings, data) {
  return post(ENDPOINTS.fetchSubscriberFields, data, apiSettings);
}

export async function updateSubscriberFieldService(apiSettings, data) {
  return put(ENDPOINTS.fetchSubscriberFields, data, apiSettings);
}

export async function deleteSubscriberFieldService(apiSettings, customFieldID) {
  return del(ENDPOINTS.deleteSubscriberField(customFieldID), null, apiSettings);
}

// tags
export async function fetchTagsService(apiSettings) {
  return get(ENDPOINTS.fetchTags, null, apiSettings);
}

export async function createTagService(apiSettings, data) {
  return post(ENDPOINTS.fetchTags, data, apiSettings);
}

export async function updateTagService(apiSettings, data) {
  return put(ENDPOINTS.fetchTags, data, apiSettings);
}

export async function deleteTagService(apiSettings, tagID) {
  return del(ENDPOINTS.deleteTag(tagID), null, apiSettings);
}

// field matchers
export async function fetchFieldMatchersService(apiSettings) {
  return get(ENDPOINTS.fetchFieldMatchers, null, apiSettings);
}
