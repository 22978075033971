import PropTypes from 'prop-types';
import ColorField from 'components/forms/ColorField';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';

const SubscriptionColorField = ({paramPath, ...rest}) => {
  const [value, onChange] = useHandleConfigParam(paramPath);
  return <ColorField {...rest} value={value} onChange={onChange} />;
};

SubscriptionColorField.propTypes = {
  label: PropTypes.string.isRequired,
  paramPath: PropTypes.string.isRequired,
  canBeTransparent: PropTypes.bool,
};

export default SubscriptionColorField;
