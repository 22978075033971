import Card from '../../../../components/Card';
import styles from './ShareNewFormPage.module.scss';
import {generateSliderCodeSnippet} from '../../generators';
import useRouter from '../../../../hooks/useRouter';
import ROUTES from '../../../../Router/routes';

const ShareNewFormPage = () => {
  const {
    query: {formId},
    push,
  } = useRouter();

  const closePage = () => {
    push(ROUTES.signupForms.index);
  };

  return (
    <Card noPadding className={styles.card}>
      <div className={styles.cardHeader}>
        <h1>Yay! Your new Signup-Form is ready to go!</h1>
        <button type="button" onClick={closePage} className={styles.closeButton}>
          &#x2715;
        </button>
      </div>
      <div className={styles.cardBody}>
        <p>Copy this code snippet and put it wherever you want to display the form on your site:</p>
        <div className={styles.codeSnippet}>{generateSliderCodeSnippet(formId)}</div>
      </div>
      <div className={styles.cardFooter}>
        <p>
          If you need help setting this up, <a href="/">let us know!</a>
        </p>
      </div>
    </Card>
  );
};

export default ShareNewFormPage;
