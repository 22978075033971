import {Helmet} from 'react-helmet';
import Router from './Router';
import ContextProviders from './layouts/ContextProviders';
import usePageTracking from './hooks/usePageTracking';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const {changeTagManager} = usePageTracking();

  return (
    <ContextProviders>
      <Helmet titleTemplate="%s - Maildroppa" onChangeClientState={changeTagManager} />
      <ScrollToTop />
      <Router />
    </ContextProviders>
  );
}

export default App;
