import {ReactSortable} from 'react-sortablejs';
import Sortable, {Swap} from 'sortablejs';

import React from 'react';

import styles from './FormFieldsSelection.module.scss';
import toolbarStyles from '../Toolbar/Toolbar.module.scss';
import {SubscriptionFormBuilderContext} from '../../context';
import {removeField} from '../../context/actions';
import IconTrash from '../../../../img/trash-grey.png';
import ToolbarSection from '../Toolbar/ToolbarSection';

Sortable.mount(new Swap());

const FormFieldsSelection = () => {
  const {
    setSelectedElement,
    formState,
    setInputList,
    dispatch,
    setShowAddNewFieldModal,
  } = React.useContext(SubscriptionFormBuilderContext);

  const sortedList = React.useMemo(() => {
    return formState.inputFields.map((value) => ({value}));
  }, [formState]);

  const setSortedList = React.useCallback(
    (ls) => {
      setInputList(ls.map(({value}, indexPosition) => ({...value, indexPosition})));
    },
    [setInputList]
  );

  const removeItem = React.useCallback(
    (fieldName) => {
      dispatch(removeField(fieldName));
    },
    [dispatch]
  );

  return (
    <ToolbarSection title="Input fields" className={toolbarStyles.toolbarContent}>
      <ReactSortable
        swap
        list={sortedList}
        setList={setSortedList}
        handle={`.${styles.customField__dragIco}`}
      >
        {sortedList.map(({value}) => (
          <div className={styles.customField} value={value.name} key={value.name}>
            <div className={styles.customField__dragIco}>
              <span />
              <span />
              <span />
            </div>
            <input
              readOnly
              type="text"
              className={styles.customField__input}
              value={value.label || value.placeholder}
            />
            <button
              type="button"
              className={styles.customField__editIco}
              onClick={() => setSelectedElement(`field.${value.name}`)}
            >
              edit
            </button>

            <div
              className={styles.remove}
              style={{cursor: value.name === 'email' && 'inherit'}}
              onClick={() => value.name !== 'email' && removeItem(value.name)}
            >
              {value.name !== 'email' && <img src={IconTrash} alt="Remove field" />}
            </div>
          </div>
        ))}
      </ReactSortable>
      <button
        type="button"
        className={styles.customFields__addField}
        onClick={() => setShowAddNewFieldModal(true)}
      >
        Add Form Field
      </button>
    </ToolbarSection>
  );
};

export default FormFieldsSelection;
