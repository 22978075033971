import {useState} from 'react';
import {ReactFlowProvider} from 'reactflow';
import {Helmet} from 'react-helmet';
import 'reactflow/dist/style.css';
import Card from '../../../components/Card';
import {useAutomation} from '../stores/automation';
import AddStepDataModal from '../modals/AddStepDataModal';
import CardHeader from '../../../components/Card/CardHeader';
import BackButton from '../../../components/BackButton';
import ROUTES from '../../../Router/routes';
import Automation from './Automation';
import AutomationLayout from './AutomationLayout';
import AutomationSidebar from './AutomationSidebar';
import {AutomationFlowProvider} from '../stores/automationFlow';
import {automationStatus} from '../utils/automationFlow';
import DashboardFullscreenCssOverride from '../../../components/DashboardFullscreenCssOverride';
import styles from './AutomationPage.module.scss';

const AutomationHeader = () => {
  const {automationReport} = useAutomation();

  return (
    <CardHeader
      title={
        <BackButton to={ROUTES.automation.index} className={styles.backButton}>
          Automations
        </BackButton>
      }
    >
      <div className={styles.automationHeader}>
        {automationReport && (
          <>
            <span className={styles.statistics}>
              Active: <b>{automationReport?.activeSubscriberCount}</b>
            </span>
            <span className={styles.statistics}>
              Completed: <b>{automationReport?.completedSubscriberCount}</b>
            </span>
          </>
        )}
      </div>
    </CardHeader>
  );
};

const AutomationPage = () => {
  const [sidebarIsOpened, setSidebarIsOpened] = useState(true);
  const {isAddModalOpen, fieldsLoading, automationData} = useAutomation();

  const handleSidebarToggle = () => setSidebarIsOpened((isOpened) => !isOpened);

  const shouldShowSidebar = automationData?.status !== automationStatus.ARCHIVED;

  return (
    <>
      <Helmet title="Automation" />
      <DashboardFullscreenCssOverride />
      <Card
        noMargin
        noPadding
        noMaxWidth
        backgroundColor="#f7f8fc"
        loading={fieldsLoading}
        cardStyles={{marginBottom: 0}}
        cardInnerStyles={{marginBottom: 0, height: 'calc(100vh - 75px)'}}
        header={<AutomationHeader />}
      >
        <ReactFlowProvider>
          <AutomationFlowProvider>
            {isAddModalOpen && <AddStepDataModal />}
            <AutomationLayout
              sidebarIsOpened={sidebarIsOpened}
              main={<Automation />}
              sidebar={
                shouldShowSidebar && (
                  <AutomationSidebar
                    sidebarIsOpened={sidebarIsOpened}
                    toggleSidebar={handleSidebarToggle}
                  />
                )
              }
            />
          </AutomationFlowProvider>
        </ReactFlowProvider>
      </Card>
    </>
  );
};

export default AutomationPage;
