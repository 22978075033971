import {API_REQUEST_TIMEOUT} from '../constants';

function networkTimeout(networkPromise, abortController, ms = API_REQUEST_TIMEOUT) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(function () {
      if (abortController) {
        abortController.abort();
      }
      reject(new Error('Timeout was reached.'));
    }, ms);

    networkPromise
      .then((response) => {
        clearTimeout(timer);
        resolve(response);
      })
      .catch(reject);
  });
}

export default networkTimeout;
