export const TEMPLATE_TYPE = {
  SYSTEM: 'SYSTEM',
  CUSTOM: 'CUSTOM',
};

export const TEMPLATE_CATEGORY = {
  BULK_MAIL: 'BULK_MAIL',
  TRANSACTIONAL: 'TRANSACTIONAL',
  WELCOME_EMAIL: 'WELCOME_EMAIL',
  CONFIRMATION_EMAIL: 'CONFIRMATION_EMAIL',
  SEASONAL: 'SEASONAL',
};

export const categoryOptions = Object.keys(TEMPLATE_CATEGORY).map((key) => ({
  label: key.replace(/(_)\w+/, '').toLowerCase(),
  value: key,
}));
