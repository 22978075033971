import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDataTableContext} from './context/TableContext';
import useDataTableFetcher from './useDataTableFetcher';
import DataTable from './DataTable';
import {handleErrorAlert} from '../../utils/handleErrorAlert';
import useAlert from '../../domain/alerts/useAlert';

function DataTableContainer({
  children,
  fetcher,
  filters,
  onDelete,
  onBulkDelete,
  onExport,
  actionModeSettings,
  ...props
}) {
  const {
    state,
    search,
    setFilters,
    setData,
    setFetcher,
    setLoading,
    bulkDelete,
  } = useDataTableContext();
  const {showAlert} = useAlert();
  const [tableFetcher] = useDataTableFetcher();
  const actionModeContent = actionModeSettings[state.mode] ?? {};
  const {submitHandler} = actionModeContent;

  useEffect(function () {
    async function setup() {
      if (filters) {
        setFilters(filters);
      }
      setFetcher(fetcher);
      const response = await fetcher();
      setData(response);
      setLoading(false);
    }

    setup();
  }, []);

  async function handleModalActionSubmit(values) {
    if (typeof submitHandler !== 'function') {
      return;
    }

    await submitHandler(values);
    const response = await tableFetcher();
    setData(response);
  }

  async function handleSearch(text) {
    const response = await tableFetcher({
      query: text,
      page: 1,
    });
    search({text, response});
  }

  function handlePartialExport() {
    if (state.selectedRows.length) {
      onExport(state.selectedRows);
    }
  }

  async function handleBulkDelete() {
    const isDeleteAll = state.mode === 'delete-all';
    const data = isDeleteAll ? undefined : state.selectedRows;
    try {
      await onBulkDelete(data);
      const response = await tableFetcher();
      if (isDeleteAll) {
        setData(response);
      } else {
        bulkDelete({response});
      }
    } catch (error) {
      handleErrorAlert(error, showAlert);
      throw error;
    }
  }

  return (
    <DataTable
      {...props}
      onBulkDelete={handleBulkDelete}
      onPartialExport={handlePartialExport}
      onActionSubmit={handleModalActionSubmit}
      onSearch={handleSearch}
      onDelete={onDelete}
      onExport={onExport}
      actionModeContent={actionModeContent}
    >
      {children}
    </DataTable>
  );
}

DataTableContainer.defaultProps = {
  hideSelection: false,
  searchPlaceholder: '',
};

DataTableContainer.propTypes = {
  headerCells: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired,
  fetcher: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  hideSelection: PropTypes.bool,
  createButtonText: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  createButtonTrigger: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onExport: PropTypes.func,
  customFilter: PropTypes.shape({
    targetFilter: PropTypes.string,
    node: PropTypes.node,
  }),
  actionModeSettings: PropTypes.objectOf(PropTypes.object).isRequired,
  filters: PropTypes.objectOf(PropTypes.string),
};

export default DataTableContainer;
