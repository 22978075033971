import {useState} from 'react';
import useFetch from '../../../hooks/useFetch';
import {saveDraftService, updateDraftService} from '../service';

function useSaveDraft(newsletterID, isNewDraft = false, {onSave, onUpdate}) {
  const [draftProcessing, setDraftProcessing] = useState(false);

  const {run: saveDraftRequest} = useFetch(saveDraftService, {throwError: true});
  const {run: updateDraftRequest} = useFetch(updateDraftService, {throwError: true});

  async function saveDraft(values) {
    setDraftProcessing(true);
    try {
      if (!isNewDraft) {
        await updateDraftRequest(values);
        onUpdate?.();
        return newsletterID;
      }

      const response = await saveDraftRequest(values);
      onSave?.();
      return response.id;
    } finally {
      setDraftProcessing(false);
    }
  }

  return {draftProcessing, saveDraft};
}

export default useSaveDraft;
