import {useMemo} from 'react';

import useGetValueType from './use-get-value-type';

const useGetValueName = (triggerValue, optionType, data) => {
  const {dataCustomFields, dataTags, formData} = data;
  const valueType = useGetValueType(optionType);

  return useMemo(() => {
    if (triggerValue) {
      if (valueType === 'Field' || valueType === 'Date') {
        return dataCustomFields.find((field) => field.id === triggerValue)?.name;
      }

      if (valueType === 'Tag') {
        return dataTags.find((tag) => tag.id === triggerValue)?.name;
      }

      if (valueType === 'Form') {
        return formData.find((form) => form.id === triggerValue)?.name;
      }
    }
  }, [triggerValue, dataCustomFields, dataTags, formData, valueType]);
};

export default useGetValueName;
