export const chunk = (array, chunkSize) =>
  Array(Math.ceil(array.length / chunkSize))
    .fill()
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize));

export const selectMapper = (valueKey = 'value', labelKey = 'label') => (v) => {
  return {
    value: v[valueKey],
    label: v[labelKey],
  };
};

export const findById = (list, id) => list.find((i) => i.id === id);

export const findAllById = (list, id) => list.filter((i) => i.id === id);

export const removeByIds = (list, ids) => list.filter((i) => !ids.includes(i.id));
