import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './Textarea.module.scss';
import Asterisk from '../../Asterisk';
import FieldError from '../FieldError';

function TextArea({
  children,
  label,
  placeholder,
  helpText,
  inputRef,
  disabled,
  required,
  error,
  hideError,
  append,
  noBottomSpace = false,
  ...props
}) {
  return (
    <div
      className={cx(styles.input, {
        [styles.disabled]: disabled,
        [styles.elErr]: error,
        [styles.bottomSpace]: !noBottomSpace,
      })}
    >
      <label className={styles.label}>
        {label && (
          <span className={styles.labelText}>
            {label}
            {required && <Asterisk />}
          </span>
        )}
        {children ?? (
          <div className={styles.group}>
            <textarea
              {...props}
              ref={inputRef}
              disabled={disabled}
              placeholder={placeholder}
              className={styles.textarea}
            />
            {append && <span className={styles.groupText}>{append}</span>}
          </div>
        )}
      </label>
      {error && !hideError && <FieldError error={error} absolute />}
      {helpText && <span className={styles.helpText}>{helpText}</span>}
    </div>
  );
}

TextArea.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]),
};

export default TextArea;
