export const createEdge = (sourceId, targetId, options = {}) => ({
  id: `e${targetId}${sourceId}`,
  source: sourceId,
  target: targetId,
  ...options,
});

export const CONDITION = {
  TRUE: true,
  FALSE: false,
};

export const createConditionalEdge = (sourceId, targetId, edgeType, options = {}) =>
  createEdge(sourceId, targetId, {
    ...options,
    data: {
      isYes: edgeType,
      ...options.data,
    },
    type: 'conditionalEdge',
  });

export const removeEdgeByHandleId = (edges, handleId) =>
  edges.filter((edge) => edge.source !== handleId && edge.target !== handleId);

// types: to | from
export const findEdgesForNode = (edges, nodeId, type) => {
  const handle = type === 'to' ? 'target' : 'source';
  return edges.filter((edge) => edge[handle] === nodeId);
};

export const findEdgesToNode = (edges, targetNodeId) => findEdgesForNode(edges, targetNodeId, 'to');

export const findEdgesFromNode = (edges, sourceNodeId) =>
  findEdgesForNode(edges, sourceNodeId, 'from');
