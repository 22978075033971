import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from '../../../components/chart/TooltipContent.module.scss';
import {numberWithSpaces} from '../../../utils/strings';

function SubscribersReportTooltipContent({active, payload, label, nameFormatter, keys}) {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const tooltipData = [payload[0].name, ...keys].map((key) => ({
    name: key,
    value: payload[0].payload[key],
  }));

  return (
    <div className={styles.main}>
      <span className={styles.label} style={{color: payload[0].color}}>
        {label}
      </span>
      {tooltipData.map(({name, value}) => (
        <div key={name} className={clsx(styles.payload, name === payload[0].name && 'text-medium')}>
          {nameFormatter(name)}: <span className={styles.value}>{numberWithSpaces(value)}</span>
        </div>
      ))}
    </div>
  );
}

SubscribersReportTooltipContent.defaultProps = {
  active: false,
  payload: [],
  label: '',
  nameFormatter: (name) => name,
  keys: [],
};

SubscribersReportTooltipContent.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  nameFormatter: PropTypes.func,
  keys: PropTypes.arrayOf(PropTypes.string),
};

export default SubscribersReportTooltipContent;
