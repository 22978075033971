import {get, post} from '../../utils/api';
import {ENDPOINTS} from './constants';

export async function fetchAuditStatusService(apiSettings) {
  return get(ENDPOINTS.fetchAuditStatus, null, apiSettings);
}

export async function initAuditService(apiSettings, bulMailId) {
  return post(ENDPOINTS.initAudit(bulMailId), null, apiSettings);
}
