const ROUTES = {
  index: '/',
  salesPage: 'https://maildroppa.com/',
  publicPagesPrefix: '/p',
  confirmEmail: '/confirm-email',
  downloadExport: '/export',
  signupForms: {
    index: '/signup-forms',
    create: '/signup-forms/:formType/new',
    edit: '/signup-forms/:formId/edit',
    share: '/signup-forms/:formType/:formId/share',
    createTypeSelection: '/signup-forms/create',
  },
  auth: {
    login: '/login',
    signup: '/sign-up',
    signupSuccess: '/sign-up/success',
    forgotPassword: '/forgot-password',
  },
  subscribers: {
    index: '/subscribers',
    create: '/subscribers/create',
    edit: '/subscribers/:subscriberID',
  },
  newsletters: {
    index: '/newsletter',
    create: '/newsletter/create',
    sent: '/newsletter/sent',
    failed: '/newsletter/failed',
    edit: '/newsletter',
    report: '/newsletter/report',
    templates: '/newsletter/templates',
    templateEditor: '/newsletter/templates/editor',
    templateSelect: '/newsletter/templates/select',
  },
  automation: {
    index: '/automation',
    createAutomation: '/automation/create',
  },
  settings: {
    index: '/settings',
    segments: {
      index: '/settings/segments',
      create: '/settings/segments/create',
      edit: '/settings/segments/:segmentID',
    },
    transactionalEmails: {
      index: '/settings/transactional-emails',
      create: '/settings/transactional-emails/create',
    },
    newsletterTemplates: '/newsletter/templates',
    subscriberFields: '/settings/subscriber-fields',
    editFormText: '/settings/subscription-flow',
    userProfile: '/settings/user-profile',
    postalAddress: '/settings/postal-address',
    checkout: '/settings/subscription/checkout',
    orderSuccess: '/settings/subscription/order-success',
    plans: '/settings/subscription/plans',
    subscriptionTrialCancelSuccess: '/settings/subscription/cancel/trial-success',
    subscriptionCancelSuccess: '/settings/subscription/cancel/success',
    unsubscribe: '/settings/subscription/cancel',
    subscription: '/settings/subscription',
    emailSender: '/settings/email-sender',
    billing: '/settings/billing',
    invoices: '/settings/invoices',
    marketingPermissions: '/settings/marketing-permissions',
    affiliate: '/settings/affiliate',
  },
  boarding: {
    index: '/boarding',
    plans: '/boarding/plans',
    billing: '/boarding/billing',
    checkout: '/boarding/checkout',
    success: '/boarding/success',
  },
};

export default ROUTES;
