import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Spinner from '../Spinner';
import useAuth from '../../domain/auth/useAuth';
import StatusMessage from '../StatusMessage/StatusMessage';
import styles from './ConfirmationCard.module.scss';
import ROUTES from '../../Router/routes';

function ConfirmationCard({children, errorMessage, title, image, processing}) {
  const {state: authState} = useAuth();
  const redirectUrl = authState.isLoggedIn ? ROUTES.index : ROUTES.auth.login;

  function renderContent() {
    if (errorMessage) {
      return <StatusMessage message={errorMessage} />;
    }

    return (
      <>
        {image && <img src={image} width="323" height="269" alt="Confirm icon" loading="lazy" />}
        {title && <h1 className={styles.title}>{title}</h1>}
        {children || (
          <>
            <p className="text text-centered">
              Please wait 5 seconds to be automatically redirected to the{' '}
              {authState.isLoggedIn ? 'home' : 'login'} page.
            </p>
            <p className="text text-centered">
              If you are not redirected, please click this{' '}
              <Link to={redirectUrl} className={styles.link}>
                link
              </Link>
            </p>
          </>
        )}
      </>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>{processing ? <Spinner /> : renderContent()}</div>
    </div>
  );
}

ConfirmationCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  processing: PropTypes.bool,
};

export default ConfirmationCard;
