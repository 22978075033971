import {Elements as StripeElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import fontSrc from '../fonts/Gilroy-Regular/font.woff';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function StripeElementsProvider({children}) {
  return (
    <StripeElements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            family: 'Gilroy',
            src: `url(${fontSrc}) format("woff")`,
            weight: '400',
          },
        ],
        locale: 'en',
      }}
    >
      {children}
    </StripeElements>
  );
}

export default StripeElementsProvider;
