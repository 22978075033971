const DoubleChevron = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_11992_1092)">
      <path d="M6.77003 12.0001C6.3025 11.9981 5.92507 11.6128 5.92706 11.1395C5.92785 10.9121 6.01794 10.6948 6.17749 10.5345L10.0721 6.60878C10.4042 6.27531 10.4062 5.73293 10.0768 5.39665C10.0752 5.39504 10.0736 5.39343 10.0721 5.39182L6.17749 1.46615C5.8453 1.13268 5.84332 0.590297 6.17273 0.254018C6.17431 0.252411 6.1759 0.250804 6.17749 0.249196C6.50769 -0.0830655 7.04109 -0.0830655 7.3713 0.249196L11.2575 4.18331C12.2481 5.18732 12.2481 6.81368 11.2575 7.8173L7.3713 11.7514C7.21135 11.9117 6.99506 12.0013 6.77003 12.0001Z" />
      <path d="M0.843025 11.9999C0.375503 11.9979 -0.00192833 11.6126 5.60613e-05 11.1394C0.000849817 10.912 0.0909411 10.6946 0.250486 10.5343L4.72925 6.00034L0.250486 1.4664C-0.0817008 1.13293 -0.0836852 0.590541 0.245723 0.254262C0.247311 0.252655 0.248898 0.251048 0.250486 0.249441C0.580688 -0.0828214 1.11409 -0.0828214 1.44429 0.249441L6.52433 5.39207C6.85652 5.72554 6.8585 6.26792 6.52909 6.6042C6.52751 6.60581 6.52592 6.60742 6.52433 6.60902L1.44429 11.7517C1.28435 11.912 1.06805 12.0015 0.843025 12.0003V11.9999Z" />
    </g>
    <defs>
      <clipPath id="clip0_11992_1092">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DoubleChevron;
