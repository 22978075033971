import queryString from 'query-string';
import request from './request';

export async function get(endpoint, params, apiSettings, options) {
  const formattedUrl = params ? `${endpoint}?${queryString.stringify(params)}` : endpoint;
  return request(formattedUrl, {...options, method: 'GET'}, apiSettings);
}

export async function put(endpoint, body, apiSettings, options) {
  return request(endpoint, {...options, method: 'PUT', body}, apiSettings);
}

export async function post(endpoint, body, apiSettings, options) {
  return request(endpoint, {...options, method: 'POST', body}, apiSettings);
}

export async function del(endpoint, body, apiSettings, options) {
  return request(endpoint, {...options, method: 'DELETE', body}, apiSettings);
}

export async function patch(endpoint, body, apiSettings, options) {
  return request(endpoint, {...options, method: 'PATCH', body}, apiSettings);
}
