import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './FlowTooltip.module.scss';

const calculateSizeRegardlessOfZoom = (initialSize, zoomLevel) => initialSize / zoomLevel;

const FlowTooltip = ({zoomLevel, children, className}) => {
  const initialScale = 1;
  const scaleToKeepInitialVisualSize = calculateSizeRegardlessOfZoom(initialScale, zoomLevel);

  return (
    <div
      style={{transform: `scale(${scaleToKeepInitialVisualSize})`}}
      className={cx(styles.container, className)}
    >
      <div className={styles.boxToMaintainTransition}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

FlowTooltip.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FlowTooltip;
