import {getComponentStyle, getTextStyles} from './service';

import {hexToRgbA} from '../../utils/hexToRgba';

async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export async function getDetailsHash() {
  {
    const unknown = '-';

    // screen
    var screenSize = '';
    if (screen.width) {
      const width = screen.width ? screen.width : '';
      const height = screen.height ? screen.height : '';
      screenSize += `${width} x ${height}`;
    }

    // browser
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    var browser = navigator.appName;
    let version = `${parseFloat(navigator.appVersion)}`;
    var majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset;
    let verOffset;
    let ix;

    // Opera
    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf('OPR')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 4);
    }
    // Legacy Edge
    else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
      browser = 'Microsoft Legacy Edge';
      version = nAgt.substring(verOffset + 5);
    }
    // Edge (Chromium)
    else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
      browser = 'Microsoft Edge';
      version = nAgt.substring(verOffset + 4);
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(verOffset + 5);
    }
    // Chrome
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
      browser = 'Chrome';
      version = nAgt.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
      browser = 'Safari';
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
      browser = 'Firefox';
      version = nAgt.substring(verOffset + 8);
    }
    // MSIE 11+
    else if (nAgt.indexOf('Trident/') != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    }
    // Other browsers
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() == browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

    majorVersion = parseInt(`${version}`, 10);
    if (isNaN(majorVersion)) {
      version = `${parseFloat(navigator.appVersion)}`;
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    // mobile version
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // cookie
    let cookieEnabled = !!navigator.cookieEnabled;

    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') != -1;
    }

    // system
    var os = unknown;
    const clientStrings = [
      {s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/},
      {s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/},
      {s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/},
      {s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/},
      {s: 'Windows Vista', r: /Windows NT 6.0/},
      {s: 'Windows Server 2003', r: /Windows NT 5.2/},
      {s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/},
      {s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/},
      {s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/},
      {s: 'Windows 98', r: /(Windows 98|Win98)/},
      {s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/},
      {s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
      {s: 'Windows CE', r: /Windows CE/},
      {s: 'Windows 3.11', r: /Win16/},
      {s: 'Android', r: /Android/},
      {s: 'Open BSD', r: /OpenBSD/},
      {s: 'Sun OS', r: /SunOS/},
      {s: 'Chrome OS', r: /CrOS/},
      {s: 'Linux', r: /(Linux|X11(?!.*CrOS))/},
      {s: 'iOS', r: /(iPhone|iPad|iPod)/},
      {s: 'Mac OS X', r: /Mac OS X/},
      {s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
      {s: 'QNX', r: /QNX/},
      {s: 'UNIX', r: /UNIX/},
      {s: 'BeOS', r: /BeOS/},
      {s: 'OS/2', r: /OS\/2/},
      {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    for (let i = 0; i < clientStrings.length; i++) {
      const cs = clientStrings[i];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    var osVersion = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS':
      case 'Mac OS X':
      case 'Android':
        osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(
          nAgt
        )[1];
        break;

      case 'iOS':
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = `${osVersion[1]}.${osVersion[2]}.${osVersion[3] | 0}`;
        break;
    }

    // flash (you'll need to include swfobject)
    /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
    let flashVersion = 'no check';
    if (typeof swfobject !== 'undefined') {
      const fv = swfobject.getFlashPlayerVersion();
      if (fv.major > 0) {
        flashVersion = `${fv.major}.${fv.minor} r${fv.release}`;
      } else {
        flashVersion = unknown;
      }
    }
  }

  const details = {
    screen: screenSize,
    browser,
    browserVersion: majorVersion,
    // mobile: mobile,
    os,
    osVersion,
    // cookies: cookieEnabled,
    // flashVersion: flashVersion,
  };

  const hashedDetails = await sha256(JSON.stringify(details));

  return hashedDetails;
}

const colorShade = (color, amt) => {
  let col = color.replace(/^#/, '');
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `${rr}${gg}${bb}`;
};

export const getMinimizedAnimationClass = (jsonConfig, styles, sliderKey) => {
  if (jsonConfig.sliderPopupPosition === 'CENTER_RIGHT') {
    return sliderKey === 'sliderState1'
      ? styles.animationCenterRight
      : styles.reverseAnimationCenterRight;
  }
  if (jsonConfig.sliderPopupPosition.includes('TOP')) {
    return sliderKey === 'sliderState1' ? styles.animationTop : styles.reverseAnimationTop;
  }
  if (jsonConfig.sliderPopupPosition.includes('BOTTOM')) {
    return sliderKey === 'sliderState1' ? styles.animationBottom : styles.reverseAnimationBottom;
  }

  if (jsonConfig.sliderPopupPosition.includes('CENTER')) {
    return sliderKey === 'sliderState1' ? styles.animationCenter : styles.reverseAnimationCenter;
  }
};

export const getExpandedAnimationClass = (jsonConfig, styles) => {
  if (jsonConfig.sliderPopupPosition === 'CENTER_RIGHT') {
    return styles.animationCenterRight;
  }
  if (jsonConfig.sliderPopupPosition.includes('TOP')) {
    return styles.animationTop;
  }
  if (jsonConfig.sliderPopupPosition.includes('BOTTOM')) {
    return styles.animationBottom;
  }
  if (jsonConfig.sliderPopupPosition.includes('CENTER')) {
    return styles.animationCenter;
  }
};

export const renderPercentProgressBar = (body, styles) => {
  return (
    <div
      className="md-embedded-form--progress-bar-type-percent"
      style={getTextStyles(body.progressBarPercentStyle.text)}
    >
      {body.progressBarPercentStyle?.text?.value || ''}
      <div className={styles.percentBarWrapper}>
        <div
          className={styles.percentBar}
          style={{
            backgroundColor: body.progressBarPercentStyle.backgroundColor,
          }}
        />
      </div>
    </div>
  );
};

export const renderOneTwoProgressBar = (body, styles) => {
  return (
    <div className="md-embedded-form--slider-type-step">
      <div className={styles.stepsWrapper}>
        <div
          className={styles.stepOneContainer}
          style={{
            backgroundColor: body.progressBarStepStyle?.backgroundColorActive,
          }}
        >
          <span className={styles.stepIndicator}>1</span>{' '}
          {body.progressBarStepStyle?.text?.stepOneValue || ''}
        </div>
        <div
          className={styles.stepTwoContainer}
          style={{
            backgroundColor: body.progressBarStepStyle?.backgroundColorInactive,
          }}
        >
          <span className={styles.stepIndicator}>2</span>{' '}
          {body.progressBarStepStyle?.text?.stepTwoValue || ''}
        </div>
      </div>
    </div>
  );
};

export const getFooterBackground = (formState) => {
  const parsedBgColor = formState.jsonConfig.mainBackgroundColor.replace('#', '');
  const c = colorShade(parsedBgColor, -10);
  const rgb = parseInt(parsedBgColor, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  const decimal = parseInt(c, 16);
  let hexStr = decimal.toString(16);

  if (luma < 20 || hexStr.startsWith('fff') || luma > 240 || (r < 133 && b > 230)) {
    hexStr = '2B3254';
  }
  return {backgroundColor: `#${hexStr.length < 5 ? `00${hexStr}` : hexStr}`};
};

export const renderFormSubmitButton = (button, formState, fullWidth, updateJsonInput) => {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div
        className="md-embedded-form--btn-wrapper"
        style={{width: button.width && !fullWidth ? button.width : '100%'}}
      >
        <button
          type="submit"
          className="md-embedded-form--btn"
          style={
            (getTextStyles(button.text),
            {boxShadow: `0 7px 15px ${hexToRgbA(button.backgroundColor, '.26')}`})
          }
          onInput={updateJsonInput}
          suppressContentEditableWarning
          ref={(node) => {
            if (node) {
              const buttonStyles = getComponentStyle(formState.jsonConfig.button);
              node.style.setProperty('border-color', buttonStyles?.borderColor, 'important');
              node.style.setProperty('border-radius', buttonStyles.borderRadius, 'important');
              node.style.setProperty('border-style', buttonStyles.borderStyle, 'important');
              node.style.setProperty('border-width', buttonStyles.borderWidth, 'important');
              node.style.setProperty('background-color', buttonStyles.backgroundColor, 'important');
            }
          }}
        >
          {button.text.value}
        </button>
      </div>
    </div>
  );
};

export const getCaretCharacterOffsetWithin = (element) => {
  let caretOffset = 0;
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  let sel;

  if (typeof win.getSelection !== 'undefined') {
    sel = win.getSelection();

    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const preCaretRange = range.cloneRange();

      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);

      caretOffset = preCaretRange.toString().length;
    }
  } else if (sel == doc.selection && sel.type != 'Control') {
    const textRange = sel.createRange();
    const preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint('EndToEnd', textRange);
    caretOffset = preCaretTextRange.text.length;
  }

  return caretOffset;
};

export const setCaret = (el, offset) => {
  const range = document.createRange();
  const sel = window.getSelection();

  if (!el.textContent.length) return;
  range.setStart(el.childNodes[0], offset);
  range.collapse(true);
  sel.removeAllRanges();

  sel.addRange(range);
  el.focus();
};
