import {europeanCountryCodes} from '../constants';
import {get} from '../api';
import {ENDPOINTS} from '../../domain/settings/billing/constants';

export function isEuropeanCountry(country) {
  return europeanCountryCodes.includes(country);
}

export async function validateVATCode(data, apiSettings) {
  if (data.vatNumber === '') {
    return true;
  }

  try {
    await get(ENDPOINTS.validateVat, data, apiSettings);
    return true;
  } catch (error) {
    return 'VAT code invalid';
  }
}
