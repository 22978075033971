import {get, post, put, del} from '../../../utils/api';
import {ENDPOINTS, FILTER_OPERATIONS, FILTER_TYPES} from './constants';
import {getRandomInt} from '../../../utils/numbers';

// requests

export async function fetchSegmentsService(apiSettings) {
  return get(ENDPOINTS.fetchSegments, null, apiSettings);
}

export async function fetchSegmentService(apiSettings, segmentID) {
  return get(ENDPOINTS.deleteSegment(segmentID), null, apiSettings);
}

export async function createSegmentService(apiSettings, data) {
  return post(ENDPOINTS.fetchSegments, data, apiSettings);
}

export async function updateSegmentService(apiSettings, data) {
  return put(ENDPOINTS.deleteSegment(data.id), data, apiSettings);
}

export async function deleteSegmentService(apiSettings, segmentID) {
  return del(ENDPOINTS.deleteSegment(segmentID), null, apiSettings);
}

// utils

export const getNewFilter = () => ({
  id: Date.now(),
  type: 'attributeId',
  operation: null,
  customFieldId: null,
  value: null,
});

export const getNewFilterGroup = () => ({
  id: Date.now(),
  matchingType: 'all',
  filters: [getNewFilter()],
});

export const getFilterType = (type) => FILTER_TYPES.find((v) => v.value === type);

export const mapForForm = (filterGroups) => {
  const rootFilters = {operator: 'OR', filterGroups: []};
  filterGroups.forEach((v) => {
    const finalFilterGroup = v.filters.map((v) => {
      if (v.type === 'attributeId') {
        return {
          type: 'field',
          matcher: v.operation,
          id: v.customFieldId,
          value: v.value,
        };
      }
      if (v.type === 'tags') {
        return {
          type: 'tag',
          matcher: v.operation,
          id: v.value,
        };
      }
      if (v.type === 'segmentId') {
        return {
          type: 'segment',
          matcher: v.operation,
          id: v.value,
        };
      }
    });
    rootFilters.filterGroups.push({elements: finalFilterGroup});
  });

  return rootFilters;
};

const generateNewFormFilter = (filter) => {
  const type = filter.type == 'field' ? 'attributeId' : filter.type == 'tag' ? 'tags' : 'segmentId';
  const res = getNewFilter();
  res.type = type;
  res.operation = filter.matcher;
  res.value = filter.value || filter.id;
  res.customFieldId = type === 'attributeId' ? filter.id : null;
  return res;
};

export const unmapForForm = (expression) => {
  if (!expression?.filterGroups?.length) {
    return [getNewFilterGroup()];
  }
  const filterGroups = [];
  expression.filterGroups.forEach((v) => {
    let filters = [];
    filters = v.elements.map(generateNewFormFilter).filter(Object);
    filterGroups.push({
      id: Date.now() + getRandomInt(100, 10000),
      matchingType: 'all',
      filters,
    });
  });

  return filterGroups;
};
