import React from 'react';

import ToolbarSection from './ToolbarSection';
import TextField from './TextField';
import SubscriptionColorField from './SubscriptionColorField';
import SizeField from './SizeField';
import styles from './Toolbar.module.scss';
import FieldCommonStyles from './FieldCommonStyles';
import {SubscriptionFormBuilderContext} from '../../context';
import {
  BUILDER_MAX_BORDER_RADIUS,
  BUILDER_MAX_BORDER_SIZE,
  FONT_FAMILY_OPTIONS,
  FREQUENCY_OPTIONS,
} from '../../constants';
import SelectField from './SelectField';
import SubscriptionCount from './SubscriptionCount';
import FormFieldsSelection from '../FormFieldsSelection/FormFieldsSelection';
import FormPositionSelector from '../FormPositionSelector/FormPositionSelector';
import ProgressBarTypes from './ProgressBarTypes';
import FormTagsSelection from '../FormTagsSelection/FormTagsSelection';
import PopupWidgetCheckbox from './PopupWidgetCheckbox';

const GeneralStyles = () => {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);

  return (
    <>
      <ToolbarSection title="General Styles" className={styles.toolbarContent}>
        <TextField label="Form Name" paramPath="name" />
        <FormPositionSelector />
        <SubscriptionColorField
          label="Background Color"
          paramPath="jsonConfig.mainBackgroundColor"
          canBeTransparent
        />
        <SizeField
          label="Border radius"
          paramPath="jsonConfig.mainBorder.radius"
          max={BUILDER_MAX_BORDER_RADIUS}
        />
        <SizeField
          label="Border Size"
          paramPath="jsonConfig.mainBorder.size"
          max={BUILDER_MAX_BORDER_SIZE}
        />
        {formState.jsonConfig.mainBorder.size > 0 ? (
          <SubscriptionColorField label="Border Color" paramPath="jsonConfig.mainBorder.color" />
        ) : null}
        <SelectField
          label="Font Family"
          paramPath="jsonConfig.mainFontFamily"
          options={FONT_FAMILY_OPTIONS}
          helpText={
            formState.jsonConfig.mainFontFamily === 'inherit'
              ? 'Form will use main font from your website. The preview might be different.'
              : ''
          }
          isClearable
        />
        {formState.type !== 'INLINE' && (
          <>
            <TextField
              label="Display Delay"
              helpText="Number of seconds until the Signup-Form is displayed."
              paramPath="jsonConfig.delayTime"
              type="number"
            />
            <SelectField
              label="Display Frequency"
              helpText="Period after which the Signup-Form is displayed to a visitor again."
              paramPath="jsonConfig.frequency"
              options={FREQUENCY_OPTIONS}
            />
          </>
        )}
      </ToolbarSection>
      <FormFieldsSelection />
      <FormTagsSelection />

      <ProgressBarTypes />
      <FieldCommonStyles />
      <PopupWidgetCheckbox />
      <SubscriptionCount />
    </>
  );
};

export default GeneralStyles;
