import Alert from '../../../components/Alert';
import SubscribersLimitAlertContent from './SubscribersLimitAlertContent';

function SubscribersLimitAlert() {
  return (
    <Alert variant="error" persist>
      <SubscribersLimitAlertContent />
    </Alert>
  );
}

export default SubscribersLimitAlert;
