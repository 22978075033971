import {useMemo, useState} from 'react';
import {format} from 'date-fns';
import {
  Bar,
  ComposedChart,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
} from 'recharts';
import styles from '../../../subscribers/tabsContent/SubscribersReportChart.module.scss';
import stylesStatsTabs from '../../../subscribers/components/StatsTabs.module.scss';

import Spinner from '../../../../components/Spinner';
import {CARTESIAN_GRID_COLOR} from '../../../../components/chart/config';
import {REPORT_ENTRIES_MIN_COUNT_FOR_AREA} from '../../../subscribers/constants';
import SubscribersReportTooltipContent from '../../../subscribers/tabsContent/SubscribersReportTooltipContent';
import IntervalPicker from '../../../subscribers/components/IntervalPicker';
import DatePicker from '../../../subscribers/components/DatePicker';
import FormSelector from './formSelector';
import formReportTooltip from './formReportTooltip';

function FormsReportChart({entryType, reportData, loading, getReport, formList}) {
  const [fetchParams, setFetchParams] = useState();
  const [reportRange, setReportRange] = useState();
  const [activeFormName, setActiveFormName] = useState('All Forms');
  const [data, setData] = useState(reportData && reportData[0]);

  useMemo(() => {
    if (!fetchParams) return;
    getReport(fetchParams.fromDate, fetchParams.toDate, fetchParams.interval);
  }, [fetchParams, getReport]);

  useMemo(() => {
    if (activeFormName === 'All Forms') return;
    if (activeFormName && reportData) {
      setData(reportData.find((formData) => formData.formName === activeFormName));
    }
  }, [reportData]);

  const nameFormatter = (name) => {
    return name
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim()
      .replace('Count', '');
  };

  const CartesianComp = data?.entries?.length > REPORT_ENTRIES_MIN_COUNT_FOR_AREA ? Line : Bar;

  const getYAxisDomainMinMax = (minMax, sign) => {
    if (!Number.isFinite(minMax)) return sign > 0 ? 2 : -1;

    if (minMax === 0) {
      return minMax + sign;
    }
    const additionalRange = Math.max(Math.ceil(Math.log(Math.abs(minMax))), 1);
    return minMax + sign * additionalRange;
  };

  const handleIntervalChange = ({interval}) => {
    setFetchParams({
      ...fetchParams,
      interval,
    });
  };

  const handleDatesChange = ({startDate, endDate}) => {
    if (reportRange) {
      setReportRange(null);
      return;
    }

    setFetchParams({
      ...fetchParams,
      fromDate: format(startDate, 'yyyy-MM-dd'),
      toDate: format(endDate, 'yyyy-MM-dd'),
    });
  };

  const getColor = (passedForm) => {
    const selectedForm = formList.filter(
      (form) => (passedForm ? passedForm.formName : data.formName) === form.name
    );

    return selectedForm[0]?.color;
  };

  const getValues = (lineData, form) => {
    const index = form.entries.findIndex((obj) => obj.logDate === lineData.logDate);

    return form.entries[index].viewCount;
  };

  const getChartData = () => {
    return activeFormName === 'All Forms' ? reportData?.[0]?.entries : data?.entries;
  };

  const hasNoDataOrLoading = () => {
    if (!reportData || loading) {
      return true;
    }
    return false;
  };

  const noDataOrLoading = () => {
    if (loading) return <Spinner height={352} />;
    return <p className={styles.no_data}>There is no data for the selected period.</p>;
  };

  const getCartesian = (form) => {
    if (form.entries.length > REPORT_ENTRIES_MIN_COUNT_FOR_AREA) {
      return (
        <Line
          type="monotone"
          dataKey={(lineData) => getValues(lineData, form)}
          stroke={getColor(form)}
          dot={false}
          strokeWidth={2}
          fill={getColor(form)}
        />
      );
    }
    return (
      <Bar
        dataKey={(lineData) => getValues(lineData, form)}
        stroke={getColor(form)}
        fill={getColor(form)}
      />
    );
  };

  return (
    <section className={stylesStatsTabs.main}>
      <div className={stylesStatsTabs.tab_list} style={{padding: '10px 10px'}}>
        <FormSelector
          selectedForm={activeFormName}
          allForms={
            reportData
              ? ['All Forms', ...reportData.map((formData) => formData.formName)]
              : ['All Forms']
          }
          handleChange={(name) => {
            if (name !== 'All Forms')
              setData(reportData.find((formData) => formData.formName === name));
            setActiveFormName(name);
          }}
        />
        <IntervalPicker
          classes={stylesStatsTabs.intervalpicker}
          handleChange={handleIntervalChange}
        />
        <DatePicker
          classes={stylesStatsTabs.datepicker}
          handleChange={handleDatesChange}
          reportRange={reportRange}
        />
      </div>
      {hasNoDataOrLoading() ? (
        noDataOrLoading()
      ) : (
        <ResponsiveContainer width="100%" height={352}>
          <ComposedChart data={getChartData()} margin={{top: 40, right: 50, left: 25, bottom: 40}}>
            <CartesianGrid vertical={false} stroke={CARTESIAN_GRID_COLOR} />
            <XAxis dataKey="logDate" axisLine={false} tickLine={false} dy={15} />
            <YAxis
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
              domain={[
                (dataMin) => getYAxisDomainMinMax(dataMin, -1),
                (dataMax) => getYAxisDomainMinMax(dataMax, 1),
              ]}
              dx={-10}
            />

            {activeFormName === 'All Forms' ? (
              <>
                <Tooltip
                  content={(tooltipData) => formReportTooltip(tooltipData, getColor, reportData)}
                />
                {reportData.map((form) => getCartesian(form))}
              </>
            ) : (
              <>
                <defs>
                  <linearGradient id="forms-report" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0% " stopColor={entryType.color} stopOpacity={0.95} />
                    <stop offset="100%" stopColor={entryType.color} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Tooltip
                  content={
                    <SubscribersReportTooltipContent
                      keys={entryType.tooltipKeys}
                      nameFormatter={nameFormatter}
                    />
                  }
                  cursor={{stroke: entryType.color, strokeWidth: 1}}
                />

                <CartesianComp
                  dataKey={entryType.dataKey}
                  stroke={getColor()}
                  type="monotone"
                  dot={false}
                  strokeWidth={2}
                />
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </section>
  );
}

export default FormsReportChart;
