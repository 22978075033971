import {useEffect, useState} from 'react';
import {Handle, Position, useReactFlow, useViewport} from 'reactflow';
import cx from 'clsx';

import {useIconOptions} from './TriggerNodeOption';
import ActionButtonsTooltip from '../shared/ActionButtonsTooltip';
import ConfigureTriggerModal from '../../modals/ConfigureTriggerModal';

import {useAutomation} from '../../stores/automation';

import styles from './TriggerNode.module.scss';

import useGetValueName from '../hooks/use-get-value-name';
import {NO_VALUE} from '../hooks/use-get-trigger-value-options';
import useValidStatus from '../hooks/use-valid-status';

const TriggerNode = (nodeData) => {
  const {id, type, data} = nodeData;

  const [showAddDataModal, setShowAddDataModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const {zoom} = useViewport();
  const reactFlowInstance = useReactFlow();
  const {dataCustomFields, dataTags, formData, automationData} = useAutomation();
  const valueName = useGetValueName(data.triggerValue, data.triggerLabel, {
    dataCustomFields,
    dataTags,
    formData,
  });
  const optionIcon = useIconOptions(data.triggerLabel);
  const nodes = reactFlowInstance.getNodes();
  const edges = reactFlowInstance.getEdges();
  const isValidStatus = useValidStatus(automationData?.status);

  const handleRemovingTrigger = () => {
    const filteredNodes = nodes.filter((n) => n.id !== id);
    const filteredEdges = edges.filter((e) => e.source !== id);
    reactFlowInstance.setNodes(filteredNodes);
    reactFlowInstance.setEdges(filteredEdges);
  };

  useEffect(() => {
    if (!automationData?.id && !nodes.find((n) => n.type === 'triggerNode')) {
      setShowAddDataModal(true);
    }

    // @TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerNodesCount = nodes.filter((n) => n.type === 'triggerNode').length;

  const tooltipEditButton = [
    {
      icon: 'edit_note',
      handler: () => setShowAddDataModal(true),
    },
  ];

  const tooltipRemoveButton = [
    {
      icon: 'delete_outline',
      handler: () => handleRemovingTrigger(),
    },
  ];

  const tooltipActionButtons = tooltipEditButton.concat(
    triggerNodesCount > 1 ? tooltipRemoveButton : []
  );

  return (
    <div>
      <ConfigureTriggerModal
        nodeData={nodeData}
        isOpen={showAddDataModal}
        onClose={() => setShowAddDataModal(false)}
      />
      <div
        className={cx(styles.TriggersContainer_AutomationDataContainer, {
          [styles.TriggersContainer_AutomationDataContainer__StartNode]: type === 'startNode',
        })}
        onMouseEnter={() => {
          if (isValidStatus) {
            setIsHovered(true);
          }
        }}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          if (isValidStatus) {
            setShowAddDataModal(true);
          }
        }}
      >
        {isHovered && type !== 'startNode' && (
          <ActionButtonsTooltip zoomLevel={zoom} buttons={tooltipActionButtons} />
        )}
        {type === 'startNode' ? (
          <div>
            {nodes.filter((n) => n.type === 'triggerNode')?.length > 0
              ? 'Add a New Trigger'
              : 'Create Automation'}
          </div>
        ) : (
          <div className={styles.TriggersContainer_OptionTypeContainer}>
            <div className={styles.TriggersContainer_OptionTypeContainer}>
              <img
                src={data.triggerLabel && optionIcon}
                className={styles.TriggersContainer_OptionTypeIcon}
                alt={`Option type icon ${data.triggerLabel}`}
              />
            </div>
            <div className={styles.TriggersContainer_OptionValue}>
              {data.triggerValue && data.triggerValue !== NO_VALUE
                ? `${data.triggerLabel}: ${valueName}`
                : data.triggerLabel}
            </div>
          </div>
        )}
      </div>

      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default TriggerNode;
