import PropTypes from 'prop-types';
import {Tab as ReactTab} from 'react-tabs';
import styles from './Tabs.module.scss';

function Tab({children, ...props}) {
  return (
    <ReactTab className={styles.tab} selectedClassName={styles.tabSelected} {...props}>
      {children}
    </ReactTab>
  );
}

Tab.tabsRole = 'Tab';
Tab.propTypes = {
  children: PropTypes.node,
};

export default Tab;
