export const TRIGGER_TYPE = {
  FORM_SUBMISSION: 'FORM_SUBMISSION',
  GENERAL_FORM_SUBMISSION: 'GENERAL_FORM_SUBMISSION',

  DATE_ANNIVERSARY: 'DATE_ANNIVERSARY',

  TAG_REMOVED: 'TAG_REMOVED',
  TAG_ADDED: 'TAG_ADDED',

  FIELD_REMOVED: 'FIELD_REMOVED',
  FIELD_ADDED: 'FIELD_ADDED',
  FIELD_UPDATED: 'FIELD_UPDATED',
};

export const TRIGGER_NODE_OPTIONS = [
  'Joins a Form',
  'Date Anniversary',
  'Tag is Added',
  'Tag is Removed',
  'Field is Added',
  'Field is Updated',
  'Field is Removed',
];

export const TRIGGER_NODE_OPTIONS_TO_TYPE = {
  [TRIGGER_NODE_OPTIONS[0]]: TRIGGER_TYPE.FORM_SUBMISSION,
  [TRIGGER_NODE_OPTIONS[1]]: TRIGGER_TYPE.DATE_ANNIVERSARY,
  [TRIGGER_NODE_OPTIONS[2]]: TRIGGER_TYPE.TAG_ADDED,
  [TRIGGER_NODE_OPTIONS[3]]: TRIGGER_TYPE.FIELD_REMOVED,
  [TRIGGER_NODE_OPTIONS[4]]: TRIGGER_TYPE.FIELD_ADDED,
  [TRIGGER_NODE_OPTIONS[5]]: TRIGGER_TYPE.FIELD_UPDATED,
  [TRIGGER_NODE_OPTIONS[6]]: TRIGGER_TYPE.FIELD_REMOVED,
};

export const TRIGGER_NODE_VALUES = {
  [TRIGGER_NODE_OPTIONS[4]]: 'Field',
  [TRIGGER_NODE_OPTIONS[5]]: 'Field',
  [TRIGGER_NODE_OPTIONS[6]]: 'Field',
  [TRIGGER_NODE_OPTIONS[2]]: 'Tag',
  [TRIGGER_NODE_OPTIONS[3]]: 'Tag',
  [TRIGGER_NODE_OPTIONS[0]]: 'Form',
  [TRIGGER_NODE_OPTIONS[1]]: 'Date',
};

export const TRIGGER_NODE_VALUES_CUSTOM_MAPPING = (valueList, defaultValue = '') => {
  const values = {};

  TRIGGER_NODE_OPTIONS.forEach((option, optionIndex) => {
    values[option] = valueList[optionIndex];
  });

  if (defaultValue) {
    values.defaultValue = defaultValue;
  }

  return values;
};

export const TRIGGER_STEP_TYPES = {
  start: 'startStep',
  node: 'stepNode',
  end: 'endStep',
};
