import PropTypes from 'prop-types';
import Title from '../Title';
import Button from '../Button';

function ModalClosableContent({
  children,
  img,
  title,
  onRequestClose,
  hideCloseButton,
  hideImage = false,
  hideTitle = false,
  closeButtonText = 'Close',
}) {
  return (
    <div>
      {img && !hideImage ? <div className="text-centered">{img}</div> : null}
      {title && !hideTitle ? <Title>{title}</Title> : null}
      {children}
      {onRequestClose && !hideCloseButton && (
        <div className="text-centered">
          <Button variant="outlined" color="grey" widePadding onClick={onRequestClose}>
            {closeButtonText}
          </Button>
        </div>
      )}
    </div>
  );
}

ModalClosableContent.propTypes = {
  onRequestClose: PropTypes.func,
  img: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node,
  hideCloseButton: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hideImage: PropTypes.bool,
  closeButtonText: PropTypes.string,
};

export default ModalClosableContent;
