import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import Button from '../../../components/Button';
import Input from '../../../components/forms/Input';
import CheckBox from '../../../components/forms/Checkbox';
import CheckboxLink from '../../../components/forms/Checkbox/CheckboxLink';
import {
  containsDigit,
  containsSpecialChar,
  lowercase,
  minLength,
  uppercase,
} from '../../../utils/validation/password';
import styles from '../Login/LoginForm/LoginForm.module.scss';
import PasswordValidation from './password-validation/PasswordValidation';
import {LANDING_URL} from '../../../utils/constants';

function Terms() {
  return (
    <>
      I agree to the <CheckboxLink path={`${LANDING_URL}/terms`}>Terms of Use</CheckboxLink>{' '}
      and <CheckboxLink path={`${LANDING_URL}/privacy`}>Privacy Policy</CheckboxLink>
    </>
  );
}

function SignupForm({onSubmit}) {
  const {register, formState, handleSubmit, errors} = useForm({
    criteriaMode: 'all',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="email"
        name="email"
        label="Email"
        placeholder="your@email.com"
        error={errors.email}
        required
        inputRef={register({required: 'Email address is required.'})}
      />
      <Input
        name="password"
        type="password"
        label="Password"
        placeholder="Your password"
        error={errors.password}
        hideError
        required
        inputRef={register({
          required: 'Non-empty password required.',
          validate: {
            minLength,
            containsSpecialChar,
            lowercase,
            uppercase,
            containsDigit,
          },
        })}
      />
      {errors.password && (
        <PasswordValidation errors={errors.password?.types} isSubmitted={formState.isSubmitted} />
      )}
      <CheckBox
        id="terms"
        name="terms"
        size="big"
        bottomSpace
        error={errors.terms}
        inputRef={register({required: 'You must agree to our Terms of Use and Privacy Policy.'})}
        label={<Terms />}
      />
      <div className={styles.btnWrapper}>
        <Button
          type="submit"
          variant="filled"
          color="green"
          size="big"
          full
          loading={formState.isSubmitting}
        >
          Create account
        </Button>
      </div>
    </form>
  );
}

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
