import {del, post, get, put} from '../../../utils/api';
import {ENDPOINTS} from './constants';

export async function deleteDomainService(apiSettings) {
  return del(ENDPOINTS.deleteDomain, null, apiSettings);
}

export async function fetchDomainService(apiSettings) {
  return get(ENDPOINTS.fetchDomain, null, apiSettings);
}

export async function setupDomainService(apiSettings) {
  return post(ENDPOINTS.setupDomain, null, apiSettings);
}

export async function fetchDomainSettingsService(apiSettings) {
  return get(ENDPOINTS.fetchDomainSettings, null, apiSettings);
}

export async function fetchSenderOptionsService(apiSettings) {
  return get(ENDPOINTS.fetchSenderOptions, null, apiSettings);
}

export async function initSenderEmailConfirmService(apiSettings, data) {
  return put(ENDPOINTS.initSenderEmailChange(data), null, apiSettings);
}

export async function changeSenderNameService(apiSettings, data) {
  return put(ENDPOINTS.changeSenderName, data, apiSettings);
}

export async function confirmSenderEmailService(apiSettings, data) {
  return post(ENDPOINTS.confirmEmail(data), null, apiSettings);
}
