/* eslint-disable no-nested-ternary */
import {useState} from 'react';
import {useReactFlow} from 'reactflow';
import {v4 as uuidv4} from 'uuid';

import Modal from '../../../../components/Modal';
import {useAutomation} from '../../stores/automation';
import Select from '../../../../components/forms/Select';
import Button from '../../../../components/Button';
import SegmentForm from '../../../settings/segments/components/SegmentForm';
import Input from '../../../../components/forms/Input';
import BranchSelector from './BranchSelector';
import Tiles from '../shared/Tiles';

import RadioIconSelected from '../../../../img/radio-icon-selected.png';
import RadioIcon from '../../../../img/radio-icon.png';
import {ReactComponent as ActionIcon} from '../../../../components/icons/automation/Action.svg';
import {ReactComponent as DelayIcon} from '../../../../components/icons/automation/Delay.svg';
import {ReactComponent as EmailIcon} from '../../../../components/icons/automation/Email.svg';
import {ReactComponent as ConditionalIcon} from '../../../../components/icons/automation/Condition.svg';
import {ReactComponent as FixedDelayIcon} from '../../../../components/icons/automation/delays/FixedDelay.svg';
import {ReactComponent as CustomDelayIcon} from '../../../../components/icons/automation/delays/CustomDelay.svg';
import {ReactComponent as AddTag} from '../../../../components/icons/automation/actions/AddTag.svg';
import {ReactComponent as RemoveTag} from '../../../../components/icons/automation/actions/RemoveTag.svg';
import {ReactComponent as StartOtherAutomation} from '../../../../components/icons/automation/actions/StartOtherAutomation.svg';
import {ReactComponent as StopOtherAutomation} from '../../../../components/icons/automation/actions/StopOtherAutomation.svg';
import {ReactComponent as StopAutomation} from '../../../../components/icons/automation/actions/StopAutomation.svg';
import {ReactComponent as Unsubscribe} from '../../../../components/icons/automation/actions/Unsubscribe.svg';
import {ReactComponent as UpdateField} from '../../../../components/icons/automation/actions/UpdateField.svg';

import styles from '../../Automation.module.scss';
import addStepModalStyles from './AddStepDataModal.module.scss';

const actionIconsByType = {
  ADD_TAG: AddTag,
  REMOVE_TAG: RemoveTag,
  START_OTHER_AUTOMATION: StartOtherAutomation,
  STOP_OTHER_AUTOMATION: StopOtherAutomation,
  UPDATE_FIELD: UpdateField,
  UNSUBSCRIBE: Unsubscribe,
  STOP_AUTOMATION: StopAutomation,
};

const delayIconsByType = {
  FIELD_DELAY: CustomDelayIcon,
  FIXED_DELAY: FixedDelayIcon,
};

const stepTypeToIcon = {
  ...actionIconsByType,
  ...delayIconsByType,
  default: UpdateField,
};

const AddStepDataModal = () => {
  const {
    toolsetData,
    dataCustomFields,
    dataTags,
    isAddModalOpen,
    setIsAddModalOpen,
    automationOverviewData,
    transactionalEmails,
    automationData,
    setIsConfigUpdate,
  } = useAutomation();
  const reactFlowInstance = useReactFlow();

  const {actionTypes, delayTypes, emailTypes, eventTypes, timeUnits} = toolsetData;
  const nodes = reactFlowInstance.getNodes();
  const edges = reactFlowInstance.getEdges();
  const activeNode = nodes.find((item) => item.selected);

  // value examples: UPDATE_FIELD, ADD_TAG, FIXED_DELAY, EITHER_OR_CONDITION
  const [actionTypeValue, setActionTypeValue] = useState(activeNode?.data?.actionTypeValue);
  // value examples: custom field id | tag id | automation | transactional email | time unit ...
  const [actionValue, setActionValue] = useState(activeNode?.data?.actionValue);
  // time delay for FIXED_DELAY step
  const [actionAmount, setActionAmount] = useState(activeNode?.data?.actionAmount || 5);
  // field value which should be set on UPDATE_FIELD step
  const [actionFieldValue, setActionFieldValue] = useState(
    activeNode?.data?.actionFieldValue || ''
  );
  const [missingDateFieldAction, setMissingDateFieldAction] = useState(
    activeNode?.data?.missingDateFieldAction || 'PROCEED_IMMEDIATELY'
  );
  const [isConditionSelect, setIsConditionSelect] = useState(false);
  const [conditionSetData, setConditionSetData] = useState([]);
  const missingDateFieldActions = ['PROCEED_IMMEDIATELY', 'WAIT_INDEFINITELY', 'REMOVE_SUBSCRIBER'];

  const getOptionText = (option) => {
    switch (option) {
      case 'PROCEED_IMMEDIATELY':
        return `Proceed Immediately`;
      case 'WAIT_INDEFINITELY':
        return 'Wait Indefinitely';
      case 'REMOVE_SUBSCRIBER':
        return 'Remove Subscriber';

      default:
        return 'Proceed Immediately';
    }
  };

  const [filterType, setFilterType] = useState(activeNode?.data?.stepType);
  const [filterGroups, setFilterGroups] = useState(activeNode?.data?.actionValue || []);

  const getActionValues = () => {
    switch (filterType) {
      case 'Delay':
        return actionTypeValue.includes('FIELD')
          ? dataCustomFields
              ?.filter((field) => field.dataType === 'DATE')
              ?.map((field) => ({label: field.name, value: field.id}))
          : timeUnits;
      case 'Action':
        // @TODO: fix this
        // eslint-disable-next-line no-nested-ternary
        return actionTypeValue.includes('FIELD')
          ? dataCustomFields?.map((field) => ({label: field.name, value: field.id})) // eslint-disable-next-line no-nested-ternary
          : actionTypeValue.includes('TAG')
          ? dataTags?.map((tag) => ({label: tag.name, value: tag.id}))
          : actionTypeValue.includes('AUTOMATION')
          ? automationOverviewData
              ?.filter((a) => a.id !== automationData?.id && a.status === 'ACTIVE')
              ?.map((automation) => ({
                label: automation.name,
                value: automation.id,
              }))
          : [];
      case 'Event':
        // @TODO: fix this
        // eslint-disable-next-line no-nested-ternary
        return actionTypeValue.includes('FIELD')
          ? dataCustomFields?.map((field) => ({label: field.name, value: field.id}))
          : actionTypeValue.includes('TAG')
          ? dataTags?.map((tag) => ({label: tag.name, value: tag.id}))
          : [];
      case 'Email':
        return transactionalEmails?.map((email) => ({
          label: email.name,
          value: email.id,
        }));
      default:
        return [];
    }
  };

  const isEndOfAutomation = () => {
    return !!(actionTypeValue === 'STOP_AUTOMATION' || actionTypeValue === 'UNSUBSCRIBE');
  };

  const handleAddActionStep = (isUnsubscribe) => {
    let shouldProceed = true;
    if (
      !isUnsubscribe &&
      (!getActionValues()?.length || getActionValues()?.length < 1) &&
      filterType !== 'Condition'
    ) {
      if (actionTypeValue?.includes('EMAIL') || actionTypeValue?.includes('AUTOMATION')) {
        setIsAddModalOpen(false);
        setActionTypeValue();
        setActionValue();
        setActionAmount();
        setActionFieldValue();

        if (actionTypeValue.includes('EMAIL')) {
          window.open('/settings/transactional-emails', '_blank');
        }
        if (actionTypeValue.includes('AUTOMATION')) {
          window.open('/automation', '_blank');
        }
      }
      return;
    }
    let edge;
    let foundNode;
    if (activeNode) {
      foundNode = activeNode;
      edge = edges.find((e) => e.source === activeNode.id);
    } else {
      edge = edges.find((e) => e.selected);
      foundNode = nodes.find((item) => item.id === edge.source);
    }

    if (filterType === 'Condition') {
      const allNodesToBeAdded = [];

      filterGroups.filterGroups.forEach((group) => {
        group.elements.forEach((element) => {
          allNodesToBeAdded.push(element);
        });
      });

      // @TODO: fix this
      // eslint-disable-next-line array-callback-return
      allNodesToBeAdded.map(() => {
        const conditionalStepId = String(uuidv4());
        const conditionalYesId = String(uuidv4());
        const conditionalNoId = String(uuidv4());
        const newStepNode = {
          id: conditionalStepId,
          position: {
            x: 0,
            y: 0,
          },
          data: {
            width: 220,
            height: 90,
            actionTypeValue: 'EITHER_OR_CONDITION',
            actionValue: filterGroups,
            filterGroups,
            stepType: filterType,
            trueEdgeSourceId: conditionalYesId,
            falseEdgeSourceId: conditionalNoId,
          },
          type: 'stepNode',
        };

        const conditionalYesNode = {
          id: conditionalYesId,
          position: {
            x: 0,
            y: 0,
          },
          data: {
            width: 30,
            height: 30,
            stepType: 'conditionalYes',
          },
          type: 'conditionalNode',
        };

        const conditionalNoNode = {
          id: conditionalNoId,
          position: {
            x: 0,
            y: 0,
          },
          data: {
            width: 30,
            height: 30,
            stepType: 'conditionalNo',
          },
          type: 'conditionalNode',
        };

        const conditionalYesEdge = {
          id: `e${conditionalYesId}${conditionalStepId}`,
          source: conditionalStepId,
          target: conditionalYesId,
          type: 'conditionalEdge',
          data: {
            isYes: true,
          },
        };

        const conditionalNoEdge = {
          id: `e${conditionalNoId}${conditionalStepId}`,
          source: conditionalStepId,
          target: conditionalNoId,
          type: 'conditionalEdge',
          data: {
            isYes: false,
          },
        };

        if (!edge) {
          const newConditionalNodeEdge = {
            id: `e${conditionalStepId}${foundNode.id}`,
            source: foundNode.id,
            target: conditionalStepId,
          };

          const indexToInsert = nodes.findIndex((item) => item.id === foundNode.id);
          const indexToInsertEdge = edges.findIndex((item) => item.target === foundNode.id);

          const updatedNodes = [...nodes];
          const updatedEdges = [...edges];

          updatedNodes.splice(indexToInsert, 0, newStepNode, conditionalYesNode, conditionalNoNode);
          updatedEdges.splice(
            indexToInsertEdge,
            0,
            newConditionalNodeEdge,
            conditionalYesEdge,
            conditionalNoEdge
          );
          reactFlowInstance.setNodes(updatedNodes);
          reactFlowInstance.setEdges(updatedEdges);

          setIsAddModalOpen(false);
          setActionTypeValue();
          setActionValue();
          setActionAmount();
          setActionFieldValue();
          return;
        }

        const updatedEdge = {
          ...edge,
          id: `e${conditionalStepId}${foundNode.id}`,
          source: foundNode.id,
          target: conditionalStepId,
        };

        const edgeIndex = edges.findIndex((item) => item.id === edge.id);

        const updatedEdges = [...edges];
        updatedEdges.splice(edgeIndex, 1);

        const newEdges = [updatedEdge, conditionalYesEdge, conditionalNoEdge];
        const newNodes = [newStepNode, conditionalYesNode, conditionalNoNode];

        const indexToInsert = nodes.findIndex((item) => item.id === edge.target);
        const indexToInsertEdge = edges.findIndex((item) => item.id === edge.id);
        const updatedNodes = [...nodes];
        updatedNodes.splice(indexToInsert, 0, ...newNodes);
        updatedEdges.splice(indexToInsertEdge, 0, ...newEdges);

        setIsConditionSelect(true);
        setConditionSetData({
          nodes: updatedNodes,
          edges: updatedEdges,
          edge,
          conditionalYesId,
          conditionalNoId,
        });
        shouldProceed = false;
      });
    } else {
      const newStepNodeId = String(uuidv4());
      const newStepNode = {
        id: newStepNodeId,
        position: {
          x: 0,
          y: 0,
        },
        data: {
          width: 220,
          height: 90,
          actionTypeValue: isUnsubscribe ? 'UNSUBSCRIBE' : actionTypeValue,
          actionValue,
          stepType: filterType,
          actionAmount: actionTypeValue === 'FIXED_DELAY' ? actionAmount : null,
          actionFieldValue,
          missingDateFieldAction: actionTypeValue === 'FIELD_DELAY' ? missingDateFieldAction : null,
        },
        type: 'stepNode',
      };

      const conditionalNodeId = String(uuidv4());

      const conditionalNode = {
        id: conditionalNodeId,
        position: {
          x: 0,
          y: 0,
        },
        data: {
          width: 30,
          height: 30,
        },
        type: 'conditionalNode',
      };

      if (!edge) {
        const newStepNodeEdge = {
          id: `e${newStepNodeId}${activeNode.id}`,
          source: activeNode.id,
          target: newStepNodeId,
        };
        const newConditionalNodeEdge = {
          id: `e${conditionalNodeId}${newStepNodeId}`,
          source: newStepNodeId,
          target: conditionalNodeId,
        };
        const indexToInsert = nodes.findIndex((item) => item.id === foundNode.id);
        const indexToInsertEdge = edges.findIndex((item) => item.target === foundNode.id);

        const updatedNodes = [...nodes];
        const updatedEdges = [...edges];

        // @TODO: fix this
        // eslint-disable-next-line no-unused-expressions
        isEndOfAutomation() || isUnsubscribe
          ? updatedNodes.splice(indexToInsert, 0, newStepNode)
          : updatedNodes.splice(indexToInsert, 0, newStepNode, conditionalNode);

        // @TODO: fix this
        // eslint-disable-next-line no-unused-expressions
        isEndOfAutomation() || isUnsubscribe
          ? updatedEdges.splice(indexToInsertEdge, 0, newStepNodeEdge)
          : updatedEdges.splice(indexToInsertEdge, 0, newStepNodeEdge, newConditionalNodeEdge);
        reactFlowInstance.setNodes(updatedNodes);
        reactFlowInstance.setEdges(updatedEdges);
        setIsAddModalOpen(false);
        setActionTypeValue();
        setActionValue();
        setActionAmount();
        setActionFieldValue();
        return;
      }

      const updatedEdge = {
        ...edge,
        id: `e${newStepNodeId}${foundNode.id}`,
        source: foundNode.id,
        target: newStepNodeId,
      };

      const conditionalToStepEdge = {
        id: `e${conditionalNodeId}${newStepNodeId}`,
        source: newStepNodeId,
        target: conditionalNodeId,
      };

      const updatedEdges = edges.map((item) => (item.id === edge.id ? updatedEdge : item));

      const newEdge = {
        id: `e${edge.target}${conditionalNodeId}`,
        source: conditionalNodeId,
        target: edge.target,
        // type: 'addActionEdge',
      };

      const indexToInsert = nodes.findIndex((item) => item.id === edge.target);
      const indexToInsertEdge = edges.findIndex((item) => item.id === edge.id);

      const updatedNodes = [...nodes];

      // @TODO: fix this
      // eslint-disable-next-line no-unused-expressions
      isEndOfAutomation() || isUnsubscribe
        ? updatedNodes.splice(indexToInsert, 0, newStepNode)
        : updatedNodes.splice(indexToInsert, 0, newStepNode, conditionalNode);

      // @TODO: fix this
      // eslint-disable-next-line no-unused-expressions
      isEndOfAutomation() || isUnsubscribe
        ? updatedEdges.splice(indexToInsertEdge, 0, newEdge)
        : updatedEdges.splice(indexToInsertEdge, 0, newEdge, conditionalToStepEdge);

      reactFlowInstance.setNodes(updatedNodes);
      reactFlowInstance.setEdges(updatedEdges);
    }
    if (!shouldProceed) return;
    setIsAddModalOpen(false);
    setActionTypeValue();
    setActionValue();
  };

  const handleUpdateActionStep = () => {
    const updatedNode = {
      ...activeNode,
      data: {
        ...activeNode.data,
        actionTypeValue,
        actionValue,
        stepType: filterType,
        filterGroups,
        actionAmount: actionTypeValue === 'FIXED_DELAY' ? actionAmount : null,
        actionFieldValue,
        missingDateFieldAction: actionTypeValue === 'FIELD_DELAY' ? missingDateFieldAction : null,
      },
    };

    reactFlowInstance.setNodes((nds) =>
      nds.map((item) => (item.id === activeNode.id ? updatedNode : item))
    );
    setIsAddModalOpen(false);
    setActionTypeValue();
    setActionValue();
    setIsConfigUpdate(true);
  };

  const shouldShowAmountInput = () => {
    if (actionTypeValue === 'FIXED_DELAY') return true;
    return false;
  };

  const getLabelValue = () => {
    if (filterType === 'Condition') return 'Condition';
    if (!actionTypeValue) return 'Action';
    if (actionTypeValue === 'FIELD_DELAY') return 'Field';
    if (actionTypeValue.includes('DELAY')) return 'Delay';
    if (actionTypeValue.includes('EMAIL')) return 'Email';
    if (actionTypeValue.includes('FIELD')) return 'Field';
    if (actionTypeValue.includes('TAG')) return 'Tag';
    if (actionTypeValue.includes('AUTOMATION')) return 'Automation';
    return 'Action';
  };

  const checkIfGroupFiltersAreFilled = () => {
    let isFilled;

    filterGroups?.filterGroups?.forEach((group) => {
      group.elements.forEach((element) => {
        isFilled = Object.keys(element).every((key) => element[key]);
      });
    });
    return isFilled;
  };

  const isButtonDisabled = () => {
    if (actionTypeValue && !getActionValues()?.length) {
      return false;
    }
    if (filterType === 'Condition') {
      return !checkIfGroupFiltersAreFilled();
    }
    if (actionTypeValue === 'FIXED_DELAY') {
      return (
        !actionAmount ||
        !actionValue ||
        (actionValue === 'MINUTES' && actionAmount < 5) ||
        (actionValue && actionAmount < 1)
      );
    }
    if (actionTypeValue === 'FIELD_DELAY') {
      return !missingDateFieldAction || !actionValue;
    }
    if (!actionValue) {
      return true;
    }
    if (getActionValues().length > 1 && !actionFieldValue && actionTypeValue === 'UPDATE_FIELD') {
      return true;
    }

    return false;
  };

  const handleAddCondition = (isYesTree) => {
    let updatedEdge;
    if (isYesTree) {
      updatedEdge = {
        ...conditionSetData.edge,
        id: `e${conditionSetData.edge.target}${conditionSetData.conditionalYesId}`,
        source: conditionSetData.conditionalYesId,
        target: conditionSetData.edge.target,
      };
    } else {
      updatedEdge = {
        ...conditionSetData.edge,
        id: `e${conditionSetData.edge.target}${conditionSetData.conditionalNoId}`,
        source: conditionSetData.conditionalNoId,
        target: conditionSetData.edge.target,
      };
    }

    const updatedEdges = [...conditionSetData.edges, updatedEdge];
    reactFlowInstance.setEdges(updatedEdges);
    reactFlowInstance.setNodes(conditionSetData.nodes);
    setIsConditionSelect(false);
    setIsAddModalOpen(false);
    setActionTypeValue();
    setActionValue();
  };

  const generalStepTypes = [
    {
      value: 'Action',
      label: 'Action',
      SVGComponent: ActionIcon,
    },
    {
      value: 'Delay',
      label: 'Delay',
      SVGComponent: DelayIcon,
    },
    {
      value: 'Email',
      label: 'Email',
      SVGComponent: EmailIcon,
    },
    {
      value: 'Condition',
      label: 'Condition',
      SVGComponent: ConditionalIcon,
    },
  ];

  const getActionTypeOptions = () => {
    const activeNodeHasOutgoingEdge = !!edges.find((item) => item.source === activeNode?.id);

    let options = [];

    switch (filterType) {
      case 'Action': {
        options = activeNodeHasOutgoingEdge
          ? actionTypes.filter(
              (type) => type.value !== 'STOP_AUTOMATION' && type.value !== 'UNSUBSCRIBE'
            )
          : actionTypes.filter((type) => type.value !== 'STOP_AUTOMATION');
        break;
      }
      case 'Delay': {
        options = delayTypes;
        break;
      }
      case 'Email': {
        options = emailTypes;
        break;
      }
      case 'Event': {
        options = eventTypes;
        break;
      }
      default:
        return [];
    }

    return options.map((i) => ({
      value: i.value,
      label: i.label,
      SVGComponent: stepTypeToIcon[i.value] || stepTypeToIcon.default,
    }));
  };

  // filter: Action | Delay | Email | Condition
  const handleClickOnGroupTile = (filter) => {
    setFilterType(filter);
    if (filter === 'Email') {
      setActionTypeValue('SEND_EMAIL');
    } else setActionTypeValue();
  };

  const handleClickOnTypeTile = (stepType) => {
    if (stepType === 'UNSUBSCRIBE') {
      handleAddActionStep(true);
    } else setActionTypeValue(stepType);
  };

  return (
    <Modal
      isOpen={isAddModalOpen}
      onRequestClose={() => setIsAddModalOpen(false)}
      title={
        // @TODO: fix this
        // eslint-disable-next-line no-nested-ternary
        isConditionSelect
          ? 'Which condition should link to the existing path?'
          : filterType && filterType === 'Condition'
          ? 'True/False Condition'
          : `${!actionTypeValue ? 'Select ' : ''}${
              filterType && filterType !== 'conditionalYes' && filterType !== 'conditionalNo'
                ? filterType
                : 'Action'
            }`
      }
      size={filterType === 'Condition' && !isConditionSelect ? 'large' : 'small'}
    >
      {isConditionSelect ? (
        <BranchSelector onAddCondition={handleAddCondition} />
      ) : !filterType || filterType === 'conditionalYes' || filterType === 'conditionalNo' ? (
        <Tiles items={generalStepTypes} onTileClick={handleClickOnGroupTile} />
      ) : (filterType === 'Action' || filterType === 'Delay') && !actionTypeValue ? (
        <Tiles items={getActionTypeOptions()} onTileClick={handleClickOnTypeTile} />
      ) : (
        <div>
          {filterType !== 'Condition' ? (
            <>
              <div style={{display: 'flex', flexDirection: 'row', flex: 1, gap: 20, width: '100%'}}>
                {shouldShowAmountInput() && (
                  <Input
                    type="number"
                    value={actionAmount}
                    onChange={(e) => setActionAmount(e.target.value)}
                    label={`Set ${getLabelValue()} Amount`}
                    style={{width: 100}}
                    min={actionValue === 'MINUTES' ? 5 : 1}
                    helpText={
                      actionValue === 'MINUTES' && actionAmount < 5
                        ? 'A minimum delay of 5 minutes is required.'
                        : actionAmount < 1
                        ? `A minimum delay of 1 ${actionValue?.toLowerCase()} is required.`
                        : ''
                    }
                    helperTextStyles={{marginTop: 10, position: 'absolute'}}
                  />
                )}
                <div style={{flexGrow: 1}}>
                  {actionTypeValue && getActionValues()?.length > 0 ? (
                    <>
                      <Select
                        label={`Select ${getLabelValue()}`}
                        options={getActionValues()}
                        value={actionValue}
                        onChange={(v) => {
                          setActionValue(v);
                          setActionFieldValue('');
                        }}
                        // dense
                        isClearable
                        // noBottomSpace
                      />

                      {actionTypeValue === 'FIELD_DELAY' && actionTypeValue && (
                        <div>
                          <div className={styles.radioTitle}>Select missing date action</div>
                          {missingDateFieldActions.map((option) => (
                            <div
                              className={styles.radio}
                              onClick={() => {
                                setMissingDateFieldAction(option);
                              }}
                            >
                              <img
                                src={
                                  option === missingDateFieldAction ? RadioIconSelected : RadioIcon
                                }
                                className={styles.radioImage}
                                alt={`Select ${option}`}
                              />
                              <div className={styles.radioText}>{getOptionText(option)}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      {actionTypeValue.includes('AUTOMATION') ? (
                        <div style={{textAlign: 'center'}}>
                          You need at least one active automation first.
                        </div>
                      ) : (
                        <div>You don&apos;t have any data yet.</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {actionTypeValue === 'UPDATE_FIELD' && actionTypeValue && (
                <>
                  {dataCustomFields.find((field) => field.id === actionValue) &&
                  dataCustomFields.find((field) => field.id === actionValue)?.optionValues ? (
                    <Select
                      label="Select Field Value"
                      options={dataCustomFields
                        .find((field) => field.id === actionValue)
                        ?.optionValues.map((option) => ({
                          label: option,
                          value: option,
                        }))}
                      onChange={(v) => {
                        setActionFieldValue(v);
                      }}
                      value={actionFieldValue}
                      isClearable
                    />
                  ) : (
                    <Input
                      label="Select Field Value"
                      type={
                        dataCustomFields.find((field) => field.id === actionValue)?.dataType ===
                        'DATE'
                          ? 'date'
                          : dataCustomFields.find((field) => field.id === actionValue)?.dataType ===
                            'NUMBER'
                          ? 'number'
                          : 'text'
                      }
                      value={actionFieldValue}
                      onChange={(e) => setActionFieldValue(e.target.value)}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <SegmentForm
              {...{filterGroups: filterGroups || [], setFilterGroups}}
              withMatchingLine={false}
              wrapperStyles={{backgroundColor: '#F7F8FC', padding: 30, borderRadius: 5}}
              addStyles={{backgroundColor: '#F7F8FC', padding: '0 20px 20px 20px', borderRadius: 5}}
            />
          )}
          <div className={addStepModalStyles.actionButtons}>
            {activeNode?.data?.actionTypeValue ? (
              <Button
                size="small"
                color="green"
                variant="filled"
                full={filterType !== 'Condition'}
                onClick={handleUpdateActionStep}
                disabled={isButtonDisabled()}
              >
                {`Update ${getLabelValue()}`}
              </Button>
            ) : actionTypeValue &&
              getActionValues()?.length === 0 &&
              actionTypeValue.includes('AUTOMATION') ? (
              <div />
            ) : (
              <Button
                size="small"
                color="green"
                variant="filled"
                full={filterType !== 'Condition'}
                onClick={() => handleAddActionStep()}
                disabled={isButtonDisabled()}
              >
                {`${getActionValues()?.length > 0 ? 'Add' : 'Create'}  ${getLabelValue()}`}
              </Button>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddStepDataModal;
