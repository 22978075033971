const paymentMethods = '/internal/payment-methods';

export const ENDPOINTS = {
  addPaymentMethod: (paymentMethodId) => `${paymentMethods}/add/${paymentMethodId}`,
  fetchPaymentMethods: paymentMethods,
  fetchSetupIntent: (paymentMethodId) => `${paymentMethods}/setup-intent/${paymentMethodId}`,
  setDefaultPaymentMethod: (paymentMethodId) => `${paymentMethods}/default/${paymentMethodId}`,
  deletePaymentMethod: (paymentMethodId) => `${paymentMethods}/${paymentMethodId}`,
  fetchDefaultMethod: `${paymentMethods}/primary`,
};
