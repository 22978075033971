import PropTypes from 'prop-types';

import '@reach/listbox/styles.css';
import {ListboxOption as ReactListboxOption} from '@reach/listbox';
import styles from './Listbox.module.scss';

function ListboxOption({children, value, ...props}) {
  return (
    <ReactListboxOption {...props} value={value} className={styles.option}>
      {children}
    </ReactListboxOption>
  );
}

ListboxOption.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

export default ListboxOption;
