import {createContext, useContext, useMemo} from 'react';
import {useNodesState, useEdgesState} from 'reactflow';

const AutomationFlowContext = createContext();

export const AutomationFlowProvider = ({children}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const value = useMemo(
    () => ({
      nodes,
      setNodes,
      onNodesChange,
      edges,
      setEdges,
      onEdgesChange,
    }),
    [nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange]
  );

  return <AutomationFlowContext.Provider value={value}>{children}</AutomationFlowContext.Provider>;
};

export const useAutomationFlow = () => {
  const context = useContext(AutomationFlowContext);

  if (context === undefined) {
    throw new Error('AutomationFlowContext must be used within an AutomationFlowProvider');
  }

  return context;
};
