import {useContext} from 'react';
import createDataContext from '../../../../utils/createDataContext';
import createReducer from '../../../../utils/createReducer';
import {SET_FETCH_LOADING, SET_DATA, ADD_DATA_ITEM, REPLACE_DATA_ITEM} from './actionTypes';
import {setFetchLoading, setData, addDataItem, replaceDataItem} from './actions';
import {
  initialState,
  setFetchLoadingReducer,
  setDataReducer,
  addDataItemReducer,
  replaceDataItemReducer,
} from './reducers';

const reducer = createReducer(initialState, {
  [SET_FETCH_LOADING]: setFetchLoadingReducer,
  [SET_DATA]: setDataReducer,
  [ADD_DATA_ITEM]: addDataItemReducer,
  [REPLACE_DATA_ITEM]: replaceDataItemReducer,
});

export const {Context, Provider} = createDataContext(
  reducer,
  {
    setFetchLoading,
    setData,
    addDataItem,
    replaceDataItem,
  },
  initialState
);

export const useSubscriberFieldsContext = function () {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useSubscriberFieldsContext must be used within a SubscriberFieldsProvider`);
  }
  return context;
};
