import PropTypes from 'prop-types';
import ColorField from 'components/forms/ColorField';
import {useHandleButtonParam} from '../../useHandleButtonParam';

const NewsletterColorField = ({paramPath, ...rest}) => {
  const [value, onChange] = useHandleButtonParam(paramPath);
  return <ColorField {...rest} value={value} onChange={onChange} />;
};

NewsletterColorField.propTypes = {
  label: PropTypes.string.isRequired,
  paramPath: PropTypes.string.isRequired,
  canBeTransparent: PropTypes.bool,
};

export default NewsletterColorField;
