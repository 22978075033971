import useFetch from './useFetch';
import {
  addPostalAddressService,
  fetchPostalAddressService,
  updatePostalAddressService,
} from '../domain/settings/postal-address/service';
import useUpdateFreePlanInfo from './useUpdateFreePlanInfo';

function usePostalAddress(options = {}) {
  const {data, loading, run: fetchPostalAddressRequest} = useFetch(fetchPostalAddressService, {
    immediate: true,
    ...options,
    throwError: options.throwOnFetch,
  });
  const {run: addPostalAddressRequest} = useFetch(addPostalAddressService, options);
  const {run: updatePostalAddressRequest} = useFetch(updatePostalAddressService, options);
  const {updateFreePlanInfo} = useUpdateFreePlanInfo();

  async function submitSenderDetails(values) {
    const create = !Object.keys(data).length;

    if (create) {
      await addPostalAddressRequest(values);
    } else {
      await updatePostalAddressRequest(values);
    }
    await updateFreePlanInfo();
  }

  return {loading, data, fetchPostalAddressRequest, submitSenderDetails};
}

export default usePostalAddress;
