import {useMemo} from 'react';

import PropTypes from 'prop-types';

import JoinsFormIcon from '../../../../../img/joins-form-icon.png';
import TagIcon from '../../../../../img/tag-automation-icon.png';
import FieldIcon from '../../../../../img/field-automation-icon.png';
import EmailIcon from '../../../../../img/email-automation-icon.png';

import {TRIGGER_NODE_VALUES_CUSTOM_MAPPING} from '../../constants';

import styles from './TriggerNodeOption.module.scss';

export const useIconOptions = (optionType) => {
  return useMemo(() => {
    const options = TRIGGER_NODE_VALUES_CUSTOM_MAPPING([
      JoinsFormIcon,
      EmailIcon,
      TagIcon,
      TagIcon,
      FieldIcon,
      FieldIcon,
      FieldIcon,
    ]);

    if (optionType && options[optionType]) {
      return options[optionType];
    }
  }, [optionType]);
};

const TriggerNodeOption = (props) => {
  const {option, onOptionClick, subtitle} = props;

  const icon = useIconOptions(option);

  return (
    <div className={styles.option} onClick={() => onOptionClick(option)}>
      <div className={styles.option_iconContainer}>
        <img src={icon} className={styles.option_icon} alt={`Option icon ${option}`} />
      </div>
      <div className={styles.option_title}>{option}</div>
      {subtitle && <div className={styles.option_description}>{subtitle}</div>}
    </div>
  );
};

TriggerNodeOption.propTypes = {
  option: PropTypes.string.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
};

export default TriggerNodeOption;
