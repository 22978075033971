import {useState} from 'react';
import useFetch from '../../../hooks/useFetch';
import {sendNewsletterService} from '../service';
import {
  SEND_TO_GROUP__FILTER,
  SEND_TO_SELECT__DEFAULT,
  FILTER_MODE,
  SCHEDULING_MODE,
} from '../constants';
import useSegmentsFetcher from '../../settings/segments/hooks/useSegmentsFetcher';
import useSubscriberFieldsFetcher from '../../settings/subscriber-fields/hooks/useSubscriberFieldsFetcher';

const buildFilterConfig = (formValues, {segments, tags}) => {
  let filterConfig = {
    filterMode: FILTER_MODE.NONE,
    filterExpression: null,
  };

  if (formValues.sendToGroup === SEND_TO_GROUP__FILTER) {
    filterConfig = {
      filterMode: FILTER_MODE.SEGMENT,
      filterExpression: formValues.filterGroups,
    };
  } else if (formValues.sendToSelect !== SEND_TO_SELECT__DEFAULT) {
    const segment = segments.find((v) => v.id === formValues.sendToSelect);
    const tag = tags.find((v) => v.id === formValues.sendToSelect);
    if (segment) {
      filterConfig = {
        filterMode: FILTER_MODE.SEGMENT,
        filterExpression: {
          operator: 'OR',
          filterGroups: [
            {
              elements: [
                {
                  type: 'segment',
                  matcher: 'CONTAINED_IN',
                  id: segment.id,
                },
              ],
            },
          ],
        },
      };
    } else if (tag) {
      filterConfig = {
        filterMode: FILTER_MODE.SEGMENT,
        filterExpression: {
          operator: 'OR',
          filterGroups: [
            {
              elements: [
                {
                  type: 'tag',
                  matcher: 'ASSIGNED_TO',
                  id: tag.id,
                },
              ],
            },
          ],
        },
      };
    }
  }

  return filterConfig;
};

const buildTransmissionTimingConfig = (scheduleTime) => {
  return scheduleTime
    ? {transmissionMode: SCHEDULING_MODE.SCHEDULED, transmissionTimestamp: scheduleTime}
    : {transmissionMode: SCHEDULING_MODE.NOW, transmissionTimestamp: null};
};

function useSendNewsletter() {
  const [sendProcessing, setSendProcessing] = useState(false);
  const {data: dataSegments} = useSegmentsFetcher({immediate: true});
  const {dataTags} = useSubscriberFieldsFetcher({immediate: true});

  const {run: sendNewsletterRequest} = useFetch(sendNewsletterService, {throwError: true});

  async function sendNewsletter(newsletterID, formValues) {
    try {
      setSendProcessing(true);
      await sendNewsletterRequest({
        bulkMailId: newsletterID,
        filterConfig: buildFilterConfig(formValues, {segments: dataSegments, tags: dataTags}),
        transmissionTiming: buildTransmissionTimingConfig(formValues.scheduleTime),
      });
    } finally {
      setSendProcessing(false);
    }
  }

  return {sendProcessing, sendNewsletter};
}

export default useSendNewsletter;
