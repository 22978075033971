import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import cx from 'clsx';

import styles from './BackButton.module.scss';

function BackButton({children, type = 'link', to, noMargin, className, ...props}) {
  const Component = type === 'button' ? 'button' : Link;

  return (
    <Component
      to={to}
      className={cx(styles.back, className, {[styles.backNoMargin]: noMargin})}
      title="Click to return back"
      {...props}
    >
      {children || 'Back'}
    </Component>
  );
}

BackButton.propTypes = {
  children: PropTypes.string,
  type: PropTypes.oneOf(['button', 'link']),
  noMargin: PropTypes.bool,
  to: PropTypes.string,
  className: PropTypes.string,
};

export default BackButton;
