import PropTypes from 'prop-types';

import Card from '../../../../components/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import ActionButton from '../../../../components/ActionButton';
import SubscriptionFormListPageList from './SubscriptionFormListPageList';
import {LANDING_URL} from '../../../../utils/constants';
import Button from '../../../../components/Button';
import IconSettings from '../../../../img/icon-form-settings.png';
import useRouter from '../../../../hooks/useRouter';
import FormsReportChart from '../../components/FormReport/formReport';
import useFormSubmitScript from '../../components/FormPreviewModal/useFormSubmitScript';

import styles from './SubscriptionFormListPage.module.scss';

const formColors = [
  '#000000',
  '#ff7f50',
  '#87cefa',
  '#da70d6',
  '#32cd32',
  '#6495ed',
  '#ff69b4',
  '#ba55d3',
  '#cd5c5c',
  '#ffa500',
  '#40e0d0',
  '#ff7f50',
  '#87cefa',
  '#da70d6',
  '#32cd32',
  '#6495ed',
  '#ff69b4',
  '#ba55d3',
  '#cd5c5c',
  '#ffa500',
  '#40e0d0',
];

function SubscriptionFormListPage({
  formList,
  loading,
  onDelete,
  onActionBtnClick,
  reportData,
  getReport,
  totalReportData,
  reportLoading,
  onStatusUpdate,
}) {
  const {history} = useRouter();
  useFormSubmitScript();

  const inactiveForms = formList && formList.filter((form) => form.status === 'INACTIVE');
  const inactiveFormNames = inactiveForms?.length && inactiveForms.map((form) => form.name);

  const filteredReportData =
    reportData?.formReports &&
    inactiveFormNames &&
    reportData?.formReports.filter((report) => !inactiveFormNames.includes(report.formName));

  const formListWithColors =
    formList && formList.map((form, index) => ({...form, color: formColors[index]}));

  return (
    <Card
      title="Forms"
      noPadding
      loading={loading}
      header={
        <CardHeader
          title="Signup-Forms"
          text={
            <p className={styles.cardHeaderText}>
              Create and customize your signup forms and flow here. Use the report and lifetime key
              figures to improve their effectiveness over time. For more information, please read
              our{' '}
              <a
                href={`${LANDING_URL}/guides/signup-forms-overview`}
                title="Click here for more details"
                target="_blank"
                rel="noopener noreferrer"
              >
                Signup-Forms-Guide
              </a>
            </p>
          }
        >
          <div className={styles.buttonsContainer}>
            <Button
              color="green"
              variant="outlined"
              onClick={() => history.push('/settings/subscription-flow')}
            >
              <img
                src={IconSettings}
                alt="settings"
                className={styles.buttonsContainer_SettingsIcon}
              />
              Form Settings
            </Button>
            <ActionButton onClick={onActionBtnClick}>Create Form</ActionButton>
          </div>
        </CardHeader>
      }
    >
      {reportData && (
        <FormsReportChart
          reportData={filteredReportData || reportData?.formReports}
          entryType={{
            dataKey: 'viewCount',
            tooltipKeys: ['confirmationCount'],
            dataName: 'View',
            color: '#109CF1',
          }}
          getReport={getReport}
          loading={reportLoading}
          formList={formListWithColors}
        />
      )}
      <SubscriptionFormListPageList
        formList={formListWithColors}
        onDelete={onDelete}
        totalReportData={totalReportData}
        onStatusUpdate={onStatusUpdate}
      />
    </Card>
  );
}

SubscriptionFormListPage.propTypes = {
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  // @TODO: fix this
  // eslint-disable-next-line react/forbid-prop-types
  formList: PropTypes.arrayOf(PropTypes.any),
};

export default SubscriptionFormListPage;
