import {useState} from 'react';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import automationStyles from '../../Automation.module.scss';
import warning from '../../../../img/modal-warning.svg';
import ModalClosableContent from '../../../../components/Modal/ModalClosableContent';
import Input from '../../../../components/forms/Input';

import alertModalStyles from './AlertModal.module.scss';

const AlertModal = ({
  isOpen,
  onRequestClose,
  title,
  titleStyle,
  onCancel,
  onAgree,
  cancelColor,
  agreeColor,
  cancelText,
  agreeText,
  children,
  confirmText,
  agreeVariant,
  cancelVariant,
  agreeLoading,
  agreeDisabled,
  cancelLoading,
  cancelDisabled,
}) => {
  const [text, setText] = useState('');
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} titleStyle={titleStyle}>
      <ModalClosableContent
        title={title}
        hideCloseButton
        img={
          <img
            className={automationStyles.infoImg}
            src={warning}
            width="307"
            height="263"
            alt="Confirm delete warning"
          />
        }
      >
        {children}
        {confirmText && (
          <Input value={text} onChange={(e) => setText(e.target.value)} style={{marginTop: 20}} />
        )}
        <div className={alertModalStyles.actions}>
          <Button
            size="small"
            color={cancelColor || 'grey'}
            variant={cancelVariant || 'outlined'}
            full
            onClick={() => onCancel()}
            disabled={cancelDisabled}
            loading={cancelLoading}
          >
            {cancelText || 'Cancel'}
          </Button>
          <Button
            size="small"
            color={agreeColor || 'red'}
            variant={agreeVariant || 'filled'}
            full
            onClick={() => onAgree()}
            disabled={(confirmText && text !== confirmText) || agreeDisabled}
            loading={agreeLoading}
          >
            {agreeText || 'Confirm'}
          </Button>
        </div>
      </ModalClosableContent>
    </Modal>
  );
};

export default AlertModal;
