import PropTypes from 'prop-types';
import {Menu, MenuButton, MenuPopover} from '@reach/menu-button';
import cx from 'clsx';
import '@reach/menu-button/styles.css';
import {positionRight} from '@reach/popover';

import styles from './ButtonDropdown.module.scss';

function ButtonDropdown({buttonText, type, children, split, popoverStyle, className, ...props}) {
  return (
    <Menu>
      <MenuButton className={cx(styles.main, {[styles[type]]: type}, className)} {...props}>
        {buttonText} <span className={cx(styles.arr, {[styles.split]: split})} aria-hidden />
      </MenuButton>
      <MenuPopover
        className={cx(styles.options, {[styles[type]]: type})}
        position={positionRight}
        style={popoverStyle}
      >
        {children}
      </MenuPopover>
    </Menu>
  );
}

ButtonDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['green', 'white']),
  buttonText: PropTypes.string,
  split: PropTypes.bool,
  className: PropTypes.string,
};

export default ButtonDropdown;
