import PropTypes from 'prop-types';
import styles from './Toolbar.module.scss';
import Textarea from '../../../../components/forms/Textarea';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';

const TextareaField = ({label, paramPath, ...textareaProps}) => {
  const [value, handleChange] = useHandleConfigParam(paramPath);

  return (
    <div className={styles.TextareaFieldContainer}>
      <div className={styles.toolbarFieldLabelContainer}>
        <span className={styles.toolbarFieldLabel}>{label}</span>
      </div>
      <Textarea value={value} onChange={handleChange} {...textareaProps} />
    </div>
  );
};

TextareaField.propTypes = {
  label: PropTypes.string.isRequired,
  paramPath: PropTypes.string.isRequired,
};

export default TextareaField;
