import {useMemo} from 'react';
import styles from './OverviewList.module.scss';
import IconWarning from '../../../img/icon-warning.png';

const OverviewFailure = ({failure}) => {
  const failures = useMemo(() => {
    return Object.values(typeof failure === 'object' ? failure : {failure}).filter((item) => item);
  }, [failure]);

  if (!failures.length) {
    return null;
  }

  return (
    <div className={styles.failWrapper}>
      <div style={{marginTop: 3}}>
        <img src={IconWarning} alt="warning" />
      </div>
      <div className={styles.fail}>
        <strong className={styles.failTitle}>Failed to send the Campaign</strong>
        <ul style={{padding: 0, paddingLeft: 12}}>
          {failures.map((item, index) => (
            <li key={`failure_${index}`} className={styles.failDetails}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OverviewFailure;
