import PropTypes from 'prop-types';
import Button from '../Button';
import Input from '../forms/Input';
import Select from '../forms/Select';
import {categoryOptions} from '../../domain/newsletters/templates/constants';
import Modal from '../Modal';
import useSaveAsTemplateModal from './useSaveAsTemplateModal';

const SaveAsTemplateModal = ({
  html,
  css,
  isOpen,
  defaultTemplateName,
  defaultTemplateCategory,
  templateId,
  onClose,
  onAfterRequest,
  onNameUpdate,
}) => {
  const {
    loading,
    templateName,
    templateCategory,
    handleChangeTemplateName,
    handleChangeTemplateCategory,
    handleOnRequestClose,
    handleSaveTemplate,
    handleUpdateTemplate,
  } = useSaveAsTemplateModal({
    html,
    css,
    templateId,
    defaultTemplateName,
    defaultTemplateCategory,
    onClose,
    onAfterRequest,
    onNameUpdate,
  });

  return (
    <Modal onRequestClose={handleOnRequestClose} isOpen={isOpen}>
      <div>
        <Input
          disabled={loading}
          value={templateName}
          onChange={handleChangeTemplateName}
          label="Template name"
        />

        <Select
          options={categoryOptions.map(({value}) => ({
            value,
            label: value.replace('_', ' '),
          }))}
          disabled={loading}
          label="Template category"
          value={templateCategory}
          onChange={handleChangeTemplateCategory}
        />
      </div>
      <Button
        disabled={!templateName || !templateCategory}
        variant="outlined"
        color="green"
        onClick={templateId ? handleUpdateTemplate : handleSaveTemplate}
        loading={loading}
      >
        {templateId ? 'Update ' : 'Save '}Template
      </Button>
    </Modal>
  );
};

SaveAsTemplateModal.propTypes = {
  onClose: PropTypes.func,
  onAfterRequest: PropTypes.func,
  onNameUpdate: PropTypes.func,
  isOpen: PropTypes.bool,
  templateId: PropTypes.string,
  html: PropTypes.string,
  css: PropTypes.string,
  defaultTemplateName: PropTypes.string,
  defaultTemplateCategory: PropTypes.string,
};

export default SaveAsTemplateModal;
