import {useState} from 'react';
import {Handle, Position, useViewport} from 'reactflow';
import clsx from 'clsx';

import NewNode from './NewNode';
import ActionButtonsTooltip from '../shared/ActionButtonsTooltip';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import StepIcon from './StepIcon';

import {useAutomation} from '../../stores/automation';

import {flowIsEditable} from '../../utils/automationFlow';

import styles from './StepNode.module.scss';

const StepNode = (nodeData) => {
  const {id, type, data} = nodeData;

  const [isHovered, setIsHovered] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const {
    toolsetData,
    dataCustomFields,
    dataTags,
    automationOverviewData,
    setIsAddModalOpen,
    stepReportData,
    validationErrors,
    automationData,
    transactionalEmails,
  } = useAutomation();
  const {actionTypes, delayTypes, emailTypes, eventTypes, timeUnits} = toolsetData;
  const {zoom} = useViewport();

  // TODO: check what is the actionField ?
  // actionTypeValue : "FIXED_DELAY" | "EITHER_OR_CONDITION"
  // stepType is a general action type based on actionTypeValue: Delay | Condition | Email | Action
  // actionValue: timeUnit (MINUTES, ...), fieldId, filterExpression, id
  // actionAmount: delayAmount (5)
  const {actionTypeValue, actionValue, stepType, actionAmount, actionField} = data;
  const filterType = stepType || 'Action';
  const newNodeType = type === 'startStep' || type === 'conditionalNode';

  const getActionTypeValueLabel = () => {
    const typeOptions = {
      Action: actionTypes,
      Delay: delayTypes,
      Email: emailTypes,
      Event: eventTypes,
    };
    return typeOptions[filterType]?.find((a) => a.value === actionTypeValue)?.label || '';
  };

  const getActionValueLabel = () => {
    switch (filterType) {
      case 'Action':
        // @TODO: fix this
        // eslint-disable-next-line no-nested-ternary
        return actionTypeValue.includes('FIELD')
          ? dataCustomFields.find((field) => field.id === actionValue)?.name // eslint-disable-next-line no-nested-ternary
          : actionTypeValue.includes('TAG')
          ? dataTags.find((tag) => tag.id === actionValue)?.name
          : actionTypeValue.includes('AUTOMATION')
          ? automationOverviewData.find((automation) => automation.id === actionValue)?.name
          : '';
      case 'Delay':
        return actionTypeValue.includes('FIELD')
          ? dataCustomFields.find((field) => field.id === actionValue)?.name
          : timeUnits.find((unit) => unit.value === actionValue)?.label;
      // case 'Email':
      //   return emailTypes.find((type) => type.value === actionValue)?.label;
      case 'Event':
        // @TODO: fix this
        // eslint-disable-next-line no-nested-ternary
        return actionTypeValue.includes('FIELD')
          ? dataCustomFields.find((field) => field.id === actionValue)?.name
          : actionTypeValue.includes('TAG')
          ? dataTags.find((tag) => tag.id === actionValue)?.name
          : '';
      case 'Email':
        return transactionalEmails?.find((email) => email.id === actionValue)?.name;
      default:
        return '';
    }
  };

  const tooltipActionButtons = [
    {
      icon: 'edit_note',
      handler: () => setIsAddModalOpen(true),
    },
    {
      icon: 'delete_outline',
      handler: () => setShowDeleteConfirm(true),
    },
  ];

  const handleClickOnStepNode = () => {
    if (flowIsEditable(automationData?.status)) setIsAddModalOpen(true);
  };

  const hasError = validationErrors && validationErrors[id];

  return (
    <div>
      <DeleteConfirmationModal
        nodeData={nodeData}
        isOpened={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
      />
      <Handle type="target" position={Position.Top} />
      {hasError && <div className={styles.error}>{validationErrors[id][0]}</div>}

      <div
        className={clsx(styles.root, newNodeType ? styles.newNode : styles.generalNode)}
        onClick={handleClickOnStepNode}
      >
        {newNodeType ? (
          <NewNode />
        ) : (
          <div
            className={styles.generalNode}
            onMouseEnter={() => {
              if (flowIsEditable(automationData?.status)) setIsHovered(true);
            }}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered && <ActionButtonsTooltip zoomLevel={zoom} buttons={tooltipActionButtons} />}
            <div className={styles.header}>
              <StepIcon stepType={stepType} />
              <div className={styles.title}>
                {stepType === 'Condition'
                  ? 'Condition'
                  : `${getActionTypeValueLabel()} ${
                      getActionValueLabel() &&
                      `: ${
                        actionField
                          ? dataCustomFields.find((field) => field.id === actionField)?.name
                          : ''
                      } ${actionAmount || ''} ${getActionValueLabel()}`
                    }`}
              </div>
            </div>
            <div className={styles.statistics}>
              <div className={styles.statisticItem}>
                Active: <b>{(stepReportData && stepReportData[id]?.activeCount) || 0}</b>
              </div>
              <div className={styles.statisticItem}>
                Completed: <b>{(stepReportData && stepReportData[id]?.completedCount) || 0}</b>
              </div>
            </div>
          </div>
        )}
      </div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default StepNode;
