/* eslint-disable no-param-reassign */
import {createContext, useCallback, useContext} from 'react';
import set from 'lodash.set';
import {useImmerReducer} from 'use-immer';

import {defaultSubmitConfig} from '../settings/edit-form-text/constants';

const NewsletterContext = createContext();

const initialState = {
  activeButtonConfig: defaultSubmitConfig.buttonStyle,
  showToolbar: false,
  editorIsInitialized: false,
};

export const NewsletterProvider = ({children}) => {
  const [state, dispatch] = useImmerReducer((draft, action) => {
    if (action.type === 'SET_BUTTON_CONFIG_PARAM') {
      set(draft, action.payload.path, action.payload.value);
    }

    if (action.type === 'TOGGLE_TOOLBAR') {
      draft.showToolbar = action.payload;
    }

    if (action.type === 'SET_BUTTON_CONFIG') {
      draft.activeButtonConfig = action.payload;
    }

    if (action.type === 'INIT_EDITOR') {
      draft.editorIsInitialized = true;
    }

    if (action.type === 'RESET_EDITOR') {
      draft.editorIsInitialized = false;
    }
  }, initialState);

  const setToolbarOpen = (toolbarState) => {
    return dispatch({type: 'TOGGLE_TOOLBAR', payload: toolbarState});
  };

  const setButtonConfig = (buttonConfig) => {
    return dispatch({type: 'SET_BUTTON_CONFIG', payload: buttonConfig});
  };

  const initEditor = useCallback(() => {
    return dispatch({type: 'INIT_EDITOR'});
  }, [dispatch]);

  const resetEditor = useCallback(() => {
    dispatch({type: 'RESET_EDITOR'});
  }, [dispatch]);

  const providerValue = {
    ...state,
    dispatch,
    setToolbarOpen,
    setButtonConfig,
    initEditor,
    resetEditor,
  };

  return <NewsletterContext.Provider value={providerValue}>{children}</NewsletterContext.Provider>;
};

export default NewsletterContext;

export const useNewsletter = () => {
  const context = useContext(NewsletterContext);

  if (context === undefined) {
    throw new Error('NewsletterContext must be used within an AutomationFlowProvider');
  }

  return context;
};
