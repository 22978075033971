import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './DetailsPanel.module.scss';

export function DetailsPanel({children, title}) {
  return (
    <div className={styles.panel}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.cols}>{children}</div>
    </div>
  );
}

DetailsPanel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export function DetailsPanelRow({label, value, percent, heading}) {
  return (
    <div className={cx(styles.row, {[styles.heading]: heading})}>
      <span>{label}</span>
      {percent ? (
        <div className={styles.cell}>
          <strong>{value}</strong>
          <span>({percent})</span>
        </div>
      ) : (
        <strong>{value}</strong>
      )}
    </div>
  );
}

DetailsPanelRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  heading: PropTypes.bool,
  percent: PropTypes.string,
};

export function DetailsPanelCol({children, style}) {
  return (
    <div className={styles.col} style={style}>
      {children}
    </div>
  );
}

DetailsPanelCol.propTypes = {
  children: PropTypes.node.isRequired,
};
