import {post} from '../../../utils/api';
import {ENDPOINTS} from '../constants';
import {GENERIC_ERROR_MESSAGE} from '../../../utils/constants';

async function login(apiSettings, values) {
  const data = {
    grant_type: 'password',
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_CLIENT_AUDIENCE,
    username: values.email,
    password: values.password,
    scope: 'openid offline_access',
  };

  return post(ENDPOINTS.login, data, {...apiSettings, isAuthenticated: false}).catch(function (
    error
  ) {
    if (error.response?.error_description) {
      throw new Error(error.response?.error_description);
    }
    throw new Error(GENERIC_ERROR_MESSAGE);
  });
}

export default login;
