import {useContext} from 'react';
import {Context} from './context/AlertContext';

function useAlert() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useAlert must be used within a AlertProvider`);
  }
  return context;
}

export default useAlert;
