import Icon from '../../../components/Icon';
import styles from './AddTag.module.scss';

function AddTag({hasTags, openAddTags}) {
  return (
    <div className={styles.addTag} onClick={openAddTags} title="Add more tags">
      {!hasTags && <span className={styles.addTagText}>Add a tag</span>}
      <Icon name="plus" className={styles.addTagIcon} />
    </div>
  );
}

export default AddTag;
