import {v4 as uuidv4} from 'uuid';
import {cloneDeep} from 'lodash';
import {sortSteps} from './jsonToGraph';

export const incrementOrAddVersionToName = (sourceName, automationOverviews) => {
  const automationNames = automationOverviews?.map((a) => a.name) || [];

  const pattern = /(.*?)(v\d+)?$/;
  const [, name, version] = sourceName.match(pattern);

  const highestVersion = automationNames.reduce((highest, item) => {
    const [, , itemVersion] = item.match(pattern);

    const itemVersionNumber = itemVersion && parseInt(itemVersion.slice(1), 10);
    return itemVersionNumber > highest ? itemVersionNumber : highest;
  }, 0);

  const existingItem = automationNames.find((item) => {
    const [, itemName] = item.match(pattern);
    return itemName === name;
  });

  if (highestVersion > 0) {
    return `${name} v${highestVersion + 1}`;
  }
  if (existingItem) {
    const [, , existingVersion] = existingItem.match(pattern);

    const newVersion = existingVersion ? parseInt(existingVersion.slice(1), 10) + 1 : 2;

    return `${name} v${newVersion}`;
  }
  if (version) {
    const newVersion =
      parseInt(version.slice(1), 10) > highestVersion
        ? parseInt(version.slice(1), 10)
        : highestVersion + 1;

    return `${name} v${newVersion}`;
  }
};

export const copyAutomation = (sourceAutomation) => {
  const dataCopy = cloneDeep(sourceAutomation);
  delete dataCopy.id;
  const oldStepIds = [];

  const sortedSteps = sortSteps(dataCopy.steps, dataCopy.startStepId);

  dataCopy.steps = sortedSteps.map((s) => {
    const step = {...s};
    if (step.stepParam?.nextStepId) {
      oldStepIds.push({oldId: step.stepParam.nextStepId, newId: String(uuidv4())});
    }
    if (step.stepParam?.nextStepIdIfTrue) {
      oldStepIds.push({oldId: step.stepParam.nextStepIdIfTrue, newId: String(uuidv4())});
    }
    if (step.stepParam?.nextStepIdIfFalse) {
      oldStepIds.push({oldId: step.stepParam.nextStepIdIfFalse, newId: String(uuidv4())});
    }

    step.id = oldStepIds.find((oldStep) => oldStep?.oldId === step.id)?.newId
      ? oldStepIds.find((oldStep) => oldStep?.oldId === step.id)?.newId
      : String(uuidv4());
    if (step.stepParam?.nextStepId) {
      step.stepParam.nextStepId = oldStepIds.find(
        (oldStep) => oldStep?.oldId === step.stepParam?.nextStepId
      )?.newId
        ? oldStepIds.find((oldStep) => oldStep?.oldId === step.stepParam?.nextStepId)?.newId
        : String(uuidv4());
    }
    if (step.stepParam?.nextStepIdIfTrue) {
      step.stepParam.nextStepIdIfTrue = oldStepIds.find(
        (oldStep) => oldStep?.oldId === step.stepParam?.nextStepIdIfTrue
      )?.newId
        ? oldStepIds.find((oldStep) => oldStep?.oldId === step.stepParam?.nextStepIdIfTrue)?.newId
        : String(uuidv4());
    }
    if (step.stepParam?.nextStepIdIfFalse) {
      step.stepParam.nextStepIdIfFalse = oldStepIds.find(
        (oldStep) => oldStep?.oldId === step.stepParam?.nextStepIdIfFalse
      )?.newId
        ? oldStepIds.find((oldStep) => oldStep?.oldId === step.stepParam?.nextStepIdIfFalse)?.newId
        : String(uuidv4());
    }

    return step;
  });
  dataCopy.startStepId = dataCopy.steps[0].id;
  dataCopy.triggers = dataCopy.triggers.map((trigger) => ({
    ...trigger,
    id: '',
  }));
  return dataCopy;
};
