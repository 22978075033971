import PropTypes from 'prop-types';
import {billingPeriodPropType, moneyPropType} from './index';

export const invoiceItemPropType = PropTypes.shape({
  description: PropTypes.string,
  amount: moneyPropType,
});

export const billingCyclePropType = PropTypes.shape({
  nextInvoiceDate: PropTypes.string,
  invoiceDayOfTheMonth: PropTypes.string,
  remainingTrialDays: PropTypes.number,
});

export const todaysChargePropType = PropTypes.shape({
  invoiceItems: PropTypes.arrayOf(invoiceItemPropType),
  subtotal: moneyPropType,
  vat: PropTypes.shape({
    rate: moneyPropType,
    amount: moneyPropType,
  }),
  total: moneyPropType,
  appliedBalance: moneyPropType,
  amountDue: moneyPropType,
});

export const recurringChargePropType = PropTypes.shape({
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    subscriberLimitTo: PropTypes.string,
  }),
  vat: PropTypes.shape({
    rate: moneyPropType,
    amount: moneyPropType,
  }),
  amount: moneyPropType,
  total: moneyPropType,
  billingPeriod: billingPeriodPropType,
});

export const orderPreviewProp = PropTypes.shape({
  recurringCharge: recurringChargePropType.isRequired,
  todaysCharge: todaysChargePropType.isRequired,
  enrollmentTimestamp: PropTypes.number,
  billingCycle: billingCyclePropType.isRequired,
});
