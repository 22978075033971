export const isFieldTag = (field) => {
  return field?.type === 'TAG';
};

export const isMatcher = (field) => {
  return field?.type === 'MATCHER';
};

export const isFieldSegment = (field) => {
  return !!field?.expression;
};

export const fieldNaming = (field) => {
  return isFieldTag(field) ? 'Tag' : 'Subscriber Field';
};

export const formValuesToRequestPayload = ({optionValues, ...rest}) => ({
  optionValues: optionValues?.map(({value}) => value),
  ...rest,
});

export const fieldDataToFormValues = (data) =>
  data && {
    ...data,
    optionValues: data.optionValues?.map((value) => ({
      value,
      label: value,
    })),
  };
