import {usePopperTooltip} from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import cx from 'clsx';
import {createPortal} from 'react-dom';

const PreviewImage = ({
  trigger,
  width,
  height,
  thumbnailUrl,
  previewImageUrl,
  imageClassName,
  alt,
  delayShow = 300,
  placement = 'auto',
  interactive = true,
  ...props
}) => {
  const {getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible} = usePopperTooltip({
    placement,
    delayShow,
    trigger,
    interactive,
    offset: [0, 10],
    ...props,
  });

  return (
    <>
      <span ref={setTriggerRef}>
        <img className={imageClassName} src={thumbnailUrl} alt={alt} />
      </span>
      {visible &&
        createPortal(
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: cx('tooltip-container'),
            })}
          >
            <div
              {...getArrowProps({
                className: cx('tooltip-arrow'),
              })}
            />
            <div style={{width, height}}>
              <img
                src={previewImageUrl}
                alt={alt}
                style={{width: '100%', height: '100%', objectFit: 'contain'}}
              />
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default PreviewImage;
