import PropTypes from 'prop-types';

const AutomationIcon = ({className}) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.5" y="1.5" width="15" height="5.66667" rx="0.5" />
      <rect x="0.5" y="14.8333" width="7" height="5.66667" rx="0.5" />
      <path d="M10 7.66669V11M10 11H6C4.89543 11 4 11.8955 4 13V14.3334M10 11H14C15.1046 11 16 11.8955 16 13V14.3334" />
      <rect x="12.5" y="14.8333" width="7" height="5.66667" rx="0.5" />
    </svg>
  );
};

AutomationIcon.propTypes = {
  className: PropTypes.string,
};

export default AutomationIcon;
