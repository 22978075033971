import StatsTabs from '../components/StatsTabs';

function SubscribersReports() {
  return (
    <>
      <StatsTabs />
    </>
  );
}

export default SubscribersReports;
