import SelectField from './SelectField';
import {selectMapper} from '../../../../../utils/arrays';
import useSegmentsFetcher from '../../hooks/useSegmentsFetcher';

function SegmentsSelectField({segmentId, ...props}) {
  const {data: dataSegments, loading} = useSegmentsFetcher({immediate: true});

  return (
    <SelectField
      {...props}
      placeholder={loading ? 'Loading..' : undefined}
      options={dataSegments?.filter((v) => v.id !== segmentId).map(selectMapper('id', 'name'))}
    />
  );
}

export default SegmentsSelectField;
