import {Helmet} from 'react-helmet';
import ForgotPassword from '../ForgotPassword';

function ForgotPasswordPage() {
  return (
    <div>
      <Helmet title="Forgot Password" />
      <ForgotPassword />
    </div>
  );
}

export default ForgotPasswordPage;
