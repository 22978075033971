import PropTypes from 'prop-types';
import Card from '../Card';
import Pagination from '../Pagination';
import Spinner from '../Spinner';
import {paginatedMetaPropType} from '../../utils/prop-types';

function PaginatedCard({children, header, loading, onPageChange, meta, ...props}) {
  return (
    <Card {...props}>
      {header}
      {loading ? <Spinner height={180} /> : children}
      {meta?.totalPages > 1 && (
        <Pagination onPageChange={onPageChange} totalPages={meta.totalPages} />
      )}
    </Card>
  );
}

PaginatedCard.propTypes = {
  children: PropTypes.node.isRequired,
  onPageChange: PropTypes.func.isRequired,
  meta: paginatedMetaPropType,
  header: PropTypes.node,
  loading: PropTypes.bool,
};

export default PaginatedCard;
