import {Link} from 'react-router-dom';
import ROUTES from '../../../Router/routes';

import styles from '../auth.module.scss';

function FooterText() {
  return (
    <p className={styles.text}>
      Already have an account? Then please{' '}
      <Link to={ROUTES.auth.login} className={styles.link}>
        Log In
      </Link>
      .
    </p>
  );
}

export default FooterText;
