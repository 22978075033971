import PropTypes from 'prop-types';
import Checkbox from '../../../../components/forms/Checkbox';
import styles from './Toolbar.module.scss';
import {useHandleConfigParam} from '../../hooks/useHandleConfigParam';

const CheckboxField = ({paramPath, ...props}) => {
  const [value, handleChange] = useHandleConfigParam(paramPath);

  return (
    <div className={styles.toolbarFieldContainer}>
      <Checkbox checked={value} onChange={handleChange} id={paramPath} middle {...props} />
    </div>
  );
};

CheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  paramPath: PropTypes.string,
};

export default CheckboxField;
