import PropTypes from 'prop-types';
import useDataTableFetcher from './useDataTableFetcher';
import {useDataTableContext} from './context/TableContext';
import ActionsDropdown from '../ActionsDropdown';
import ActionDropdownItem from '../ActionsDropdown/ActionDropdownItem';
import CheckBox from '../forms/Checkbox';
import styles from './TableRow.module.scss';
import useRouter from '../../hooks/useRouter';
import ROUTES from '../../Router/routes';

function TableRow({children, onDelete, rowData}) {
  const {history} = useRouter();
  const {state, selectRow, setProcessing, setData, deselectRow} = useDataTableContext();
  const [tableFetcher] = useDataTableFetcher();

  async function handleDeleteSubscriber() {
    try {
      await onDelete(rowData.id);
      const response = await tableFetcher();
      setData(response);
    } catch (e) {
      setProcessing(false);
    }
  }

  function handleEdit() {
    history.push(`${ROUTES.subscribers.index}/${rowData.id}`);
  }

  function handleSelectSubscriber(e) {
    if (e.target.checked) {
      selectRow(rowData.id);
    } else {
      deselectRow(rowData.id);
    }
  }

  function isSelected() {
    return state.selectedRows.includes(rowData.id);
  }

  return (
    <tr className={styles.row}>
      <td>
        <CheckBox
          checked={isSelected()}
          onChange={handleSelectSubscriber}
          value={rowData.id}
          id={rowData.id}
        />
      </td>
      {children}
      <td>
        <ActionsDropdown>
          <ActionDropdownItem onSelect={handleEdit}>Edit</ActionDropdownItem>
          <ActionDropdownItem onSelect={handleDeleteSubscriber}>Delete</ActionDropdownItem>
        </ActionsDropdown>
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  onDelete: PropTypes.func.isRequired,
  rowData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default TableRow;
