import {useCallback, useMemo, useState} from 'react';
import useRouter from '../../../../../../hooks/useRouter';
import useFetch from '../../../../../../hooks/useFetch';
import {fetchTemplateService} from '../../../../service';
import useFetchTemplate from '../../../../hooks/useFetchTemplate';

const useTemplatesList = ({categories, search, type}) => {
  const {history, location} = useRouter();

  const [open, setOpen] = useState(null);

  const {templates, page, hasMore, loading, handleFetchMore} = useFetchTemplate(
    type,
    categories,
    search
  );

  const {run: fetchTemplateRequest} = useFetch(fetchTemplateService, {
    throwError: true,
  });

  const handleClick = useCallback(
    (template) => async () => {
      const templateData = await fetchTemplateRequest(template?.id);
      history.push({
        pathname: location.state?.back,
        state: {template: templateData, data: location.state?.data},
      });
    },
    [fetchTemplateRequest, history, location.state?.back, location.state?.data]
  );

  const toggleModal = useCallback(
    (state) => () => {
      setOpen(state);
    },
    []
  );

  const isDirty = useMemo(() => location?.state?.isDirty, [location?.state?.isDirty]);

  return {
    open,
    isDirty,
    templates,
    page,
    loading,
    handleFetchMore,
    handleClick,
    hasMore,
    toggleModal,
  };
};

export default useTemplatesList;
