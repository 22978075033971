import PropTypes from 'prop-types';
import {TabPanel as ReactTabPanel} from 'react-tabs';
import styles from './Tabs.module.scss';

function TabPanel({children, ...props}) {
  return (
    <ReactTabPanel className={styles.panel} selectedClassName={styles.panelSelected} {...props}>
      {children}
    </ReactTabPanel>
  );
}

TabPanel.tabsRole = 'TabPanel';
TabPanel.propTypes = {
  children: PropTypes.node,
};

export default TabPanel;
