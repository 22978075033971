import {Controller} from 'react-hook-form';
import Select from '../../../../components/forms/Select';
import Label from '../../../../components/forms/Label';
import Checkbox from '../../../../components/forms/Checkbox';
import styles from './ConstrainedValueField.module.scss';

const ConstrainedValueField = ({optionValues, inputRef, ...props}) => {
  if (optionValues.length === 1) {
    return (
      <div>
        <Label>{props.label}</Label>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            {...props}
            defaultValue={props.value ?? false}
            inputRef={inputRef}
            middle
            size="big"
            label="Selected"
            id={props.name}
          />
        </div>
      </div>
    );
  }

  return (
    <Controller
      {...props}
      as={Select}
      options={optionValues.map((value) => ({
        value: value.value ? value.value : value,
        label: value.label ? value.label : value,
      }))}
      isClearable
      inputRef={inputRef}
    />
  );
};

export default ConstrainedValueField;
