import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './StatusMessage.module.scss';

function StatusMessage({message, type}) {
  return message ? (
    <p
      className={cx(styles.message, {
        [styles[type]]: type,
      })}
    >
      {message}
    </p>
  ) : null;
}

StatusMessage.defaultProps = {
  type: 'error',
};

StatusMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'success']),
};

export default StatusMessage;
