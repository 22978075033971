import PropTypes from 'prop-types';
import {useState} from 'react';

import HighlightedText from '../../../components/HighlightedText';
import Subtitle from '../../../components/Subtitle';
import ResendEmailButton from '../components/ResendEmailButton';
import PostalAddressContent from '../components/PostalAddressContent';
import styles from './BulkInformationModalContent.module.scss';
import SuccessResendText from '../components/SuccessResendText';

function BulkInformationModalContent({email, onRequestClose}) {
  const [isResend, setIsResend] = useState(false);
  const onResend = () => setIsResend(true);

  return (
    <>
      <Subtitle noMarginTop>We still need some information from you!</Subtitle>
      {!isResend && (
        <HighlightedText>
          Welcome to Maildroppa! Your account setup is almost complete. Please check your{' '}
          <b>{email}</b> inbox for the confirmation email we sent when you signed up. Click on the
          link provided to confirm your email address. If you did not receive an email from us
          please <ResendEmailButton onResend={onResend} /> and we will resend it.
          <div className={styles.textWrapper}>
            You will also need to supply a valid physical/postal address to comply with anti-spam
            regulations. Please fill in the form below and click the <b>Submit</b> button to update
            your details.
          </div>
        </HighlightedText>
      )}
      {isResend && <SuccessResendText email={email} />}
      <PostalAddressContent submitCallback={onRequestClose} />
    </>
  );
}

BulkInformationModalContent.propTypes = {
  email: PropTypes.string,
  onRequestClose: PropTypes.func,
};

export default BulkInformationModalContent;
