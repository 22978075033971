export const ENDPOINTS = {
  fetchSignupFormId: '/signup-form',
  fetchAll: '/form-builder',
  fetchAllActive: '/form-builder/active',
  fetchById: (id) => `/form-builder/${id}`,
  fetchNonBuilderById: (id, fingerPrint) => `/form/${id}?${new URLSearchParams({fingerPrint})}`,
  create: '/form-builder',
  update: '/form-builder',
  updateStatus: '/form-builder/status',
  delete: (id) => `/form-builder/${id}`,
  fetchReport: '/report/form',
  fetchTotalReport: '/report/form/total',
};

export const FORM_VARIANTS = {
  slider: {
    name: 'Slider',
  },
  inline: {
    name: 'Page Inline',
  },
  popup: {
    name: 'Popup',
  },
};

export const FONT_FAMILY_OPTIONS = [
  {
    value: 'inherit',
    label: '(Use website default)',
  },
  {
    value: 'Arial, sans-serif',
    label: 'Arial',
  },
  {
    value: 'Verdana, sans-serif',
    label: 'Verdana',
  },
  {
    value: 'Tahoma, sans-serif',
    label: 'Tahoma',
  },
  {
    value: '"Trebuchet MS", sans-serif',
    label: 'Trebuchet MS',
  },
  {
    value: 'Impact, sans-serif',
    label: 'Impact',
  },
  {
    value: 'Didot, sans-serif',
    label: 'Didot',
  },
  {
    value: 'Georgia, sans-serif',
    label: 'Georgia',
  },
  {
    value: '"American Typewriter", serif',
    label: 'American Typewriter',
  },
  {
    value: '"Andalé Mono", monospace',
    label: 'Andalé Mono',
  },
  {
    value: '"Times New Roman", serif',
    label: 'Times New Roman',
  },
  {
    value: 'Courier, monospace',
    label: 'Courier',
  },
  {
    value: '"Lucida Console", monospace',
    label: 'Lucida Console',
  },
  {
    value: 'Monaco, monospace',
    label: 'Monaco',
  },
  {
    value: '"Bradley Hand", cursive',
    label: 'Bradley Hand',
  },
  {
    value: '"Brush Script MT", cursive',
    label: 'Brush Script MT',
  },
  {
    value: '"Luminari", fantasy',
    label: 'Luminari',
  },
  {
    value: '"Comic Sans MS", cursive',
    label: 'Comic Sans MS',
  },
];

export const FONT_WEIGHT_OPTIONS = [
  {
    value: 400,
    label: 'Normal',
  },
  {
    value: 600,
    label: 'Semibold',
  },
  {
    value: 700,
    label: 'Bold',
  },
];

export const FREQUENCY_OPTIONS = [
  {
    value: 0,
    label: 'Always',
  },
  {
    value: 1,
    label: 'Once per hour',
  },
  {
    value: 24,
    label: 'Once per day',
  },
  {
    value: 168,
    label: 'Once per week',
  },
  {
    value: 720,
    label: 'Once per month',
  },
];

export const DEFAULT_SIZE_UNIT = 'px';

export const FONT_SIZE_OPTIONS = Array.from(Array(10), (_, index) => index * 2 + 6).map((size) => ({
  label: `${size}${DEFAULT_SIZE_UNIT}`,
  value: size,
}));

export const CUSTOM_FIELD_DEFAULT_DATA = {
  name: '',
  label: '',
  placeholder: '',
  isRequired: false,
  inputFieldType: 'CUSTOM',
  dataType: 'TEXT',
};

const DEFAULT_FORM_DATA = {
  id: '',
  name: 'Default Name',
  type: 'Default Type',
  jsonConfig: {
    isPopupWidget: false,
    sliderPopupPosition: 'BOTTOM_RIGHT',
    mainBorder: {
      color: '#000000',
      radius: 8,
      size: 0,
    },
    mainBackgroundColor: '#2B3254',
    mainFontFamily: 'inherit',
    frequency: 0,
    delayTime: 0,
    header: {
      text: {
        value: 'Join us',
        font: {
          fontSize: 24,
          fontWeight: 800,
          color: '#FFFFFF',
        },
        textAlign: 'CENTER',
      },
    },
    body: {
      text: {
        value:
          'To join our email newsletter and receive various content from our service, input your name and email address to confirm your subscription!',
        font: {
          fontSize: 17,
          fontWeight: 400,
          color: '#FFFFFF',
        },
        textAlign: 'CENTER',
      },
      inputFieldStyle: {
        fontWeight: 400,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        border: {
          color: '#000000',
          radius: 8,
          size: 0,
        },
        label: {
          textAlign: 'LEFT',
          font: {
            fontSize: 17,
            fontWeight: 400,
            color: '#FFFFFF',
          },
        },
      },
      subscriberCounterStyle: {
        text: {
          value: 'Happy subscribers and counting',
          font: {
            fontSize: 13,
            fontWeight: 400,
            color: '#FFFFFF',
          },
          textAlign: 'CENTER',
        },
      },
      progressBarType: 'None',
      progressBarPercentStyle: {
        text: {
          value: '50% Completed',
          font: {
            fontSize: 13,
            fontWeight: 700,
            color: '#FFFFFF',
          },
          textAlign: 'CENTER',
        },
        backgroundColor: '#F26B86',
      },
      progressBarStepStyle: {
        text: {
          stepOneValue: 'Join Newsletter',
          stepTwoValue: 'Get Reward',
        },
        backgroundColorActive: '#109CF1',
        backgroundColorInactive: '#AAADC0',
      },
    },
    button: {
      text: {
        value: 'Count me in!',
        font: {
          fontSize: 16,
          fontWeight: 400,
          color: '#FFFFFF',
        },
        textAlign: 'CENTER',
      },
      width: '100%',
      backgroundColor: '#0ED2AF',
      border: {
        color: '#000000',
        radius: 8,
        size: 0,
      },
    },
    footer: {
      text: {
        value: "We respect your privacy. We'll NEVER sell, rent or share your email address.",
        font: {
          fontSize: 13,
          fontWeight: 400,
          color: '#FFFFFF',
        },
        textAlign: 'CENTER',
      },
    },
  },
  subscriberCounter: {
    enabled: false,
    offset: 0,
  },
  inputFields: [],
  tags: [],
};

export const DEFAULT_FORM_DATA_SLIDER = {
  ...DEFAULT_FORM_DATA,
  name: 'My Slider Form',
  type: 'SLIDER',
};

export const DEFAULT_INLINE_FORM_DATA = {
  ...DEFAULT_FORM_DATA,
  name: 'My Inline Form',
  type: 'INLINE',
  jsonConfig: {
    ...DEFAULT_FORM_DATA.jsonConfig,
    mainBackgroundColor: '#faeed0',
    header: {
      ...DEFAULT_FORM_DATA.jsonConfig.header,
      text: {
        ...DEFAULT_FORM_DATA.jsonConfig.header.text,
        font: {
          ...DEFAULT_FORM_DATA.jsonConfig.header.text.font,
          color: '#000000',
        },
      },
    },
    body: {
      ...DEFAULT_FORM_DATA.jsonConfig.body,
      text: {
        ...DEFAULT_FORM_DATA.jsonConfig.body.text,
        font: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.text.font,
          color: '#000000',
        },
      },
      inputFieldStyle: {
        ...DEFAULT_FORM_DATA.jsonConfig.body.inputFieldStyle,
        backgroundColor: '#ffffff',
        border: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.inputFieldStyle.border,
          color: '#e7b943',
          size: 1,
        },
        label: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.inputFieldStyle.label,
          font: {
            ...DEFAULT_FORM_DATA.jsonConfig.body.inputFieldStyle.label.font,
            color: '#000000',
          },
        },
      },
      subscriberCounterStyle: {
        ...DEFAULT_FORM_DATA.jsonConfig.body.subscriberCounterStyle,
        text: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.subscriberCounterStyle.text,
          font: {
            ...DEFAULT_FORM_DATA.jsonConfig.body.subscriberCounterStyle.text.font,
            color: '#000000',
          },
        },
      },
      progressBarPercentStyle: {
        ...DEFAULT_FORM_DATA.jsonConfig.body.progressBarPercentStyle,
        text: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.progressBarPercentStyle.text,
          font: {
            ...DEFAULT_FORM_DATA.jsonConfig.body.progressBarPercentStyle.text.font,
            color: '#000000',
          },
        },
      },
    },
    button: {
      ...DEFAULT_FORM_DATA.jsonConfig.button,
      text: {
        ...DEFAULT_FORM_DATA.jsonConfig.button.text,
        font: {
          ...DEFAULT_FORM_DATA.jsonConfig.button.text.font,
          color: '#000000',
        },
      },
      backgroundColor: '#f0c14b',
      border: {
        ...DEFAULT_FORM_DATA.jsonConfig.button.border,
        color: '#9c7e31',
        size: 1,
      },
    },
    footer: {
      ...DEFAULT_FORM_DATA.jsonConfig.footer,
      text: {
        ...DEFAULT_FORM_DATA.jsonConfig.footer.text,
        font: {
          ...DEFAULT_FORM_DATA.jsonConfig.footer.text.font,
          color: '#000000',
        },
      },
    },
  },
};

export const DEFAULT_FORM_DATA_POPUP = {
  ...DEFAULT_FORM_DATA,
  name: 'My Form Popup',
  type: 'POPUP',
  jsonConfig: {
    ...DEFAULT_FORM_DATA.jsonConfig,
    mainBackgroundColor: '#FFFFFF',
    header: {
      ...DEFAULT_FORM_DATA.jsonConfig.header,
      text: {
        ...DEFAULT_FORM_DATA.jsonConfig.header.text,
        font: {
          ...DEFAULT_FORM_DATA.jsonConfig.header.text.font,
          fontSize: 34,
          fontWeight: 700,
          color: '#2B3254',
        },
      },
    },
    body: {
      ...DEFAULT_FORM_DATA.jsonConfig.body,
      text: {
        ...DEFAULT_FORM_DATA.jsonConfig.body.text,
        font: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.text.font,
          fontSize: 14,
          color: '#5C6280',
        },
      },
      inputFieldStyle: {
        ...DEFAULT_FORM_DATA.jsonConfig.body.inputFieldStyle,
        backgroundColor: '#FFFFFF',
        border: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.inputFieldStyle.border,
          color: '#DDE3E8',
          size: 1,
        },
        label: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.inputFieldStyle.label,
          font: {
            ...DEFAULT_FORM_DATA.jsonConfig.body.inputFieldStyle.label.font,
            color: '#676C85',
          },
        },
      },
      subscriberCounterStyle: {
        ...DEFAULT_FORM_DATA.jsonConfig.body.subscriberCounterStyle,
        text: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.subscriberCounterStyle.text,
          font: {
            ...DEFAULT_FORM_DATA.jsonConfig.body.subscriberCounterStyle.text.font,
            color: '#5C6280',
          },
        },
      },
      progressBarType: '50%',
      progressBarPercentStyle: {
        ...DEFAULT_FORM_DATA.jsonConfig.body.progressBarPercentStyle,
        text: {
          ...DEFAULT_FORM_DATA.jsonConfig.body.progressBarPercentStyle.text,
          font: {
            ...DEFAULT_FORM_DATA.jsonConfig.body.progressBarPercentStyle.text.font,
            color: '#2B3254',
          },
        },
      },
    },
    button: {
      ...DEFAULT_FORM_DATA.jsonConfig.button,
      text: {
        ...DEFAULT_FORM_DATA.jsonConfig.button.text,
        font: {
          ...DEFAULT_FORM_DATA.jsonConfig.button.text.font,
          color: '#FFFFFF',
        },
      },
      backgroundColor: '#109CF1',
    },
    footer: {
      ...DEFAULT_FORM_DATA.jsonConfig.footer,
      text: {
        ...DEFAULT_FORM_DATA.jsonConfig.footer.text,
        value:
          "I respect your privacy. I'll NEVER sell, rent or share your email address. That's more than a policy, it's my personal guarantee!",
        font: {
          ...DEFAULT_FORM_DATA.jsonConfig.footer.text.font,
          color: '#747B9A',
        },
      },
    },

    subscriberCounter: {
      ...DEFAULT_FORM_DATA.jsonConfig.subscriberCounter,
      enabled: true,
    },
  },
};

export const PROGRESS_BAR_OPTIONS = ['50%', '1/2', 'None'];

export const BUILDER_MAX_BORDER_RADIUS = 32;
export const BUILDER_MAX_BORDER_SIZE = 12;
export const BUILDER_MIN_FONT_SIZE = 4;
export const BUILDER_MAX_FONT_SIZE = 48;
