import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
import cx from 'clsx';
import Label from '../../../../components/Label';
import Button from '../../../../components/Button';
import ActionsDropdown from '../../../../components/ActionsDropdown';
import ActionDropdownItem from '../../../../components/ActionsDropdown/ActionDropdownItem';
import styles from './SubscriptionFormListPage.module.scss';
import useRouter from '../../../../hooks/useRouter';
import SubscriptionFormCodeModal from './SubscriptionFormCodeModal';
import {useFreePlanModals} from '../../../free-plan-info/hooks/useFreePlanModals';

function SubscriptionFormListPageList({formList, totalReportData, onDelete, onStatusUpdate}) {
  const {history} = useRouter();
  const hasData = !!formList?.length;
  const {
    isFreePlanModalOpen,
    FreePlanModal,
    checkFreePlanVerified,
    FreePlanVerificationError,
  } = useFreePlanModals();

  const [codeFormId, setCodeFormId] = useState('');
  const [codeFormType, setCodeFormType] = useState('');

  const handleOpenForm = useCallback(
    (id) => {
      history.push(`/signup-forms/${id}/edit`);
    },
    [history]
  );

  const renderActions = useCallback((item) => {
    return item.type === 'DEFAULT' ? (
      <ActionDropdownItem
        onSelect={() => onStatusUpdate(item.id, item.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
      >
        {item.status == 'ACTIVE' ? 'Deactivate' : 'Activate'}
      </ActionDropdownItem>
    ) : (
      <>
        <ActionDropdownItem onSelect={() => handleOpenForm(item.id)}>Edit Form</ActionDropdownItem>
        <ActionDropdownItem onSelect={() => onDelete(item.id)}>Delete</ActionDropdownItem>
        <ActionDropdownItem
          onSelect={() => onStatusUpdate(item.id, item.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
        >
          {item.status == 'ACTIVE' ? 'Deactivate' : 'Activate'}
        </ActionDropdownItem>
      </>
    );
  }, []);

  const getReport = (formId, type) => {
    const defaultValue = '0';
    if (!totalReportData.formTotals) return defaultValue;
    const foundFormTotalReport = totalReportData.formTotals.find(
      (formTotal) => formTotal.formId == formId
    );

    if (!foundFormTotalReport) return defaultValue;

    return foundFormTotalReport[type].formattedValue;
  };

  const handleCodeSnippet = async (item) => {
    try {
      checkFreePlanVerified();
      setCodeFormId(item.id);
      setCodeFormType(item.type);
    } catch (err) {
      if (err instanceof FreePlanVerificationError) {
      }
    }
  };

  return (
    <div className={styles.list}>
      {isFreePlanModalOpen && <FreePlanModal />}
      {hasData ? (
        formList.map((item) => {
          const isInactive = item.status === 'INACTIVE';

          return (
            <div key={item.id} className={styles.item}>
              {item.type !== 'DEFAULT' && (
                <div className={styles.labelWrapper}>
                  <Label
                    color={isInactive ? '#676c85' : item.color}
                    style={{opacity: isInactive && 0.3}}
                  >
                    {item.type}
                  </Label>
                </div>
              )}
              <div className={styles.content}>
                {item.type !== 'DEFAULT' ? (
                  <button
                    type="button"
                    className={styles.title}
                    style={{opacity: isInactive && 0.3}}
                    onClick={() => {
                      handleOpenForm(item.id);
                    }}
                    title="Click to edit this form"
                  >
                    {item.name}
                  </button>
                ) : (
                  <span
                    className={cx(styles.title, styles.defaultFormName)}
                    style={{opacity: isInactive && 0.3}}
                  >
                    {item.name}
                  </span>
                )}
              </div>
              {totalReportData && (
                <>
                  <div className={styles.reportItem} style={{opacity: isInactive && 0.3}}>
                    <div className={styles.reportValue}>{getReport(item.id, 'viewTotal')}</div>
                    <div className={styles.reportName}>Views</div>
                  </div>
                  <div className={styles.reportItem} style={{opacity: isInactive && 0.3}}>
                    <div className={styles.reportValue}>
                      {getReport(item.id, 'confirmationTotal')}
                    </div>
                    <div className={styles.reportName}>Confirmations</div>
                  </div>
                  <div className={styles.reportItem} style={{opacity: isInactive && 0.3}}>
                    <div className={styles.reportValue}>{getReport(item.id, 'conversionRate')}</div>
                    <div className={styles.reportName}>Conversion Rate</div>
                  </div>
                </>
              )}
              <div className={styles.codeButtonWrapper}>
                {!isInactive && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="green"
                    onClick={() => handleCodeSnippet(item)}
                  >
                    Show Code Snippet
                  </Button>
                )}
              </div>

              <div className={cx(styles.actions)}>
                <ActionsDropdown>{renderActions(item)}</ActionsDropdown>
              </div>
            </div>
          );
        })
      ) : (
        <aside className={styles.emptyItem}>
          <p className={styles.emptyItemText}>You don&apos;t have any data yet.</p>
        </aside>
      )}

      <SubscriptionFormCodeModal
        formId={codeFormId}
        formType={codeFormType}
        onRequestClose={() => {
          setCodeFormId('');
          setCodeFormType('');
        }}
      />
    </div>
  );
}

SubscriptionFormListPageList.propTypes = {
  formList: PropTypes.arrayOf(PropTypes.any),
  onDelete: PropTypes.func.isRequired,
};

export default SubscriptionFormListPageList;
