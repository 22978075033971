import cx from 'clsx';
import styles from './PasswordValidation.module.scss';

const errorsList = {
  containsDigit: 'Must contain at least 1 number letter (0-9).',
  containsSpecialChar: 'Must contain at least 1 special character (!@#$%^&*).',
  uppercase: 'Must contain at least 1 uppercase letter (A-Z).',
  lowercase: 'Must contain at least 1 lowercase letter (a-z).',
  minLength: 'Must be 8 characters long.',
};

function PasswordValidation({errors, isSubmitted}) {
  return (
    <div className={styles.rules}>
      <p className={styles.text}>The password must comply with the following rules:</p>
      <ul>
        {Object.entries(errorsList).map(([type, message], i) => {
          const invalid = !!errors?.[type];
          const valid = isSubmitted && !errors?.[type];
          return (
            <li
              key={i}
              className={cx(styles.text, {[styles.invalid]: invalid, [styles.valid]: valid})}
            >
              {message}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default PasswordValidation;
