import {API_REQUEST_ERROR_MESSAGE} from './api/constants';
import {handleAsyncValidation} from './validation/handleAsyncValidation';

export function handleErrorAlert(error, showAlert, setFormError) {
  if (typeof error !== 'object' || !error) {
    throw new Error('The error must be an object.');
  }
  if (typeof showAlert !== 'function') {
    throw new Error('You must provide a showAlert function to handleErrorAlert.');
  }

  if (error.error === 'validation') {
    handleAsyncValidation(error, setFormError);
    return;
  }

  if (error.message) {
    showAlert(API_REQUEST_ERROR_MESSAGE, {
      variant: 'error',
      details: error.message,
      dismiss: true,
    });
  } else {
    showAlert(API_REQUEST_ERROR_MESSAGE, {variant: 'error'});
  }
}
