import {DEFAULT_SIZE_UNIT, ENDPOINTS, FORM_VARIANTS} from './constants';
import {del, get, post, put} from '../../utils/api';

import {getDetailsHash} from './helper';
import {hexToRgbA} from '../../utils/hexToRgba';

export async function fetchSignupFormIdService(apiSettings) {
  return get(ENDPOINTS.fetchSignupFormId, null, apiSettings);
}

export async function loadAllSignupForms(apiSettings, params) {
  return get(ENDPOINTS.fetchAll, params, apiSettings);
}

export async function loadAllActiveSignupForms(apiSettings) {
  return get(ENDPOINTS.fetchAllActive, null, apiSettings);
}

export async function loadSignupFormById(apiSettings, formId) {
  return get(ENDPOINTS.fetchById(formId), null, apiSettings);
}

export async function loadGeneratedFormById(apiSettings, formId) {
  const fingerPrint = await getDetailsHash();
  return get(ENDPOINTS.fetchNonBuilderById(formId, fingerPrint), null, apiSettings);
}

export async function createSignupForm(apiSettings, data) {
  return post(ENDPOINTS.create, data, apiSettings);
}

export async function updateSignupForm(apiSettings, data) {
  return put(ENDPOINTS.update, data, apiSettings);
}

export async function updateSignupFormStatus(apiSettings, data) {
  return put(ENDPOINTS.updateStatus, data, apiSettings);
}

export async function deleteSignupForm(apiSettings, formId) {
  return del(ENDPOINTS.delete(formId), null, apiSettings);
}

export async function loadReport(apiSettings, params) {
  return get(ENDPOINTS.fetchReport, params, apiSettings);
}

export async function loadTotalReport(apiSettings) {
  return get(ENDPOINTS.fetchTotalReport, null, apiSettings);
}

export const getFormName = (type) => FORM_VARIANTS[type?.toLowerCase() || '']?.name || '';

export const findFormByType = (formData, type) => formData.find((form) => form.type === type);

const getSize = (value) => `${value}${DEFAULT_SIZE_UNIT}`;

export const getComponentStyle = ({border, backgroundColor, color}, boolean, fieldValue) => {
  return {
    backgroundColor,
    ...(border ? {borderRadius: getSize(border.radius)} : null),
    ...(border ? {borderWidth: getSize(border.size)} : null),
    ...(border ? {borderColor: border.color} : null),
    ...(border?.size ? {borderStyle: 'solid'} : null),
    ...(color && {color}),
    ...(color && (boolean && !fieldValue ? {color: hexToRgbA(color, '.4')} : {color})),
    ...{'--placeholder-color': color},
  };
};

export const getTextStyles = ({font: {fontSize, color, fontWeight, fontFamily}, textAlign}) => {
  const styles = {
    textAlign,
    color,
    fontSize: fontSize && getSize(fontSize),
    lineHeight: fontSize && getSize(fontSize),
    fontWeight,
    minHeight: fontSize && getSize(fontSize),
  };

  if (fontFamily) {
    styles.fontFamily = fontFamily;
  }

  return styles;
};

export const stringToOption = (value) => ({value, label: value});
