import Input from '../../../../../components/forms/Input';
import {REQUIRED_FIELD_MESSAGE} from '../../../../../components/forms/constants';
import styles from '../SegmentFilter.module.scss';

function TextField({name, error, register, ...props}) {
  return (
    <Input
      {...{name, error}}
      {...props}
      inputRef={register({required: REQUIRED_FIELD_MESSAGE})}
      noBottomSpace
      classNameEl={styles.inputEl}
    />
  );
}

export default TextField;
