import {STEPS} from '../constants';

function setStepsInitialState() {
  const state = {
    completed: false,
    data: {},
    visited: false,
  };
  return Object.values(STEPS).reduce(
    (acc, step) => ({
      ...acc,
      [step]: state,
    }),
    {}
  );
}

export const initialState = {
  targetLocation: undefined,
  activeStep: '',
  activePanelPage: '',
  steps: setStepsInitialState(),
};

export function setActiveStepReducer(state, action) {
  const prevStepId = state.activeStep;
  const newStepId = action.payload;

  let newState = {
    ...state,
    activeStep: action.payload,
  };

  if (prevStepId) {
    newState = {
      ...newState,
      steps: {
        ...state.steps,
        [newStepId]: {
          ...state.steps[newStepId],
          completed: false,
        },
        [prevStepId]: {
          ...state.steps[prevStepId],
          visited: true,
        },
      },
    };
  }

  return newState;
}

export function completeStepReducer(state, action) {
  const stepId = action.payload || state.activeStep;
  return {
    ...state,
    steps: {
      ...state.steps,
      [stepId]: {
        ...state.steps[stepId],
        completed: true,
      },
    },
  };
}

export function uncompleteStepReducer(state, action) {
  const stepId = action.payload || state.activeStep;
  return {
    ...state,
    steps: {
      ...state.steps,
      [stepId]: {
        ...state.steps[stepId],
        completed: false,
      },
    },
  };
}

export function saveStepDataReducer(state, action) {
  const step = action.meta || state.activeStep;
  const stepState = state.steps[step];
  return {
    ...state,
    steps: {
      ...state.steps,
      [step]: {
        ...stepState,
        data: action.payload,
      },
    },
  };
}

export function openIntroReducer(state, action) {
  return {
    ...state,
    activeStep: STEPS.initial,
    targetLocation: action.payload,
  };
}

export function closeIntroReducer(state) {
  return {
    ...state,
    activeStep: '',
  };
}

export function destroyReducer() {
  return initialState;
}

export function openPanelPageReducer(state, action) {
  return {
    ...state,
    activePanelPage: action.payload,
  };
}

export function closePanelPageReducer(state) {
  return {
    ...state,
    activePanelPage: '',
  };
}
