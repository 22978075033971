import {Helmet} from 'react-helmet';
import Card from '../../../../components/Card';
import FormVariantPreview from '../../components/FormVariantPreview';
import styles from './SubscriptionFormSelectPage.module.scss';
import signupFormSliderSvg from '../../../../img/signup-form-slider.svg';
import signupFormPageInlineSvg from '../../../../img/signup-form-page-inline.svg';
import signupFormPopupWidgetSvg from '../../../../img/signup-form-popup-widget.svg';

function SubscriptionFormSelectPage() {
  return (
    <>
      <Helmet title="Signup-Forms" />
      <Card
        title="Select a form to create"
        subtitle={<p>Create your own personalized forms! Choose which one to create.</p>}
        className="cardSubscribtionFormsListHeader"
        centeredHeader
      />
      <Card noBorder noBg noPadding className="cardSubscribtionFormsList">
        <div className={styles.variantsList}>
          <FormVariantPreview
            title="Slider"
            imageSrc={signupFormSliderSvg}
            imageAlt="Slider Signup-Form"
            type="slider"
          />
          <FormVariantPreview
            title="Page Inline"
            imageSrc={signupFormPageInlineSvg}
            imageAlt="Page Inline Signup-Form"
            type="inline"
          />
          <FormVariantPreview
            title="Popup"
            imageSrc={signupFormPopupWidgetSvg}
            imageAlt="Popup"
            type="popup"
          />
        </div>
      </Card>
    </>
  );
}

export default SubscriptionFormSelectPage;
