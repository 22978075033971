import TextareaField from './TextareaField';
import ToolbarSection from './ToolbarSection';
import FontOptionsSection from './FontOptionsSection';
import styles from './Toolbar.module.scss';

const BodyStyles = () => {
  return (
    <>
      <ToolbarSection sectionName="Body Styles" className={styles.toolbarContent}>
        <TextareaField label="Text" paramPath="jsonConfig.body.text.value" />
      </ToolbarSection>
      <FontOptionsSection pathPrefix="jsonConfig.body.text" sectionTitle="Body Text Styles" />
    </>
  );
};

export default BodyStyles;
