import React from 'react';
import get from 'lodash.get';

import NewsletterContext from './context';

export const useHandleButtonParam = (paramPath) => {
  const {dispatch, activeButtonConfig} = React.useContext(NewsletterContext);
  // const {dispatch} = React.useContext(NewsletterContext);

  const value = React.useMemo(() => (paramPath ? get(activeButtonConfig, paramPath) : ''), [
    paramPath,
    activeButtonConfig,
  ]);

  const isNumber = paramPath?.includes('fontSize') || paramPath?.includes('borderRadius');

  const handleInputChange = React.useCallback(
    (e) => {
      if (paramPath) {
        const isCheckbox = e?.target?.type === 'checkbox';

        let v = '';
        if (isCheckbox) {
          v = e.target.checked;
        } else {
          v = typeof e?.target === 'object' ? e.target.value : e;

          if (e?.target?.type === 'number') {
            v = parseFloat(v);
          }
        }

        dispatch({
          type: 'SET_BUTTON_CONFIG_PARAM',
          payload: {path: `activeButtonConfig.${paramPath}`, value: isNumber ? Number(v) : v},
        });
      }
    },
    [dispatch, paramPath]
  );

  return [value, handleInputChange];
  // return [handleInputChange];
};
