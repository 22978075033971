export const camel2title = (camelCase) => {
  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const NUMBER_SPACES_LOCALE = 'ge';
export const numberWithSpaces = (val) => {
  if (typeof val !== 'number') {
    return '';
  }
  return val.toLocaleString(NUMBER_SPACES_LOCALE);
};
