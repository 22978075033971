import {
  CardNumberElement as StripeCardNumberElement,
  CardExpiryElement as StripeCardExpiryElement,
  CardCvcElement as StripeCardCvcElement,
} from '@stripe/react-stripe-js';
import merge from 'lodash.merge';
import cloneDeep from 'lodash.clonedeep';
import styles from '../Input/Input.module.scss';

const baseStyles = {
  style: {
    base: {
      fontFamily: '"Gilroy", sans-serif',
      '::placeholder': {color: '#9198b6'},
      color: '#2b3254',
      letterSpacing: '0.2rem',
      fontSize: '16px',
    },
    invalid: {
      color: '#2b3254',
    },
  },
  classes: {
    base: styles.el,
    focus: styles.focus,
  },
};

export function CardNumberElement({options, ...props}) {
  const clonedBaseStyles = cloneDeep(baseStyles);
  return <StripeCardNumberElement options={merge(clonedBaseStyles, options)} {...props} />;
}

export function CardExpiryElement({options, ...props}) {
  const clonedBaseStyles = cloneDeep(baseStyles);
  return <StripeCardExpiryElement options={merge(clonedBaseStyles, options)} {...props} />;
}

export function CardCvcElement({options, ...props}) {
  const clonedBaseStyles = cloneDeep(baseStyles);
  return <StripeCardCvcElement options={merge(clonedBaseStyles, options)} {...props} />;
}
