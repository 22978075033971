import {useEffect} from 'react';
import useFetch from '../../../../hooks/useFetch';
import {fetchCountries} from '../service';
import {handleErrorAlert} from '../../../../utils/handleErrorAlert';
import useAlert from '../../../alerts/useAlert';
import {useCountriesContext} from '../context/CountriesContext';

function useCountries(options = {}) {
  const {showAlert} = useAlert();
  const {
    state: {data, dataMapped},
    setData,
  } = useCountriesContext();
  const {run: fetchCountriesRequest} = useFetch(fetchCountries, {
    throwError: true,
  });

  async function fetcher() {
    try {
      const response = await fetchCountriesRequest(null);
      setData(response);
    } catch (error) {
      handleErrorAlert(error, showAlert);
    }
  }

  const getCountryByCode = (code) => {
    if (!code) {
      return null;
    }
    return data[code];
  };

  useEffect(() => {
    if (options.immediate && !data) {
      fetcher();
    }
  }, []);

  return {
    data,
    dataMapped,
    fetcher,
    getCountryByCode,
  };
}

export default useCountries;
