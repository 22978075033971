import {useContext} from 'react';
import createDataContext from '../../../../utils/createDataContext';
import createReducer from '../../../../utils/createReducer';
import {SET_DATA} from './actionTypes';
import {setData} from './actions';
import {initialState, setDataReducer} from './reducers';

const reducer = createReducer(initialState, {
  [SET_DATA]: setDataReducer,
});

export const {Context, Provider} = createDataContext(
  reducer,
  {
    setData,
  },
  initialState
);

export const useCountriesContext = function () {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useCountriesContext must be used within a SubscriberFieldsProvider`);
  }
  return context;
};
