import PropTypes from 'prop-types';
import Alert from '../../../../components/Alert';
import {API_REQUEST_ERROR_MESSAGE} from '../../../../utils/api/constants';

import styles from './Panel.module.scss';
import Spinner from '../../../../components/Spinner';

function PanelContent({children, loading, errorMessage}) {
  return (
    <div className={styles.content}>
      {errorMessage && (
        <Alert message={API_REQUEST_ERROR_MESSAGE} variant="error" persist details={errorMessage} />
      )}
      {loading ? <Spinner /> : children}
    </div>
  );
}

PanelContent.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
};

export default PanelContent;
