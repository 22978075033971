import {SHOW_ALERT, CLOSE_ALERT, CLOSE_ALL} from './actionTypes';

export function showAlert(dispatch) {
  return function (message, options = {}) {
    dispatch({
      type: SHOW_ALERT,
      payload: {message, ...options, id: options.id || Date.now()},
    });
    if (!options.preventScrollTop) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  };
}

export function closeAlert(dispatch) {
  return function (id) {
    dispatch({type: CLOSE_ALERT, payload: id});
  };
}

export function closeAllAlerts(dispatch) {
  return function () {
    dispatch({type: CLOSE_ALL});
  };
}
