import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useState} from 'react';
import ColorPicker from 'components/forms/ColorPicker';
import CheckBox from 'components/forms/Checkbox';
import styles from './ColorField.module.scss';

const ColorField = ({label, value, onChange, canBeTransparent, className}) => {
  const [isTransparent, setTransparent] = useState(value === 'transparent');

  return (
    <div>
      <div
        className={clsx(styles.colorFieldContainer, className)}
        style={{marginBottom: canBeTransparent ? 5 : 20}}
      >
        <div className={styles.toolbarFieldLabelContainer}>
          <span className={styles.toolbarFieldLabel}>{label}</span>
        </div>
        <ColorPicker onChange={onChange} value={value} isTransparent={isTransparent} />
      </div>
      {canBeTransparent && (
        <div style={{marginBottom: 20, display: 'flex', justifyContent: 'end', marginRight: 12}}>
          <CheckBox
            label="Transparent"
            middle
            onChange={() => {
              if (!isTransparent) {
                onChange('transparent');
                setTransparent(true);
              } else {
                onChange('#2B3254');
                setTransparent(false);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

ColorField.propTypes = {
  label: PropTypes.string.isRequired,
  canBeTransparent: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ColorField;
