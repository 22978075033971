import {useCallback, useState, useEffect} from 'react';
import useFetch from '../../../hooks/useFetch';
import {
  deleteNewsletterService,
  fetchOverviewService,
  fetchTrackingService,
  rescheduleNewsletterService,
  unscheduleService,
  updateTrackingService,
} from '../service';
import ROUTES from '../../../Router/routes';
import useRouter from '../../../hooks/useRouter';
import {handleErrorAlert} from '../../../utils/handleErrorAlert';
import useAlert from '../../alerts/useAlert';
import Overview from './Overview';
import useAlertOnRedirect from '../../../hooks/useAlertOnRedirect';
import {SCHEDULING_MODE} from '../constants';

function OverviewContainer() {
  const {history} = useRouter();
  const {showAlert} = useAlert();
  const [loading, setLoading] = useState(true);
  const [newsletters, setNewsletters] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [trackingOptions, setTrackingOptions] = useState();
  const [isTrackingOptionsOpen, setTrackingOptionsOpen] = useState(false);

  useAlertOnRedirect();

  const {run: fetchOverviewRequest} = useFetch(fetchOverviewService, {throwError: true});
  const {run: deleteNewsletterRequest} = useFetch(deleteNewsletterService);
  const {run: unscheduleNewsletterRequest} = useFetch(unscheduleService);
  const {run: rescheduleNewsletterRequet} = useFetch(rescheduleNewsletterService);
  const {run: fetchTrackingRequest} = useFetch(fetchTrackingService, {throwError: true});
  const {run: updateTrackingRequest} = useFetch(updateTrackingService, {throwError: true});

  const fetchNewsletters = useCallback(async function (params) {
    setLoading(true);
    try {
      const response = await fetchOverviewRequest(params);
      setNewsletters(response);
      if (params?.page) {
        setCurrentPage(params.page);
      }
    } catch (error) {
      handleErrorAlert(error, showAlert);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchTrackingOptions = useCallback(async function () {
    try {
      const response = await fetchTrackingRequest();
      setTrackingOptions(response);
    } catch (error) {
      handleErrorAlert(error, showAlert);
    }
  }, []);

  useEffect(
    function () {
      fetchNewsletters();
      fetchTrackingOptions();
    },
    [fetchOverviewRequest]
  );

  const handleSearch = (text) => {
    fetchNewsletters({
      query: text,
      page: 1,
    });
  };

  const handleMarkDraft = async (id) => {
    history.push(`${ROUTES.newsletters.create}?fromDraft=${id}`);
  };

  const handleDelete = async (id) => {
    await deleteNewsletterRequest(id);
    await fetchNewsletters({page: currentPage});
  };

  const handleUnschedule = async (id) => {
    await unscheduleNewsletterRequest(id);
    await fetchNewsletters({page: currentPage});
  };

  const handleReschedule = async (id, timestamp) => {
    await rescheduleNewsletterRequet({
      bulkMailId: id,
      transmissionTiming: {
        transmissionMode: SCHEDULING_MODE.SCHEDULED,
        transmissionTimestamp: timestamp,
      },
    });
    await fetchNewsletters();
  };

  const handleTrackingOptionsUpdate = async (options) => {
    await updateTrackingRequest(options);
    await fetchTrackingOptions();
  };

  const handleActionBtnClick = (route) => () => {
    if (route === ROUTES.newsletters.templateSelect) {
      return history.push({
        pathname: route,
        state: {
          back: ROUTES.newsletters.create,
        },
      });
    }

    return history.push({pathname: route});
  };

  const handlePageChange = useCallback(
    async function (page) {
      return fetchNewsletters({page});
    },
    [fetchNewsletters]
  );

  return (
    <Overview
      newsletters={newsletters}
      loading={loading}
      onSearch={handleSearch}
      onDelete={handleDelete}
      onMarkDraft={handleMarkDraft}
      onActionBtnClick={handleActionBtnClick}
      onPageChange={handlePageChange}
      onUnschedule={handleUnschedule}
      handleReschedule={handleReschedule}
      isTrackingOptionsOpen={isTrackingOptionsOpen}
      setTrackingOptionsOpen={setTrackingOptionsOpen}
      trackingOptions={trackingOptions}
      handleTrackingOptionsUpdate={handleTrackingOptionsUpdate}
    />
  );
}

export default OverviewContainer;
