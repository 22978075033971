import {get, put, post, del} from '../../utils/api';
import {ENDPOINTS} from './constants';

export async function fetchAutomationOverviewService(apiSettings) {
  return get(ENDPOINTS.automationOverview, null, apiSettings);
}

export async function fetchAutomationToolsetService(apiSettings) {
  return get(ENDPOINTS.automationToolset, null, apiSettings);
}

export async function fetchAutomationConfigService(apiSettings, id) {
  return get(ENDPOINTS.getAutomationConfig(id), null, apiSettings);
}

export async function saveAutomationConfigService(apiSettings, data) {
  return post(ENDPOINTS.automationConfig, data, apiSettings);
}

export async function updateAutomationConfigService(apiSettings, data) {
  return put(ENDPOINTS.automationConfig, data, apiSettings);
}

export async function updateActiveStatusService(apiSettings, data) {
  return put(ENDPOINTS.updateActiveStatus(data), null, apiSettings);
}

export async function deleteAutomationsService(apiSettings, data) {
  return del(ENDPOINTS.getAutomationConfig(data), null, apiSettings);
}

export async function checkAutomationReferencesService(apiSettings, id) {
  return get(ENDPOINTS.checkAutomationReferences(id), null, apiSettings);
}

export async function fetchAutomationInitiators(apiSettings, automationId) {
  return get(ENDPOINTS.getAutomationInitiators(automationId), null, apiSettings);
}

export async function createAutomationRevisionService(apiSettings, {automation, id}) {
  return post(ENDPOINTS.newAutomationRevision(id), automation, apiSettings);
}

export async function createAutomationRevisionWithRedirectedRefsService(
  apiSettings,
  {automation, id}
) {
  return post(ENDPOINTS.newAutomationRevisionWithRedirect(id), automation, apiSettings);
}
