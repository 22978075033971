import PropTypes from 'prop-types';
import styles from './BillingDetails.module.scss';
import useCountries from '../../domain/settings/countries/hooks/useCountries';

function BillingDetails({data}) {
  const {data: countries, getCountryByCode} = useCountries({immediate: true});

  if (!data || !countries) {
    return null;
  }

  function formatAddress(values) {
    const {address1, address2, city, countryCode, province, zipCode} = values;
    const combinedAddress = [];

    if (address1) {
      combinedAddress.push(address1);
    }

    if (address2) {
      combinedAddress.push(address2);
    }

    if (zipCode) {
      combinedAddress.push(zipCode);
    }

    if (city) {
      if (zipCode) {
        const zipCodeIndex = combinedAddress.findIndex((el) => el === zipCode);
        combinedAddress.splice(zipCodeIndex, 1, `${zipCode} ${city}`);
      } else {
        combinedAddress.push(city);
      }
    }

    if (province) {
      combinedAddress.push(province);
    }

    if (countryCode) {
      combinedAddress.push(getCountryByCode(countryCode));
    }

    return combinedAddress.join(', ');
  }

  const {name, vatNumber} = data;

  return (
    <div className={styles.details}>
      <div className={styles.row}>
        <div className={styles.cell}>
          <span className={styles.title}>Name / Company</span>
          <span className={styles.text}>{name}</span>
        </div>
        {vatNumber && (
          <div className={styles.cell}>
            <span className={styles.title}>VAT ID</span>
            <span className={styles.text}>{vatNumber}</span>
          </div>
        )}
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <span className={styles.title}>Address</span>
          <span className={styles.text}>{formatAddress(data)}</span>
        </div>
      </div>
    </div>
  );
}

BillingDetails.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    vatNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    countryCode: PropTypes.string,
    province: PropTypes.string,
    zipCode: PropTypes.string,
  }),
};

export default BillingDetails;
