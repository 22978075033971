export const STEPS = {
  initial: 'initial',
  billing: 'billing',
  plans: 'plans',
  checkout: 'checkout',
  success: 'success',
};

export const STEPS_SETTINGS = [
  {
    key: STEPS.plans,
    label: 'Step 1: Select Plan',
    icon: 'plan',
  },
  {
    key: STEPS.billing,
    label: 'Step 2: Billing',
    icon: 'payment',
  },
  {
    key: STEPS.checkout,
    label: 'Step 3: Check Out',
    icon: 'payment',
  },
];

export const STEPS_SETTINGS_EXISTING_BILLING = [
  {
    key: STEPS.plans,
    label: 'Step 1: Select Plan',
    icon: 'plan',
  },
  {
    key: STEPS.checkout,
    label: 'Step 2: Check Out',
    icon: 'payment',
  },
];
