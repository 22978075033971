import {useContext} from 'react';
import createDataContext from '../../../../utils/createDataContext';
import createReducer from '../../../../utils/createReducer';
import {
  SET_FETCH_LOADING,
  SET_DATA,
  ADD_DATA_ITEM,
  REPLACE_DATA_ITEM,
} from '../../subscriber-fields/context/actionTypes';
import {
  setFetchLoading,
  setData,
  addDataItem,
  replaceDataItem,
} from '../../subscriber-fields/context/actions';
import {initialState, setFetchLoadingReducer, setDataReducer} from './reducers';
import {addDataItemReducer, replaceDataItemReducer} from '../../subscriber-fields/context/reducers';

const reducer = createReducer(initialState, {
  [SET_FETCH_LOADING]: setFetchLoadingReducer,
  [SET_DATA]: setDataReducer,
  [ADD_DATA_ITEM]: addDataItemReducer,
  [REPLACE_DATA_ITEM]: replaceDataItemReducer,
});

export const {Context, Provider} = createDataContext(
  reducer,
  {
    setFetchLoading,
    setData,
    addDataItem,
    replaceDataItem,
  },
  initialState
);

export const useSegmentsContext = function () {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useSegmentsContext must be used within a SegmentsProvider`);
  }
  return context;
};
