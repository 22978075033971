const AlignRightIcon = (props) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="11" width="1" height="13" fill="currentColor" />
    <rect x="9" y="3" width="2" height="9" transform="rotate(90 9 3)" fill="currentColor" />
    <rect x="9" y="8" width="2" height="5" transform="rotate(90 9 8)" fill="currentColor" />
  </svg>
);

export default AlignRightIcon;
