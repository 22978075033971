import PropTypes from 'prop-types';
import cx from 'clsx';
import Spinner from '../Spinner';
import styles from './Table.module.scss';

function Table({
  headerCells,
  noBorder,
  loading,
  caption,
  children,
  data,
  showHead = true,
  className,
}) {
  function renderRows() {
    if (loading) {
      return (
        <tr className={styles.placeholder}>
          <td colSpan="5">
            <Spinner />
          </td>
        </tr>
      );
    }

    if (data?.length) {
      return data.map((row, index) => <tr key={row.id}>{children(row, index)}</tr>);
    }

    if (children) {
      return children;
    }

    return (
      <tr className={styles.placeholder}>
        <td colSpan="5">No data.</td>
      </tr>
    );
  }

  return (
    <table
      className={cx(
        styles.table,
        {
          [styles.noBorder]: noBorder,
        },
        className
      )}
    >
      {caption && <caption>{caption}</caption>}
      {showHead && (
        <thead>
          <tr>
            {typeof headerCells === 'function'
              ? headerCells
              : headerCells.map((cell, i) => <th key={i}>{cell}</th>)}
          </tr>
        </thead>
      )}
      <tbody>{renderRows()}</tbody>
    </table>
  );
}

Table.propTypes = {
  headerCells: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  caption: PropTypes.string,
  loading: PropTypes.bool,
  noBorder: PropTypes.bool,
  className: PropTypes.string,
};

export default Table;
