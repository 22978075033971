import {Link} from 'react-router-dom';
import ROUTES from '../../../Router/routes';

import styles from '../auth.module.scss';

function FooterText() {
  return (
    <p className={styles.text}>
      Remember your password?{' '}
      <Link to={ROUTES.auth.login} className={styles.link}>
        Log In
      </Link>{' '}
      here.
    </p>
  );
}

export default FooterText;
