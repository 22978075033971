import {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';

import styles from './Pagination.module.scss';

function range(start, stop, step = 1) {
  return Array.from({length: (stop - start) / step + 1}, (_, i) => start + i * step);
}

function Pagination({
  onPageChange,
  totalPagesFormatted,
  page = 1,
  totalPages = 1,
  siblingCount = 1,
}) {
  const [currentPage, setCurrentPage] = useState(page);

  if (totalPages === 0) {
    return null;
  }

  async function goToPage(newPage) {
    if (onPageChange) {
      await onPageChange(newPage);
      setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
    }
  }

  function handleClick(newPage) {
    goToPage(newPage);
  }

  function handleNext() {
    goToPage(currentPage + 1);
  }

  function handlePrev() {
    goToPage(currentPage - 1);
  }

  function generatePages() {
    const spillCount = 2;
    const defaultStartPage = 2;
    const sidesCount = 2;
    const minimumNumber = 3;
    const totalNumbers = siblingCount * sidesCount + minimumNumber;
    const totalBlocks = totalNumbers + spillCount;

    if (totalPages < totalBlocks) {
      return range(1, totalPages);
    }

    let pageRange = [];

    const leftBound = currentPage - siblingCount;
    const rightBound = currentPage + siblingCount;
    const beforeLastPage = totalPages - 1;

    const startPage = leftBound > defaultStartPage ? leftBound : defaultStartPage;
    const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

    pageRange = range(startPage, endPage);

    const singleSpillOffset = totalNumbers - (pageRange.length + 1);

    const leftSpill = startPage > defaultStartPage;
    const rightSpill = endPage < beforeLastPage;

    if (leftSpill && !rightSpill) {
      const extraPages = range(startPage - singleSpillOffset, startPage - 1);
      pageRange = ['ellipsis', ...extraPages, ...pageRange];
    } else if (!leftSpill && rightSpill) {
      const extraPages = range(endPage + 1, endPage + singleSpillOffset);
      pageRange = [...pageRange, ...extraPages, 'ellipsis'];
    } else {
      pageRange = ['ellipsis', ...pageRange, 'ellipsis'];
    }

    return [1, ...pageRange, totalPagesFormatted];
  }

  function renderPages() {
    return generatePages().map((item, i) => {
      let pageItem;

      if (item === 'ellipsis') {
        pageItem = '...';
      } else {
        pageItem = (
          <button type="button" onClick={() => handleClick(item)}>
            {item}
          </button>
        );
      }

      return (
        <li key={i} className={cx(styles.item, {[styles.selected]: currentPage === item})}>
          {pageItem}
        </li>
      );
    });
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.pagination}>
        <div className={styles.inner}>
          <ul className={styles.list}>
            <li className={`${styles.arrow} ${styles.prev}`}>
              <button type="button" onClick={handlePrev} disabled={currentPage === 1}>
                Prev
              </button>
            </li>
            {renderPages()}
            <li className={`${styles.arrow} ${styles.next}`}>
              <button type="button" onClick={handleNext} disabled={currentPage === totalPages}>
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  siblingCount: PropTypes.number,
};

export default Pagination;
