import React from 'react';
import {Link} from 'react-router-dom';
import uniq from 'lodash.uniq';
import SelectField from './SelectField';
import {SubscriptionFormBuilderContext} from '../../context';
import Button from '../../../../components/Button';
import {addTag} from '../../context/actions';
import ROUTES from '../../../../Router/routes';

const AddNewTag = () => {
  const {formState, dispatch, setShowAddNewTagModal, subscriberTags} = React.useContext(
    SubscriptionFormBuilderContext
  );

  const subscriberTagsOptions = React.useMemo(() => {
    const existedsubscriberTagsNames = formState.tags ? formState.tags.map(({name}) => name) : [];

    return subscriberTags
      .filter(({name}) => {
        return !existedsubscriberTagsNames.includes(name);
      })
      .map(({name, id}) => ({label: name, value: name, id}));
  }, [formState, subscriberTags]);

  const [newTagName, setNewTagName] = React.useState('');
  const [errors, setErrors] = React.useState(() => []);

  const handleChangeName = React.useCallback(
    (value) => {
      setNewTagName(value);
    },
    [setNewTagName]
  );

  const handleConfirm = React.useCallback(() => {
    if (!newTagName) {
      setErrors((prevErrors) => uniq([...prevErrors, 'name']));
    }
    const id = subscriberTagsOptions.find((i) => i.value === newTagName)?.id;

    dispatch(addTag(newTagName, id));

    setShowAddNewTagModal(false);
  }, [dispatch, newTagName, setErrors, setShowAddNewTagModal]);

  React.useEffect(() => {
    if (newTagName) {
      setErrors([]);
    }
  }, [newTagName, setErrors]);

  return (
    <>
      {subscriberTagsOptions.length ? (
        <>
          <SelectField
            label="Select tag"
            options={subscriberTagsOptions}
            error={errors.includes('name') && 'Tag is not selected'}
            onChange={handleChangeName}
            value={newTagName}
          />
          <Button
            color="green"
            variant="filled"
            size="small"
            onClick={handleConfirm}
            full
            disabled={!newTagName}
          >
            Confirm
          </Button>
        </>
      ) : (
        <>
          <div style={{marginBottom: 20, marginTop: -20, color: '#676c85', textAlign: 'center'}}>
            {subscriberTags.length
              ? 'There are no other tags to be added'
              : 'You should create a tag first'}
          </div>
          <Button
            as={Link}
            color="green"
            variant="filled"
            size="small"
            to={{pathname: ROUTES.settings.subscriberFields, query: 'createTag'}}
            full
          >
            Create a new tag
          </Button>
        </>
      )}
      <div style={{marginTop: 10}}>
        <Button
          color="grey"
          variant="outlined"
          size="small"
          onClick={() => setShowAddNewTagModal(false)}
          full
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default AddNewTag;
