import {v4 as uuidv4} from 'uuid';

export const generateId = () => uuidv4();

const newEmailIdKey = 'newEmailId';

export const generateEmailIdLocally = () => {
  let newEmailId = localStorage.getItem(newEmailIdKey);

  if (!newEmailId) {
    newEmailId = generateId();
    localStorage.setItem(newEmailIdKey, newEmailId);
  }

  return newEmailId;
};

export const clearLocallyGeneratedEmailId = () => {
  localStorage.removeItem(newEmailIdKey);
};
