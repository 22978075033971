import PropTypes from 'prop-types';
import {Controller, useForm} from 'react-hook-form';
import DragUpload from '../../../components/forms/DragUpload';
import {REQUIRED_FIELD_MESSAGE} from '../../../components/forms/constants';
import styles from '../ImportSubscribers.module.scss';
import CheckBox from '../../../components/forms/Checkbox';
import FormRow from '../../../components/forms/FormRow/FormRow';
import NativeSelect from '../../../components/forms/NativeSelect';
import Button from '../../../components/Button';
import {subscriberStatuses} from '../../../utils/constants';

const delimiterOptions = [
  {value: ',', label: 'Comma'},
  {value: ';', label: 'Semicolon'},
  {value: '\t', label: 'Tab'},
  {value: ' ', label: 'Space'},
];

function ImportForm({onSubmit, isLocked}) {
  const {formState, errors, control, setError, handleSubmit, register} = useForm({
    mode: 'onChange',
    defaultValues: {
      skipFirstRow: false,
      subscriberStatus: 'CONFIRMED',
      delimiter: ',',
    },
  });

  async function handleFormSubmit(values) {
    return onSubmit(values, setError);
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Controller
        name="file"
        error={errors.file}
        control={control}
        setError={setError}
        rules={{required: REQUIRED_FIELD_MESSAGE}}
        defaultValue={null}
        as={DragUpload}
        options={{
          noClick: true,
          accept: '.csv',
        }}
        helpText="Drag and Drop a CSV file with your subscribers"
      />
      <div className={styles.settings}>
        <div className="form">
          <strong className={styles.subTitle}>Settings</strong>
          <CheckBox
            name="skipFirstRow"
            id="first-row-header"
            label="The first row contains field names"
            inputRef={register}
          />
          <FormRow>
            <NativeSelect
              name="subscriberStatus"
              options={subscriberStatuses}
              label="Select Status of Subscribers"
              small
              full
              inputRef={register}
            />
            <NativeSelect
              name="delimiter"
              options={delimiterOptions}
              label="File Delimiter"
              small
              full
              inputRef={register}
            />
          </FormRow>
        </div>
      </div>
      <Button
        type="submit"
        variant="filled"
        color="green"
        widePadding
        loading={formState.isSubmitting}
        disabled={isLocked || formState.isSubmitting}
      >
        Continue
      </Button>
    </form>
  );
}

ImportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
};

export default ImportForm;
