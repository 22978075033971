import {Controller} from 'react-hook-form';
import Input from '../../../../../components/forms/Input';
import Select from '../../../../../components/forms/Select';
import CheckBox from '../../../../../components/forms/Checkbox';
import TokenizedInput from '../../../../../components/forms/TokenizedInput';
import styles from './editors.module.scss';
import {REQUIRED_FIELD_MESSAGE} from '../../../../../components/forms/constants';

export const TextField = ({name, label, placeholder, error, register}) => (
  <Input
    {...{name, label, placeholder, error}}
    inputRef={register({required: REQUIRED_FIELD_MESSAGE})}
    noBottomSpace
  />
);

export const SelectField = ({name, label, error, control, options}) => {
  return (
    <div className={styles.selectFieldWrapper}>
      <Controller
        {...{name, error, control}}
        rules={{required: REQUIRED_FIELD_MESSAGE}}
        as={Select}
        {...{label, options}}
        noBottomSpace
      />
    </div>
  );
};

export const CheckboxField = ({id, name, label, error, register}) => {
  return (
    <CheckBox
      id={id}
      name={name}
      label={label}
      error={error}
      inputRef={register}
      className={[styles.checkboxField, styles.checkboxFieldBlock].join(' ')}
    />
  );
};

export const OptionValuesField = (props) => {
  const {dataType} = props;

  if (dataType === 'RADIO' || dataType === 'CHECKBOX' || dataType === 'DROPDOWN') {
    return (
      <div className={styles.optionValues}>
        <Controller as={TokenizedInput} {...props} dataType={dataType} />
      </div>
    );
  }

  return null;
};
