import PropTypes from 'prop-types';

import Step from './Step';
import ImportForm from './ImportForm';
import MappingForm from './MappingForm';
import ImportSuccess from './ImportSuccess';
import {useImportSubscribersContext} from '../useImportSubscribersContext';
import {stepsSettings} from '../constants';
import StepSummary from './StepSummary';
import Spinner from '../../../components/Spinner';
import {LANDING_URL} from '../../../utils/constants';

import styles from '../ImportSubscribers.module.scss';

function ImportSubscribers({onImport, planLimit, onMappingSubmit, onRequestClose, isLocked}) {
  const {state} = useImportSubscribersContext();
  const [importSettings, mappingSettings, successSettings] = stepsSettings;
  const importStepData = state.steps[importSettings.key].data;
  const mappingStepData = state.steps[mappingSettings.key].data;

  return (
    <div className={styles.main}>
      <div className={styles.inner}>
        <div>
          <strong className={styles.mainTitle}>Import Subscribers</strong>
          <p className="text text-semibold">
            Import multiple subscribers at once by uploading a .csv file (10MB file size limit). For
            more information, please read our{' '}
            <a
              href={`${LANDING_URL}/guides/import-subscribers-from-a-file`}
              title="Click to find out more"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              Import Subscribers Guide
            </a>
            .
          </p>
        </div>
        {!planLimit ? (
          <Spinner />
        ) : (
          <ul className={styles.list}>
            <Step
              active={state.activeStep === importSettings.key}
              completed={state.steps[importSettings.key].completed}
              title={importSettings.label}
              ico={importSettings.icon}
              description={importSettings.description(planLimit)}
              error={state.steps[importSettings.key].error}
              summary={
                <StepSummary title={importStepData.file?.name}>
                  <p className="text text-small text-medium">
                    The first row contains field names, Status: {importStepData.subscriberStatus},
                    File delimiter: {importStepData.delimiter}
                  </p>
                </StepSummary>
              }
            >
              <ImportForm onSubmit={onImport} isLocked={isLocked} />
            </Step>
            <Step
              active={state.activeStep === mappingSettings.key}
              completed={state.steps[mappingSettings.key].completed}
              title={mappingSettings.label}
              ico={mappingSettings.icon}
              description={mappingSettings.description}
              error={state.steps[mappingSettings.key].error}
              summary={
                <StepSummary title="Imported fields:">
                  <p className="text text-small text-medium">
                    {mappingStepData.fieldMapping &&
                      Object.values(mappingStepData.fieldMapping).join(', ')}
                  </p>
                </StepSummary>
              }
            >
              <MappingForm onSubmit={onMappingSubmit} data={importStepData} />
            </Step>
            <Step
              active={state.activeStep === successSettings.key}
              title={successSettings.label}
              ico={successSettings.icon}
            >
              <ImportSuccess onRequestClose={onRequestClose} />
            </Step>
          </ul>
        )}
      </div>
    </div>
  );
}

ImportSubscribers.propTypes = {
  onImport: PropTypes.func.isRequired,
  onMappingSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  planLimit: PropTypes.string,
  isLocked: PropTypes.bool,
};

export default ImportSubscribers;
