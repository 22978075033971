import {Helmet} from 'react-helmet';
import ConfirmEmailContainer from './ConfirmEmailContainer';

function ConfirmEmailPage() {
  return (
    <div>
      <Helmet title="Email Confirmation" />
      <ConfirmEmailContainer />
    </div>
  );
}

export default ConfirmEmailPage;
