import React from 'react';

import ToolbarSection from './ToolbarSection';

import SubscriptionColorField from './SubscriptionColorField';
import SizeField from './SizeField';
import TextField from './TextField';
import {SubscriptionFormBuilderContext} from '../../context';
import styles from './Toolbar.module.scss';
import {
  BUILDER_MAX_BORDER_RADIUS,
  BUILDER_MAX_BORDER_SIZE,
  BUILDER_MIN_FONT_SIZE,
  BUILDER_MAX_FONT_SIZE,
  FONT_WEIGHT_OPTIONS,
} from '../../constants';
import SelectField from './SelectField';
import WidthAdjustmentField from './WidthAdjustmentField';

const ButtonStyles = () => {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);

  return (
    <ToolbarSection sectionName="Button Styles" className={styles.toolbarContent}>
      <TextField label="Label" paramPath="jsonConfig.button.text.value" />
      <SubscriptionColorField label="Text Color" paramPath="jsonConfig.button.text.font.color" />
      <SizeField
        label="Font Size"
        paramPath="jsonConfig.button.text.font.fontSize"
        min={BUILDER_MIN_FONT_SIZE}
        max={BUILDER_MAX_FONT_SIZE}
      />
      <SelectField
        label="Font Weight"
        paramPath="jsonConfig.button.text.font.fontWeight"
        options={FONT_WEIGHT_OPTIONS}
      />
      <SubscriptionColorField
        label="Background Color"
        paramPath="jsonConfig.button.backgroundColor"
      />
      <SizeField
        label="Border Radius"
        paramPath="jsonConfig.button.border.radius"
        max={BUILDER_MAX_BORDER_RADIUS}
      />
      <SizeField
        label="Border Size"
        paramPath="jsonConfig.button.border.size"
        max={BUILDER_MAX_BORDER_SIZE}
      />
      {formState.jsonConfig.button.border.size > 0 ? (
        <SubscriptionColorField label="Border Color" paramPath="jsonConfig.button.border.color" />
      ) : null}
      <WidthAdjustmentField label="Button Width" paramPath="jsonConfig.button.width" />
    </ToolbarSection>
  );
};

export default ButtonStyles;
