import PropTypes from 'prop-types';
import img from '../../../img/icon-warning.svg';
import ModalClosableContent from '../../../components/Modal/ModalClosableContent';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import styles from './AuditSubscribersBulkModal.module.scss';

function AuditSubscribersBulkModal({isOpen, onRequestClose}) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalClosableContent
        title="Your account is currently under review"
        onRequestClose={onRequestClose}
        img={<img src={img} alt="not allowed action" className={styles.img} loading="lazy" />}
        hideCloseButton
      >
        <p className="text-centered">
          The selected action will be available again as soon as the review has been completed
          successfully. Thank you for your understanding!
        </p>
        <div className="text-centered">
          <Button variant="filled" color="green" widePadding onClick={onRequestClose}>
            OK
          </Button>
        </div>
      </ModalClosableContent>
    </Modal>
  );
}

AuditSubscribersBulkModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
};

AuditSubscribersBulkModal.defaultProps = {
  isOpen: true,
};

export default AuditSubscribersBulkModal;
