import {TRIGGER_TYPE} from '../../CustomNodes/constants';

const triggerOptionInfoByType = {
  [TRIGGER_TYPE.FORM_SUBMISSION]: {
    title: 'Form Trigger - Select Form',
    subtitle: "Select 'Any Form' for broad use or choose a specific form for targeted automation.",
    description:
      'Starts when a subscriber signs up throught the selected form and confirms their subscription',
  },
  [TRIGGER_TYPE.GENERAL_FORM_SUBMISSION]: {
    title: 'Form Trigger - Select Form',
    subtitle: "Select 'Any Form' for broad use or choose a specific form for targeted automation.",
    description:
      'Starts when a subscriber signs up throught the selected form and confirms their subscription',
  },
  [TRIGGER_TYPE.DATE_ANNIVERSARY]: {
    title: 'Date Anniversary Trigger - Select Date Field',
    subtitle:
      "Select 'Any Date Field' for broad use or choose a specific date field for targeted automation.",
    description: 'Triggers each year on the selected date field of a subscriber',
  },
  [TRIGGER_TYPE.TAG_ADDED]: {
    title: 'Tag is Added Trigger - Select Tag',
    subtitle: "Select 'Any Tag' for broad use or choose a specific tag for targeted automation.",
    description: 'Begins when the selected tag is added to a subscriber',
  },
  [TRIGGER_TYPE.TAG_REMOVED]: {
    title: 'Tag is Removed Trigger - Select Tag',
    subtitle: "Select 'Any Tag' for broad use or choose a specific tag for targeted automation.",
    description: 'Triggers when the selected tag is removed from a subscriber',
  },
  [TRIGGER_TYPE.FIELD_ADDED]: {
    title: 'Field is Added Trigger - Select Field',
    subtitle:
      "Select 'Any Field' for broad use or choose a specific field for targeted automation.",
    description: 'Starts when the selected field is added to a subscriber',
  },
  [TRIGGER_TYPE.FIELD_REMOVED]: {
    title: 'Field is Removed Trigger - Select Field',
    subtitle:
      "Select 'Any Field' for broad use or choose a specific field for targeted automation.",
    description: 'Begins when the selected field is removed from a subscriber',
  },
  [TRIGGER_TYPE.FIELD_UPDATED]: {
    title: 'Field is Updated Trigger - Select Field',
    subtitle:
      "Select 'Any Field' for broad use or choose a specific field for targeted automation.",
    description: 'Activates when the selected field of a subscriber is updated',
  },
};

export default triggerOptionInfoByType;
