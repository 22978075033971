import {useEffect} from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './Alert.module.scss';
import useAlert from '../../domain/alerts/useAlert';

const AUTO_HIDE_DURATION = 5000;

function Alert({
  details,
  autoHideDuration = AUTO_HIDE_DURATION,
  id,
  action,
  dismiss,
  persist,
  message,
  variant,
  children,
  className,
}) {
  const {closeAlert} = useAlert();
  function handleClose() {
    closeAlert(id);
  }

  useEffect(function () {
    if (!dismiss && !persist) {
      const timer = setTimeout(function () {
        handleClose();
      }, autoHideDuration);

      return function () {
        clearTimeout(timer);
      };
    }

    return undefined;
  }, []);

  function renderAction() {
    if (action) {
      return typeof action === 'function' ? action(id) : action;
    }

    return (
      <button type="button" className={styles.close} onClick={handleClose} title="Click to close" />
    );
  }

  return (
    <div className={cx(styles.alert, {[styles[variant]]: variant}, className)}>
      <div className={styles.inner}>
        <div className={styles.ico} />
        <div className={styles.body}>
          <strong className={styles.bodyMainText}>{message}</strong>
          {details && (
            <div className={styles.details}>
              {variant === 'error' && <span className={styles.detailsType}>Error Detail:</span>}
              <span className={styles.detailsText}>{details}</span>
            </div>
          )}
          {children}
        </div>
        {dismiss && !persist && renderAction()}
      </div>
    </div>
  );
}

Alert.defaultProps = {
  variant: 'info',
};

Alert.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  message: PropTypes.string,
  details: PropTypes.node,
  children: PropTypes.node,
  dismiss: PropTypes.bool,
  persist: PropTypes.bool,
  action: PropTypes.node,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.oneOf(['info', 'success', 'error']),
};

export default Alert;
