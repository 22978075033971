import {Switch, Route} from 'react-router-dom';
import ROUTES from '../../Router/routes';
import PageNotFound from '../../Router/PageNotFound';
import SubscriptionFormSelectPage from './pages/SubscriptionFormSelectPage';
import SubscriptionFormBuilder from './pages/SubscriptionFormBuilder';
import ShareNewFormPage from './pages/ShareNewFormPage';
import SubscriptionFormListPageContainer from './pages/SubscriptionFormListPage/SubscriptionFormListPageContainer';

function SubscriptionFormRouter() {
  return (
    <Switch>
      <Route path={ROUTES.signupForms.index} exact>
        <SubscriptionFormListPageContainer />
      </Route>
      <Route path={ROUTES.signupForms.createTypeSelection} exact>
        <SubscriptionFormSelectPage />
      </Route>
      <Route path={ROUTES.signupForms.create} exact>
        <SubscriptionFormBuilder />
      </Route>
      <Route path={ROUTES.signupForms.edit} exact>
        <SubscriptionFormBuilder />
      </Route>
      <Route path={ROUTES.signupForms.share}>
        <ShareNewFormPage />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default SubscriptionFormRouter;
