import {useEffect, useState} from 'react';
import {Menu, MenuButton, MenuPopover, MenuItem} from '@reach/menu-button';
import {positionRight} from '@reach/popover';
import cx from 'clsx';
import {REPORT_INTERVALS} from '../constants';
import {capitalize} from '../../../utils/strings';
import styles from './IntervalPicker.module.scss';
import stylesDropdown from '../../../components/ButtonDropdown/ButtonDropdown.module.scss';

function IntervalPicker({classes, handleChange}) {
  const [interval, setInterval] = useState(REPORT_INTERVALS[0]);

  useEffect(() => {
    handleChange({interval});
  }, [interval]);

  return (
    <Menu>
      <MenuButton className={cx(styles.main, classes)}>
        <span className={styles.label}>Group by:</span>
        <span>{capitalize(interval.toLowerCase())}</span>
        <span className={stylesDropdown.arr} aria-hidden />
      </MenuButton>
      <MenuPopover className={cx(stylesDropdown.options, styles.options)} position={positionRight}>
        {REPORT_INTERVALS.map((val) => (
          <MenuItem key={val} className={stylesDropdown.option} onSelect={() => setInterval(val)}>
            {capitalize(val.toLowerCase())}
          </MenuItem>
        ))}
      </MenuPopover>
    </Menu>
  );
}

export default IntervalPicker;
