import PropTypes from 'prop-types';
import {
  useCreateAutomationRevision,
  useForceUpdateAutomation,
} from 'domain/automation/hooks/services';
import {AutomationReferencesError} from 'domain/automation/errors/errors';
import {
  useAutomationErrorHandler,
  useAutomationRevisionErrorHandler,
} from 'domain/automation/hooks/handleError';
import AlertModal from '../AlertModal';

const AutomationWithActiveUsersAlert = ({isOpen, onClose, onSuccess, onError}) => {
  const createAutomationRevisionService = useCreateAutomationRevision();
  const useForceUpdateAutomationService = useForceUpdateAutomation();
  const handleAutomationError = useAutomationErrorHandler();
  const handleAutomationRevisionError = useAutomationRevisionErrorHandler();

  const handleCreateRevision = async () => {
    try {
      await createAutomationRevisionService.handler();
      onSuccess();
    } catch (error) {
      if (error instanceof AutomationReferencesError) {
        onError(error);
      } else {
        handleAutomationRevisionError(error);
        onError(error);
      }
    }
  };

  const handleForceUpdate = async () => {
    try {
      await useForceUpdateAutomationService.handler();
      onSuccess();
    } catch (error) {
      handleAutomationError(error);
      onError(error);
    }
  };

  return (
    <AlertModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Are you sure?"
      agreeColor="green"
      agreeText="Save as New Revision"
      onAgree={handleCreateRevision}
      agreeDisabled={
        createAutomationRevisionService.loading || useForceUpdateAutomationService.loading
      }
      agreeLoading={createAutomationRevisionService.loading}
      cancelColor="green"
      cancelText="Update Automation"
      onCancel={handleForceUpdate}
      cancelDisabled={
        createAutomationRevisionService.loading || useForceUpdateAutomationService.loading
      }
      cancelLoading={useForceUpdateAutomationService.loading}
    >
      <span>
        You&apos;re editing an automation with active subscribers. Changes might disrupt their
        journey or even remove subscribers from the automation.
        <br />
        You can either directly apply your changes to the automation <b>(Update Automation)</b>, or
        preserve the current setup for existing subscribers and activate your changes for new ones{' '}
        <b>(Save as New Revision)</b>.
      </span>
    </AlertModal>
  );
};

AutomationWithActiveUsersAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AutomationWithActiveUsersAlert;
