import React from 'react';

import ToolbarSection from './ToolbarSection';
import CheckboxField from './CheckboxField';
import styles from './Toolbar.module.scss';
import {SubscriptionFormBuilderContext} from '../../context';

const PopupWidgetCheckbox = ({isSection = false}) => {
  const {formState} = React.useContext(SubscriptionFormBuilderContext);

  const titleProps = isSection ? {sectionName: 'Widget Settings'} : {title: 'Widget Settings'};

  return (
    formState.type === 'POPUP' && (
      <>
        <ToolbarSection {...titleProps} className={styles.toolbarContent}>
          <CheckboxField paramPath="jsonConfig.isPopupWidget" label="Manually toggle Popup" />
          <CheckboxField paramPath="jsonConfig.isExitIntentPopup" label="Exit Intent Popup" />
        </ToolbarSection>
      </>
    )
  );
};

export default PopupWidgetCheckbox;
