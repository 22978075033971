import img2x from '../../img/laptop-on-table-with-maildroppa-app-opened@2x.jpg';
import img3x from '../../img/laptop-on-table-with-maildroppa-app-opened@3x.jpg';
import img from '../../img/laptop-on-table-with-maildroppa-app-opened.jpg';
import webp2x from '../../img/laptop-on-table-with-maildroppa-app-opened@2x.webp';
import webp3x from '../../img/laptop-on-table-with-maildroppa-app-opened@3x.webp';
import webp from '../../img/laptop-on-table-with-maildroppa-app-opened.webp';

import styles from './auth.module.scss';

function Hero() {
  return (
    <div className={styles.img}>
      <picture>
        <source srcSet={`${webp} 1x, ${webp2x} 2x, ${webp3x} 3x`} type="image/webp" />
        <img
          src={img}
          srcSet={`${img2x} 2x, ${img3x} 3x`}
          width="450"
          height="700"
          alt="Laptop on the table with maildroppa app opened"
        />
      </picture>
    </div>
  );
}

export default Hero;
