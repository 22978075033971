import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import styles from './TextAlignmentInput.module.scss';

const TextAlignmentInputField = forwardRef(({children, fieldValue, value, name, ...props}, ref) => (
  <label className={styles.container}>
    <input
      {...props}
      name={name}
      type="radio"
      value={fieldValue}
      checked={fieldValue === value}
      className={styles.input}
      ref={ref}
    />
    {children}
  </label>
));

TextAlignmentInputField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fieldValue: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default TextAlignmentInputField;
